.sm-newinvoice-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 0px 20px;
  border-radius: 3px;
}

.sm-row-cont {
  display: flex;
  align-items: center;
  margin: 25px 10px;
}

.sm-icon-border {
  border: 1px solid #d6d6d6;
  padding: 5px 4px 4px 6px;
  border-radius: 3px;
}

.sm-color-row-text {
  color: #04214b;
  font-size: 10px;
  font-weight: 500;
}
