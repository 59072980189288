.report-container {
  background-color: #ffffff;
  margin: 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 10px 50px;
  border-radius: 3px;
}

.report-btn-tab-row {
  display: inline-block;
  margin: 15px 15px 0px;
  width: 97%;
}

.report-active-btn-tab {
  background-color: #36b1e4;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  width: 17%;
  text-align: center;
  margin: 5px;
  cursor: pointer;
}

.report-inactive-btn-tab {
  background-color: #3ccfad;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  width: 17%;
  text-align: center;
  margin: 5px;
  cursor: pointer;
}

.report-table-first-row {
  display: flex;
  align-items: center;
}

.report-column-btn {
  background-color: #3ccfad;
  display: flex;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 5px;
}

.report-table-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  padding: 15px;
  text-align: center;
}

.report-total-main-cont {
  display: flex;
  justify-content: space-between;
  padding-right: 3px;
}

.report-total-cont {
  display: flex;
  justify-content: flex-end;
}

.report-total-text-div {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 5px;
}

.rep-rec-am-total-cont {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rep-cs-table-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
}

.rep-print-row-cont {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.rep-print-icon-cont {
  display: flex;
  border: 1px solid #bfbfbf;
  padding: 5px 3px;
  border-radius: 3px;
  margin-top: 10px;
}

.rep-print-icon {
  width: 15px;
  height: 15px;
}

/* =========== Potential clients reports ========= */

.pcr-active-btn-tab {
  background-color: #36b1e4;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  width: 15%;
  justify-content: center;
}

.pcr-inactive-btn-tab {
  background-color: #3ccfad;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  width: 15%;
  justify-content: center;
}

.report-table {
  padding: 5px 10px 10px;
}

.report-table .ant-table-thead .ant-table-cell {
  background-color: #f5f5f5 !important;
}

.client-source-report-table {
  padding: 5px 10px 10px;
}
