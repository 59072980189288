.tt-wt-heading {
  color: #6E6E6E;
  font-size: 10px;
  padding: 10px;
  border-bottom: 1px solid #BFBFBF;
}

.tt-wt-data-row {
  font-size: 10px;
  color: #6E6E6E;
  font-weight: 500;
  padding: 15px;
  border-bottom: 1px solid #CECECE;
  text-align: center;
}

.tt-vtp-text-border-cont {
  display: flex;
  border: 1px solid #BFBFBF;
  padding: 3px 20px 3px 5px;
  width: 70px;
  border-radius: 3px;
}
