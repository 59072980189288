.as-container {
  padding: 30px 35px 80px 35px;
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 2px;
}

.client-tag {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 25%);
  background-color: #ffffff;
  margin: 35px 25px;
  height: 100%;
  border-radius: 3px;
}
.client-tag-form {
  width: 80%;
  /* background-color: red; */
  margin: auto;
}
.d-flex {
  display: flex;
}
.align-item {
  align-items: center;
}

.client-tag-top {
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  background-color: #ffffff;
  padding: 13px;
}
.client-tag-table .ant-table-wrapper {
  margin-top: 35px;
}
.client-tag-table .ant-table-tbody {
  padding: 8px 16px !important;
}

.client-section {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 45px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}

.client-top {
  width: 90%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}
.top-text {
  /* font-size: 19px; */
  /* font-weight: 400; */
  margin-left: 20px;
  color: #5b6875;
}

.heading-forms {
  /* font-size: 19px; */
  /* font-weight: 400; */
  color: #172b4d !important;
  font-family: "Poppins", sans-serif !important;
}

.icons-client {
  width: 26px;
  cursor: pointer;
}
.add-tag-btn {
  display: flex;
  justify-content: flex-end;
}

.form-btn {
  float: right;
  margin: 10px 0px 0px 0px;
}

.ac-info-icon {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.ac-info-icon-email-temp {
  width: 20px;
  height: 20px;
  margin-top: -2vh;
}

.ac-back-icon {
  color: #1281b9 !important;
  font-size: 25px !important;
}
