.bi-card {
  margin: 10px !important;
  border-radius: 4px !important;
  border: none !important;
  background: white !important;
}
.bi-card .ant-card-body {
  padding: 0 !important;
}
.bi-card .ant-card-extra {
}
.bi-count-circle {
  width: 40px;
  height: 40px;
  line-height: 2.8;
  text-align: center;
  border-radius: 50%;
}
.color1 {
  background: #f26d7d;
}
.color2 {
  background: #3eceae;
}
.color3 {
  background: #f69679;
}
.color4 {
  background: #9f4bcd;
}
.color5 {
  background: #7cc576;
}
.color6 {
  background: #2180fe;
}
.bi-date {
  width: 140px !important;
  border-radius: 4px !important;
}
.bi-date1 {
  width: 110px !important;
  border-radius: 4px !important;
}
.abc .apexcharts-legend-series {
  margin: 2px 3px !important;
  width: 130px;
  background: #eee;
  padding: 30px 15px;
}
.abc .apexcharts-legend-text {
  color: rgb(55, 61, 63);
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica, Arial, sans-serif;
}
.abc .apexcharts-legend {
  padding: 0 !important;
}
/* .abc .apexcharts-legend-marker{
  background: rgb(0, 143, 251) !important;
    color: rgb(0, 143, 251);
    height: 12px;
    width: 12px;
    right: 10px;
    top: 10px;
    position: absolute;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 12px;
} */
.mixed-chart svg {
  overflow: visible !important;
}
#apexchartsbasicxbar svg {
  overflow: visible !important;
}
.client-ico-download {
  right: 0px;
  top: -48px;
  position: absolute;
}
.visa-ico-download {
  right: 0px;
  top: -48px;
  position: absolute;
}
.idle-ico-download {
  right: 0px;
  top: -55px;
  position: absolute;
}
.status-ico-download {
  right: 0px;
  top: -45px;
  position: absolute;
}
.bi-card .ant-card-head-title {
  color: #0f82b8;
  font-weight: 600;
  font-style: italic;
}
.bi-card .ant-card-extra {
  padding: 0;
}
.DashboardBiWrapper .ant-btn-primary {
  color: #fff !important;
  background: #1281b9 !important;
  border-color: #1281b9 !important;
  padding: 0px 15px !important;
  border-radius: 5px !important;
  font-size: 13px !important;
}
.DashboardBiWrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(4, 61, 91);
  font-weight: 600;
  font-size: 14px;
  font-family: "POPPINS";
}
.ant-tabs-tab:hover {
  color: #1281b9;
}
.DashboardBiWrapper .ant-tabs-ink-bar {
  background: rgb(4, 61, 91) !important;
}
.task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
  width: 100%;
}
.donut svg {
  overflow: visible !important;
}
.add-pointer {
  cursor: pointer !important;
}
.bi-table-card {
  background: #fff;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 5px;
}
.bi-table-card .heading-bi {
  background: #f5f5f5;
  padding: 12px 10px;
  border-radius: 4px 0px 4px 0px;
}
