.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.top-container {
  padding: 15px 6px 0px 6px;
  display: flex;
}
.table-card th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px;
}
.table-filter .ant-table-filter-trigger-container {
  top: -15px;
}
.table-card td {
  font-family: "Poppins" !important;
  font-size: 12px;
}
.priority-text {
  font-size: 11px;
  margin-left: 5px;
  color: #687278;
  font-weight: 500;
}
/* .table-filter tbody .ant-table-measure-row{
  display: none!important;
} */

.priority-count-text {
  font-size: 8px;
  color: #687278;
}
.c-footer-button button {
  background: #3c79b0 !important;
  border-color: #3c79b0 !important;
}
#rcDialogTitle0 {
  font-size: 14px !important;
}
.dashboard-table {
  width: calc(100% - 40px);
  padding: 20px;
}

.dsh-table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fb;
  border-bottom: 0.5px solid #e9eaec;
}

.dsh-header-font {
  font-size: 10px;
  font-weight: 500;
}

.priority-high-border {
  width: 12px;
  height: 12px;
  border: 0.5px solid #d62f1d;
  border-radius: 50%;
}

.priority-high-filled {
  width: 12px;
  height: 12px;
  background-color: #d8544f;
  border-radius: 50%;
  border: 0.5px solid #d62f1d;
}

.priority-medium-border {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f0a93b;
  border-radius: 50%;
}

.priority-medium-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f0a93b;
  border-radius: 50%;
  background-color: #efae4d;
}

.priority-low-border {
  width: 12px;
  height: 12px;
  border: 0.5px solid #55b451;
  border-radius: 50%;
}

.priority-low-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #55b451;
  border-radius: 50%;
  background-color: #5ab859;
}

.todays-tasks-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f5e1af;
  border-radius: 50%;
  background-color: #f6e4b6;
}

.pending-tasks-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f6af92;
  border-radius: 50%;
  background-color: #f7b69c;
}

.offshore-clients-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f6d3fd;
  border-radius: 50%;
  background-color: #f8d5ff;
}

.clients-count-cont {
  box-shadow: 0px 0px 5px 0px rgba(232, 229, 232, 1);
  padding: 5px 7px;
  display: flex;
  align-items: center;
}

.setting-export-cont {
  padding: 5px;
  background-color: #35b1e4;
  border-radius: 4px;
  display: flex;
  margin-left: 5px;
}

.setting-export-img {
  width: 15px;
  height: 15px;
}

.dsh-content-text {
  font-size: 10px;
  color: #687278;
}

.size-row {
  display: flex;
  margin: 25px 20px 0px 20px;
  align-items: center;
}

.size-font {
  font-size: 12px;
  font-weight: 600;
  color: #555555;
}

.count-text-cont {
  display: flex;
  margin-left: 10px;
  margin-top: 4px;
}

.content-drpdown {
}
/* .cp-top-bar {
  background-color: #127eb6 !important;
}
.cp-top-bar-text {
  color: white;
  font-size: 14px;
} */
.table-card {
  display: block !important;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 0px 0px 20px 15px;
  padding-bottom: 5px;
}
/* .site-layout {
  position: relative !important;
} */
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 0px;
  z-index: 1;
  width: 100% !important;
  width: -webkit-fill-available !important;
  width: fill-available !important;
}
.open-sidebar {
  margin-top: 0px;
}
.priority-count-text {
  font-size: 10px;
  color: #687278;
}
.count-text-cont {
  display: flex;
  margin-left: 20px;
  margin-top: 4px;
}
.priority-cont {
  margin-left: 20px;
}
.priority-button {
  width: 16px;
  height: 16px;
  float: left;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid;
  margin: 2px;
}
.grid-view .ant-form-item {
  width: 25%;
  display: inline-grid !important;
}
.c-footer-button {
  display: block !important;
  width: 100% !important;
  text-align: end;
}
.offshore {
  background-color: #f8d5ff !important;
  cursor: pointer !important;
}
.dueTask {
  background-color: #f7b69c !important;
  cursor: pointer;
}
.followDate .ant-picker {
  width: 120px !important;
}
.ant-table-row .dueTask {
  cursor: pointer !important;
}
.ant-table-row .cursorPointer {
  cursor: pointer !important;
}
.grid-view .ant-col-16 {
  max-width: 100% !important;
}
.modalTable {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.modalTable .cm-table-content-row {
  margin: 15px 10px;
}
.email-table {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin: 10px 0;
}
.email-table .ant-table-cell {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #e3e3e3 !important;
  width: 250px;
}
.email-table table {
  background-color: #f5f5f5;
}
.file-note-modal .ant-col {
  max-width: 100% !important;
}
.file-note {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
}
.file-note-list {
  border: 1px solid #e3e3e3;
  padding: 5px;
}
.addPointer {
  cursor: pointer !important;
}
/* .ant-table table {
  border-spacing: 0 10px;
} */
.today-task-reminders {
  background-color: #f6e4b6 !important;
  cursor: pointer;
}
.client-user-cont {
  margin-right: 7px;
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 1px 3px;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 3px;
  cursor: pointer;
}
/* .ant-table-measure-row {
  display: none !important;
} */
.ant-picker-content th:first-child {
  padding-left: 8px !important;
}
.ant-picker-content td {
  text-align: center;
}
.table-filter .ant-table-filter-column-title {
  padding: 0px 2.3em 16px 16px !important;
}
