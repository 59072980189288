body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.lgin-text {
  text-align: center;
  font-size: 25px;
  margin: 10px 10px 15px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lgin-text-main {
  margin-left: 17px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lg-item-center {
  display: flex;
  justify-content: center;
}

.lg-blue-normal-text {
  color: #1684ba;
  margin-left: 0px;
  font-size: 10px;
  text-decoration: underline;
  font-family: "Poppins", sans-serif !important;
}

.lg-copyright-text {
  font-size: 8px;
  text-align: center;
  color: #5b5b5b;
  font-family: "Poppins", sans-serif !important;
}

. {
  width: 550px;
  margin-top: 80px;
  margin-right: 30px;
}
