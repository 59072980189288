.cm-profile-cont {
  border-radius: 50%;
  margin-top: -12px;
  border: 0px solid #007eba;
  display: flex;
  width: 120px;
  height: 120px;
}

.cm-profile-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #1085bb;
}

.label-name-cont {
  display: flex;
}

.label-blue {
  color: #006099;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.cm-profile-fill-cont {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: 10px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  width: 24px;
  height: 24px;
}

.cm-profile-fill {
  width: 12px;
  height: 12px;
}

.cm-active-status {
  background-color: #00aff0;
  display: flex;
  width: 75px;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: 5px;
}

.cm-active-text {
  font-size: 12px;
  color: #ffffff;
}

.cm-id-text {
  font-size: 12px;
  color: #9da2a7;
}

.cm-blue-btn {
  /* box-shadow: 0px 0px 5px 0px rgba(232, 229, 232, 1); */
  padding: 5px 10px;
  display: flex;
  align-items: center;
  /* background-color: #0171bb; */
  border-radius: 5px;
  margin-left: 3px;
}

.cm-blue-btn-text {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}

.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 40px;
  width: 500px;
}

.progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #000;
  height: 30px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 68%;
  }
}

.cm-visa-cont {
  background-color: #e8eced;
  margin: 10px 20px;
  border-radius: 10px;
}

.cm-student-visa-cnt {
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm-student-visa-text {
  color: #255d8e;
  font-size: 14px;
  font-weight: 500;
}

.cm-status-approved {
  /* background-color: #9D0C0E; */
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.cm-status-completed {
  background-color: #3ab449;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.cm-status-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cm-checklist-label {
  margin-left: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #2d5d84;
}

.cm-header-text {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.cm-clintlist-content {
  color: #255d8e;
  font-size: 12px;
  font-weight: 500;
}

.cm-childlist-table {
  width: calc(100% - 40px);
  padding: 20px;
}

.cm-main-table {
  border: 1px solid #e2e2e3;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}

.cm-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 2px solid #cccccc;
}

.cm-header-font {
  font-size: 16px;
  color: #093d6a;
  font-weight: 600;
}

.cm-icon-size {
  width: 13px;
  height: 13px;
}

.cm-green-box {
  width: 20px;
  height: 20px;
  background-color: #fafafa;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.cm-greenbox-cont {
  display: flex;
  margin-left: 0px;
  align-items: center;
}

.cm-task-text {
  margin-left: 10px;
  color: #266090;
  font-size: 12px;
  font-weight: 500;
}
.cm-task-comment {
  color: #266090;
  font-size: 14px;
  font-weight: 500;
}
.cm-table-content-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e3;
  align-items: center;
  margin: 15px 25px;
  padding-bottom: 10px;
}

.cm-followers-text {
  color: #416d99;
  font-size: 12px;
}

.cm-textarea {
  width: calc(100% - 50px);
  margin-left: 25px;
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.cm-gray-btn-cont {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  padding-bottom: 25px;
}

.cm-gray-btn {
  background-color: #cccccc;
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.cm-gray-btn-text {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.cm-client-cl-status {
  /* display: flex;
  justify-content: space-between; */
  padding: 20px 0px;
}

.cm-add-btn-cont {
  width: 210px;
  display: flex;
  align-items: center;
  margin-top: 45px;
}

.cm-add-btn {
  background-color: #0172bb;
  padding: 5px 25px;
  display: flex;
  height: 15px;
}

.cm-add-btn-text {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}

.cm-down-arrow {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.p-text {
  color: #005400;
  font-size: 16px;
  font-weight: 500;
}
.file-notes-container {
  margin: 0px 15px 0px 15px;
}
.ant-list-item-meta-title {
  line-height: 1;
}
.top-blubar {
  background-color: #083c5d;
  padding: 20px 28px;
  display: block;
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.top-row {
  display: block !important;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  background: white;
  margin: 20px 15px 20px 15px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 75%);
  padding-bottom: 5px;
  position: relative;
  border-radius: 4px;
}
.top-row-button {
  display: flex;
  position: absolute;
  right: 30px;
  top: 30px;
}
.container {
  background-color: transparent;
  margin: 0;
  box-shadow: none;
  padding-bottom: 20px;
  border-radius: 2px;
}
.cm-add-btn {
  height: 30px;
}
.ant-form-item {
  margin-bottom: 5px !important;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: fixed !important;
  top: 120px !important;
}
.remove-client {
  position: absolute !important;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  font-size: 22px;
  color: #a8a7a5 !important;
  font-weight: bold;
}

.client-card {
  position: relative !important;
}
.cm-table-content-rows {
  display: block;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e3;
  align-items: center;
  margin: 15px 25px !important;
  padding-bottom: 10px;
}
.task-description-cm {
  color: rgb(38, 96, 144);
  font-size: 12px;
  font-weight: 600;
  word-break: break-all;
  margin: -10px 25px 5px 25px;
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: block;
}
.cm-profile-fill-conts {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin-right: 10px;
}
.cm-profile-boxes {
  display: flex;
  margin-bottom: 5px;
}
.cm-profile-fills {
  width: 24px;
  height: 24px;
}
.cm-task-texts {
  margin-left: 0px;
  color: #266090;
  font-size: 16px;
}
.cm-task-text:hover {
  cursor: pointer;
  color: #000;
}
.list-heading {
  padding: 10px;
  background-color: #1179af;
  display: flex;
  justify-content: space-between;
}
.list-heading h1 {
  color: white;
  margin: 0;
}
.cm-childlist-table {
  width: calc(100% - 0px) !important;
}
.cm-cross {
  position: absolute;
  right: 0;
  top: 0;
}
.advance-search-area {
  background-color: #f3f3f3;
  padding: 10px;
  margin: 10px 0;
}
.cm-form-input {
  margin-right: 30px;
}
.cm-form-input label {
  font-weight: 500;
  margin-right: 15px;
  color: #083c5d;
  font-size: 14px;
}
.cm-form-input input {
  width: 200px;
  border-radius: 5px;
}
.advance-search-area .ant-btn {
  background: #1179af;
  color: white;
  border-radius: 6px;
  font-size: 16px;
  height: 36px;
  line-height: 1;
  width: 110px;
  font-weight: 400;
}
.cm-form-date .ant-picker {
  width: 200px !important;
  border-radius: 5px;
}
.cm-form-date label {
  font-weight: 500;
  margin-right: 15px;
  margin-left: 15px;
  color: #083c5d;
}
.cm-form-date {
  display: flex;
}
.cm-search-area {
  /* background-color: #083c5d; */
  display: flex;
  padding: 8px 0;
}
.cm-pagination {
  margin-bottom: 30px;
  text-align: end;
  margin-right: 15px;
}
.ant-popover-inner-content .cm-profile-fill {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}
.ant-popover-placement-bottom .ant-popover-title {
  min-width: 95px !important;
  text-align: center;
}
/* .ant-popover-open{
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-left: 10px;
    box-shadow: 0px 0px 5px -2px rgb(0 0 0 / 75%);
} */
.cm-blue-btn .ant-btn {
  background: #1281b9;
  border-color: #1281b9;
  font-size: 11px;
  border-radius: 5px;
  font-weight: 700;
}
.cm-popover {
  text-align: center;
}
.adv-btn .ant-btn {
  box-shadow: 0px 1px 9px -2px rgb(0 0 0 / 24%);
  border: none;
  border-radius: 0px !important;
  margin: 0 5px;
}
.select-options {
  /* border: none !important; */
  /* margin-right: 5px; */
}

.select-options-technical-support {
  border: none !important;
}
.select-options .ant-select-arrow {
  top: 40%;
}
.select-options .ant-btn {
  width: 275px;
  text-align: inherit;
}
.select-options .anticon {
  float: right;
  margin-top: 6px;
}
.profile-container {
  padding-top: 30px;
}
.cm-search-area .ant-select-single .ant-select-selector {
  border: none !important;
}
.cm-search-area .ant-input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #eee;
}
.cm-search-area .ant-input-search-button {
  font-size: 14px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-left: 0px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  background: white;
  color: #aaa;
  box-shadow: none !important;
  text-shadow: none;
  padding: 0px 12px;
}
.cm-search-area
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child {
  left: 0px !important;
  padding: 0;
  border: 0;
}
.content-index-100 {
  border-bottom: 1px solid #e2e2e3;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.comment-bg {
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
}
