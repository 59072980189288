.ag-login-link-cont {
  border: 1px solid #40B5CE;
  padding: 5px;
  border-radius: 3px;
}

.ag-user-icon-cont {
  background-color: #BFBFBF;
  width: 100px;
  height: 75px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
