.icon-container {
  width: 20%;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
}
.docIcon {
  width: 26%;
}
.tooltip {
  visibility: hidden; /* Or display: none, depending on how you want it to behave */
}

.tooltip-on-hover:hover + .tooltip {
  /* Uses the adjacent sibling selector */
  visibility: visible; /* Or display: block */
}
.yellow_box {
  background-color: #f5d04c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.green_box {
  background-color: #5cb85c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.blue_box {
  background-color: #46b8da;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.yellow_span {
  background-color: #f5d04c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.green_span {
  background-color: #5cb85c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.blue_span {
  background-color: #46b8da;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.white_span {
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.ant-table .ant-table-selection-col {
  width: 12px !important;
}
.employer-table .ant-table-cell {
  text-align: center !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.employer-tables .ant-table-cell {
  text-align: left !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.ant-table-column-sorters {
  padding: 0px 0px !important;
}
.selectType {
  width: 100%;
}

.docOption-width {
  width: 150px;
}
.docId {
  width: 10%;
}
.docNameInput {
  width: 40%;
}
.docInput {
  width: 100%;
}

.docNameType {
  width: 20%;
  text-align: center !important;
}
.docNameTitle {
  width: 20%;
}
.docNameAction {
  width: 20%;
}

.cv-action-icons-borderDocType {
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 29px;
  width: 27px;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;
  margin: 2px;
}

.folderClass {
  padding: 15px;
  width: 15%;
  display: flex;
  flex-direction: column;
  white-space: normal;
}
