.cq-form-cont {
  background-color: #f4f4f4;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
  width: 100%;
  margin-left: 0px;
  margin-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.cq-container {
  width: calc(100% - 50px);
  margin-left: 25px;
}

.cq-primary-btn {
  background-color: #1281b9 !important;
  border-color: #1281b9 !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.cq-orng-btn {
  background-color: #f7941e !important;
  border-color: #f7941e !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.cq-save-btn {
  background-color: #3ab54a !important;
  border-color: #3ab54a !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.cq-close-btn {
  background-color: #f16d4f !important;
  border-color: #f16d4f !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.heading-container {
  padding: 20px 30px 10px;
  border-bottom: 1px solid #dce0e3;
}

.heading-text {
  color: #2f4678;
  font-size: 18px;
  font-weight: 500;
}

.title-container {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
}

.cq-label-text {
  color: #2f4678 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.section-heading-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}

.cq-add-btn {
  color: #929292;
  font-weight: 500;
}

.cq-section-cont {
  background-color: rgb(255, 255, 255);
  padding: 0px 20px 20px;
  margin-top: 40px;
  border-radius: 5px;
}

.cq-optional-cont {
  padding: 30px;
  background-color: rgb(255, 247, 236);
  margin-top: 20px;
  border-radius: 5px;
}

.cq-optional-section-cont {
  padding: 30px;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 5px;
}

.cq-btn-text {
  color: #ffffff !important;
}

.cq-section-name-cont {
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
}

.footer-title {
  color: #209f8f;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 1px;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.footer-logo {
  width: 25%;
  text-align: center;
  position: relative;
}
.footer-logo img {
  max-width: 170px;
  margin-bottom: 1rem;
}
.footer-logo p {
  margin: 0;
  color: #2e2e2e;
  line-height: 20px;
  font-size: 18px;
}
.footer-logo:after {
  content: "";
  background-color: #b7b7b7;
  width: 2px;
  height: 150px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -34px;
}
.footer-info-text {
  width: 75%;
}
.footer-info-box {
  width: 33.33%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-info-box h4 {
  font-size: 18px;
  color: #2e2e2e;
  margin-bottom: 0px;
  position: relative;
}
.footer-info-box h4:nth-child(1),
.footer-info-box h4:nth-child(2) {
  margin-bottom: 10px;
}
.footer-info-box h4 span {
  position: relative;
}
.footer-info-box h4 span:after {
  content: "";
  height: 2px;
  width: 30px;
  bottom: -5px;
  left: 50%;
  margin-left: -15px;
  background-color: #b7b7b7;
  position: absolute;
}
.footer-info-box p {
  color: #686767;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}

.cq-list-table-cont {
  padding: 0px 20px;
}

.cq-questionnaire-list-cont {
  border: 1px solid #458ccc;
}

.cq-questionnaire-list-heading {
  display: flex;
  background-color: #f9f9fb;
  padding: 10px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
  align-items: center;
  justify-content: space-between;
}

.cq-list-content-row {
  display: flex;
  padding: 3px 10px 2px;
  border-top: 1px solid #dbdcde;
  margin-bottom: 10px;
}

.cq-add-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.cq-width-30 {
  width: 30%;
}

.bf-content {
  height: 250px !important;
}

.rich-text-area {
  width: 70%;
  background: rgb(255, 255, 255);
  border: 0.3px solid #bab9b9;
}

.section-arrow-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.section-arrow-icons .anticon-up {
  font-size: 19px !important;
}
.section-arrow-icons .anticon-down {
  font-size: 19px !important;
}

.question-field-row {
  display: flex;
  justify-content: space-between;
  width: 82%;
  margin-left: 5%;
}

.cq-checkbox-width {
  display: flex;
  align-items: center;
  margin-right: 35px;
}

.options-cont .ant-radio-wrapper {
  white-space: inherit !important;
}

.contact-us-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 40px;
}
