@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff1f4 !important;
}

.line-height .ant-table-thead > tr > th {
  padding: 0px 8px !important;
}

.p-client .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.color-apply-template {
  color: rgb(54, 177, 228) !important;
}
.color-background-template-stage {
  background-color: rgb(54, 177, 228) !important;
}

.balance-input .ant-input-number:hover {
  border: transparent !important;
  display: none !important;
}

.balance-input .ant-input-number:focused {
  border: none !important;
  display: none !important;
}

.success:focus {
  border: none !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
}

.balance-client-des {
  color: #889093;
  font-size: 10px;
  margin: -4px;
}

.center-elements {
  display: block !important;
  align-items: center !important;
}

.marginTemplate {
  margin-top: -13px;
}

.poppins-font-family {
  font-family: "Poppins", sans-serif !important;
}

.width-cross-browser {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.employer .ant-modal-title {
  font-family: "Poppins", sans-serif !important;
}
.textDecoration {
  text-decoration: none !important;
}

.col-emp .ant-table-column-sorters-with-tooltip {
  margin-left: 15px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  /*padding: 2px !important;*/
}

.ant-checkbox-inner {
  position: unset !important;
  margin-bottom: -4px !important;
  height: 19px !important;
  width: 19px !important;
}

.login-checkbox .ant-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.hover-link {
  text-decoration: none;
  cursor: pointer !important;
  color: black;
}
.hover-link:hover {
  color: #1890ff;
  cursor: pointer !important;
}

.emp-section-font {
  color: #556370 !important;
  font-weight: 500;
}

.img-login-bg {
  width: 100%;
  height: calc(100vh - 20px);
  object-fit: cover;
}

.lg-right-image-admin {
  width: 100%;
  float: right;
}

lg-right-image {
  width: 550px;
  margin-top: 80px;
  margin-right: 30px;
}
.ant-upload-profile {
  width: 104px;
  height: 104px;
  margin-left: -11px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-top: -1px !important;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Poppins" !important;
}

/* span {
  font-family: "Poppins";
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker {
  padding: 6px 11px 6px 10px !important;
}

.client-form .ant-picker {
  padding: 4px 11px 4px !important;
}
/* 
.client-form .ant-row {
  display: block !important;
} */

.d-flex-contract {
  display: flex;
  justify-content: flex-end;
}

.d-flex-contract .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: flex-end !important;
}

.multi-select-option .ant-select-selector {
  height: auto !important;
}

.ant-select-selector {
  height: 32px;
  padding: 0 11px;
  width: auto !important;
}

.ant-select-selector span {
  font-size: 12px !important;
}

.ant-picker-input input {
  font-size: 12px !important;
  font-family: "Poppins" !important;
}

.ant-input {
  font-size: 12px !important;
}

.ant-form label {
  font-size: 12px !important;
  font-family: "Poppins";
}

.client-form .ant-select-selector {
  height: 28px !important;
}

.all-client-form .ant-form-item-label {
  font-weight: 400 !important;
}

.container-box {
  width: 90%;
  margin: auto;
}

.width-100 {
  width: 100% !important;
}

th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

td {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.height-100 {
  height: 100% !important;
}
.width-25 {
  width: 22%;
}
.width-52 {
  width: 52%;
}
.w-20 {
  width: 20%;
}
.w-80-t {
  width: 80%;
}
.width-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
  margin: auto;
}
.p-box {
  padding: 23px !important;
}
.employer-doc-tab .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff !important;
}

.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.flex-end {
  justify-content: flex-end;
}

.float-right {
  float: right !important;
}
.border-3 {
  border-radius: 3px !important;
}
.float-right {
  float: right !important;
}
.mar-zero {
  margin: 10px !important;
  padding: 0px !important;
}
.margin-top {
  margin-top: 13px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-btm {
  margin-bottom: 26px;
}
.d-aligin-center {
  display: flex !important;
  align-items: center !important;
}
.align-items {
  align-items: center;
}
.d-aligin-center p {
  font-size: 12px;
}
.text-style .ant-col .ant-form-item-label > label {
  font-weight: 500;
  color: #737373 !important;
  font-size: 13px;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.top-60 {
  margin-top: 60px;
}
.mar-top-35 {
  margin-top: 35px;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.border-color {
  border: 1px solid #d6d0d0;
  border-radius: 3px;
  margin-bottom: 12px;
}

.border-5px .ant-input {
  border-radius: 5px;
}
.border-5px textarea.ant-input {
  border-radius: 5px;
  margin-top: 12px;
}
.mar-top .ant-form-item-control-input-content {
  margin-top: 40px;
}
.margin-btns .ant-btn-primary {
  margin-left: 12px;
}
.space-between {
  justify-content: space-between;
}
.contant-around {
  justify-content: space-around !important;
}
.contant-center {
  justify-content: center !important;
}
.align-center {
  align-items: center;
}
.reminder-tabs-header .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header-temp .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
}
.reminder-tabs-header-temp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header-temp .ant-tabs-tab-active {
  background: #36b1e4;
}

.reminder-tabs-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header .ant-tabs-tab-active {
  background: #36b1e4;
}
.mar-zero .task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
}
.reminder-btn-main {
  display: flex;
  justify-content: center;
}

.add-task-btn .ant-btn {
  background-color: #1281ba;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.reminder-btn .ant-btn {
  background-color: #ff9e37;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.marked-name {
  background-color: #ff9d36;
  border-radius: 15px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  margin-left: 5px;
}

.marked-name-font {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.mdtr-reminder-tasks-cont {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}
.mdtr-reminder-tasks-header-cont {
  background-color: #f8f9fb;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8eaec;
  margin-top: 33px;
}
.mdtr-reminder-tasks-head-text-cont {
  display: flex;
  align-items: center;
}
.mdtr-rem-row-cont {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8eaec;
}
.cv-bold-text {
  color: #073d5d;
  font-size: 19px;
  font-weight: bolder;
}
.todo-main {
  justify-content: space-between;
  padding: 0px 34px;
  font-weight: 700;
}
.heading-style span {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.mdtr-user-cont {
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
}
.sus-checkbox {
  height: 15px;
  width: 20px;
}
.hide-icon {
  border: 1px solid;
  display: flex;
  padding: 0px 8px;
  border-radius: 50%;
  align-items: center;
}
.reminder-dropdown {
  margin-top: 33px;
}
.reminder-dropdown .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.reminder-dropdown .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.balance-accordian {
  margin-top: 15px !important;
}

.balance-accordian
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px;
  left: auto;
}

.invoice-status-btn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block !important;
}

.balance-accordian
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.balance-accordian .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.balance-accordian .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.template .ant-collapse-header {
  display: block !important;
}

.balance-accordian .ant-collapse-extra {
  margin-top: -37px !important;
  padding-right: 27px !important;
}

.reminder-border {
  border-bottom: 1px solid #d6d6d6;
  margin-top: 11px !important;
}

.reminder-head {
  color: #075a80;
  margin-left: 15px;
  font-weight: bold;
}
.reminder-calender {
  margin: 20px 0px 33px 0px;
}
.sidebar-reminder {
  margin-bottom: 33px;
  margin-top: 22px;
}
.reminder-calender .react-calendar {
  width: 100%;
  padding: 23px;
  border: 0;
}
.completed-tasks {
  margin-top: 45px;
}
.reminder-model-main {
  border-radius: 30px;
}
.reminder-model-main .ant-modal-content {
  /* width: 616px !important; */
  padding: 15px;
  border-radius: 3px;
}
.reminder-model-main .mdtr-modal-gray-cont {
  background-color: #f6f7f9;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e4e4e4;
  display: flex;
  border-radius: 5px;
  padding-right: 20px;
}
.reminder-model-main .mdtr-checkbox-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reminder-model-main .cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
}
.reminder-model-main .profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}
.reminder-model-main .profile-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}
.reminder-model-main .mdtr-add-tasks {
  background-color: #1281b9;
  padding: 7px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.reminder-model-main .ant-modal-header {
  border-bottom: none !important;
  color: #6c6c6c !important;
  padding: 2px 26px 0px 26px;
  font-size: 18px;
  margin-bottom: 38px;
  border-radius: 30px 30px 30px 30px !important;
}
.reminder-model-main .ant-modal-title {
  /* color: #6c6c6c; */
  font-size: 14px;
  font-weight: 600;
}
.reminder-model-main .cv-bold-text {
  font-size: 12px;
  font-weight: 600;
  margin-top: 36px;
}
.reminder-model-main .ant-modal-body {
  padding: 0px 20px;
}
.reminder-model {
  border-radius: 40px;
}
.reminder-model-main .emp-ae-input-width p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.reminder-model-main .emp-ae-input-width {
  margin-right: 32px;
}
.modal-parts {
  width: 84%;
  margin: auto;
}
.reminder-model-main .ant-form-item {
  padding: 15px 0px;
}
.form-parts .ant-form-item-label {
  font-weight: 500;
}
.bg-box-white {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  padding: 22px 0;
}
.mar-b {
  margin-bottom: 32px;
}
.w-96 {
  width: 96%;
  margin: auto;
}
.mar-tb {
  padding: 20px 5px;
}
.comment-btn .ant-btn-primary {
  float: right !important;
  margin-top: 14px;
  border-radius: 4px;
  border: 0px;
}
.follower-row h4 {
  margin-right: 13px;
}
.mar-right {
  margin-right: 7px !important;
}
/* .tasks-comment .ant-comment {
  width: 96%;
  margin: auto;
} */
.box-lenght {
  max-height: 400px;
  overflow: scroll;
}

.box-lenght::-webkit-scrollbar {
  width: 4px;
}
.box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 0px;
}
.box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.completed-box-lenght {
  max-height: 1000px;
  overflow: scroll;
}
.completed-box-lenght::-webkit-scrollbar {
  width: 4px;
}
.completed-box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.completed-box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}
.completed-box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.color-check {
  margin: 5px 8px 0px 0px;
  border-radius: 2px;
}
.delete-model p {
  font-size: 15px;
  font-weight: 200;
  padding: 13px 0px 0px 7px;
}
.margin-right {
  margin-right: 10px;
}
.client-form .ant-form-item {
  display: block;
  margin-top: 9px;
}
.all-client-form .ant-form-item-label {
  font-weight: 600;
}
.header-text-style {
  font-size: 17px;
  font-weight: 500;
  color: #083c5d;
  padding: 30px 40px 0px 20px;
}
.all-client-form .client-form .ant-form-item-no-colon {
  color: #55626c !important;
}
.all-client-btn {
  margin: 25px 23px 0 0px;
  justify-content: flex-end;
}
.reminder-settings {
  width: calc(100% - 170px);
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 50px;
  margin: 35px 85px;
  border-radius: 3px;
}

.invoice-table-body-dates {
  font-size: 14px;
  font-weight: 450;
  color: black;
  padding-bottom: 5px;
}
.client-tag-table .ant-row .ant-form-item {
  display: block;
}

.reminder-set-form {
  /* background-color: #ffffff; */
  /* padding: 30px; */
  /* border-radius: 5px; */
  /* border: 1px solid #eeeeee; */
}
.reminder-set-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-set-form p {
  font-size: 16px;
  /* color: #556370; */
  font-weight: 100;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 100;
}

.reminder-setting-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.imap-set-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.imap-set-form .ant-form-item-label {
  margin-right: 0px;
}
.margin-top-34 {
  margin-top: 34px;
}
.Reminder-top {
  width: 100%;
  /* margin: auto; */
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
}
.time-tracking {
  width: 95%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}
.employer-manag {
  width: 91%;
  height: 100%;
  margin: 35px auto;
}
.dynamic-keys {
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  padding: 4px 0px 6px 17px;
}
.dynamicForm {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin: 21px 0px 59px 0px;
}
.mail-Chimp-text {
  padding-top: 35px;
  font-size: 16px;
}
.mail-Chimp-text p {
  font-size: 13px;
  font-weight: 450;
  margin-top: 0px !important;
}
.mail-text p {
  padding-left: 35px;
}
.mail-chimp-btn {
  display: flex;
  justify-content: flex-end;
}
.mail-chimp-box {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 54px 74px 54px;
  margin: 35px auto;
  border-radius: 3px;
}
.default-font-box {
  width: 98%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 35px 20px;
  border-radius: 3px;
}
.document-sync-box {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 10px auto 35px auto;
  border-radius: 3px;
}

.document-checklist-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 17px;
  margin: 35px auto;
  border-radius: 3px;
}
.document-checklist--btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
}
.email-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  margin: 27px auto;
  border-radius: 3px;
}
.email-content-box h4 {
  padding-bottom: 20px;
  color: #448ccb;
  font-size: 16px;
}
.questionnaire-content {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 81px;
  margin: 35px auto;
  border-radius: 3px;
}
.questionnaire-top {
  width: 82%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}

.letter-font p {
  font-size: 14px;
}
.letter-spacing {
  margin: 16px 0px 0px 0px;
}
.letter-height {
  height: 500px;
}
.letter-froala .fr-box.fr-basic .fr-element {
  max-height: 500px;
  border-bottom: 2px solid #cccccc;
  min-height: 274px;
}
.fr-second-toolbar {
  display: none !important;
}
.file-uploader .ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9 !important;
  background-color: transparent;
  padding: 5rem;
  margin-bottom: 2rem;
}
.file-uploader .ant-upload-text {
  color: #1890ff !important;
}
.file-uploader {
  margin-top: 15px;
}
.file-uploader .ant-upload {
  border-radius: 0px !important;
}
.border-box {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-emp-manag {
  background: white;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-add-emp {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px 50px;
  margin-top: 38px !important;
}
.border-box-employer {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 34px;
  /* margin-top: 38px !important; */
}
.padding-20 {
  padding: 20px !important;
}

.w-9 {
  width: 13%;
}
.w-91 {
  width: 87%;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.mar-top-75 {
  margin-top: 75px;
}
.doc-office {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.Upload-signature .avatar-uploader > .ant-upload {
  width: 190px;
  height: 128px;
  border-radius: 0% !important;
  margin-bottom: 10px;
}
.Upload-signature .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background: white;
  border: 2px dotted #d9d9d9 !important;
  border-radius: 0px !important;
}
.MainSideBar .ant-tabs-nav-list {
  background-color: #213037;
  color: #fff;
}
.dashboardSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.dashboardSideBar {
  width: 265px !important;
  /* min-width: 100% !important; */
}
.supervisorSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.supervisorSideBar {
  width: 262px !important;
}
.site-layout {
  background: #ffffff;
  /* height: 100%; */
  position: fixed;
  margin-top: 64px;
  /* min-width: 265px !important; */
  overflow: auto;
}
.site-layout .ant-layout-sider-children {
  overflow: auto;
  position: fixed;
}
.screen-width {
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
  /* margin-left: 265px; */
  /* margin-top: 64px; */
}

.dashboardSideBar .ant-menu-item a {
  /* padding-left: 14px !important; */
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.dashboardSideBar .ant-menu .ant-menu-dark .ant-menu-root .ant-menu-inline {
  color: #ffffff;
  font-size: 18px;
  border: 0px;
}
.dashboardSideBar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: red !important;
}
.ant-layout-header {
  position: fixed;
  z-index: 10;
  width: 100%;
  /* height: 3rem; */
  background: #fff !important;
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
  overflow: overlay;
  width: 100%;
}
/* .open-sidebar div>div>div{
  position: relative!important;
}
.close-sidebar div>div>div{
  position: relative!important;
} */
/* added by aatif */
.site-layout {
  position: absolute !important;
  /* position: fixed !important; */
}
.ant-layout-content {
  padding-top: 65px !important;
}
.site-layout .ant-layout.ant-layout-has-sider {
  width: 100% !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: rgba(33, 48, 55, 255) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(69 89 99);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #f1e9e9 !important;
}
.employer-table .ant-table-pagination.ant-pagination {
  margin: 7px 26px !important;
}
/*.employer-table .ant-table-cell {*/
/*  text-align: center;*/
/*}*/
/* .table-head-clr .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
}
.table-head-clr .ant-table-column-sorters-with-tooltip {
  background-color: #f0f2f5;
} */
.add-employer-para .ant-col .ant-col-12 p {
  font-size: 14px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.employer-manag-tabs .ant-tabs-nav-wrap {
  background-color: #f0f2f5 !important;
}
.employer-manag-tabs .ant-tabs-tab {
  color: black;
  font-weight: 800 !important;
  font-size: 16px;
  margin: 0px;
  background: #0383bc !important;
  padding: 5px 17px !important ;
  font-size: 12px !important;
}
.employer-manag-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.employer-manag-tabs .ant-tabs-tab-active {
  background: #36b1e4 !important;
}
.employer-manag-tabs .ant-form-item {
  padding: 15px 0px !important;
}

.emp-profile-box-employer-new .ant-form-item {
  padding: 15px 0px !important;
}

.employer-manag-tabs .ant-tabs-tab {
  margin-right: 0px !important;
  border-radius: 0px !important;
}
.employer-manag-tabs .ant-tabs-tab-btn {
  font-weight: 500;
  color: white;
}
.upload-button .ant-btn {
  border-radius: 0px !important;
}
.employer-head {
  margin-top: 33px;
  margin: 44px 0px 10px 10px;
}
.form-add-btn {
  width: 26px;
  border-radius: 33px !important;
  height: 26px;
  /* float: right; */
}
.form-add-btn .ant-btn-icon-only {
  height: 25px;
  border-radius: 45px;
  margin-bottom: 4px;
  /* position: fixed; */
  z-index: 20;
}
.form-add-btn .ant-btn-icon-only svg {
  margin-bottom: 4px;
}
.remove-button {
  cursor: pointer;
  background-color: #f52b2b;
  color: white;
  display: flex;
  width: 5%;
  padding: 7px 14px;
  border-radius: 44px;
  font-weight: 400;
  margin: 35px 0px;
}
.remove-button:hover {
  background-color: #1890ff;
  color: white;
}
.contact-Head {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.contact-emp {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin-top: 25px;
}
.add-job-form .ant-row .ant-col .ant-col-4 {
  font-size: 19px !important;
}
.add-job-form .ant-input-number {
  width: 100% !important;
}
.emp-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  /*margin: 27px auto;*/
  border-radius: 3px;
}
.emp-froala h4 {
  color: #448ccb;
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 12px;
}

.green-btn {
  background: #0ca22c !important;
  border: 0px !important;
  color: #fff !important;
}
.margin-client {
  display: flex;
  margin: 18px 0px 0px 15px;
}
.padding-btn {
  padding: 10px 23px;
}
.file-note-light {
  background-color: #fff;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}
.payment-form-labels {
  margin-top: 16px;
}
.file-note-dark {
  background-color: #fffcfc;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}

.accounts-flow-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.table-row-light {
  background-color: #fff !important;
  /* padding: 6px 16px; */
}
.table-row-dark {
  background-color: #f0f2f5 !important;
  /* padding: 6px 16px; */
}
.file-contacts .ant-row .ant-form-item {
  width: 90%;
  margin: auto;
}
.file-delete-head {
  margin: 18px 25px 0px 23px;
}
.border-color-blue .ant-tabs-tabpane .ant-tabs-tabpane-active {
  border: 1px rgb(16, 129, 185);
}
/*.ant-table-thead > tr > th {*/
/*  text-align: center !important;*/
/*  font-size: 13px;*/
/*}*/

.school-table .ant-table-thead > tr > th {
  text-align: left !important;
  font-size: 14px !important;
}

.child-invoice-table .table-head .table-tbody > tr > th {
  padding: 16px 0px 16px 0px !important;
}

.table-head .ant-table-column-sorters {
  padding: 11px 0px;
}
.table-head .ant-table-column-sorters-with-tooltip {
  text-align: center;
}
.table-head .ant-table-cell {
  /* text-align: center; */
}
.table-head .table-tbody > tr > td {
  align-items: center !important;
  padding: 12px 0px !important;
}
/* .table-head .ant-table-cell {
  padding: 10px !important;
} */
.ant-select {
  width: 100%;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
  margin-top: 4px;
}
/* .employer-Header {
  height: 54px;
} */
.alert-msg .ant-form-item-explain .ant-form-item-explain-error {
  float: right !important;
}
/* .required p::before {
  content: "*";
  position: absolute;
  /* right: -8px; */
/* left: 18px;
color: red;
top: -2px; */
/* } * */
.job-table .ant-table-cell {
  padding: 8px 4px 8px 10px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}
.contact-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-table .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}

.contact-tables .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-tables .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  margin: 16px 42px !important;
  text-align: left;
  background: transparent !important;
}

.job-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}
.job-table .ant-table-container {
  /* padding: 20px; */
}

.contact-us .ant-table-cell {
  padding: 13px 7px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: center;
  color: #474a4c;
}
.contact-us .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.add-employer-para .ant-col .ant-col-12 p {
  font-size: 13px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.width-web-kit {
  width: -webkit-fill-available !important;
}

.width-modal-sending-receipt {
  width: 1022px !important;
}

.width-modal-add-template {
  width: 641px !important;
}

.width-modal-add-email-template {
  width: 70vw !important;
}

.border-box-modal-sending-receipt {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 72px 10px 17px;
  margin-top: 38px !important;
}

.font-school-list-filter-text {
  font-size: smaller;
  font-weight: 700;
}

.font-school-list-filter-text-sub {
  color: #889093;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.sus-tab-container-schools {
  display: flex;
  margin: 30px 0px 0px 20px;
  cursor: pointer;
}

.sus-tab-container-clients {
  display: flex;
  margin-left: 20px;
  margin: 44px 0px 0px 20px;
  cursor: pointer;
}

.sus-active-tab-schools {
  background-color: rgb(51, 170, 218);
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.sus-inactive-tab-schools {
  padding: 5px 20px;
  display: flex;
  background-color: #0383bc;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.upload-technical-support-complaint .ant-upload.ant-upload-select-picture-card {
  margin-top: 9px;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.upload-technical-support-complaint .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 9px 8px 8px 0;
  vertical-align: top;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-active-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ts-container-schools {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.button {
  color: #fff !important;
  background: #096dd9 !important;
  border-color: #096dd9 !important;
  padding: 5px 16px !important;
  border-radius: 2px !important;
}

.cont-positioning {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.border-box-modal {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: 38px !important;
}

.add-new-line-container {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: auto;
  margin-top: 16px;
  margin-left: 16px;
  padding-bottom: 15px;
}
.add-new-line-header {
  background-color: #e6e6e6;
  height: 26px;
}

.add-new-line-header-school-invoices {
  background-color: #e6e6e6;
}

.add-new-line-inputs {
  padding: 7px;
}

.add-new-line-header-position {
  margin-top: 5px;
  text-align: center;
}

.add-new-line-button {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: 36px;
  width: 107px;
  margin-left: 22px;
  margin-top: 6px;
  cursor: pointer;
}
.add-new-line-button p {
  text-align: center;
  font-size: 13px;
}

.width-modal {
  width: 702px !important;
}

.label-search-schools {
  font-size: 16px;
  font-weight: bold;
  color: rgb(24, 91, 148);
}

.width-modal-outgoing-payments {
  width: 735px !important;
}

.incoming-payments-table-row {
  background: #bcf6c5 !important;
}

.invoices-children-table-row {
  background: #e5e4e2 !important;
}

.outgoing-payments-table-row {
  background: #f6d6d0 !important;
}

.font-size-add-school-form {
  font-size: 12px;
}

.text-style-show-container {
  color: #fff;
}

.ca-gray-padding {
  padding: 15px 20px 15px 0px !important;
}

.ca-gray-padding-payment-history {
  padding: 15px 20px 15px 20px !important;
}

.payment-buttons-container {
  display: flex;
  margin-left: 19px;
  margin-top: 10px;
}

.payment-label {
  font-size: 12px;
  font-weight: initial;
  margin-left: 19px;
}

.show-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: -3%;
  cursor: pointer;
}

.display-contact-text-container {
  display: block;
  margin-inline-start: 20px !important;
}

.margin-contact-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-icon {
  margin-top: -35px !important;
}

.haha {
  border: 1px solid red;
}

.border-box-modal-add {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}

.positioning-delete-icon {
  margin-top: 7px;
}

.positioning-show-levels {
  text-align: center;
}

.border-show-contacts {
  border-bottom: 1px solid rgb(214, 208, 208);
  width: 587px;
  margin-left: -11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.border-add-template-stages {
  border-bottom: 1px solid rgb(214, 208, 208);
  padding-top: 22px;
  margin-left: 25px;
}

.border-detail {
  border-bottom: 1px solid rgb(214, 208, 208);
}

.hide-levels-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: 7%;
  cursor: pointer;
}

.ts-container-commission-reminders {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.redo-icon {
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.upload-technical-support-complaint {
  margin-top: 13px !important;
  margin-left: 20px !important;
}

.upload-technical-support {
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.employer-doc-tab .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.employerin-box {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 56px;
  height: 100%;
  border-radius: 3px;
}
.ant-table-cell .ant-table-column-sort .ant-table-column-has-sorters {
  background: white;
}
.ant-table-column-sorters-with-tooltip {
  background: white;
}
.ant-table-cell .ant-table-selection-column {
  background: white !important;
}
.document-table .ant-table-cell {
  text-align: center;
}
.send-btn .ant-btn-primary {
  border-radius: 4px !important;
}
.border-box-checklist {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 13px 54px;
}
.checklist-add-field {
  background: #f5f5f5;
  border: 0px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}
.checklist-textarea .ant-input {
  height: 100px !important;
}
.checklist-switch .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch .ant-switch-checked {
  height: 10px;
  background-color: #bf0909b8 !important;
}
.checklist-switch .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #aa2626 !important;
}
.checklist-switch .ant-switch-inner {
  height: 10px;
  /* background-color: black !important; */
}
.checklist-switch-low .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch-low .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #eec914;
}
.checklist-switch-low .ant-switch-checked {
  height: 10px;
  background-color: #eec914ad !important;
}
.checklist-switch-low .ant-switch-inner ::before {
  height: 10px;
  background-color: black !important;
}
.checklist-switch-low .ant-form-item-label > label {
  height: 22px !important;
}
.checklist-switch .ant-form-item-label > label {
  height: 22px !important;
}

.all-client-form .ant-form-item-label > label {
  height: 22px !important;
}

.imap-text p {
  font-size: 13px !important;
  /* color: rgb(141, 139, 139) !important; */
}
.imap-text {
  font-size: 15px !important;
}
.input-name {
  font-weight: 500 !important;
}
.employer-logo-box {
  background-color: #fff;
  justify-content: center !important;
  display: flex !important;
  padding: 50px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 26%);
}
.container {
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.link-client-logo {
  width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  height: 8rem;
}
.footer-info-text {
  display: flex;
}
.red-dot {
  height: 14px;
  width: 14px;
  background-color: #e61616;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}
.yellow-dot {
  height: 14px;
  width: 14px;
  background-color: #faad14;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}

.upload-image-heading {
  margin-top: 70px;
}

.personal-info-heading {
  margin-bottom: 24px;
  padding-top: 18px;
}

.ts-container-add-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 35px 30px 0px;
  border-radius: 3px;
}

.personal-info-add-client-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-right: 30px;
  border-radius: 6px;
  margin-left: 35px;
  padding-bottom: 37px;
}

.personal-info-add-client-container
  .ant-form-horizontal
  .ant-form-item-control {
  min-width: 100% !important;
}

.ts-container-import-clients {
  background-color: #ffffff;
  margin: 15px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.post-to-xero {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.xero-filters-label {
  width: 19%;
  margin-top: -5px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border: solid 10px transparent;
}

#style-3::-webkit-scrollbar {
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px;
}

.save-button {
  margin-left: 7px;
  margin-top: 29px;
}

.container-center {
  height: 2em;
  position: relative;
}
div.container-center h5 {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ts-potential-client-statuses {
  background-color: #ffffff;
  margin: 50px 13px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 2px 16px 5px;
  border-radius: 5px;
}

.duplicate-client-table-container {
  margin-top: 37px;
  margin-left: 31px;
  margin-bottom: 15px;
}

.spinner-xero {
  text-align: center;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  -webkit-align-content: center;
  align-content: center;
  vertical-align: middle;
}

.spinner {
  text-align: center;
  z-index: 1000;
  padding-top: calc(50% - 25%);
  width: 100%;
  height: 100vh;
  -webkit-align-content: center;
  align-content: center;
  vertical-align: middle;
}
.client-table .ant-table {
  border: 1px solid rgb(184, 179, 179) !important;
  border-radius: 2px;
}
.employer-btn {
  /* border-radius: 6px !important; */
  border: 0px !important;
}
.emp-w-box {
  padding: 29px 84px;
  background-color: white;
  margin-top: 20px 20px;
}

.emp-w-box-employer {
  padding: 29px 84px;
  background-color: rgb(245 247 248) !important;
  margin-top: 20px 20px;
}

.emp-profile-table {
  background-color: whitesmoke;
}
.auto-email {
  border: 1px solid #c9c9ca;
  padding: 14px 41px !important;
  margin-bottom: 10px;
}
.create-email-form .ant-form-item {
  padding: 7px 0px !important;
}
.visa-body-text {
  justify-content: space-between;
  display: flex;
  max-width: 64%;
}
.visa-body-text span {
  color: #5c5c5c;
  font-weight: 450;
}
.visa-status-header {
  height: 100px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 90%;
  margin: auto;
}
.status-header-left {
  width: 80%;
  background-color: #f3f3f3;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.status-header-left h2 {
  color: #858585;
  margin: 0;
}
.status-header-left p {
  color: #858585;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-top: 6px;
}
.event-visa-btn {
  background: #f6752f;
  border: 0;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 3px 17px;
}
.status-header-right {
  width: 20%;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}
.status-header-right h2 {
  color: #ffffff;
  margin: 0;
}
.visa-status-body {
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px 30px 30px 30px;
}
.visa-status-body button {
  margin-bottom: 7px;
}
.visa-status-body-data {
  background-color: white;
  width: 100%;
  margin: auto;
  border-radius: 5px !important;
  padding: 30px !important;
}
.case-new {
  width: 90%;
  background-color: #36b1e4;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  height: 8rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-top: 40px;
  margin-top: 10px;
}
.case-new h4 {
  color: #fff;
  font-size: 18px;
}
.incoive-table .ant-table-container {
  background: #eff1f4;
  width: 90%;
  margin: auto;
}
.file-spacing {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
}
.document-table .ant-row .ant-form-item {
  /* justify-content: center; */
  width: 40%;
}
.box-emp {
  width: 100%;
  align-items: center;
  /* box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 25%); */
  background-color: #ffffff;
  margin: 35px 25px;
  /* height: 100%; */
  border-radius: 3px;
}
.box-emp .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
.close-editor {
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}
.add-emp-form .ant-form-item-explain {
  justify-content: flex-start !important;
  display: flex !important;
}
.slider-small {
}
.slider-small .ant-layout-sider-children {
  width: 84px !important;
}
.slider-small .ant-layout-sider {
  background-color: transparent;
}
.site-layout-background {
  width: 100% auto !important;
}
.open-sidebar {
  width: 89%;
  margin-left: 265px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.rightbar-icons {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.rightbar-icons img {
  margin-right: 10px;
}

.client-tabs {
  margin: 10px 0px 20px 0px !important;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75) !important;
}
.client-tabs .ant-tabs-left > .ant-tabs-content-holder {
  background: white;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  /* width: auto; */
}
.client-tabs .ant-tabs-tab-active {
  background-color: #ffffff !important;
}
.reminder-tabs-header
  .client-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #083c5d !important;
  font-weight: 500 !important;
}
.client-tabs .ant-tabs-tab {
  display: flex;
  color: #083c5d !important;
  font-weight: 500 !important;
}
.client-tabs .ant-tabs-ink-bar-animated {
  border: none;
  background: none;
}
.client-tabs .ant-tabs-nav {
  font-weight: 500;
  color: #083c5d;
}

.client-tabs .ant-tabs-tab {
  padding: 8px 15px !important;
}

.short-tabs {
  margin: 10px 0px 20px 0px !important;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75) !important;
  width: 115px;
}
.short-tabs .ant-tabs-left > .ant-tabs-content-holder {
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  display: none;
}
.employer-tabs-content > .ant-tabs-content-holder {
  background-color: #f0f2f5 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.employer-tabs-contents > .ant-tabs-content-holder {
  font-size: 12px !important;
  font-weight: inherit !important;
  font-family: "Poppins", sans-serif !important;
}
.short-tabs .ant-tabs-tab-active {
  background-color: #ffffff !important;
}
.short-tabs .ant-tabs-ink-bar-animated {
  border: none;
  background: none;
}
.short-tabs .ant-tabs-nav {
  font-weight: 500;
  color: #083c5d !important;
}
.reminder-tabs-header .short-tabs .ant-tabs-tab {
  font-weight: 500;
  color: #083c5d !important;
}

.short-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  color: #083c5d !important;
}
.short-tabs .ant-tabs .ant-tabs-left {
  display: block !important;
}
.profile-tags {
  padding: 4px 10px;
  border: 1px solid #3fb5cf;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.tags-text {
  font-size: 10px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}
.signup-contaienr {
  width: 80%;
  margin: auto;
}
.signup-container {
  width: 67%;
  margin: 51px auto;
  border: 1px solid #cecece;
  padding: 20px 0px;
  background-color: white;
}
.signup-fields .ant-form-item {
  padding: 15px 0 !important;
}
.signup-fields {
  width: 87%;
  margin: 10px auto 32px auto;
  background-color: #f0f2f5;
  padding: 10px 63px;
}
.signup-text {
  margin: 18px 0px;
}
.signup-text span {
  /* font-size: 14px; */
  font-weight: 600;
  color: #5d5d5d;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.save-signup .ant-form-item-control-input {
  width: 88%;
  margin: auto;
}

.cmv-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 16px !important;
}
.cmv-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.ant-input-number-handler-wrap {
  position: absolute;
  display: none !important;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  /*border-left: 1px solid #d9d9d9;*/
  border-radius: 0 2px 2px 0;
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.1s;
  transition: opacity 0.24s linear 0.1s;
}
.profile-container {
  width: 98%;
  margin: auto;
  padding-top: 45px;
  display: flex;
}
.profile-input-border .ant-form-item-control-input {
  min-height: 30px !important;
}
.sign-up-head {
  justify-content: space-around;
  align-items: center;
}
.head-d {
  border: 1px solid;
  height: 10px;
}
.head-link {
  padding: 0 10px 10px 0;
}
.head-text h1 {
  color: #095c81;
}
.email-input input {
  width: 440px;
  height: 39;
  border-radius: 44px 0px 0px 44px;
  background: #eeeeee;
  border: 0px;
}
.sign-up-btn button {
  width: 160 !important;
  border-radius: 44px;
  height: 39;
}
.sign-up-btn .ant-col {
  margin-left: -6px !important;
  padding-left: 0px;
}
.email-input .ant-col {
  padding: 0px !important;
}
.bg-white .ant-layout {
  background-color: white;
}

.sign-up-btn .ant-btn-primary {
  background: #083c5d;
  border: 0px;
  margin-left: -20px;
  margin-bottom: 40px;
}
.sign-up-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: rgb(3 131 188);
  border-color: #fff;
  border: 1px solid;
}
.email-input .ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #eeeeee !important;
}
.signup-email .ant-row {
  justify-content: center;
}
.video-btn {
  text-align: center;
}
.video-btn button {
  height: 65px;
  width: 274px !important;
  background: #083c5d;
  border: 0px;
  border-radius: 4px;
}

.quick-box {
  background: #f5f5f5;
  text-align: center;
  padding: 28px 0px;
  margin-top: 23px;
}
.feature-btn button {
  border-radius: 44px;
  margin-top: 30px;
  background: #083c5d;
  border: 0;
}
.feature-content {
  font-weight: 600;
  font-size: 32px;
  color: black;
}
.feature-content-lg {
  font-size: 30px;
}
.quick-box .ant-row {
  justify-content: center;
}
.features-img .ant-image-img {
  margin-bottom: -112px;
}
.amzing-feature .ant-row {
  justify-content: center;
}
.signup-header .ant-layout-header {
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 22px -13px rgb(11 9 10 / 59%);
}
.watch-video .ant-btn-primary {
  background: #1281ba;
  border: 0px;
  border-radius: 32px;
  width: 182px;
  height: 45px;
  margin: 21px;
}
.client-docs {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
}
.client-ehead {
  width: 48%;
  margin: auto;
}
.draft {
  padding: 3px 12px;
  background: #d9534f;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}
.sent-email {
  padding: 3px 12px;
  background: #5cb85c;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}

.client-doc-main {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.doc-filter .ant-select-dropdown {
  width: 119px !important;
}
.add-emp-btn .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}
.required-field {
  color: red;
  padding-left: 2px;
}
.client-detail-modal .ant-modal-body {
  background: rgb(245 245 245);
  margin: 24px;
  border: 1px solid rgb(192 190 190);
}
.detail-head {
  font-size: 18px;
  font-weight: 500;
  color: #636363;
}
.search-name {
  font-size: 15px;
  color: rgb(59, 59, 59);
  font-weight: 500;
  width: 100%;
}
.search-text {
  font-size: 13px;
  color: rgb(126, 126, 126);
  font-weight: 400;
  width: 100%;
}
.docuemt-padding {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.filenote-box {
  width: 98%;
  margin: 0 auto 50px auto;
}
.emp-profile-box {
  width: 90%;
  margin: 0 auto 50px auto;
}

.emp-profile-box-employer {
  width: 98%;
  margin: 0 auto 50px auto;
}

.filenote-btn .ant-form-item-control-input-content {
  margin-top: 10px;
}
.float-left {
  float: left;
}
.agreement-data {
  font-weight: 500;
  color: #556370;
  margin-right: 8px;
}
.single-job
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
  padding: 15px 16px !important;
}
.add-client-form-inputs .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label .ant-form-item-no-colon {
  padding: 0px !important;
  margin: 0;
  font-weight: 600;
}
.add-client-form-inputs .ant-form-item-no-colon {
  /* font-size: 11px !important; */
  color: #556370 !important;
  font-weight: 500;
}

.table-action .anticon {
  margin-right: 2px;
  cursor: pointer;
  color: #4b90cd;
  font-size: 16px;
}
.table-action {
  display: flex;
  align-items: center;
}

.BranchPermissions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminder-small-text {
  color: #33aada;
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
}

.reminder-bold-text {
  color: #33aada;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}

.reminder-date-text {
  color: #555555;
  font-size: 12px;
  margin: 0px;
}

.reminder-tabs-cont {
  border: 1px solid #e1e1e1;
  margin: 10px 15px;
  margin-left: 0px;
  padding: 10px;
}

.ant-tabs-tab-btn {
  width: 100% !important;
}

.short-tabs .ant-tabs-nav-wrap {
  white-space: unset !important;
  width: 115px !important;
  background-color: rgb(243, 244, 246) !important;
}
.client-tabs .ant-tabs-nav-wrap {
  background-color: rgb(243, 244, 246) !important;
}

.short-tabs .ant-tabs-tab {
  margin: 0px !important;
  border-bottom: 1px solid #e8eaec !important;
  padding: 8px 10px !important;
  color: #083c5d;
}

.content-width-open-sidebar {
  width: calc(100% - 458px) !important;
}
.content-width-close-sidebar {
  width: calc(100% - 140px) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}

.ant-modal-title {
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.ec-new-case-btn {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
  border-radius: 5px !important;
}

.ant-modal-footer .ant-btn-primary {
  border: 1px solid #1281b9 !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.doc-dropdown-width {
  width: 250px !important;
}

.action-text-cont .ant-btn {
  font-size: 12px !important;
}

.email-address-btn {
  height: 32px;
  line-height: 30px;
  border: 1px solid #cccccc;
  font-family: Lato-R;
  color: #333333;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 5px;
  background-color: #fff;
  border-radius: 0;
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
}

.imp-emails-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.attachments-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}

.attachment-content-item {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f8950a;
  margin: 3px;
}

.attachment-content-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.template-des-position {
  /*display: inline-block;*/
  vertical-align: -webkit-baseline-middle;
}

.client-email-label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5bc0de;
}

.client-email-popup-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 15px;
}

.client-email-address {
  font-size: 14px;
  color: #185b94;
  display: inline-block;
  /* width: 42%; */
  margin: 0;
  margin-bottom: 15px;
  padding-right: 5px;
  text-transform: capitalize;
}

.client-email-to-row {
  display: flex;
  justify-content: space-between;
}

.body-attachment-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.body-attachment-text {
  margin-left: 10px;
  color: #337ab7;
}

.select-border .ant-select-borderless .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.faq-main-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 12px;
}

.faq-item-main {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #d36205;
}

.faq-item-first {
  width: 100%;
}

.faq-item-first-child {
  /* float: left; */
  font-family: Lato-R;
  font-size: 16px;
  color: #0f82bd;
  padding: 5px 8px;
}

.first-width {
  width: 85%;
}

.faq-item-sec {
  width: 100%;
}

.faq-item-sec-child {
  width: 100%;
  /* float: left; */
  font-family: Lato-R;
  font-size: 14px;
  color: #0f82bd;
  padding: 5px 25px;
}

.faq-head-text {
  color: #07699b;
  font-weight: 500;
  font-size: 16px;
}

.permission-time-zone .timezone-picker .timezone-picker-textfield input {
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
}

.cq-inactive-inner-tab {
  /* background-color: rgb(51, 170, 218); */
  padding: 4px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 1px solid rgb(51, 170, 218);
}

.cq-active-inner-tab {
  background-color: #2e6da4;
  padding: 5px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.cs-attachment-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  /* margin-top: 15px;
  margin-bottom: 20px; */
  padding: 12px;
  /* margin-left: 30px; */
  margin: 15px 30px 20px;
}

.cs-attachment-haeding {
  font-family: Lato-R;
  font-weight: 300;
  color: #073551;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
}
/* .dashboard-summary-table > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
}
.dashboard-student-summary > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
} */
.dashboard-summary-table table colgroup col {
  width: 100px;
  min-width: 100px;
  /*maxHeight: 10px !important;*/
}
.dashboard-student-summary table colgroup col {
  width: 180px !important;
  min-width: 180px !important;
}

.term-condition-container h4 {
  color: #09587e;
  padding-right: 8px;
  text-transform: uppercase;
}

.add-background-color {
}

.add-background-color .ant-select-selector {
  background-color: #ffffff !important;
}
.forgot-loader {
  position: absolute !important;
  top: 0 !important;
}
.supervisor-batches {
  margin: 0 !important;
  /* height: 650px !important; */
  max-height: fit-content;
}
/* .superDashboard .ant-layout-sider-children {
  position: relative !important;
} */

.message-textArea {
  padding: 10px;
  display: block !important;
  background-color: #f5f5f6;
  margin-top: 15px;
  border: 1px solid #ebebec;
}

.message-content-box-radius {
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.input-number {
}

.input-number .ant-input-number {
  width: 100% !important;
}

.row-style {
  display: flex;
  justify-content: space-between;
}

.questionnaire-input-width-0 {
  width: 0%;
}

.questionnaire-input-width-55 {
  width: 55%;
}

.questionnaire-input-width-60 {
  width: 60%;
}

.questionnaire-full-name-input {
  width: 60%;
  border: 0;
}

.full-name-row-padding {
  /* paddingleft: 30;
   paddingRight: 30
                             paddingBottom: 30, */
  padding: 0 30px 30px 30px !important;
}

.questionnaire-label-width-38 {
  width: 38%;
}

@media (max-width: 820px) {
  .contact-info-sec {
    display: block !important;
  }
  .footer-logo {
    width: 100% !important;
  }
  .footer-info-text {
    width: 100% !important;
    display: block !important;
  }
  .footer-info-box {
    width: 100% !important;
  }
  .row-style {
    display: block !important;
  }

  .questionnaire-input-width-55 {
    width: 100% !important;
  }
  .questionnaire-input-width-60 {
    width: 100% !important;
  }
  .questionnaire-full-name-input {
    width: 100% !important;
  }

  .questionnaire-label-width-38 {
    width: 100% !important;
  }

  .cq-container {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .full-name-row-padding {
    padding: 0 0 30px 0 !important;
  }
}

.layout-class .ant-layout.ant-layout-has-sider {
  height: 0% !important;
}

.rem-table-margin .table-card {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.rem-table-margin .ant-table-pagination.ant-pagination {
  margin: 15px !important;
}

/* .cell-margin .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px !important;
} */

.display-inline-block {
  display: inline-block;
}

.display-inline-block .ant-select {
  width: 35px !important;
}

.display-inline-block .ant-select-selector span {
  color: #0052cc !important;
  font-size: 14px !important;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff1f4 !important;
}

.line-height .ant-table-thead > tr > th {
  padding: 0px 8px !important;
}

.p-client .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.color-apply-template {
  color: rgb(54, 177, 228) !important;
}
.color-background-template-stage {
  background-color: rgb(54, 177, 228) !important;
}

.balance-input .ant-input-number:hover {
  border: transparent !important;
  display: none !important;
}

.balance-input .ant-input-number:focused {
  border: none !important;
  display: none !important;
}

.success:focus {
  border: none !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
}

.balance-client-des {
  color: #889093;
  font-size: 10px;
  margin: -4px;
}

.center-elements {
  display: block !important;
  align-items: center !important;
}

.marginTemplate {
  margin-top: -13px;
}

.poppins-font-family {
  font-family: "Poppins", sans-serif !important;
}

.width-cross-browser {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.employer .ant-modal-title {
  font-family: "Poppins", sans-serif !important;
}
.textDecoration {
  text-decoration: none !important;
}

.col-emp .ant-table-column-sorters-with-tooltip {
  margin-left: 15px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  /*padding: 2px !important;*/
}

.ant-checkbox-inner {
  position: unset !important;
  margin-bottom: -4px !important;
  height: 19px !important;
  width: 19px !important;
}

.login-checkbox .ant-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.hover-link {
  text-decoration: none;
  cursor: pointer !important;
  color: black;
}
.hover-link:hover {
  color: #1890ff;
  cursor: pointer !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Poppins" !important;
}

/* span {
  font-family: "Poppins";
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker {
  padding: 6px 11px 6px 10px !important;
}

.client-form .ant-picker {
  padding: 4px 11px 4px !important;
}
/* 
.client-form .ant-row {
  display: block !important;
} */

.d-flex-contract {
  display: flex;
  justify-content: flex-end;
}

.d-flex-contract .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: flex-end !important;
}

.multi-select-option .ant-select-selector {
  height: auto !important;
}

.ant-select-selector {
  height: 32px;
  padding: 0 11px;
  width: auto !important;
}

.ant-select-selector span {
  font-size: 12px !important;
}

.ant-picker-input input {
  font-size: 12px !important;
  font-family: "Poppins" !important;
}

.ant-input {
  font-size: 12px !important;
}

.ant-form label {
  font-size: 12px !important;
  font-family: "Poppins";
}

.client-form .ant-select-selector {
  height: 28px !important;
}

.all-client-form .ant-form-item-label {
  font-weight: 400 !important;
}

.container-box {
  width: 90%;
  margin: auto;
}

.width-100 {
  width: 100% !important;
}

th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

td {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.height-100 {
  height: 100% !important;
}
.width-25 {
  width: 22%;
}
.width-52 {
  width: 52%;
}
.w-20 {
  width: 20%;
}
.w-80-t {
  width: 80%;
}
.width-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
  margin: auto;
}
.p-box {
  padding: 23px !important;
}
.employer-doc-tab .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff !important;
}

.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.flex-end {
  justify-content: flex-end;
}

.float-right {
  float: right !important;
}
.border-3 {
  border-radius: 3px !important;
}
.float-right {
  float: right !important;
}
.mar-zero {
  margin: 10px !important;
  padding: 0px !important;
}
.margin-top {
  margin-top: 13px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-btm {
  margin-bottom: 26px;
}
.d-aligin-center {
  display: flex !important;
  align-items: center !important;
}
.align-items {
  align-items: center;
}
.d-aligin-center p {
  font-size: 12px;
}
.text-style .ant-col .ant-form-item-label > label {
  font-weight: 500;
  color: #737373 !important;
  font-size: 13px;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.top-60 {
  margin-top: 60px;
}
.mar-top-35 {
  margin-top: 35px;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.border-color {
  border: 1px solid #d6d0d0;
  border-radius: 3px;
  margin-bottom: 12px;
}

.border-5px .ant-input {
  border-radius: 5px;
}
.border-5px textarea.ant-input {
  border-radius: 5px;
  margin-top: 12px;
}
.mar-top .ant-form-item-control-input-content {
  margin-top: 40px;
}
.margin-btns .ant-btn-primary {
  margin-left: 12px;
}
.space-between {
  justify-content: space-between;
}
.contant-around {
  justify-content: space-around !important;
}
.contant-center {
  justify-content: center !important;
}
.align-center {
  align-items: center;
}
.reminder-tabs-header .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header-temp .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
}
.reminder-tabs-header-temp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header-temp .ant-tabs-tab-active {
  background: #36b1e4;
}

.reminder-tabs-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header .ant-tabs-tab-active {
  background: #36b1e4;
}
.mar-zero .task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
}
.reminder-btn-main {
  display: flex;
  justify-content: center;
}

.add-task-btn .ant-btn {
  background-color: #1281ba;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.reminder-btn .ant-btn {
  background-color: #ff9e37;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.marked-name {
  background-color: #ff9d36;
  border-radius: 15px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  margin-left: 5px;
}

.marked-name-font {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.mdtr-reminder-tasks-cont {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}
.mdtr-reminder-tasks-header-cont {
  background-color: #f8f9fb;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8eaec;
  margin-top: 33px;
}
.mdtr-reminder-tasks-head-text-cont {
  display: flex;
  align-items: center;
}
.mdtr-rem-row-cont {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8eaec;
}
.cv-bold-text {
  color: #073d5d;
  font-size: 19px;
  font-weight: bolder;
}
.todo-main {
  justify-content: space-between;
  padding: 0px 34px;
  font-weight: 700;
}
.heading-style span {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.mdtr-user-cont {
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
}
.sus-checkbox {
  height: 15px;
  width: 20px;
}
.hide-icon {
  border: 1px solid;
  display: flex;
  padding: 0px 8px;
  border-radius: 50%;
  align-items: center;
}
.reminder-dropdown {
  margin-top: 33px;
}
.reminder-dropdown .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.reminder-dropdown .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.balance-accordian {
  margin-top: 15px !important;
}

.balance-accordian
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px;
  left: auto;
}

.invoice-status-btn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block !important;
}

.balance-accordian
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.balance-accordian .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.balance-accordian .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.template .ant-collapse-header {
  display: block !important;
}

.balance-accordian .ant-collapse-extra {
  margin-top: -37px !important;
  padding-right: 27px !important;
}

.reminder-border {
  border-bottom: 1px solid #d6d6d6;
  margin-top: 11px !important;
}

.reminder-head {
  color: #075a80;
  margin-left: 15px;
  font-weight: bold;
}
.reminder-calender {
  margin: 20px 0px 33px 0px;
}
.sidebar-reminder {
  margin-bottom: 33px;
  margin-top: 22px;
}
.reminder-calender .react-calendar {
  width: 100%;
  padding: 23px;
  border: 0;
}
.completed-tasks {
  margin-top: 45px;
}
.reminder-model-main {
  border-radius: 30px;
}
.reminder-model-main .ant-modal-content {
  /* width: 616px !important; */
  padding: 15px;
  border-radius: 3px;
}
.reminder-model-main .mdtr-modal-gray-cont {
  background-color: #f6f7f9;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e4e4e4;
  display: flex;
  border-radius: 5px;
  padding-right: 20px;
}
.reminder-model-main .mdtr-checkbox-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reminder-model-main .cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
}
.reminder-model-main .profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}
.reminder-model-main .profile-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}
.reminder-model-main .mdtr-add-tasks {
  background-color: #1281b9;
  padding: 7px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.reminder-model-main .ant-modal-header {
  border-bottom: none !important;
  color: #6c6c6c !important;
  padding: 2px 26px 0px 26px;
  font-size: 18px;
  margin-bottom: 38px;
  border-radius: 30px 30px 30px 30px !important;
}
.reminder-model-main .ant-modal-title {
  /* color: #6c6c6c; */
  font-size: 14px;
  font-weight: 600;
}
.reminder-model-main .cv-bold-text {
  font-size: 12px;
  font-weight: 600;
  margin-top: 36px;
}
.reminder-model-main .ant-modal-body {
  padding: 0px 20px;
}
.reminder-model {
  border-radius: 40px;
}
.reminder-model-main .emp-ae-input-width p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.reminder-model-main .emp-ae-input-width {
  margin-right: 32px;
}
.modal-parts {
  width: 84%;
  margin: auto;
}
.reminder-model-main .ant-form-item {
  padding: 15px 0px;
}
.form-parts .ant-form-item-label {
  font-weight: 500;
}
.bg-box-white {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  padding: 22px 0;
}
.mar-b {
  margin-bottom: 32px;
}
.w-96 {
  width: 96%;
  margin: auto;
}
.mar-tb {
  padding: 20px 5px;
}
.comment-btn .ant-btn-primary {
  float: right !important;
  margin-top: 14px;
  border-radius: 4px;
  border: 0px;
}
.follower-row h4 {
  margin-right: 13px;
}
.mar-right {
  margin-right: 7px !important;
}
/* .tasks-comment .ant-comment {
  width: 96%;
  margin: auto;
} */
.box-lenght {
  max-height: 400px;
  overflow: scroll;
}

.box-lenght::-webkit-scrollbar {
  width: 4px;
}
.box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 0px;
}
.box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.completed-box-lenght {
  max-height: 1000px;
  overflow: scroll;
}
.completed-box-lenght::-webkit-scrollbar {
  width: 4px;
}
.completed-box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.completed-box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}
.completed-box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.color-check {
  margin: 5px 8px 0px 0px;
  border-radius: 2px;
}
.delete-model p {
  font-size: 15px;
  font-weight: 200;
  padding: 13px 0px 0px 7px;
}
.margin-right {
  margin-right: 10px;
}
.client-form .ant-form-item {
  display: block;
  margin-top: 9px;
}
.all-client-form .ant-form-item-label {
  font-weight: 600;
}
.header-text-style {
  font-size: 17px;
  font-weight: 500;
  color: #083c5d;
  padding: 30px 40px 0px 20px;
}
.all-client-form .client-form .ant-form-item-no-colon {
  color: #55626c !important;
}
.all-client-btn {
  margin: 25px 23px 0 0px;
  justify-content: flex-end;
}
.reminder-settings {
  width: calc(100% - 170px);
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 50px;
  margin: 35px 85px;
  border-radius: 3px;
}

.invoice-table-body-dates {
  font-size: 14px;
  font-weight: 450;
  color: black;
  padding-bottom: 5px;
}
.client-tag-table .ant-row .ant-form-item {
  display: block;
}

.reminder-set-form {
  /* background-color: #ffffff; */
  /* padding: 30px; */
  /* border-radius: 5px; */
  /* border: 1px solid #eeeeee; */
}
.reminder-set-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-set-form p {
  font-size: 16px;
  /* color: #556370; */
  font-weight: 100;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 100;
}

.reminder-setting-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.imap-set-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.imap-set-form .ant-form-item-label {
  margin-right: 0px;
}
.margin-top-34 {
  margin-top: 34px;
}
.Reminder-top {
  width: 100%;
  /* margin: auto; */
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
}
.time-tracking {
  width: 95%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}
.employer-manag {
  width: 91%;
  height: 100%;
  margin: 35px auto;
}
.dynamic-keys {
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  padding: 4px 0px 6px 17px;
}
.dynamicForm {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin: 21px 0px 59px 0px;
}
.mail-Chimp-text {
  padding-top: 35px;
  font-size: 16px;
}
.mail-Chimp-text p {
  font-size: 13px;
  font-weight: 450;
  margin-top: 0px !important;
}
.mail-text p {
  padding-left: 35px;
}
.mail-chimp-btn {
  display: flex;
  justify-content: flex-end;
}
.mail-chimp-box {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 54px 74px 54px;
  margin: 35px auto;
  border-radius: 3px;
}
.default-font-box {
  width: 98%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 35px 20px;
  border-radius: 3px;
}
.document-sync-box {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 10px auto 35px auto;
  border-radius: 3px;
}

.document-checklist-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 17px;
  margin: 35px auto;
  border-radius: 3px;
}
.document-checklist--btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
}
.email-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  margin: 27px auto;
  border-radius: 3px;
}
.email-content-box h4 {
  padding-bottom: 20px;
  color: #448ccb;
  font-size: 16px;
}
.questionnaire-content {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 81px;
  margin: 35px auto;
  border-radius: 3px;
}
.questionnaire-top {
  width: 82%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}

.letter-font p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-spacing {
  margin: 16px 0px 0px 0px;
}
.letter-height {
  height: 500px;
}

.letter-froala p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-froala .fr-box.fr-basic .fr-element {
  max-height: 500px;
  border-bottom: 2px solid #cccccc;
  min-height: 274px;
}
.fr-second-toolbar {
  display: none !important;
}
.file-uploader .ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9 !important;
  background-color: transparent;
  padding: 5rem;
  margin-bottom: 2rem;
}
.file-uploader .ant-upload-text {
  color: #1890ff !important;
}
.file-uploader {
  margin-top: 15px;
}
.file-uploader .ant-upload {
  border-radius: 0px !important;
}
.border-box {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-emp-manag {
  background: white;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-add-emp {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px 50px;
  margin-top: 38px !important;
}
.border-box-employer {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 34px;
  /* margin-top: 38px !important; */
}
.padding-20 {
  padding: 20px !important;
}

.w-9 {
  width: 13%;
}
.w-91 {
  width: 87%;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.mar-top-75 {
  margin-top: 75px;
}
.doc-office {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.Upload-signature .avatar-uploader > .ant-upload {
  width: 190px;
  height: 128px;
  border-radius: 0% !important;
  margin-bottom: 10px;
}
.Upload-signature .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background: white;
  border: 2px dotted #d9d9d9 !important;
  border-radius: 0px !important;
}
.MainSideBar .ant-tabs-nav-list {
  background-color: #213037;
  color: #fff;
}
.dashboardSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.dashboardSideBar {
  width: 265px !important;
  /* min-width: 100% !important; */
}
.supervisorSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.supervisorSideBar {
  width: 262px !important;
}
.site-layout {
  background: #ffffff;
  /* height: 100%; */
  position: fixed;
  margin-top: 64px;
  /* min-width: 265px !important; */
  overflow: auto;
}
.site-layout .ant-layout-sider-children {
  overflow: auto;
  position: fixed;
}
.screen-width {
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
  /* margin-left: 265px; */
  /* margin-top: 64px; */
}

.dashboardSideBar .ant-menu-item a {
  /* padding-left: 14px !important; */
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.dashboardSideBar .ant-menu .ant-menu-dark .ant-menu-root .ant-menu-inline {
  color: #ffffff;
  font-size: 18px;
  border: 0px;
}
.dashboardSideBar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: red !important;
}
.ant-layout-header {
  position: fixed;
  z-index: 10;
  width: 100%;
  /* height: 3rem; */
  background: #fff !important;
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
  overflow: overlay;
  width: 100%;
}
/* .open-sidebar div>div>div{
  position: relative!important;
}
.close-sidebar div>div>div{
  position: relative!important;
} */
/* added by aatif */
.site-layout {
  position: absolute !important;
  /* position: fixed !important; */
}
.ant-layout-content {
  padding-top: 65px !important;
}
.site-layout .ant-layout.ant-layout-has-sider {
  width: 100% !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: rgba(33, 48, 55, 255) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(69 89 99);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #f1e9e9 !important;
}
.employer-table .ant-table-pagination.ant-pagination {
  margin: 7px 26px !important;
}
/*.employer-table .ant-table-cell {*/
/*  text-align: center;*/
/*}*/
/* .table-head-clr .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
}
.table-head-clr .ant-table-column-sorters-with-tooltip {
  background-color: #f0f2f5;
} */
.add-employer-para .ant-col .ant-col-12 p {
  font-size: 14px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.employer-manag-tabs .ant-tabs-nav-wrap {
  background-color: #f0f2f5 !important;
}
.employer-manag-tabs .ant-tabs-tab {
  color: black;
  font-weight: 800 !important;
  font-size: 16px;
  margin: 0px;
  background: #0383bc !important;
  padding: 5px 17px !important ;
  font-size: 12px !important;
}
.employer-manag-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.employer-manag-tabs .ant-tabs-tab-active {
  background: #36b1e4 !important;
}
.employer-manag-tabs .ant-form-item {
  padding: 15px 0px !important;
}

.employer-manag-tabs .ant-tabs-tab {
  margin-right: 0px !important;
  border-radius: 0px !important;
}
.employer-manag-tabs .ant-tabs-tab-btn {
  font-weight: 500;
  color: white;
}
.upload-button .ant-btn {
  border-radius: 0px !important;
}
.employer-head {
  margin-top: 33px;
  margin: 44px 0px 10px 10px;
}
.form-add-btn {
  width: 26px;
  border-radius: 33px !important;
  height: 26px;
  /* float: right; */
}
.form-add-btn .ant-btn-icon-only {
  height: 25px;
  border-radius: 45px;
  margin-bottom: 4px;
  /* position: fixed; */
  z-index: 20;
}
.form-add-btn .ant-btn-icon-only svg {
  margin-bottom: 4px;
}
.remove-button {
  cursor: pointer;
  background-color: #f52b2b;
  color: white;
  display: flex;
  width: 5%;
  padding: 7px 14px;
  border-radius: 44px;
  font-weight: 400;
  margin: 35px 0px;
}
.remove-button:hover {
  background-color: #1890ff;
  color: white;
}
.contact-Head {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.contact-emp {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin-top: 25px;
}
.add-job-form .ant-row .ant-col .ant-col-4 {
  font-size: 19px !important;
}
.add-job-form .ant-input-number {
  width: 100% !important;
}
.emp-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  /*margin: 27px auto;*/
  border-radius: 3px;
}
.emp-froala h4 {
  color: #448ccb;
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 12px;
}

.green-btn {
  background: #0ca22c !important;
  border: 0px !important;
  color: #fff !important;
}
.margin-client {
  display: flex;
  margin: 18px 0px 0px 15px;
}
.padding-btn {
  padding: 10px 23px;
}
.file-note-light {
  background-color: #fff;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}
.payment-form-labels {
  margin-top: 16px;
}
.file-note-dark {
  background-color: #fffcfc;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}

.accounts-flow-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.table-row-light {
  background-color: #fff !important;
  /* padding: 6px 16px; */
}
.table-row-dark {
  background-color: #f0f2f5 !important;
  /* padding: 6px 16px; */
}
.file-contacts .ant-row .ant-form-item {
  width: 90%;
  margin: auto;
}
.file-delete-head {
  margin: 18px 25px 0px 23px;
}
.border-color-blue .ant-tabs-tabpane .ant-tabs-tabpane-active {
  border: 1px rgb(16, 129, 185);
}
/*.ant-table-thead > tr > th {*/
/*  text-align: center !important;*/
/*  font-size: 13px;*/
/*}*/

.school-table .ant-table-thead > tr > th {
  text-align: left !important;
  font-size: 14px !important;
}

.child-invoice-table .table-head .table-tbody > tr > th {
  padding: 16px 0px 16px 0px !important;
}

.table-head .ant-table-column-sorters {
  padding: 11px 0px;
}
.table-head .ant-table-column-sorters-with-tooltip {
  text-align: center;
}
.table-head .ant-table-cell {
  /* text-align: center; */
}
.table-head .table-tbody > tr > td {
  align-items: center !important;
  padding: 12px 0px !important;
}
/* .table-head .ant-table-cell {
  padding: 10px !important;
} */
.ant-select {
  width: 100%;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
  margin-top: 4px;
}
/* .employer-Header {
  height: 54px;
} */
.alert-msg .ant-form-item-explain .ant-form-item-explain-error {
  float: right !important;
}
/* .required p::before {
  content: "*";
  position: absolute;
  /* right: -8px; */
/* left: 18px;
color: red;
top: -2px; */
/* } * */
.job-table .ant-table-cell {
  padding: 8px 4px 8px 10px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}
.contact-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-table .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}

.contact-tables .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-tables .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  margin: 16px 42px !important;
  text-align: left;
  background: transparent !important;
}

.job-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}
.job-table .ant-table-container {
  /* padding: 20px; */
}

.contact-us .ant-table-cell {
  padding: 13px 7px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: center;
  color: #474a4c;
}
.contact-us .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.add-employer-para .ant-col .ant-col-12 p {
  font-size: 13px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.width-web-kit {
  width: -webkit-fill-available !important;
}

.width-modal-sending-receipt {
  width: 1022px !important;
}

.width-modal-add-template {
  width: 641px !important;
}

.width-modal-add-email-template {
  width: 70vw !important;
}

.border-box-modal-sending-receipt {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 72px 10px 17px;
  margin-top: 38px !important;
}

.font-school-list-filter-text {
  font-size: smaller;
  font-weight: 700;
}

.font-school-list-filter-text-sub {
  color: #889093;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.sus-tab-container-schools {
  display: flex;
  margin: 30px 0px 0px 20px;
  cursor: pointer;
}

.sus-tab-container-clients {
  display: flex;
  margin-left: 20px;
  margin: 44px 0px 0px 20px;
  cursor: pointer;
}

.sus-active-tab-schools {
  background-color: rgb(51, 170, 218);
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.sus-inactive-tab-schools {
  padding: 5px 20px;
  display: flex;
  background-color: #0383bc;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.upload-technical-support-complaint .ant-upload.ant-upload-select-picture-card {
  margin-top: 9px;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.upload-technical-support-complaint .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 9px 8px 8px 0;
  vertical-align: top;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-active-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ts-container-schools {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.button {
  color: #fff !important;
  background: #096dd9 !important;
  border-color: #096dd9 !important;
  padding: 5px 16px !important;
  border-radius: 2px !important;
}

.cont-positioning {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.border-box-modal {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: 38px !important;
}

.add-new-line-container {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: auto;
  margin-top: 16px;
  margin-left: 16px;
  padding-bottom: 15px;
}
.add-new-line-header {
  background-color: #e6e6e6;
  height: 26px;
}

.add-new-line-header-school-invoices {
  background-color: #e6e6e6;
}

.add-new-line-inputs {
  padding: 7px;
}

.add-new-line-header-position {
  margin-top: 5px;
  text-align: center;
}

.add-new-line-button {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: 36px;
  width: 107px;
  margin-left: 22px;
  margin-top: 6px;
  cursor: pointer;
}
.add-new-line-button p {
  text-align: center;
  font-size: 13px;
}

.width-modal {
  width: 702px !important;
}

.label-search-schools {
  font-size: 16px;
  font-weight: bold;
  color: rgb(24, 91, 148);
}

.width-modal-outgoing-payments {
  width: 735px !important;
}

.incoming-payments-table-row {
  background: #bcf6c5 !important;
}

.invoices-children-table-row {
  background: #e5e4e2 !important;
}

.outgoing-payments-table-row {
  background: #f6d6d0 !important;
}

.font-size-add-school-form {
  font-size: 12px;
}

.text-style-show-container {
  color: #fff;
}

.ca-gray-padding {
  padding: 15px 20px 15px 0px !important;
}

.ca-gray-padding-payment-history {
  padding: 15px 20px 15px 20px !important;
}

.payment-buttons-container {
  display: flex;
  margin-left: 19px;
  margin-top: 10px;
}

.payment-label {
  font-size: 12px;
  font-weight: initial;
  margin-left: 19px;
}

.show-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: -3%;
  cursor: pointer;
}

.display-contact-text-container {
  display: block;
  margin-inline-start: 20px !important;
}

.margin-contact-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-icon {
  margin-top: -35px !important;
}

.haha {
  border: 1px solid red;
}

.border-box-modal-add {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}

.positioning-delete-icon {
  margin-top: 7px;
}

.positioning-show-levels {
  text-align: center;
}

.border-show-contacts {
  border-bottom: 1px solid rgb(214, 208, 208);
  width: 587px;
  margin-left: -11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.border-add-template-stages {
  border-bottom: 1px solid rgb(214, 208, 208);
  padding-top: 22px;
  margin-left: 25px;
}

.border-detail {
  border-bottom: 1px solid rgb(214, 208, 208);
}

.hide-levels-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: 7%;
  cursor: pointer;
}

.ts-container-commission-reminders {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.redo-icon {
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.upload-technical-support-complaint {
  margin-top: 13px !important;
  margin-left: 20px !important;
}

.upload-technical-support {
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.employer-doc-tab .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.employerin-box {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 56px;
  height: 100%;
  border-radius: 3px;
}
.ant-table-cell .ant-table-column-sort .ant-table-column-has-sorters {
  background: white;
}
.ant-table-column-sorters-with-tooltip {
  background: white;
}
/* .ant-table-cell {
  background: white !important;
} */
.ant-table-cell .ant-table-selection-column {
  background: white !important;
}
.document-table .ant-table-cell {
  text-align: center;
}
.send-btn .ant-btn-primary {
  border-radius: 4px !important;
}
.border-box-checklist {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 13px 54px;
}
.checklist-add-field {
  background: #f5f5f5;
  border: 0px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}
.checklist-textarea .ant-input {
  height: 100px !important;
}
.checklist-switch .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch .ant-switch-checked {
  height: 10px;
  background-color: #bf0909b8 !important;
}
.checklist-switch .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #aa2626 !important;
}
.checklist-switch .ant-switch-inner {
  height: 10px;
  /* background-color: black !important; */
}
.checklist-switch-low .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch-low .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #eec914;
}
.checklist-switch-low .ant-switch-checked {
  height: 10px;
  background-color: #eec914ad !important;
}
.checklist-switch-low .ant-switch-inner ::before {
  height: 10px;
  background-color: black !important;
}
.checklist-switch-low .ant-form-item-label > label {
  height: 22px !important;
}
.checklist-switch .ant-form-item-label > label {
  height: 22px !important;
}

.all-client-form .ant-form-item-label > label {
  height: 22px !important;
}

.imap-text p {
  font-size: 13px !important;
  /* color: rgb(141, 139, 139) !important; */
}
.imap-text {
  font-size: 15px !important;
}
.input-name {
  font-weight: 500 !important;
}
.employer-logo-box {
  background-color: #fff;
  justify-content: center !important;
  display: flex !important;
  padding: 50px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 26%);
}
.container {
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.link-client-logo {
  width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  height: 8rem;
}
.footer-info-text {
  display: flex;
}
.red-dot {
  height: 14px;
  width: 14px;
  background-color: #e61616;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}
.yellow-dot {
  height: 14px;
  width: 14px;
  background-color: #faad14;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}

.upload-image-heading {
  margin-top: 70px;
}

.personal-info-heading {
  margin-bottom: 24px;
  padding-top: 18px;
}

.ts-container-add-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 35px 30px 0px;
  border-radius: 3px;
}

.personal-info-add-client-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-right: 30px;
  border-radius: 6px;
  margin-left: 35px;
  padding-bottom: 37px;
}

.personal-info-add-client-container
  .ant-form-horizontal
  .ant-form-item-control {
  min-width: 100% !important;
}

.ts-container-import-clients {
  background-color: #ffffff;
  margin: 15px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.post-to-xero {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.xero-filters-label {
  width: 19%;
  margin-top: -5px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border: solid 10px transparent;
}

#style-3::-webkit-scrollbar {
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px;
}

.save-button {
  margin-left: 7px;
  margin-top: 29px;
}

.container-center {
  height: 2em;
  position: relative;
}
div.container-center h5 {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ts-potential-client-statuses {
  background-color: #ffffff;
  margin: 50px 13px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 2px 16px 5px;
  border-radius: 5px;
}

.duplicate-client-table-container {
  margin-top: 37px;
  margin-left: 31px;
  margin-bottom: 15px;
}

.spinner-xero {
  text-align: center;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  -webkit-align-content: center;
  align-content: center;
  vertical-align: middle;
}

.spinner {
  text-align: center;
  z-index: 1000;
  padding-top: calc(50% - 25%);
  width: 100%;
  height: 100vh;
  -webkit-align-content: center;
  align-content: center;
  vertical-align: middle;
}
.client-table .ant-table {
  border: 1px solid rgb(184, 179, 179) !important;
  border-radius: 2px;
}
.employer-btn {
  /* border-radius: 6px !important; */
  border: 0px !important;
}
.emp-w-box {
  padding: 29px 84px;
  background-color: white;
  margin-top: 20px 20px;
}

.emp-w-box-employer {
  padding: 29px 84px;
  background-color: rgb(245 247 248) !important;
  margin-top: 20px 20px;
}

.emp-profile-table {
  background-color: whitesmoke;
}
.auto-email {
  border: 1px solid #c9c9ca;
  padding: 14px 41px !important;
  margin-bottom: 10px;
}
.create-email-form .ant-form-item {
  padding: 7px 0px !important;
}
.visa-body-text {
  justify-content: space-between;
  display: flex;
  max-width: 64%;
}
.visa-body-text span {
  color: #5c5c5c;
  font-weight: 450;
}
.visa-status-header {
  height: 100px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 90%;
  margin: auto;
}
.status-header-left {
  width: 80%;
  background-color: #f3f3f3;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.status-header-left h2 {
  color: #858585;
  margin: 0;
}
.status-header-left p {
  color: #858585;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-top: 6px;
}
.event-visa-btn {
  background: #f6752f;
  border: 0;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 3px 17px;
}
.status-header-right {
  width: 20%;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}
.status-header-right h2 {
  color: #ffffff;
  margin: 0;
}
.visa-status-body {
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px 30px 30px 30px;
}
.visa-status-body button {
  margin-bottom: 7px;
}
.visa-status-body-data {
  background-color: white;
  width: 100%;
  margin: auto;
  border-radius: 5px !important;
  padding: 30px !important;
}
.case-new {
  width: 90%;
  background-color: #36b1e4;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  height: 8rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-top: 40px;
  margin-top: 10px;
}
.case-new h4 {
  color: #fff;
  font-size: 18px;
}
.incoive-table .ant-table-container {
  background: #eff1f4;
  width: 90%;
  margin: auto;
}
.file-spacing {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
}
.document-table .ant-row .ant-form-item {
  /* justify-content: center; */
  width: 40%;
}
.close-editor {
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}
.add-emp-form .ant-form-item-explain {
  justify-content: flex-start !important;
  display: flex !important;
}
.slider-small {
}
.slider-small .ant-layout-sider-children {
  width: 84px !important;
}
.slider-small .ant-layout-sider {
  background-color: transparent;
}
.site-layout-background {
  width: 100% auto !important;
}
.open-sidebar {
  width: 89%;
  margin-left: 265px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.rightbar-icons {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.rightbar-icons img {
  margin-right: 10px;
}

.employer-tabs-content > .ant-tabs-content-holder {
  background-color: #f0f2f5 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.employer-tabs-contents > .ant-tabs-content-holder {
  font-size: 12px !important;
  font-weight: inherit !important;
  font-family: "Poppins", sans-serif !important;
}

.profile-tags {
  padding: 4px 10px;
  border: 1px solid #3fb5cf;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.tags-text {
  font-size: 10px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}
.signup-contaienr {
  width: 80%;
  margin: auto;
}
.signup-container {
  width: 67%;
  margin: 51px auto;
  border: 1px solid #cecece;
  padding: 20px 0px;
  background-color: white;
}
.signup-fields .ant-form-item {
  padding: 15px 0 !important;
}
.signup-fields {
  width: 87%;
  margin: 10px auto 32px auto;
  background-color: #f0f2f5;
  padding: 10px 63px;
}
.signup-text {
  margin: 18px 0px;
}
.signup-text span {
  /* font-size: 14px; */
  font-weight: 600;
  color: #5d5d5d;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.save-signup .ant-form-item-control-input {
  width: 88%;
  margin: auto;
}

.cmv-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 16px !important;
}
.cmv-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.ant-input-number-handler-wrap {
  position: absolute;
  display: none !important;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  /*border-left: 1px solid #d9d9d9;*/
  border-radius: 0 2px 2px 0;
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.1s;
  transition: opacity 0.24s linear 0.1s;
}
.profile-container {
  width: 98%;
  margin: auto;
  padding-top: 45px;
  display: flex;
}
.profile-input-border .ant-form-item-control-input {
  min-height: 30px !important;
}
.sign-up-head {
  justify-content: space-around;
  align-items: center;
}
.head-d {
  border: 1px solid;
  height: 10px;
}
.head-link {
  padding: 0 10px 10px 0;
}
.head-text h1 {
  color: #095c81;
}
.email-input input {
  width: 440px;
  height: 39;
  border-radius: 44px 0px 0px 44px;
  background: #eeeeee;
  border: 0px;
}
.sign-up-btn button {
  width: 160 !important;
  border-radius: 44px;
  height: 39;
}
.sign-up-btn .ant-col {
  margin-left: -6px !important;
  padding-left: 0px;
}
.email-input .ant-col {
  padding: 0px !important;
}
.bg-white .ant-layout {
  background-color: white;
}

.sign-up-btn .ant-btn-primary {
  background: #083c5d;
  border: 0px;
  margin-left: -20px;
  margin-bottom: 40px;
}
.sign-up-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: rgb(3 131 188);
  border-color: #fff;
  border: 1px solid;
}
.email-input .ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #eeeeee !important;
}
.signup-email .ant-row {
  justify-content: center;
}
.video-btn {
  text-align: center;
}
.video-btn button {
  height: 65px;
  width: 274px !important;
  background: #083c5d;
  border: 0px;
  border-radius: 4px;
}

.quick-box {
  background: #f5f5f5;
  text-align: center;
  padding: 28px 0px;
  margin-top: 23px;
}
.feature-btn button {
  border-radius: 44px;
  margin-top: 30px;
  background: #083c5d;
  border: 0;
}
.feature-content {
  font-weight: 600;
  font-size: 32px;
  color: black;
}
.feature-content-lg {
  font-size: 30px;
}
.quick-box .ant-row {
  justify-content: center;
}
.features-img .ant-image-img {
  margin-bottom: -112px;
}
.amzing-feature .ant-row {
  justify-content: center;
}
.signup-header .ant-layout-header {
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 22px -13px rgb(11 9 10 / 59%);
}
.watch-video .ant-btn-primary {
  background: #1281ba;
  border: 0px;
  border-radius: 32px;
  width: 182px;
  height: 45px;
  margin: 21px;
}
.client-docs {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
}
.client-ehead {
  width: 48%;
  margin: auto;
}
.draft {
  padding: 3px 12px;
  background: #d9534f;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}
.sent-email {
  padding: 3px 12px;
  background: #5cb85c;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}

.client-doc-main {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.doc-filter .ant-select-dropdown {
  width: 119px !important;
}
.add-emp-btn .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}
.required-field {
  color: red;
  padding-left: 2px;
}
.client-detail-modal .ant-modal-body {
  background: rgb(245 245 245);
  margin: 24px;
  border: 1px solid rgb(192 190 190);
}
.detail-head {
  font-size: 18px;
  font-weight: 500;
  color: #636363;
}
.search-name {
  font-size: 15px;
  color: rgb(59, 59, 59);
  font-weight: 500;
  width: 100%;
}
.search-text {
  font-size: 13px;
  color: rgb(126, 126, 126);
  font-weight: 400;
  width: 100%;
}
.docuemt-padding {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.filenote-box {
  width: 98%;
  margin: 0 auto 50px auto;
}
.emp-profile-box {
  width: 90%;
  margin: 0 auto 50px auto;
}

.emp-profile-box-employer {
  width: 98%;
  margin: 0 auto 50px auto;
}

.filenote-btn .ant-form-item-control-input-content {
  margin-top: 10px;
}
.float-left {
  float: left;
}
.agreement-data {
  font-weight: 500;
  color: #556370;
  margin-right: 8px;
}
.single-job
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
  padding: 15px 16px !important;
}
.add-client-form-inputs .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label .ant-form-item-no-colon {
  padding: 0px !important;
  margin: 0;
  font-weight: 600;
}
.add-client-form-inputs .ant-form-item-no-colon {
  /* font-size: 11px !important; */
  color: #556370 !important;
  font-weight: 500;
}

.table-action .anticon {
  margin-right: 2px;
  cursor: pointer;
  color: #4b90cd;
  font-size: 16px;
}
.table-action {
  display: flex;
  align-items: center;
}

.BranchPermissions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminder-small-text {
  color: #33aada;
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
}

.reminder-bold-text {
  color: #33aada;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}

.reminder-date-text {
  color: #555555;
  font-size: 12px;
  margin: 0px;
}

.reminder-tabs-cont {
  border: 1px solid #e1e1e1;
  margin: 10px 15px;
  margin-left: 0px;
  padding: 10px;
}

.ant-tabs-tab-btn {
  width: 100% !important;
}

.short-tabs .ant-tabs-nav-wrap {
  white-space: unset !important;
  width: 115px !important;
  background-color: rgb(243, 244, 246) !important;
}
.client-tabs .ant-tabs-nav-wrap {
  background-color: rgb(243, 244, 246) !important;
}

.short-tabs .ant-tabs-tab {
  margin: 0px !important;
  border-bottom: 1px solid #e8eaec !important;
  padding: 8px 10px !important;
  color: #083c5d;
}

.content-width-open-sidebar {
  width: calc(100% - 458px) !important;
}
.content-width-close-sidebar {
  width: calc(100% - 140px) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}

.ant-modal-title {
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.ec-new-case-btn {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
  border-radius: 5px !important;
}

.ant-modal-footer .ant-btn-primary {
  border: 1px solid #1281b9 !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.doc-dropdown-width {
  width: 250px !important;
}

.action-text-cont .ant-btn {
  font-size: 12px !important;
}

.email-address-btn {
  height: 32px;
  line-height: 30px;
  border: 1px solid #cccccc;
  font-family: Lato-R;
  color: #333333;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 5px;
  background-color: #fff;
  border-radius: 0;
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
}

.imp-emails-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.attachments-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}

.attachment-content-item {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f8950a;
  margin: 3px;
}

.attachment-content-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.template-des-position {
  /*display: inline-block;*/
  vertical-align: -webkit-baseline-middle;
}

.client-email-label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5bc0de;
}

.client-email-popup-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 15px;
}

.client-email-address {
  font-size: 14px;
  color: #185b94;
  display: inline-block;
  /* width: 42%; */
  margin: 0;
  margin-bottom: 15px;
  padding-right: 5px;
  text-transform: capitalize;
}

.client-email-to-row {
  display: flex;
  justify-content: space-between;
}

.body-attachment-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.body-attachment-text {
  margin-left: 10px;
  color: #337ab7;
}

.select-border .ant-select-borderless .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.faq-main-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 12px;
}

.faq-item-main {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #d36205;
}

.faq-item-first {
  width: 100%;
}

.faq-item-first-child {
  /* float: left; */
  font-family: Lato-R;
  font-size: 16px;
  color: #0f82bd;
  padding: 5px 8px;
}

.first-width {
  width: 85%;
}

.faq-item-sec {
  width: 100%;
}

.faq-item-sec-child {
  width: 100%;
  /* float: left; */
  font-family: Lato-R;
  font-size: 14px;
  color: #0f82bd;
  padding: 5px 25px;
}

.faq-head-text {
  color: #07699b;
  font-weight: 500;
  font-size: 16px;
}

.permission-time-zone .timezone-picker .timezone-picker-textfield input {
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
}

.cq-inactive-inner-tab {
  /* background-color: rgb(51, 170, 218); */
  padding: 4px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 1px solid rgb(51, 170, 218);
}

.cq-active-inner-tab {
  background-color: #2e6da4;
  padding: 5px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.cs-attachment-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  /* margin-top: 15px;
  margin-bottom: 20px; */
  padding: 12px;
  /* margin-left: 30px; */
  margin: 15px 30px 20px;
}

.cs-attachment-haeding {
  font-family: Lato-R;
  font-weight: 300;
  color: #073551;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
}
/* .dashboard-summary-table > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
}
.dashboard-student-summary > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
} */
.dashboard-summary-table table colgroup col {
  width: 100px;
  min-width: 100px;
  /*maxHeight: 10px !important;*/
}
.dashboard-student-summary table colgroup col {
  width: 180px !important;
  min-width: 180px !important;
}

.term-condition-container h4 {
  color: #09587e;
  padding-right: 8px;
  text-transform: uppercase;
}

.add-background-color {
}

.add-background-color .ant-select-selector {
  background-color: #ffffff !important;
}
.forgot-loader {
  position: absolute !important;
  top: 0 !important;
}
/* .superDashboard .ant-layout-sider-children {
  position: relative !important;
} */

.message-textArea {
  padding: 10px;
  display: block !important;
  background-color: #f5f5f6;
  margin-top: 15px;
  border: 1px solid #ebebec;
}

.message-content-box-radius {
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.input-number {
}

.input-number .ant-input-number {
  width: 100% !important;
}

.row-style {
  display: flex;
  justify-content: space-between;
}

.questionnaire-input-width-0 {
  width: 0%;
}

.questionnaire-input-width-55 {
  width: 55%;
}

.questionnaire-input-width-60 {
  width: 60%;
}

.questionnaire-full-name-input {
  width: 60%;
  border: 0;
}

.full-name-row-padding {
  /* paddingleft: 30;
   paddingRight: 30
                             paddingBottom: 30, */
  padding: 0 30px 30px 30px !important;
}

.questionnaire-label-width-38 {
  width: 38%;
}

@media (max-width: 820px) {
  .contact-info-sec {
    display: block !important;
  }
  .footer-logo {
    width: 100% !important;
  }
  .footer-info-text {
    width: 100% !important;
    display: block !important;
  }
  .footer-info-box {
    width: 100% !important;
  }
  .row-style {
    display: block !important;
  }

  .questionnaire-input-width-55 {
    width: 100% !important;
  }
  .questionnaire-input-width-60 {
    width: 100% !important;
  }
  .questionnaire-full-name-input {
    width: 100% !important;
  }

  .questionnaire-label-width-38 {
    width: 100% !important;
  }

  .cq-container {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .full-name-row-padding {
    padding: 0 0 30px 0 !important;
  }
}

.layout-class .ant-layout.ant-layout-has-sider {
  height: 0% !important;
}

.rem-table-margin .table-card {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.rem-table-margin .ant-table-pagination.ant-pagination {
  margin: 15px !important;
}

/* .cell-margin .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px !important;
} */

.display-inline-block {
  display: inline-block;
}

.display-inline-block .ant-select {
  width: 35px !important;
}

.display-inline-block .ant-select-selector span {
  color: #0052cc !important;
  font-size: 14px !important;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff1f4 !important;
}

.line-height .ant-table-thead > tr > th {
  padding: 0px 8px !important;
}

.p-client .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.color-apply-template {
  color: rgb(54, 177, 228) !important;
}
.color-background-template-stage {
  background-color: rgb(54, 177, 228) !important;
}

.balance-input .ant-input-number:hover {
  border: transparent !important;
  display: none !important;
}

.balance-input .ant-input-number:focused {
  border: none !important;
  display: none !important;
}

.success:focus {
  border: none !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
}

.balance-client-des {
  color: #889093;
  font-size: 10px;
  margin: -4px;
}

.center-elements {
  display: block !important;
  align-items: center !important;
}

.marginTemplate {
  margin-top: -13px;
}

.poppins-font-family {
  font-family: "Poppins", sans-serif !important;
}

.width-cross-browser {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.employer .ant-modal-title {
  font-family: "Poppins", sans-serif !important;
}
.textDecoration {
  text-decoration: none !important;
}

.col-emp .ant-table-column-sorters-with-tooltip {
  margin-left: 15px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  /*padding: 2px !important;*/
}

.ant-checkbox-inner {
  position: unset !important;
  margin-bottom: -4px !important;
  height: 19px !important;
  width: 19px !important;
}

.login-checkbox .ant-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.hover-link {
  text-decoration: none;
  cursor: pointer !important;
  color: black;
}
.hover-link:hover {
  color: #1890ff;
  cursor: pointer !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Poppins" !important;
}

/* span {
  font-family: "Poppins";
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker {
  padding: 6px 11px 6px 10px !important;
}

.client-form .ant-picker {
  padding: 4px 11px 4px !important;
}
/* 
.client-form .ant-row {
  display: block !important;
} */

.d-flex-contract {
  display: flex;
  justify-content: flex-end;
}

.d-flex-contract .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: flex-end !important;
}

.multi-select-option .ant-select-selector {
  height: auto !important;
}

.ant-select-selector {
  height: 32px;
  padding: 0 11px;
  width: auto !important;
}

.ant-select-selector span {
  font-size: 12px !important;
}

.ant-picker-input input {
  font-size: 12px !important;
  font-family: "Poppins" !important;
}

.ant-input {
  font-size: 12px !important;
}

.ant-form label {
  font-size: 12px !important;
  font-family: "Poppins";
}

.client-form .ant-select-selector {
  height: 28px !important;
}

.all-client-form .ant-form-item-label {
  font-weight: 400 !important;
}

.container-box {
  width: 90%;
  margin: auto;
}

.width-100 {
  width: 100% !important;
}

th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

td {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.height-100 {
  height: 100% !important;
}
.width-25 {
  width: 22%;
}
.width-52 {
  width: 52%;
}
.w-20 {
  width: 20%;
}
.w-80-t {
  width: 80%;
}
.width-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
  margin: auto;
}
.p-box {
  padding: 23px !important;
}
.employer-doc-tab .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff !important;
}

.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.flex-end {
  justify-content: flex-end;
}

.float-right {
  float: right !important;
}
.border-3 {
  border-radius: 3px !important;
}
.float-right {
  float: right !important;
}
.mar-zero {
  margin: 10px !important;
  padding: 0px !important;
}
.margin-top {
  margin-top: 13px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-btm {
  margin-bottom: 26px;
}
.d-aligin-center {
  display: flex !important;
  align-items: center !important;
}
.align-items {
  align-items: center;
}
.d-aligin-center p {
  font-size: 12px;
}
.text-style .ant-col .ant-form-item-label > label {
  font-weight: 500;
  color: #737373 !important;
  font-size: 13px;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.top-60 {
  margin-top: 60px;
}
.mar-top-35 {
  margin-top: 35px;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.border-color {
  border: 1px solid #d6d0d0;
  border-radius: 3px;
  margin-bottom: 12px;
}

.border-5px .ant-input {
  border-radius: 5px;
}
.border-5px textarea.ant-input {
  border-radius: 5px;
  margin-top: 12px;
}
.mar-top .ant-form-item-control-input-content {
  margin-top: 40px;
}
.margin-btns .ant-btn-primary {
  margin-left: 12px;
}
.space-between {
  justify-content: space-between;
}
.contant-around {
  justify-content: space-around !important;
}
.contant-center {
  justify-content: center !important;
}
.align-center {
  align-items: center;
}
.reminder-tabs-header .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header-temp .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
}
.reminder-tabs-header-temp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header-temp .ant-tabs-tab-active {
  background: #36b1e4;
}

.reminder-tabs-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header .ant-tabs-tab-active {
  background: #36b1e4;
}
.mar-zero .task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
}
.reminder-btn-main {
  display: flex;
  justify-content: center;
}

.add-task-btn .ant-btn {
  background-color: #1281ba;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.reminder-btn .ant-btn {
  background-color: #ff9e37;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.marked-name {
  background-color: #ff9d36;
  border-radius: 15px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  margin-left: 5px;
}

.marked-name-font {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.mdtr-reminder-tasks-cont {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}
.mdtr-reminder-tasks-header-cont {
  background-color: #f8f9fb;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8eaec;
  margin-top: 33px;
}
.mdtr-reminder-tasks-head-text-cont {
  display: flex;
  align-items: center;
}
.mdtr-rem-row-cont {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8eaec;
}
.cv-bold-text {
  color: #073d5d;
  font-size: 19px;
  font-weight: bolder;
}
.todo-main {
  justify-content: space-between;
  padding: 0px 34px;
  font-weight: 700;
}
.heading-style span {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.mdtr-user-cont {
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
}
.sus-checkbox {
  height: 15px;
  width: 20px;
}
.hide-icon {
  border: 1px solid;
  display: flex;
  padding: 0px 8px;
  border-radius: 50%;
  align-items: center;
}
.reminder-dropdown {
  margin-top: 33px;
}
.reminder-dropdown .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.reminder-dropdown .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.balance-accordian {
  margin-top: 15px !important;
}

.balance-accordian
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px;
  left: auto;
}

.invoice-status-btn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block !important;
}

.balance-accordian
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.balance-accordian .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.balance-accordian .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.template .ant-collapse-header {
  display: block !important;
}

.balance-accordian .ant-collapse-extra {
  margin-top: -37px !important;
  padding-right: 27px !important;
}

.reminder-border {
  border-bottom: 1px solid #d6d6d6;
  margin-top: 11px !important;
}

.reminder-head {
  color: #075a80;
  margin-left: 15px;
  font-weight: bold;
}
.reminder-calender {
  margin: 20px 0px 33px 0px;
}
.sidebar-reminder {
  margin-bottom: 33px;
  margin-top: 22px;
}
.reminder-calender .react-calendar {
  width: 100%;
  padding: 23px;
  border: 0;
}
.completed-tasks {
  margin-top: 45px;
}
.reminder-model-main {
  border-radius: 30px;
}
.reminder-model-main .ant-modal-content {
  /* width: 616px !important; */
  padding: 15px;
  border-radius: 3px;
}
.reminder-model-main .mdtr-modal-gray-cont {
  background-color: #f6f7f9;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e4e4e4;
  display: flex;
  border-radius: 5px;
  padding-right: 20px;
}
.reminder-model-main .mdtr-checkbox-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reminder-model-main .cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
}
.reminder-model-main .profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}
.reminder-model-main .profile-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}
.reminder-model-main .mdtr-add-tasks {
  background-color: #1281b9;
  padding: 7px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.reminder-model-main .ant-modal-header {
  border-bottom: none !important;
  color: #6c6c6c !important;
  padding: 2px 26px 0px 26px;
  font-size: 18px;
  margin-bottom: 38px;
  border-radius: 30px 30px 30px 30px !important;
}
.reminder-model-main .ant-modal-title {
  /* color: #6c6c6c; */
  font-size: 14px;
  font-weight: 600;
}
.reminder-model-main .cv-bold-text {
  font-size: 12px;
  font-weight: 600;
  margin-top: 36px;
}
.reminder-model-main .ant-modal-body {
  padding: 0px 20px;
}
.reminder-model {
  border-radius: 40px;
}
.reminder-model-main .emp-ae-input-width p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.reminder-model-main .emp-ae-input-width {
  margin-right: 32px;
}
.modal-parts {
  width: 84%;
  margin: auto;
}
.reminder-model-main .ant-form-item {
  padding: 15px 0px;
}
.form-parts .ant-form-item-label {
  font-weight: 500;
}
.bg-box-white {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  padding: 22px 0;
}
.mar-b {
  margin-bottom: 32px;
}
.w-96 {
  width: 96%;
  margin: auto;
}
.mar-tb {
  padding: 20px 5px;
}
.comment-btn .ant-btn-primary {
  float: right !important;
  margin-top: 14px;
  border-radius: 4px;
  border: 0px;
}
.follower-row h4 {
  margin-right: 13px;
}
.mar-right {
  margin-right: 7px !important;
}
/* .tasks-comment .ant-comment {
  width: 96%;
  margin: auto;
} */
.box-lenght {
  max-height: 400px;
  overflow: scroll;
}

.box-lenght::-webkit-scrollbar {
  width: 4px;
}
.box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 0px;
}
.box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.completed-box-lenght {
  max-height: 1000px;
  overflow: scroll;
}
.completed-box-lenght::-webkit-scrollbar {
  width: 4px;
}
.completed-box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.completed-box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}
.completed-box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.color-check {
  margin: 5px 8px 0px 0px;
  border-radius: 2px;
}
.delete-model p {
  font-size: 15px;
  font-weight: 200;
  padding: 13px 0px 0px 7px;
}
.margin-right {
  margin-right: 10px;
}
.client-form .ant-form-item {
  display: block;
  margin-top: 9px;
}
.all-client-form .ant-form-item-label {
  font-weight: 600;
}
.header-text-style {
  font-size: 17px;
  font-weight: 500;
  color: #083c5d;
  padding: 30px 40px 0px 20px;
}
.all-client-form .client-form .ant-form-item-no-colon {
  color: #55626c !important;
}
.all-client-btn {
  margin: 25px 23px 0 0px;
  justify-content: flex-end;
}
.reminder-settings {
  width: calc(100% - 170px);
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 50px;
  margin: 35px 85px;
  border-radius: 3px;
}

.invoice-table-body-dates {
  font-size: 14px;
  font-weight: 450;
  color: black;
  padding-bottom: 5px;
}
.client-tag-table .ant-row .ant-form-item {
  display: block;
}

.reminder-set-form {
  /* background-color: #ffffff; */
  /* padding: 30px; */
  /* border-radius: 5px; */
  /* border: 1px solid #eeeeee; */
}
.reminder-set-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-set-form p {
  font-size: 16px;
  /* color: #556370; */
  font-weight: 100;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 100;
}

.reminder-setting-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.imap-set-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.imap-set-form .ant-form-item-label {
  margin-right: 0px;
}
.margin-top-34 {
  margin-top: 34px;
}
.Reminder-top {
  width: 100%;
  /* margin: auto; */
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
}
.time-tracking {
  width: 95%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}
.employer-manag {
  width: 91%;
  height: 100%;
  margin: 35px auto;
}
.dynamic-keys {
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  padding: 4px 0px 6px 17px;
}
.dynamicForm {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin: 21px 0px 59px 0px;
}
.mail-Chimp-text {
  padding-top: 35px;
  font-size: 16px;
}
.mail-Chimp-text p {
  font-size: 13px;
  font-weight: 450;
  margin-top: 0px !important;
}
.mail-text p {
  padding-left: 35px;
}
.mail-chimp-btn {
  display: flex;
  justify-content: flex-end;
}
.mail-chimp-box {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 54px 74px 54px;
  margin: 35px auto;
  border-radius: 3px;
}
.default-font-box {
  width: 98%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 35px 20px;
  border-radius: 3px;
}
.document-sync-box {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 10px auto 35px auto;
  border-radius: 3px;
}

.document-checklist-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 17px;
  margin: 35px auto;
  border-radius: 3px;
}
.document-checklist--btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
}
.email-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  margin: 27px auto;
  border-radius: 3px;
}
.email-content-box h4 {
  padding-bottom: 20px;
  color: #448ccb;
  font-size: 16px;
}
.questionnaire-content {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 81px;
  margin: 35px auto;
  border-radius: 3px;
}
.questionnaire-top {
  width: 82%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}

.letter-font p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-spacing {
  margin: 16px 0px 0px 0px;
}
.letter-height {
  height: 500px;
}

.letter-froala p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-froala .fr-box.fr-basic .fr-element {
  max-height: 500px;
  border-bottom: 2px solid #cccccc;
  min-height: 274px;
}
.fr-second-toolbar {
  display: none !important;
}
.file-uploader .ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9 !important;
  background-color: transparent;
  padding: 5rem;
  margin-bottom: 2rem;
}
.file-uploader .ant-upload-text {
  color: #1890ff !important;
}
.file-uploader {
  margin-top: 15px;
}
.file-uploader .ant-upload {
  border-radius: 0px !important;
}
.border-box {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-emp-manag {
  background: white;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-add-emp {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px 50px;
  margin-top: 38px !important;
}
.border-box-employer {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 34px;
  /* margin-top: 38px !important; */
}
.padding-20 {
  padding: 20px !important;
}

.w-9 {
  width: 13%;
}
.w-91 {
  width: 87%;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.mar-top-75 {
  margin-top: 75px;
}
.doc-office {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.Upload-signature .avatar-uploader > .ant-upload {
  width: 190px;
  height: 128px;
  border-radius: 0% !important;
  margin-bottom: 10px;
}
.Upload-signature .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background: white;
  border: 2px dotted #d9d9d9 !important;
  border-radius: 0px !important;
}
.MainSideBar .ant-tabs-nav-list {
  background-color: #213037;
  color: #fff;
}
.dashboardSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.dashboardSideBar {
  width: 265px !important;
  /* min-width: 100% !important; */
}
.supervisorSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.supervisorSideBar {
  width: 262px !important;
}
.site-layout {
  background: #ffffff;
  /* height: 100%; */
  position: fixed;
  margin-top: 64px;
  /* min-width: 265px !important; */
  overflow: auto;
}
.site-layout .ant-layout-sider-children {
  overflow: auto;
  position: fixed;
}
.screen-width {
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
  /* margin-left: 265px; */
  /* margin-top: 64px; */
}

.dashboardSideBar .ant-menu-item a {
  /* padding-left: 14px !important; */
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.dashboardSideBar .ant-menu .ant-menu-dark .ant-menu-root .ant-menu-inline {
  color: #ffffff;
  font-size: 18px;
  border: 0px;
}
.dashboardSideBar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: red !important;
}
.ant-layout-header {
  position: fixed;
  z-index: 10;
  width: 100%;
  /* height: 3rem; */
  background: #fff !important;
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
  overflow: overlay;
  width: 100%;
}
/* .open-sidebar div>div>div{
  position: relative!important;
}
.close-sidebar div>div>div{
  position: relative!important;
} */
/* added by aatif */
.site-layout {
  position: absolute !important;
  /* position: fixed !important; */
}
.ant-layout-content {
  padding-top: 65px !important;
}
.site-layout .ant-layout.ant-layout-has-sider {
  width: 100% !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: rgba(33, 48, 55, 255) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(69 89 99);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #f1e9e9 !important;
}
.employer-table .ant-table-pagination.ant-pagination {
  margin: 7px 26px !important;
}
/*.employer-table .ant-table-cell {*/
/*  text-align: center;*/
/*}*/
/* .table-head-clr .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
}
.table-head-clr .ant-table-column-sorters-with-tooltip {
  background-color: #f0f2f5;
} */
.add-employer-para .ant-col .ant-col-12 p {
  font-size: 14px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.employer-manag-tabs .ant-tabs-nav-wrap {
  background-color: #f0f2f5 !important;
}
.employer-manag-tabs .ant-tabs-tab {
  color: black;
  font-weight: 800 !important;
  font-size: 16px;
  margin: 0px;
  background: #0383bc !important;
  padding: 5px 17px !important ;
  font-size: 12px !important;
}
.employer-manag-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.employer-manag-tabs .ant-tabs-tab-active {
  background: #36b1e4 !important;
}
.employer-manag-tabs .ant-form-item {
  padding: 15px 0px !important;
}

.employer-manag-tabs .ant-tabs-tab {
  margin-right: 0px !important;
  border-radius: 0px !important;
}
.employer-manag-tabs .ant-tabs-tab-btn {
  font-weight: 500;
  color: white;
}
.upload-button .ant-btn {
  border-radius: 0px !important;
}
.employer-head {
  margin-top: 33px;
  margin: 44px 0px 10px 10px;
}
.form-add-btn {
  width: 26px;
  border-radius: 33px !important;
  height: 26px;
  /* float: right; */
}
.form-add-btn .ant-btn-icon-only {
  height: 25px;
  border-radius: 45px;
  margin-bottom: 4px;
  /* position: fixed; */
  z-index: 20;
}
.form-add-btn .ant-btn-icon-only svg {
  margin-bottom: 4px;
}
.remove-button {
  cursor: pointer;
  background-color: #f52b2b;
  color: white;
  display: flex;
  width: 5%;
  padding: 7px 14px;
  border-radius: 44px;
  font-weight: 400;
  margin: 35px 0px;
}
.remove-button:hover {
  background-color: #1890ff;
  color: white;
}
.contact-Head {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.contact-emp {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin-top: 25px;
}
.add-job-form .ant-row .ant-col .ant-col-4 {
  font-size: 19px !important;
}
.add-job-form .ant-input-number {
  width: 100% !important;
}
.emp-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  /*margin: 27px auto;*/
  border-radius: 3px;
}
.emp-froala h4 {
  color: #448ccb;
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 12px;
}

.green-btn {
  background: #0ca22c !important;
  border: 0px !important;
  color: #fff !important;
}
.margin-client {
  display: flex;
  margin: 18px 0px 0px 15px;
}
.padding-btn {
  padding: 10px 23px;
}
.file-note-light {
  background-color: #fff;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}
.payment-form-labels {
  margin-top: 16px;
}
.file-note-dark {
  background-color: #fffcfc;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}

.accounts-flow-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.table-row-light {
  background-color: #fff !important;
  /* padding: 6px 16px; */
}
.table-row-dark {
  background-color: #f0f2f5 !important;
  /* padding: 6px 16px; */
}
.file-contacts .ant-row .ant-form-item {
  width: 90%;
  margin: auto;
}
.file-delete-head {
  margin: 18px 25px 0px 23px;
}
.border-color-blue .ant-tabs-tabpane .ant-tabs-tabpane-active {
  border: 1px rgb(16, 129, 185);
}
/*.ant-table-thead > tr > th {*/
/*  text-align: center !important;*/
/*  font-size: 13px;*/
/*}*/

.school-table .ant-table-thead > tr > th {
  text-align: left !important;
  font-size: 14px !important;
}

.child-invoice-table .table-head .table-tbody > tr > th {
  padding: 16px 0px 16px 0px !important;
}

.table-head .ant-table-column-sorters {
  padding: 11px 0px;
}
.table-head .ant-table-column-sorters-with-tooltip {
  text-align: center;
}
.table-head .ant-table-cell {
  /* text-align: center; */
}
.table-head .table-tbody > tr > td {
  align-items: center !important;
  padding: 12px 0px !important;
}
/* .table-head .ant-table-cell {
  padding: 10px !important;
} */
.ant-select {
  width: 100%;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
  margin-top: 4px;
}
/* .employer-Header {
  height: 54px;
} */
.alert-msg .ant-form-item-explain .ant-form-item-explain-error {
  float: right !important;
}
/* .required p::before {
  content: "*";
  position: absolute;
  /* right: -8px; */
/* left: 18px;
color: red;
top: -2px; */
/* } * */
.job-table .ant-table-cell {
  padding: 8px 4px 8px 10px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}
.contact-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-table .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}

.contact-tables .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-tables .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  margin: 16px 42px !important;
  text-align: left;
  background: transparent !important;
}

.job-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}
.job-table .ant-table-container {
  /* padding: 20px; */
}

.contact-us .ant-table-cell {
  padding: 13px 7px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: center;
  color: #474a4c;
}
.contact-us .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.add-employer-para .ant-col .ant-col-12 p {
  font-size: 13px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.width-web-kit {
  width: -webkit-fill-available !important;
}

.width-modal-sending-receipt {
  width: 1022px !important;
}

.width-modal-add-template {
  width: 641px !important;
}

.width-modal-add-email-template {
  width: 70vw !important;
}

.border-box-modal-sending-receipt {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 72px 10px 17px;
  margin-top: 38px !important;
}

.font-school-list-filter-text {
  font-size: smaller;
  font-weight: 700;
}

.font-school-list-filter-text-sub {
  color: #889093;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.sus-tab-container-schools {
  display: flex;
  margin: 30px 0px 0px 20px;
  cursor: pointer;
}

.sus-tab-container-clients {
  display: flex;
  margin-left: 20px;
  margin: 44px 0px 0px 20px;
  cursor: pointer;
}

.sus-active-tab-schools {
  background-color: rgb(51, 170, 218);
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.sus-inactive-tab-schools {
  padding: 5px 20px;
  display: flex;
  background-color: #0383bc;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.upload-technical-support-complaint .ant-upload.ant-upload-select-picture-card {
  margin-top: 9px;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.upload-technical-support-complaint .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 9px 8px 8px 0;
  vertical-align: top;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-active-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ts-container-schools {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.button {
  color: #fff !important;
  background: #096dd9 !important;
  border-color: #096dd9 !important;
  padding: 5px 16px !important;
  border-radius: 2px !important;
}

.cont-positioning {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.border-box-modal {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: 38px !important;
}

.add-new-line-container {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: auto;
  margin-top: 16px;
  margin-left: 16px;
  padding-bottom: 15px;
}
.add-new-line-header {
  background-color: #e6e6e6;
  height: 26px;
}

.add-new-line-header-school-invoices {
  background-color: #e6e6e6;
}

.add-new-line-inputs {
  padding: 7px;
}

.add-new-line-header-position {
  margin-top: 5px;
  text-align: center;
}

.add-new-line-button {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: 36px;
  width: 107px;
  margin-left: 22px;
  margin-top: 6px;
  cursor: pointer;
}
.add-new-line-button p {
  text-align: center;
  font-size: 13px;
}

.width-modal {
  width: 702px !important;
}

.label-search-schools {
  font-size: 16px;
  font-weight: bold;
  color: rgb(24, 91, 148);
}

.width-modal-outgoing-payments {
  width: 735px !important;
}

.incoming-payments-table-row {
  background: #bcf6c5 !important;
}

.invoices-children-table-row {
  background: #e5e4e2 !important;
}

.outgoing-payments-table-row {
  background: #f6d6d0 !important;
}

.font-size-add-school-form {
  font-size: 12px;
}

.text-style-show-container {
  color: #fff;
}

.ca-gray-padding {
  padding: 15px 20px 15px 0px !important;
}

.ca-gray-padding-payment-history {
  padding: 15px 20px 15px 20px !important;
}

.payment-buttons-container {
  display: flex;
  margin-left: 19px;
  margin-top: 10px;
}

.payment-label {
  font-size: 12px;
  font-weight: initial;
  margin-left: 19px;
}

.show-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: -3%;
  cursor: pointer;
}

.display-contact-text-container {
  display: block;
  margin-inline-start: 20px !important;
}

.margin-contact-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-icon {
  margin-top: -35px !important;
}

.haha {
  border: 1px solid red;
}

.border-box-modal-add {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}

.positioning-delete-icon {
  margin-top: 7px;
}

.positioning-show-levels {
  text-align: center;
}

.border-show-contacts {
  border-bottom: 1px solid rgb(214, 208, 208);
  width: 587px;
  margin-left: -11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.border-add-template-stages {
  border-bottom: 1px solid rgb(214, 208, 208);
  padding-top: 22px;
  margin-left: 25px;
}

.border-detail {
  border-bottom: 1px solid rgb(214, 208, 208);
}

.hide-levels-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: 7%;
  cursor: pointer;
}

.ts-container-commission-reminders {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.redo-icon {
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.upload-technical-support-complaint {
  margin-top: 13px !important;
  margin-left: 20px !important;
}

.upload-technical-support {
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.employer-doc-tab .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.employerin-box {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 56px;
  height: 100%;
  border-radius: 3px;
}
.ant-table-cell .ant-table-column-sort .ant-table-column-has-sorters {
  background: white;
}
.ant-table-column-sorters-with-tooltip {
  background: white;
}
/* .ant-table-cell {
  background: white !important;
} */
.ant-table-cell .ant-table-selection-column {
  background: white !important;
}
.document-table .ant-table-cell {
  text-align: center;
}
.send-btn .ant-btn-primary {
  border-radius: 4px !important;
}
.border-box-checklist {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 13px 54px;
}
.checklist-add-field {
  background: #f5f5f5;
  border: 0px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}
.checklist-textarea .ant-input {
  height: 100px !important;
}
.checklist-switch .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch .ant-switch-checked {
  height: 10px;
  background-color: #bf0909b8 !important;
}
.checklist-switch .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #aa2626 !important;
}
.checklist-switch .ant-switch-inner {
  height: 10px;
  /* background-color: black !important; */
}
.checklist-switch-low .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch-low .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #eec914;
}
.checklist-switch-low .ant-switch-checked {
  height: 10px;
  background-color: #eec914ad !important;
}
.checklist-switch-low .ant-switch-inner ::before {
  height: 10px;
  background-color: black !important;
}
.checklist-switch-low .ant-form-item-label > label {
  height: 22px !important;
}
.checklist-switch .ant-form-item-label > label {
  height: 22px !important;
}

.all-client-form .ant-form-item-label > label {
  height: 22px !important;
}

.imap-text p {
  font-size: 13px !important;
  /* color: rgb(141, 139, 139) !important; */
}
.imap-text {
  font-size: 15px !important;
}
.input-name {
  font-weight: 500 !important;
}
.employer-logo-box {
  background-color: #fff;
  justify-content: center !important;
  display: flex !important;
  padding: 50px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 26%);
}
.container {
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.link-client-logo {
  width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  height: 8rem;
}
.footer-info-text {
  display: flex;
}
.red-dot {
  height: 14px;
  width: 14px;
  background-color: #e61616;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}
.yellow-dot {
  height: 14px;
  width: 14px;
  background-color: #faad14;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}

.upload-image-heading {
  margin-top: 70px;
}

.personal-info-heading {
  margin-bottom: 24px;
  padding-top: 18px;
}

.ts-container-add-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 35px 30px 0px;
  border-radius: 3px;
}

.personal-info-add-client-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-right: 30px;
  border-radius: 6px;
  margin-left: 35px;
  padding-bottom: 37px;
}

.personal-info-add-client-container
  .ant-form-horizontal
  .ant-form-item-control {
  min-width: 100% !important;
}

.ts-container-import-clients {
  background-color: #ffffff;
  margin: 15px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.post-to-xero {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.xero-filters-label {
  width: 19%;
  margin-top: -5px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border: solid 10px transparent;
}

#style-3::-webkit-scrollbar {
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px;
}

.save-button {
  margin-left: 7px;
  margin-top: 29px;
}

.container-center {
  height: 2em;
  position: relative;
}
div.container-center h5 {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ts-potential-client-statuses {
  background-color: #ffffff;
  margin: 50px 13px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 2px 16px 5px;
  border-radius: 5px;
}

.duplicate-client-table-container {
  margin-top: 37px;
  margin-left: 31px;
  margin-bottom: 15px;
}

.spinner-xero {
  text-align: center;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  -webkit-align-content: center;
  align-content: center;
  vertical-align: middle;
}

.spinner {
  text-align: center;
  z-index: 1000;
  padding-top: calc(50% - 25%);
  width: 100%;
  height: 100vh;
  -webkit-align-content: center;
  align-content: center;
  vertical-align: middle;
}
.client-table .ant-table {
  border: 1px solid rgb(184, 179, 179) !important;
  border-radius: 2px;
}
.employer-btn {
  /* border-radius: 6px !important; */
  border: 0px !important;
}
.emp-w-box {
  padding: 29px 84px;
  background-color: white;
  margin-top: 20px 20px;
}

.emp-w-box-employer {
  padding: 29px 84px;
  background-color: rgb(245 247 248) !important;
  margin-top: 20px 20px;
}

.emp-profile-table {
  background-color: whitesmoke;
}
.auto-email {
  border: 1px solid #c9c9ca;
  padding: 14px 41px !important;
  margin-bottom: 10px;
}
.create-email-form .ant-form-item {
  padding: 7px 0px !important;
}
.visa-body-text {
  justify-content: space-between;
  display: flex;
  max-width: 64%;
}
.visa-body-text span {
  color: #5c5c5c;
  font-weight: 450;
}
.visa-status-header {
  height: 100px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 90%;
  margin: auto;
}
.status-header-left {
  width: 80%;
  background-color: #f3f3f3;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.status-header-left h2 {
  color: #858585;
  margin: 0;
}
.status-header-left p {
  color: #858585;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-top: 6px;
}
.event-visa-btn {
  background: #f6752f;
  border: 0;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 3px 17px;
}
.status-header-right {
  width: 20%;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}
.status-header-right h2 {
  color: #ffffff;
  margin: 0;
}
.visa-status-body {
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px 30px 30px 30px;
}
.visa-status-body button {
  margin-bottom: 7px;
}
.visa-status-body-data {
  background-color: white;
  width: 100%;
  margin: auto;
  border-radius: 5px !important;
  padding: 30px !important;
}
.case-new {
  width: 90%;
  background-color: #36b1e4;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  height: 8rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-top: 40px;
  margin-top: 10px;
}
.case-new h4 {
  color: #fff;
  font-size: 18px;
}
.incoive-table .ant-table-container {
  background: #eff1f4;
  width: 90%;
  margin: auto;
}
.file-spacing {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
}
.document-table .ant-row .ant-form-item {
  /* justify-content: center; */
  width: 40%;
}

.close-editor {
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}
.add-emp-form .ant-form-item-explain {
  justify-content: flex-start !important;
  display: flex !important;
}
.slider-small {
}
.slider-small .ant-layout-sider-children {
  width: 84px !important;
}
.slider-small .ant-layout-sider {
  background-color: transparent;
}
.site-layout-background {
  width: 100% auto !important;
}
.open-sidebar {
  width: 89%;
  margin-left: 265px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.rightbar-icons {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.rightbar-icons img {
  margin-right: 10px;
}

.employer-tabs-content > .ant-tabs-content-holder {
  background-color: #f0f2f5 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.employer-tabs-contents > .ant-tabs-content-holder {
  font-size: 12px !important;
  font-weight: inherit !important;
  font-family: "Poppins", sans-serif !important;
}

.profile-tags {
  padding: 4px 10px;
  border: 1px solid #3fb5cf;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.tags-text {
  font-size: 10px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}
.signup-contaienr {
  width: 80%;
  margin: auto;
}
.signup-container {
  width: 67%;
  margin: 51px auto;
  border: 1px solid #cecece;
  padding: 20px 0px;
  background-color: white;
}
.signup-fields .ant-form-item {
  padding: 15px 0 !important;
}
.signup-fields {
  width: 87%;
  margin: 10px auto 32px auto;
  background-color: #f0f2f5;
  padding: 10px 63px;
}
.signup-text {
  margin: 18px 0px;
}
.signup-text span {
  /* font-size: 14px; */
  font-weight: 600;
  color: #5d5d5d;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.save-signup .ant-form-item-control-input {
  width: 88%;
  margin: auto;
}

.cmv-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 16px !important;
}
.cmv-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.ant-input-number-handler-wrap {
  position: absolute;
  display: none !important;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  /*border-left: 1px solid #d9d9d9;*/
  border-radius: 0 2px 2px 0;
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.1s;
  transition: opacity 0.24s linear 0.1s;
}
.profile-container {
  width: 98%;
  margin: auto;
  padding-top: 45px;
  display: flex;
}
.profile-input-border .ant-form-item-control-input {
  min-height: 30px !important;
}
.sign-up-head {
  justify-content: space-around;
  align-items: center;
}
.head-d {
  border: 1px solid;
  height: 10px;
}
.head-link {
  padding: 0 10px 10px 0;
}
.head-text h1 {
  color: #095c81;
}
.email-input input {
  width: 440px;
  height: 39;
  border-radius: 44px 0px 0px 44px;
  background: #eeeeee;
  border: 0px;
}
.sign-up-btn button {
  width: 160 !important;
  border-radius: 44px;
  height: 39;
}
.sign-up-btn .ant-col {
  margin-left: -6px !important;
  padding-left: 0px;
}
.email-input .ant-col {
  padding: 0px !important;
}
.bg-white .ant-layout {
  background-color: white;
}

.sign-up-btn .ant-btn-primary {
  background: #083c5d;
  border: 0px;
  margin-left: -20px;
  margin-bottom: 40px;
}
.sign-up-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: rgb(3 131 188);
  border-color: #fff;
  border: 1px solid;
}
.email-input .ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #eeeeee !important;
}
.signup-email .ant-row {
  justify-content: center;
}
.video-btn {
  text-align: center;
}
.video-btn button {
  height: 65px;
  width: 274px !important;
  background: #083c5d;
  border: 0px;
  border-radius: 4px;
}

.quick-box {
  background: #f5f5f5;
  text-align: center;
  padding: 28px 0px;
  margin-top: 23px;
}
.feature-btn button {
  border-radius: 44px;
  margin-top: 30px;
  background: #083c5d;
  border: 0;
}
.feature-content {
  font-weight: 600;
  font-size: 32px;
  color: black;
}
.feature-content-lg {
  font-size: 30px;
}
.quick-box .ant-row {
  justify-content: center;
}
.features-img .ant-image-img {
  margin-bottom: -112px;
}
.amzing-feature .ant-row {
  justify-content: center;
}
.signup-header .ant-layout-header {
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 22px -13px rgb(11 9 10 / 59%);
}
.watch-video .ant-btn-primary {
  background: #1281ba;
  border: 0px;
  border-radius: 32px;
  width: 182px;
  height: 45px;
  margin: 21px;
}
.client-docs {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
}
.client-ehead {
  width: 48%;
  margin: auto;
}
.draft {
  padding: 3px 12px;
  background: #d9534f;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}
.sent-email {
  padding: 3px 12px;
  background: #5cb85c;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}

.client-doc-main {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.doc-filter .ant-select-dropdown {
  width: 119px !important;
}
.add-emp-btn .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}
.required-field {
  color: red;
  padding-left: 2px;
}
.client-detail-modal .ant-modal-body {
  background: rgb(245 245 245);
  margin: 24px;
  border: 1px solid rgb(192 190 190);
}
.detail-head {
  font-size: 18px;
  font-weight: 500;
  color: #636363;
}
.search-name {
  font-size: 15px;
  color: rgb(59, 59, 59);
  font-weight: 500;
  width: 100%;
}
.search-text {
  font-size: 13px;
  color: rgb(126, 126, 126);
  font-weight: 400;
  width: 100%;
}
.docuemt-padding {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.filenote-box {
  width: 98%;
  margin: 0 auto 50px auto;
}
.emp-profile-box {
  width: 90%;
  margin: 0 auto 50px auto;
}

.emp-profile-box-employer {
  width: 98%;
  margin: 0 auto 50px auto;
}

.filenote-btn .ant-form-item-control-input-content {
  margin-top: 10px;
}
.float-left {
  float: left;
}
.agreement-data {
  font-weight: 500;
  color: #556370;
  margin-right: 8px;
}
.single-job
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
  padding: 15px 16px !important;
}
.add-client-form-inputs .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label .ant-form-item-no-colon {
  padding: 0px !important;
  margin: 0;
  font-weight: 600;
}
.add-client-form-inputs .ant-form-item-no-colon {
  /* font-size: 11px !important; */
  color: #556370 !important;
  font-weight: 500;
}

.table-action .anticon {
  margin-right: 2px;
  cursor: pointer;
  color: #4b90cd;
  font-size: 16px;
}
.table-action {
  display: flex;
}

.BranchPermissions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminder-small-text {
  color: #33aada;
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
}

.reminder-bold-text {
  color: #33aada;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}

.reminder-date-text {
  color: #555555;
  font-size: 12px;
  margin: 0px;
}

.reminder-tabs-cont {
  border: 1px solid #e1e1e1;
  margin: 10px 15px;
  margin-left: 0px;
  padding: 10px;
}

.ant-tabs-tab-btn {
  width: 100% !important;
}

.content-width-open-sidebar {
  width: calc(100% - 458px) !important;
}
.content-width-close-sidebar {
  width: calc(100% - 140px) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}

.ant-modal-title {
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.ec-new-case-btn {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
  border-radius: 5px !important;
}

.ant-modal-footer .ant-btn-primary {
  border: 1px solid #1281b9 !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.doc-dropdown-width {
  width: 250px !important;
}

.action-text-cont .ant-btn {
  font-size: 12px !important;
}

.email-address-btn {
  height: 32px;
  line-height: 30px;
  border: 1px solid #cccccc;
  font-family: Lato-R;
  color: #333333;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 5px;
  background-color: #fff;
  border-radius: 0;
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
}

.imp-emails-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.attachments-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}

.attachment-content-item {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f8950a;
  margin: 3px;
}

.attachment-content-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.template-des-position {
  /*display: inline-block;*/
  vertical-align: -webkit-baseline-middle;
}

.client-email-label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5bc0de;
}

.client-email-popup-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 15px;
}

.client-email-address {
  font-size: 14px;
  color: #185b94;
  display: inline-block;
  /* width: 42%; */
  margin: 0;
  margin-bottom: 15px;
  padding-right: 5px;
  text-transform: capitalize;
}

.client-email-to-row {
  display: flex;
  justify-content: space-between;
}

.body-attachment-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.body-attachment-text {
  margin-left: 10px;
  color: #337ab7;
}

.select-border .ant-select-borderless .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.faq-main-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 12px;
}

.faq-item-main {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #d36205;
}

.faq-item-first {
  width: 100%;
}

.faq-item-first-child {
  /* float: left; */
  font-family: Lato-R;
  font-size: 16px;
  color: #0f82bd;
  padding: 5px 8px;
}

.first-width {
  width: 85%;
}

.faq-item-sec {
  width: 100%;
}

.faq-item-sec-child {
  width: 100%;
  /* float: left; */
  font-family: Lato-R;
  font-size: 14px;
  color: #0f82bd;
  padding: 5px 25px;
}

.faq-head-text {
  color: #07699b;
  font-weight: 500;
  font-size: 16px;
}

.permission-time-zone .timezone-picker .timezone-picker-textfield input {
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
}

.cq-inactive-inner-tab {
  /* background-color: rgb(51, 170, 218); */
  padding: 4px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 1px solid rgb(51, 170, 218);
}

.cq-active-inner-tab {
  background-color: #2e6da4;
  padding: 5px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.cs-attachment-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  /* margin-top: 15px;
  margin-bottom: 20px; */
  padding: 12px;
  /* margin-left: 30px; */
  margin: 15px 30px 20px;
}

.cs-attachment-haeding {
  font-family: Lato-R;
  font-weight: 300;
  color: #073551;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
}
/* .dashboard-summary-table > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
}
.dashboard-student-summary > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
} */
.dashboard-summary-table table colgroup col {
  width: 100px;
  min-width: 100px;
  /*maxHeight: 10px !important;*/
}
.dashboard-student-summary table colgroup col {
  width: 180px !important;
  min-width: 180px !important;
}

.term-condition-container h4 {
  color: #09587e;
  padding-right: 8px;
  text-transform: uppercase;
}

.add-background-color {
}

.add-background-color .ant-select-selector {
  background-color: #ffffff !important;
}
.forgot-loader {
  position: absolute !important;
  top: 0 !important;
}
/* .superDashboard .ant-layout-sider-children {
  position: relative !important;
} */

.message-textArea {
  padding: 10px;
  display: block !important;
  background-color: #f5f5f6;
  margin-top: 15px;
  border: 1px solid #ebebec;
}

.message-content-box-radius {
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.input-number {
}

.input-number .ant-input-number {
  width: 100% !important;
}

.row-style {
  display: flex;
  justify-content: space-between;
}

.questionnaire-input-width-0 {
  width: 0%;
}

.questionnaire-input-width-55 {
  width: 55%;
}

.questionnaire-input-width-60 {
  width: 60%;
}

.questionnaire-full-name-input {
  width: 60%;
  border: 0;
}

.full-name-row-padding {
  /* paddingleft: 30;
   paddingRight: 30
                             paddingBottom: 30, */
  padding: 0 30px 30px 30px !important;
}

.questionnaire-label-width-38 {
  width: 38%;
}

@media (max-width: 820px) {
  .contact-info-sec {
    display: block !important;
  }
  .footer-logo {
    width: 100% !important;
  }
  .footer-info-text {
    width: 100% !important;
    display: block !important;
  }
  .footer-info-box {
    width: 100% !important;
  }
  .row-style {
    display: block !important;
  }

  .questionnaire-input-width-55 {
    width: 100% !important;
  }
  .questionnaire-input-width-60 {
    width: 100% !important;
  }
  .questionnaire-full-name-input {
    width: 100% !important;
  }

  .questionnaire-label-width-38 {
    width: 100% !important;
  }

  .cq-container {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .full-name-row-padding {
    padding: 0 0 30px 0 !important;
  }
}

.layout-class .ant-layout.ant-layout-has-sider {
  height: 0% !important;
}

.rem-table-margin .table-card {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.rem-table-margin .ant-table-pagination.ant-pagination {
  margin: 15px !important;
}

/* .cell-margin .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px !important;
} */

.display-inline-block {
  display: inline-block;
}

.display-inline-block .ant-select {
  width: 35px !important;
}

.display-inline-block .ant-select-selector span {
  color: #0052cc !important;
  font-size: 14px !important;
}

.display-inline-block .ant-select-selector {
  border: none !important;
  padding: 0 !important;
}
.table-action .ant-select-selector {
  border: none !important;
  padding: 0 !important;
}
.table-action .ant-select-arrow {
  right: 0;
}

.comment-width .ant-spin-nested-loading {
  width: 100% !important;
}
.froala-font-arial-use span p {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  background-color: transparent;
  line-height: 1.5;
}

.display-inline-block .ant-select-selector {
  border: none !important;
  padding: 0 !important;
}

.comment-width .ant-spin-nested-loading {
  width: 100% !important;
}
.froala-font-arial-use span p {
  font-family: Arial, Helvetica, sans-serif;
}

.ant-card-meta-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 44px;
}

.ant-collapse-content > .ant-collapse-content-box {
  background-color: #fff7ec;
}

.deal-title {
  color: #555555;
  font-size: 16px;
  font-weight: 500;
}

.deal-card-text {
  color: gray;
  font-size: 14px;
}

.total-deal-count {
  background-color: #f0ad4e;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px;
  display: inline-block;
  margin-left: 3px;
}

.deal-icon-position {
  display: flex;
  justify-content: flex-end;
}

.ant-table-blue-view {
}

.ant-table-blue-view .ant-spin-nested-loading .ant-spin-container .ant-table {
  background-color: rgb(238, 249, 255);
}

.ant-table-client-yellow-view {
}

.ant-table-client-yellow-view
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table {
  background-color: rgb(255, 247, 236);
}

.ant-table-visa-white-view {
}

.ant-table-visa-white-view
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table {
  background-color: #fff;
}

.box-shadow {
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 75%);
}

.content-text-file-note {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: green;
}

.public-api-view {
  margin-top: 30px;
  background-color: #f1f4f4;
  padding: 15px;
  border-radius: 5px;
}

.link-text-style {
  color: #0283ee;
  font-size: 14px;
  text-decoration: underline;
}

.link-label-style {
  color: #555;
  font-size: 14px;
}

.link-method-style {
  color: #0db990;
  font-size: 14px;
  font-weight: 500;
}

.no-copy-selection {
  user-select: none;
}

.visa-name {
  font-size: 16px;
  color: #185b94;
  font-family: Lato-B;
  font-weight: 300;
  padding: 10px 0px px;
}

.visa-row {
  font-family: Lato-R;
  font-size: 14px;
  color: #185b94;
  padding: 5px 0px;
}
.remove-icon-cross {
  display: flex;
  justify-content: flex-end;
  margin-right: -35px;
  margin-top: -15px;
  margin-bottom: 15px;
}

.table-row-red {
  background-color: #ffb7be;
}
.table-row-transparent {
  background-color: transparent;
}

.remove-xero-account {
  background-color: #d84961;
  color: #fff;
  border-color: #d84961;
  border-radius: 4px;
}

.reminder-tabs-header .ant-tabs-tab {
  color: #fff !important;
}

.client-tabs .short-tabs .ant-tabs-tab {
  color: #555 !important;
}

.short-tabs .ant-tabs-tab {
  color: #555 !important;
}

.client-tabs .ant-tabs-tab {
  color: #555 !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  color: #fff !important;
}

.reload-email-icon-cont {
  border: 1px solid #b8bdbd;
  display: flex;
  height: 31px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

.reload-email-icon {
  transform: rotate(270deg);
  font-size: 18px;
  color: rgb(69, 140, 204);
}

.dropdown-btn-reload-btn-row {
  display: inline-block;
  width: 40%;
}

.dropdown-btn-reload-btn-row-pc-emp {
  /* display: inline-block;
  width: 40%; */
}

.dropdown-btn-reload-btn-row .ant-btn {
  padding: 0 !important;
}

.dropdown-btn-reload-btn-row-pc-emp .ant-btn {
  padding: 0 !important;
}

.disabled-link {
  pointer-events: none;
}

.acc-set-btn-cont {
  display: inline-block;
  width: 250px;
  margin: 15px 25px;
  align-items: center;
  cursor: pointer;
  padding: 15px 7px 15px;
  box-shadow: 0px 0px 5px 1px rgba(204, 204, 204, 0.75);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(204, 204, 204, 0.75);
  -moz-box-shadow: 0px 0px 5px 1px rgba(204, 204, 204, 0.75);
}

.acc-tab-flex {
  display: flex;
  margin-left: 7px;
}

.arrow-round-cont {
  transform: rotate(90deg);
  background-color: #1281b9;
  padding: 5px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
}

.arrow-color-size {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}
