.container {
  background-color: #ffffff;
  margin: 40px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 20px;
  border-radius: 2px;
}
.container-ui {
  background-color: #ffffff;
  margin: 40px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 5%);
  padding-bottom: 20px;
  border-radius: 2px;
}

.overflow {
  overflow: auto !important;
}

.container-bar {
  background-color: #f6f7f8;
  height: 100px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}

.bar-left-side {
  background-color: #edf0f2;
  width: 200px;
  height: 100px;
  border-right: 1px solid;
  border-color: #cbcbcb;
}

.partner-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #1280b9;
  margin-top: -60px;
  margin-left: 25px;
}

.header-title {
  padding: 30px 40px 0px 20px;
}

.form-container {
  background-color: #f5f7f8;
  margin: 20px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
}

.form {
  background-color: #f5f7f8;
}
.input {
  width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  outline: none;
}

.input-border {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
  overflow: hidden;
}

.select-options {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
}

.select-options-technical-support-types {
  width: 76%;
  margin-left: 2%;
}

.drop-files-technical-support-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  font-size: 12px;
  color: #556370;
  font-family: "Poppins";
}

.button-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.button {
  border: 1px solid #1179af;
  padding: 5px 30px;
  border-radius: 5px;
}

.all-clients-table {
  width: calc(100% - 40px);
  padding: 20px;
}
.all-clients-tables {
  padding: 20px;
}

.header-table {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.header-tab-5 {
  width: 5%;
}

.header-tab-7 {
  width: 7%;
}

.header-tab-8 {
  width: 8%;
}

.header-tab-10 {
  width: 10%;
}

.header-tab-15 {
  width: 15%;
}

.header-tab-20 {
  width: 20%;
}

.header-tab-25 {
  width: 25%;
}

.header-tab-30 {
  width: 30%;
}

.header-tab-40 {
  width: 40%;
}

.header-tab-50 {
  width: 50%;
}

.table-content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e2e2e3;
  align-items: center;
}

.main-table-cont {
  border: 1px solid #e2e2e3;
  border-radius: 3px;
}

.content-index-5 {
  width: 5%;
}

.content-index-7 {
  width: 7%;
}

.content-index-8 {
  width: 8%;
}

.content-index-10 {
  width: 10%;
}

.content-index-15 {
  width: 15%;
}

.content-index-20 {
  width: 20%;
}

.content-index-30 {
  width: 30%;
}

.content-index-40 {
  width: 40%;
}

.content-index-50 {
  width: 50%;
}

.delete-container {
  width: 5%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.delete-icon {
  display: flex;
  border: 1px solid #ccc;
  width: 16px;
  padding: 5px;
  border-radius: 5px;
}

.all-show-button {
  padding: 7px 20px;
  border-radius: 4px;
  background-color: #97cb4a;
}

.button-search {
  padding: 7px 20px;
  border-radius: 4px;
  background-color: #ff9e37;
  margin-left: 9px;
}

.page-container {
  width: 100%;
}

.input-div {
  margin-left: 25px;
}

.header-font {
  font-size: 13px;
  font-weight: 600;
}

.content-text {
  font-size: 13px;
}

/* ====== Visa flow CSS ( .cv-class-name for Client Visa) ===== */

.cv-main-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.cv-left-container {
  background-color: #ffffff;
  margin: 31px 0px 20px 0px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px;
  border-radius: 2px;
  width: 53%;
}

.cv-top-btn-row {
  display: flex;
  padding: 10px 15px;
}

.cv-btn-blue {
  background-color: #36b1e4;
  padding: 10px 20px;
  border-radius: 5px;
}

.cv-top-lbtn-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cv-top-rbtn-text {
  color: #ffffff;
  font-size: 10px;
}

.cv-gray-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  padding: 20px 10px 0px 15px;
}

.cv-bold-text {
  color: #073d5d;
  font-size: 13px;
  font-weight: 600;
}

.cv-assist-cont {
  display: flex;
  /* margin-top: 5px; */
  align-items: center;
}

.cv-green-box {
  border: 1px solid #3fceab;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 10px;
}

.cv-green-text {
  color: #3fceab;
  font-size: 10px;
  display: flex;
}

.cv-purpel-box {
  background-color: #845fa8;
  padding: 5px 15px;
  border-radius: 3px;
}

.cv-preparing-box {
  background-color: #f8940a;
  padding: 5px 15px;
  border-radius: 3px;
}

.cv-imm-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cv-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cv-imm-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.cv-icons-row {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-top: 20px;
}

.cv-show-hide-cont {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dce0e3;
  margin-top: 50px;
}

.cv-ass-rows-cont {
  margin: 20px 30px;
}

.cv-ass-row {
  display: flex;
  align-items: center;
}

.cv-blue-box {
  width: 13px;
  height: 13px;
  background-color: #6ccef6;
}

.cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif !important;
}

.cv-cross-cont {
  background-color: #7c7c7c;
  padding: 2px;
  border-radius: 50%;
  width: 25px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
}

.cv-svg-8 {
  width: 8px;
  height: 8px;
}

.cv-org-cont {
  background-color: #f26a4f;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  border-radius: 5px;
}

.cv-org-text {
  font-size: 10px;
  color: #ffffff;
}

.cv-delete-cont {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 19px;
  width: 20px;
  justify-content: center;
  border-radius: 3px;
  padding: 2px;
  padding-left: 3px;
  margin-left: 10px;
  margin-top: 10px;
}

.cv-dlt-icon {
  width: 12px;
  height: 12px;
}

.cv-right-cont {
  width: 27%;
}

.cv-top-heading {
  display: flex;
  justify-content: space-between;
}

.cv-textarea {
  width: calc(100% - 7px);
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.cv-active-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  border: 1px solid #acacac;
  justify-content: center;
  box-shadow: 0px 0px 5px 2px rgba(224, 221, 224, 1);
  z-index: 999;
}

.cv-inactive-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 5px 2px rgba(230, 230, 230, 0.75);
}

.cv-active-tab-text {
  font-size: 12px;
  font-weight: 600;
  color: #898989;
}

.cv-inactive-tab-text {
  font-size: 12px;
  font-weight: 500;
  color: #898989;
}

.cv-white-cont {
  background-color: #ffffff;
  margin: 10px;
  margin-top: 0px;
  padding: 10px 10px 0px 15px;
  border: 1px solid #929292;
}

.cv-doc-head-row {
  display: flex;
  background-color: #eeeff1;
  margin-left: -15px;
  margin-right: -10px;
  padding: 15px 10px 2px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
}

.cv-doc-head-text {
  font-size: 12px;
  font-weight: 600;
  color: #093b5e;
}

.cv-width-55 {
  width: 55%;
}

.cv-width-52 {
  width: 52%;
}

.cv-width-20 {
  width: 20%;
}

.cv-width-17 {
  width: 17%;
}

.cv-width-15 {
  width: 15%;
}

.cv-width-13 {
  width: 13%;
}

.cv-doc-inner-index {
  width: 30px;
  margin-left: 5px;
}

.cv-doc-width {
  width: calc(100% - 20px);
}

.cv-doc-row {
  display: flex;
  margin-left: -15px;
  margin-right: -10px;
  padding: 3px 10px 2px;
  border-top: 1px solid #dbdcde;
  margin-bottom: 10px;
  align-items: center;
}

.cv-doc-text {
  font-size: 10px;
  font-weight: 600;
}

.cv-doc-date-text-cont {
  display: flex;
  margin-top: 3px;
}

.cv-doc-date-text {
  font-size: 8px;
  color: #36afe3;
}

.cv-title-box {
  display: flex;
  margin-top: 6px;
  border: 1px solid #dbdcde;
  padding: 2px;
}

.cv-action-icons-border {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 16px;
  width: 16px;
  justify-content: center;
  border-radius: 3px;
  padding: 12px;
}

.cv-action-icon {
  width: 17px;
  height: 17px;
}

.cv-client-circle {
  width: 10px;
  height: 10px;
  background-color: #efad4c;
  border-radius: 50%;
}

.cv-circle-lable-cont {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.cv-agent-circle {
  width: 10px;
  height: 10px;
  background-color: #98d04e;
  border-radius: 50%;
}

.cv-both-circle {
  width: 10px;
  height: 10px;
  background-color: #23bee6;
  border-radius: 50%;
}

.cv-org-btn-cont {
  display: flex;
  background-color: #ff9d36;
  padding: 7px 20px;
  border-radius: 5px;
  margin-left: 10px;
  align-items: center;
}

.cv-org-btn-text {
  font-size: 9px;
  color: #ffffff;
  font-weight: 500;
}

.cv-actions-cont {
  margin-left: 10px;
  border: 1px solid #dbdcde;
  padding: 2px 10px;
  display: flex;
  border-radius: 3px;
}

.cv-print-icon-cont {
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2cc3ff;
}

.cv-extend-icon-cont {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.cv-extend-icon {
  width: 12px;
  height: 12px;
}

.cv-plus-cont {
  background-color: #ffffff;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
}

.cv-plus-icon {
  color: #ff9d36;
  font-size: 16px;
  margin-bottom: 2px;
  margin-left: 1px;
  font-weight: 500;
}

.cv-time-spent-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
  align-items: flex-end;
}

.cv-file-notes-gray-text {
  color: rgb(118, 133, 143);
  font-size: 10px;
  font-weight: 500;
}

.cv-filenotes-cont {
  width: calc(100% - 20px);
  padding: 5px 0px;
  margin: 5px 10px;
}

.cv-filenotes-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
  align-items: center;
}

.cv-redish-btn {
  background-color: #ec441f;
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 15px 0px 5px 15px;
}

.cv-redish-btn-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cv-lf-white-cont {
  display: flex;
  background-color: #ffffff;
  margin: 30px 20px 20px 20px;
  box-shadow: 0px 0px 5px 0px rgba(189, 189, 189, 1);
  border-radius: 10px;
  height: 200px;
}

.cv-lf-checkbox-label-cont {
  margin-left: 10px;
  width: 120px;
  display: flex;
}

.cv-horizontal-line {
  border: 1px solid #dce0e3;
  margin: 30px -10px 10px -15px;
}

/* ====== Client Documents CSS ( .cd-class-name for Client Documents) ===== */

.cd-container {
  background-color: #ffffff;
  margin: 40px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 50px 0px 20px;
  border-radius: 2px;
}

.cd-blue-plus-btn-cont {
  display: flex;
  background-color: #448bcb;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
  align-items: center;
}

.cd-blue-plus-text {
  color: #448bcb;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: 500;
}

.cd-white-cont {
  background-color: #ffffff;
  margin: 10px;
  margin-top: 0px;
  padding: 50px 10px 0px 15px;
  border: 1px solid #929292;
}

.cd-doc-head-row {
  display: flex;
  background-color: #f9f9fb;
  margin-left: -15px;
  margin-right: -10px;
  padding: 10px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
  align-items: center;
  justify-content: space-between;
}

.cd-table-row {
  height: 30px;
  background-color: #f9f9fb;
  margin: 5px -10px 10px -14px;
}

.cd-inactive-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #acacae;
}

.cd-blue-border-cont {
  border: 1px solid #458ccc;
  padding: 20px 0px;
}

.cd-doc-checklist-cont {
  background-color: transparent;
  margin-top: 0px;
  padding: 50px 10px 0px 15px;
  border-top: 1px solid #929292;
}

.cd-doc-active-tab {
  background-color: #458ccc;
  padding: 7px 15px;
  display: flex;
  border: 1px solid #458ccc;
  justify-content: center;
  z-index: 999;
}

.cd-doc-active-tab-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.cd-doc-inactive-tab {
  background-color: #1cbbb4;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #1cbbb4;
}

.cd-doc-inactive-tab-text {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}

.cd-doc-sub-inactive-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #458ccc;
}

.cd-header-title-index {
  display: flex;
  width: 33.3%;
  border: 1px solid rgb(146, 146, 146);
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
  margin-top: -5px;
  margin-bottom: -5px;
  align-items: center;
  padding: 6px;
}

.cd-top-row-table {
  display: flex;
  margin-left: -15px;
  margin-right: -10px;
  padding: 15px 10px 2px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
}

.cd-send-btn-cont {
  background-color: #0172bb;
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
}

.cd-send-btn-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

/* ======== Employer CSS ======== */

.emp-container {
  margin: 45px 14px 20px 14px;
  padding-bottom: 20px;
  border-radius: 2px;
  display: flex;
}

.emp-prof-cont {
  width: 150px;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
  padding-bottom: 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 3px;
}

.emp-lab-val-cont {
  display: flex;
  border: 0.5px solid #c8c8c8;
  height: 21px;
  width: 70%;
  border-radius: 4px;
  padding-right: 1px;
  box-shadow: 0px 0px 5px 0px rgba(235, 232, 235, 1);
}

.emp-student-label {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #1182bc;
}

.emp-middle-cont {
  background-color: #ffffff;
  margin: 0px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 10px;
  width: 99%;
  border-radius: 3px;
}

.emp-ace-bold-text {
  margin: 5px 10px;
  color: #073d5d;
  font-size: 13px;
  font-weight: 600;
}

.emp-ace-cont {
  background-color: #f6f7f9;
  margin: 0px 10px;
  border-radius: 3px;
  padding: 15px;
  width: calc(100% - 20px);
}

.emp-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden;
  background-color: #ffffff !important;
  width: 65%;
}

.emp-blue-btn {
  border: 1px solid #1081b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1081b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* ========= Client Account CSS ========== */

.ca-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 0px 20px;
  border: 1px solid #8c8c8a;
  border-radius: 3px;
  width: 50%;
}

.ca-inner-cont {
  padding: 15px 10px 0px 15px;
}

.ca-org-cont {
  background-color: #f48f59;
  display: flex;
  padding: 3px 10px;
  border-radius: 3px;
  align-items: center;
}

.ca-inactive-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
}

.ca-inactive-text {
  color: #ffffff;
  font-size: 10px;
}

.ca-active-arrow-cont {
  display: flex;
  background-color: #d0d4d6;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.ca-active-arrow-icon {
  width: 9px;
  height: 9px;
  margin-bottom: 1px;
  transform: rotate(180deg);
}

.ca-active-status-cont {
  background-color: #41ab51;
  display: flex;
  padding: 3px 14px;
  border-radius: 3px;
  align-items: center;
}

.ca-table-cont {
  width: 100%;
  background-color: #ebeff2;
}

.ca-table-head-row {
  background-color: #ebeff2;
}

.ca-head-text {
  font-size: 10px;
  color: #213037;
  width: 25%;
  padding: 7px 10px;
  text-align: left;
}

.ca-cell-background-color {
  background-color: #40b5ce;
}

.ca-td-row-text {
  font-size: 10px;
  color: #ffffff;
  width: 25%;
  padding: 7px 10px;
  text-align: left;
  font-weight: 600;
}

.ca-checkbox-row {
  display: flex;
  align-items: center;
  margin: 10px;
}

.ca-other-table {
  width: 100%;
  border: 1px solid #e5e9ec;
  border-radius: 3px;
  overflow: hidden;
  border-spacing: 0px;
}

.ca-other-table-cont {
  padding: 15px 20px;
}

.ca-other-tab-head {
  font-size: 10px;
  color: #213037;
  padding: 7px 10px;
  text-align: left;
}

.ca-oth-td-row-text {
  font-size: 10px;
  color: #54626b;
  padding: 5px;
  text-align: left;
  font-weight: 600;
}

.ca-actions-td-row {
  display: flex;
  justify-content: space-around;
}

.ca-action-blue-index {
  display: flex;
  border: 1px solid #3fb4cf;
  padding: 5px;
  border-radius: 3px;
}

.ca-action-blue-text {
  font-size: 8px;
  color: #3fb4cf;
}

.ca-action-green-index {
  display: flex;
  border: 1px solid #45bb56;
  padding: 7px 10px;
  border-radius: 3px;
}

.ca-active-tab {
  background-color: #458ccc;
  padding: 5px 15px;
  display: flex;
  border: 1px solid #458ccc;
  justify-content: center;
  z-index: 999;
  cursor: pointer !important;
}

.ca-active-tab-text {
  font-size: 9px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer !important;
}

.ca-inactive-tab {
  background-color: #f7f7f7;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  border: 1px solid #458ccc;
  cursor: pointer !important;
}

.ca-inactive-tab-text {
  font-size: 8px;
  font-weight: 500;
  color: #898989;
  cursor: pointer !important;
}

.ca-label-cont {
  margin: 15px 30px;
}

.ca-label-text {
  font-size: 12px;
  font-weight: 500;
  color: #012d83;
}

.ca-invoice-row-cont {
  display: flex;
}

.ca-small-index {
  width: 14%;
  margin-left: 8px;
}

.ca-form-cont {
  background-color: #f5f7f8;
  margin: 20px 20px 0px 20px;
  border-radius: 2px;
  padding: 10px 20px;
  border: 1px solid #e6e6e6;
}

.ca-white-cont {
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  margin-top: 10px;
  padding: 3px;
  border-radius: 2px;
}

.ca-color-row {
  display: flex;
  background-color: #ebebeb;
  padding: 5px 0px;
}

.ca-color-row-index {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ca-color-row-text {
  color: #04214b;
  font-size: 12px;
  font-weight: 600;
}

.ca-input-cont {
  display: flex;
}

.ca-text-area {
  width: calc(100% - 0px);
  /* margin-left: 30px; */
  margin-right: 30px;
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.ca-right-input-cont {
  margin-left: 30px;
  width: 40%;
}

.ca-btn-blue {
  padding: 8px 20px;
  background-color: #4e7eea;
  display: flex;
  border-radius: 3px;
}

.ca-btn-blue-text {
  font-size: 12px;
  color: #ffffff;
}

.ca-gray-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e6e6e6;
}

.ca-calendar-cont-row {
  display: flex;
  justify-content: space-around;
}

.ca-show-add-btn-cont {
  display: flex;
  width: 35%;
  justify-content: space-around;
}

.ca-show-add-cont {
  background-color: #4e7eea;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.ca-show-add-text {
  font-size: 12px;
  color: #ffffff;
}

.ca-invoice-table-cont {
  background-color: #f3f3f3;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-spacing: 1px;
  border-radius: 5px;
}

.ca-table-heading {
  color: #6e6e6e;
  font-size: 10px;
  padding: 10px;
}

.ca-table-head-width {
  width: 55px;
  padding: 10px;
}

.ca-green-cont {
  background-color: #41ab51;
  border-radius: 10px;
  align-items: center;
  width: 46px;
  justify-content: center;
  display: flex;
  padding: 1px 0px;
}

.ca-colored-box-text {
  font-size: 10px;
  color: #ffffff;
}

.ca-font-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  text-align: center;
  padding: 15px;
}

.ca-og-show-add-cont {
  display: flex;
  width: 35%;
}

.ca-table-bottom-text {
  color: rgb(109, 109, 109);
  font-size: 10px;
  margin-left: 10px;
  font-weight: 600;
}

.ca-acc-flow-row {
  display: flex;
  justify-content: space-between;
  padding: 0px 70px;
}

.ca-form-label {
  margin-top: 8px;
  margin-bottom: 9px;
  font-weight: 500;
  width: 120px;
}

.ca-form-a-label {
  color: #0e5ea3;
  font-size: 12px;
  font-weight: 500;
}

.ca-add-btn-row {
  display: flex;
  justify-content: space-between;
  margin-right: 38%;
  margin-top: 10px;
}

.ca-add-btn-cont {
  background-color: #4e7eea;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.ca-agent-table-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
}

/* ========= Client Activities CSS ========== */

.cl-act-container {
  background-color: #ffffff;
  margin: 40px 0px 0px 0px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 25px 15px;
}

.cl-act-org-btn-cont {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cl-act-org-btn-text {
  color: rgb(255, 255, 255);
  font-size: 9px;
}

/* ======= Client email CSS ========= */

.cl-em-container {
  margin: 25px 30px 25px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 50px 33px 20px;
  border-radius: 2px;
}

.cl-em-blue-btn {
  background-color: #1179af;
  display: flex;
  padding: 5px 10px;
  border-radius: 3px;
}

.cl-em-btn-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.cl-em-aei-text {
  color: #013ea5;
  font-size: 14px;
  font-weight: 600;
}

.cl-em-white-cont {
  background-color: #ffffff;
  margin: 20px;
  padding: 10px;
}

/* .all-client-form form input {
  width: 19vw;
} */

.all-client-form form p {
  font-size: 13px;
}

.all-client-form form {
  padding: 12px 8px;
}
.all-client-form {
  width: 100%;
}

.width-100 {
  width: 100% !important;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.w-91 {
  width: 91%;
}

.linked-member-cont {
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
  border: 0.5px solid #8f8e8d;
  background-color: transparent;
}

.linked-member-text {
  font-size: 10px;
  color: #555555;
  font-weight: 500;
}

.ce-field-row {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.ce-add-button-cont {
  margin: 0px 14px;
  padding-bottom: 10px;
  width: 99%;
}

.emp-field-div {
  width: 65%;
}

.emp-line {
  border-top: 1px solid #9b9b9b;
  margin: 10px 0px 20px;
}

.cj-job-status-text {
  color: rgb(51, 170, 218);
  font-size: 12px;
}

.cj-status-cross-row {
  display: flex;
  align-items: center;
}

.input-number-field {
  width: 100% !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.dropdown-options-width {
  width: 330px !important;
}
.dropdown-options-width-search {
  width: 150px !important;
}

.mobile-field-margin-left {
  margin-left: 5px !important;
  margin-top: 12px !important;
}

.mobile-field-margin-left .ant-input-number {
  width: 100% !important;
}

.cv-date-row {
  display: flex;
  align-items: center;
}

.cv-date-label {
  width: 175px;
  font-size: 10px;
}

.emp-input-border .ant-form-item {
  margin-bottom: 0px !important;
}

.cv-detail-ppi-date {
  background-color: #f5f5f5;
  padding: 15px;
  margin: 0px 40px 40px;
  border-radius: 10px;
}

.save-button-add-client {
  display: flex !important;
  justify-content: flex-end;
  margin-right: 19px;
  margin-bottom: 5px;
}

.dropdown-options-width-potential {
  width: 150px !important;
}
