.modalTable {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 15px;
}
.modalTable .cm-table-content-row {
  margin: 15px 10px;
}
.file-note-modal .ant-col {
  max-width: 100% !important;
}
.file-note {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
}
.file-note-list {
  border: 1px solid #e3e3e3;
  padding: 5px;
}
.potential-client-select .ant-select-selector {
  background-color: #4e7eea !important ;
  color: white !important;
}
.addCursor {
  cursor: pointer !important;
}
.potential-client-select .ant-select-selection-placeholder {
  color: white !important;
}
