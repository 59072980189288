.dashboard_container .hide_btn {
  font-size: 12px;
  font-family: "Poppins";
  /* text-transform: uppercase; */
  text-align: center;
  padding: 0 4px;
  height: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  cursor: default;
  border: none;
  box-shadow: none;
  outline: 0;
  color: #fff;
  background-color: #46b8da;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-top: 5px;
  width: 50%;
  margin-left: auto;
}
.task-blue {
  background: #1281b9 !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.task-blue {
  background: #1281b9 !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.cv-normal-text {
  word-break: break-all !important;
  text-overflow: inherit !important;
}
