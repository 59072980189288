.pc-top-btn-row {
  display: flex;
  margin: 40px 40px 20px;
}

.pc-btn-cont {
  background-color: #1081b9;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

.pc-btn-text {
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

.pc-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 10px 20px;
  border-radius: 3px;
}

.pc-input-width {
  width: 18%;
  margin-left: 8px;
}

.pc-text-field-row {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.pc-select-width {
  width: 22.2%;
  margin-left: 8px;
}

.pc-table-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  text-align: center;
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
}

.pc-table-icon-row {
  display: flex;
  justify-content: center;
}

.pc-icon-container {
  display: flex;
  border: 1px solid #d6d6d6;
  padding: 5px;
  border-radius: 5px;
}

/* ======= Potential clients inquiry questionnaire ======== */

.pciq-top-div {
  margin: 20px 20px 0px;
}

.pc-top-div-text {
  color: #6b6b6b;
  font-weight: 600;
  font-size: 14px;
}

.pciq-top-row {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 40px 15px 20px;
}

.pciq-org-box {
  background-color: #fbaf5c;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.pciq-org-box-text {
  font-size: 10px;
  color: #ffffff;
  font-weight: 500;
}

.pc-inactive-inner-tab {
  background-color: rgb(51, 170, 218);
  padding: 10px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.pc-active-inner-tab {
  background-color: #2e6da4;
  padding: 10px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.pc-text-inner-tab {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
}

.pc-normal-text-cont {
  padding: 20px 40px 10px;
}

.pc-bottom-org-box-cont {
  width: 130px;
  margin-left: 55px;
}

.pc-link-main-cont {
  display: flex;
  padding: 0px 55px;
  margin-top: 10px;
}

.pc-link-color-box {
  background-color: #998675;
  display: flex;
  padding: 5px;
  border-radius: 5px;
}

.pc-link-text {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 400px;
  display: inline-block;
}

.pc-language-cont {
  padding: 15px 40px;
}

.pc-radio-cont {
  display: flex;
  margin: 10px;
  align-items: center;
}

.pc-add-btn {
  background-color: #1281b9;
  padding: 5px 15px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

.pc-wa-web-inquiry-link-detailed-cont {
  background-color: #ffffff;
  margin: 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 50px 10px 80px;
  border-radius: 3px;
}

.buttons-container-potential-clients {
  margin-top: 42px;
  margin-left: 29px;
  border-radius: 40%;
}

.buttons-potential-clients {
  font-size: 11px;
  border-radius: 3px;
}

.filter-text {
  color: #1890ff;
}

.inputs-filters-container {
  width: 17%;
  margin-right: 5px;
}

.width-selects-filters {
  width: -webkit-fill-available !important;
}

.remove-select-border .ant-select-selector {
  border: 0px !important;
}

.remove-select-border .ant-select-selection-placeholder {
  color: #4ca0ff !important;
}

.ts-container-inquiry {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.p-emp-container {
  width: 97%;
  margin: auto;
}

.p-emp-table {
  background-color: #ffffff;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.employer-txt {
  font-size: 18px;
  color: #073551;
  font-weight: 400;
}

.ts-container-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.ts-container-Pclient {
  background-color: #ffffff;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 17px;
}

.ts-container-school-invoices {
  background-color: #ffffff;
  margin: 22px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 21px 30px;
  border-radius: 3px;
}

.ts-container-employer-invoices {
  margin: 0px 20px 20px;
  padding: 30px 23px 13px;
  border-radius: 3px;
}

.date-from-container {
  width: 43% !important;
  display: flex !important;
  margin-left: 2px !important;
}

.date-to-container {
  width: 41% !important;
  display: flex !important;
  margin-left: 27px !important;
}

.date-text {
  width: 100px;
}

.checkbox {
  width: 15%;
  margin-right: 5px;
  margin-left: 19px;
  align-items: center;
  display: flex;
}

.filters-container-main {
  width: 21%;
  margin-right: 3px;
}

.filters-container-sub {
  width: 22%;
  margin-right: 3px;
}

.filters-container-sub-status {
  width: 29%;
  margin-right: 3px;
}

.add-new-client-heading {
  margin-top: 48px;
  margin-left: 19px;
  margin-bottom: -30px;
}

.add-new-client-heading-new-case {
  margin-top: 48px;
  margin-left: 19px;
}

.back-button {
  margin-left: 19px;
  margin-top: 46px;
  cursor: pointer !important;
}

.processing-persons-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  border-radius: 6px;
}

.processing-persons-heading {
  margin-left: 71px;
  margin-bottom: 13px;
  color: #556370 !important;
}

.client-tags-form-label {
  font-weight: bold;
  font-size: small;
  margin-left: 2px;
}

.add-client-form-top-element {
  margin-left: 32px !important;
  margin-top: 31px !important;
}

.add-client-form-inputs {
  margin-left: 32px !important;
  margin-top: 0px !important;
}

.pclient-input-margins {
  margin-bottom: 36px !important;
}

.view-detail-link {
  color: #1890ff !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.view-country {
  color: #1890ff !important;
  cursor: auto !important;
  text-decoration: none !important;
}

.add-client-pinput {
  margin-left: 32px !important;
  /* margin-top: 12px !important; */
}

.questionnaire-option-width {
  width: 400px !important;
}

.quesitonnaire-action-buttons {
  display: inline-block;
  border: 1px solid;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #fff;
  border-color: #ccc;
  cursor: pointer;
  margin: 3px;
}

.quesitonnaire-action-buttons > span {
  color: #333;
}
