.remove-account {
  position: absolute !important;
  right: 10px;
  top: 3px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #000 !important;
  font-weight: bold;
}
.userDetail {
  position: relative !important;
}
