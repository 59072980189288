.ts-container {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.ts-normal-text {
  color: #5b5b5b;
  font-size: 10px;
}

.ts-text-area-cont {
  width: 80%;
  padding: 8px 20px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin-top: 10px;
  margin-left: 10px;
}

.ts-upload-file-cont {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: 150px;
}

.ts-text-area {
  width: calc(100% - 5px);
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.ts-send-btn-cont {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.ts-send-btn {
  background-color: #1281b9 !important;
  padding: 5px 15px !important;
  border-radius: 3px !important;
  display: flex !important;
  justify-content: center !important;
  border-color: #1281b9 !important;
}
