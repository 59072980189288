.acc-table-head-row {
  display: flex;
  align-items: center;
}

.acc-color-row-text {
  color: #04214b;
  font-size: 10px;
  font-weight: 500;
  margin-left: 8px;
}

.acc-due-over-due-table-cont {
  width: 90%;
  height: 100%;
  margin: 35px auto;
  border-radius: 3px;
}

.ts-container-settings {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 24px 7px 30px;
  border-radius: 3px;
}

.ts-container-settings-graph-accounts {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 3px;
}

.settings-heading {
  margin-left: 28px !important;
  margin-top: 20px !important;
}

.acc-saved-cont {
  background-color: #ff9f36;
  padding: 1px 15px;
  border-radius: 20px;
}

.acc-color-text {
  color: #ffffff;
  font-size: 13px;
}

.acc-actions-button {
  color: #120bab;
  font-size: 13px;
  font-weight: 500;
}

.acc-search-cont {
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 25px;
  height: 100%;
  border-radius: 3px;
}

.acc-search-head-cont {
  padding-top: 20px;
}

.acc-search-text {
  font-size: 14px;
  color: #075980;
  font-weight: 600;
  margin-left: 20px;
}

.acc-search-input-cont {
  width: 95%;
  height: 100%;
  margin: 0px auto;
  border-radius: 3px;
  padding-top: 20px;
}
