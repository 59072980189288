.addCursor {
  cursor: pointer !important;
}
.emp-contact-form .ant-table {
  background: #f5f5f5 !important;
}
.emp-contact-form .contact-Head {
  padding: 5px;
  margin: 0 !important;
  background: #f5f5f5 !important;
}
.emp-contact-form .contact-emp {
  margin: 0 !important;
}
.file-note-modal .ant-col {
  max-width: 100% !important;
}
