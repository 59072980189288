.m-container {
  background-color: #ffffff;
  margin: 20px 15px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 20px 35px 20px 20px;
  border-radius: 3px;
  border: 1px solid #a0a0a0;
}

.m-row-cont {
  display: flex;
  align-items: center;
}

.m-mehroon-circle {
  background-color: #9d0b0e;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.m-icon-border {
  border: 1px solid #bfbfbf;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}

.m-gray-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  padding: 20px 30px 20px 20px;
  border: 1px solid #e6e6e6;
}

.m-calendar-input-row {
  display: flex;
  margin-top: 10px;
}

.m-white-cont {
  display: flex;
  justify-content: space-between;
  background-color: #fcfcfc;
  box-shadow: 0px 0px 5px 0px rgba(199, 195, 199, 1);
  width: calc(30% - 13px);
  padding: 10px 20px;
  margin-left: -20px;
  margin-top: 35px;
}

.m-card-cont {
  background-color: #fcfcfc;
  box-shadow: 0px 0px 5px 0px rgba(199, 195, 199, 1);
  width: 31%;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
}

.m-org-cont {
  background-color: #fbaf5c;
  display: flex;
  padding: 3px 5px;
  margin-left: 10px;
  border-radius: 5px;
}

.m-row-cont {
  display: flex;
  align-items: center;
}

.m-white-font {
  font-size: 10px;
  color: #ffffff;
}

.m-title-cont {
  padding: 10px 20px;
}

.m-org-border-cont {
  border: 1px solid #fbaf5c;
  padding: 5px;
  padding: 3px 10px;
  display: flex;
  border-radius: 3px;
  margin-left: 20px;
}

.m-popup-user-cont {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
}

.m-followers-row {
  display: flex;
  padding: 0px 20px;
  margin-bottom: 10px;
}

.m-client-btn-row-cont {
  display: flex;
  padding: 10px 40px;
}

.m-client-btn-cont {
  background-color: #1cbbb4;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  border-radius: 15px;
}

.m-client-btn-text {
  font-size: 12px;
  color: #ffffff;
  margin-left: 5px;
}
