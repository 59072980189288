body {
  background-color: #e9ecee;
}

.profile-first-box {
  background-color: #ffffff;
  /* margin: 10px 14px 20px 14px; */
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 20px;
  /* width: 99%; */
}

.profile-cont-left {
  width: 150px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 0.5px solid #e3e3e3;
  height: auto;
  margin-bottom: -20px;
}

.profile-img-cont {
  border: 3px solid #1085bb;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -30px !important;
}

.profile-img {
  width: 100px;
  height: 100px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h5 {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #1182bc;
}

.svg-img {
  width: 15px;
  height: 15px;
}

.svg-btn-img {
  width: 13px;
  height: 13px;
}

.right-cont {
  width: calc(100% - 150px);
}

.profile-puq-cont {
  display: flex;
  padding: 15px 10px 0px 10px;
  justify-content: space-between;
}

.profile-print-box {
  background-color: #36b1e4;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.profile-print-icon {
  width: 15px;
  height: 14px;
}

.date-div {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 20px 16px;
  width: calc(100% - 50px);
}
.date-text {
  font-size: 12px;
  color: #889093;
}

.lv-main-cont {
  display: flex;
  width: calc(100% - 40px);
  justify-content: space-between;
  padding: 0px 10px;
}

.label-value-cont {
  display: flex;
  border: 0.5px solid #c8c8c8;
  height: 21px;
  width: 30%;
  border-radius: 4px;
  padding-right: 1px;
  box-shadow: 0px 0px 5px 0px rgba(235, 232, 235, 1);
}

.label-cont {
  background-color: #e6eaec;
  height: 19px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
  border-right: 1px solid #929292;
  width: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.label-text {
  color: #788892;
  font-size: 10px;
  font-weight: 600;
}

.profile-inner-emailicon-cont {
  background-color: #35b2e4;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  border-radius: 3px;
}

.profile-updbtn-cont {
  background-color: #45bb56;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid #08a576;
  display: flex;
  align-items: center;
}

.profile-updbtn-text {
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

.profile-down-arrow-cont {
  display: flex;
  background-color: #a2d950;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.profile-down-arrow-icon {
  width: 9px;
  height: 9px;
  margin-top: 1px;
}

.profile-btn-img {
  width: 20px;
  height: 20px;
}

.value-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 39px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.value-text {
  font-size: 10px;
  color: #9ba6ac;
}

.buttons-row {
  margin: 20px 10px;
  display: flex;
}

.black-button {
  width: 65px;
  background-color: #535e62;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px 0px;
}

.black-button-text {
  color: #ffffff;
  font-size: 12px;
}

.cross-cont {
  background-color: #dc2f36;
  width: 65px;
  height: 30px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.right-green-btn-cont {
  background-color: #98d14b;
  width: 65px;
  height: 30px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.agent-tag-cont {
  display: flex;
  padding: 10px;
}

.agent-tag {
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-text {
  margin-left: 10px;
  font-size: 10px;
  color: #7c8992;
}

.profile-additional-box {
  background-color: #ffffff;
  margin: 20px 0px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 10px;
  /* width: 99%; */
}

.form-cont {
  padding: 10px 20px;
  display: flex;
}

.input-cont-width {
  width: 48%;
}

.form-btn-cont {
  display: flex;
}

.button-first {
  background-color: #3fb5cf;
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.client-tag-btn {
  background-color: #40d2b3;
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 5px;
  display: inline-block;
  margin-left: 5px;
}
.main-tags {
  margin: auto;
}

.button-second {
  background-color: #40d2b3;
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
  margin-left: 5px;
}

.button-first-second-row {
  display: flex;
  align-items: center;
}

.btn-cross-icon {
  width: 8px;
  height: 8px;
}

.btn-fst-scnd-text {
  font-size: 10px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}

.radio-label {
  color: #555555;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.divided-box {
  width: 47%;
}

.denied-cont {
  padding: 20px 20px 0px 20px;
  display: flex;
}

.detailed-text {
  font-size: 12px;
  margin-left: 40px;
  color: #0a3c5f;
  font-weight: 500;
}

.medical-label {
  margin-top: 8px;
  margin-bottom: 9px;
  font-weight: 500;
}

.denied-text {
  font-size: 12px;
  font-weight: 600;
}

.profile-input-with-icon {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.profile-refresh-cont {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
  margin-left: 5px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.label {
  color: #00003a;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0px;
}

.button-org-cont {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.button-org {
  border: 1px solid #ed441f !important;
  padding: 4px 12px !important;
  border-radius: 5px !important;
  background-color: #ed441f !important;
  display: flex !important;
  height: auto !important;
}

.header-title {
  padding: 30px 40px 0px 20px;
}

.form-container {
  background-color: #f5f7f8;
  margin: 20px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
}

.form {
  background-color: #f5f7f8;
}

.profile-calendar-icon {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.profile-input {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 100%;
}

.profile-input-login {
  padding-top: 16px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  font-size: 16px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}

.profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}

.select-options {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
}

.button-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.button {
  border: 1px solid #1179af;
  padding: 5px 30px;
  border-radius: 5px;
}

.visa-table {
  width: calc(100% - 0px);
  padding: 20px;
}

.visa-table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fb;
  border-bottom: 1px solid #e2e2e3;
}

.authority-table-head-font {
  font-size: 10px;
  font-weight: 500;
}

.auth-text {
  font-size: 10px;
}

.remove-cont {
  border: 1px solid #40b5d0;
  padding: 5px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-text {
  font-size: 10px;
}

.button-blue-cont {
  padding: 0px 10px;
  width: 80px;
}

.button-blue {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #ffffff !important;
}

.visa-type-text {
  font-size: 12px;
  font-weight: 600;
  color: #2a6282;
}

.visa-date-text {
  font-size: 12px;
  color: #b3b9bc;
}

.invoice-status-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px 3px;
}

.pdf-file-btn {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  margin-top: 12px;
  margin-bottom: -10px;
  margin-right: -60px;
}

.case-priority-btn {
  width: 40px;
  background-color: #3fb6cd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px 0px;
}

.case-priority-text {
  font-size: 10px;
  color: #ffffff;
}

.invoice-status-text {
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  word-break: break-all;
}

.invoice-status-text-due-over {
  font-size: 10px;
  color: #ffffff;
  text-align: center;
}

.pdf-file-text {
  font-size: 10px;
  text-decoration: none;
  text-align: center;
  word-break: break-all;
}

.case-priority-cross {
  background-color: #9d0c0e;
  width: 40px;
  height: 15px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cp-green-btn-cont {
  background-color: #98d14b;
  width: 40px;
  height: 15px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.case-priority {
  font-size: 11px;
  color: #2a6282;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__divider {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}

.react-date-picker__inputGroup__input {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}

.white-box {
  background-color: rgb(255, 255, 255);
  width: 10px;
  height: 10px;
  /* margin-right: 15px; */
}

.profile-progressbar-cont {
  padding: 20px 10px 0px;
}

p {
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}

.profile-input-border .ant-form-item {
  margin-bottom: 0px !important;
}

.ant-picker {
  width: 100% !important;
}

.add-partner-btn {
  border: 1px solid #53a8cc !important;
  padding: 20px !important;
  border-radius: 5px !important;
  background-color: #53a8cc !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.add-member-btn {
  border: 1px solid #53a8cc !important;
  /* padding: 20px !important; */
  border-radius: 5px !important;
  /* background-color: #53a8cc !important; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.cf-tags-cont {
  display: inline-block;
  padding: 15px;
  padding-bottom: 0px;
}

.ant-upload-select-picture-card {
}

.form-item-email {
  width: 100%;
  row-gap: 0px;
  margin-right: 10px !important;
  border-radius: 4px;
  margin-top: 7px !important;
}

.form-item-email .ant-form-item-control-input {
  min-height: 30px;
}

.form-item-email .ant-form-item-control-input {
  border: 0.8px solid #c9c9ca !important;
}

.form-mobile-number {
  display: flex;
  margin-left: -31px;
  margin-top: -14px;
}

.form-mobile-number .ant-form-item {
  margin-bottom: 0px !important;
}
