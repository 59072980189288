.file-notes-container {
  background-color: #ffffff;
  margin: 40px 0px 0px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 25px 24px;
  width: 100%;
}

.container-bar {
  background-color: #f6f7f8;
  height: 100px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}

.button-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.button-container-cont {
  padding: 20px;
  display: flex;
}

.button {
  border: 1px solid #1179af;
  padding: 5px 30px;
  border-radius: 5px;
}

.file-notes-content {
  width: 100%;
  padding: 5px 0px;
}

.file-content-index {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
}

.activity-content-index {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
}

.activity-content-index:hover {
  background-color: #ffffff;
}

.file-content-index-cont {
  padding: 15px 20px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
}

.file-content-index-row {
  display: flex;
  justify-content: space-between;
}

.title {
  color: #34b0e1;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}

.text-area {
  width: 100%;
  margin: 15px 0px;
  height: 100px;
}

.date-time {
  color: #768790;
  font-size: 10px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.file-content {
  color: #1280b8;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  padding: 3px 0px 5px;
  font-weight: 600;
}

.table-file-content {
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
}

.content-index {
  width: 20%;
}

.delete-cont {
  justify-content: center;
  /* align-items: center; */
  display: flex;
  margin-top: 10px;
}

.delete-icon {
  display: flex;
  border: 1px solid #ccc;
  width: 27px;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
}

.button-show-all {
  border: 1px solid #eb441f;
  padding: 5px 30px;
  border-radius: 5px;
  background-color: #eb441f;
}

.right-nav-div {
  background-color: #f3f4f5;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  margin: 10px 20px 0 30px;
}

.right-nav-contant {
  border-bottom: 1px solid #e8eaec;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.detail-button {
  margin-left: 10px;
  border: 1px solid #1281b1;
  padding: 2px 7px;
  border-radius: 3px;
  background-color: #1281b1;
  display: flex;
}

.detail-btn-text {
  color: rgb(255, 255, 255);
  font-size: 10px;
}

.client-doc {
  border: 1px solid #ffa037;
  padding: 3px 10px;
  border-radius: 12px;
  background-color: #ffa037;
  display: flex;
}

.fn-normal-text {
  color: #53626c;
  margin-left: 0;
  font-size: 9px;
}

.right-bar-icon {
  width: 20px;
  height: 20px;
}

.right-bar-text {
  font-size: 10px;
  color: #093b5c;
  font-weight: 600;
  text-align: center;
}

.add-file-note-btn {
  padding: 5px 15px;
  border-radius: 3px;
  background-color: #4b91ff;
  display: flex;
}

.file-notes-editor-cont {
  margin-top: 20px;
  margin-bottom: 20px;
}

.file-notes-editor-cont .fr-wrapper {
  height: 175px !important;
}
