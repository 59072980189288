.pp-container {
  background-color: #fff;
  padding: 20px 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  /* -webkit-box-shadow: 0px 2px 7px 1px rgb(0 0 0 / 17%); */
  -moz-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 2px 7px 1px rgb(0 0 0 / 17%);
  /* -webkit-transition: all 0.7s ease; */
  transition: all 0.7s ease;
  display: flex;
  justify-content: space-between;
  padding: 15px 50px;
}

.pp-heading-text {
  font-size: 11pt;
  font-family: Arial;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  /* white-space: pre; */
  white-space: pre-wrap;
}

.pp-heading1-div {
  line-height: 1.38;
  margin-top: 0px;
  margin-bottom: 0px;
}

.pp-link-text {
  font-size: 11pt;
  font-family: Arial;
  color: #1155cc;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: underline;
  /* -webkit-text-decoration-skip: none; */
  text-decoration-skip-ink: none;
  vertical-align: baseline;
  /* white-space: pre; */
  white-space: pre-wrap;
}

.sign-in-btn {
  font-family: Lato-B;
  font-size: 24px;
  color: #09587e;
  background-color: #fff;
  text-align: left;
  border: none;
  padding: 0;
  text-transform: uppercase;
  box-shadow: none;
  outline: 0;
  z-index: 9999;
  cursor: pointer;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.fa-sign-in:before {
  content: "\f090";
}

* {
  margin: 0;
  padding: 0;
  text-indent: 0;
}
.term-conditions {
}
.term-conditions .s1 {
  color: #2b5cac;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 20pt;
  padding-top: 3pt;
  text-indent: 0pt;
  text-align: center;
}
.term-conditions .s2 {
  color: #44536a;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.term-conditions h1 {
  color: #555;

  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.term-conditions p {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin: 0pt;
}
.term-conditions .p {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin: 0pt;
}
.term-conditions .s3 {
  color: #44536a;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s4 {
  color: #555;

  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.s5 {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s6 {
  color: #c00000;

  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.s8 {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s9 {
  color: #c00000;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.term-conditions .a {
  color: #555;
  /*  */
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s13 {
  color: #c00000;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
}
.s14 {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
li {
  display: block;
}
#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}
#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) " ";
  color: #1f487c;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}
#l2 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l2 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l2 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l3 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l3 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l3 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l4 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l4 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l4 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l5 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l5 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l5 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l6 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l6 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l6 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l7 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l7 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l7 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l8 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l8 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l8 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l9 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l9 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l9 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l10 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l10 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l10 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l11 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l11 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l11 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l12 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l12 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l12 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l13 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l13 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l13 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l14 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l14 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l14 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l15 {
  padding-left: 0pt;
  counter-reset: d1 2;
}
#l15 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin-right: 20px;
}
#l15 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}
#l16 {
  padding-left: 0pt;
  counter-reset: d2 1;
}
#l16 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d2, lower-latin) " ";
  color: #555;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  margin-right: 20px;
}
#l16 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}
#l17 {
  padding-left: 0pt;
  counter-reset: d2 1;
}
#l17 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d2, lower-latin) " ";
  color: #555;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  margin-right: 20px;
}
#l17 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}
#l18 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l18 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l18 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l19 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l19 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l19 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l20 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l20 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l20 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l21 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l21 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l21 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l22 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l22 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l22 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l23 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l23 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l23 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l24 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l24 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l24 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l25 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l25 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l25 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l26 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l26 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l26 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l27 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l27 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l27 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l28 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l28 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l28 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l29 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l29 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l29 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l30 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l30 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l30 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l31 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l31 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l31 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l32 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l32 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l32 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l33 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l33 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l33 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l34 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l34 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l34 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l35 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l35 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l35 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l36 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l36 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l36 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l37 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l37 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l37 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l38 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l38 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l38 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l39 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l39 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l39 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l40 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l40 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l40 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l41 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l41 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l41 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l42 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l42 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l42 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l43 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l43 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l43 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l44 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l44 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l44 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l45 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l45 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l45 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l46 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l46 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l46 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l47 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l47 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l47 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l48 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l48 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l48 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l49 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l49 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l49 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l50 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l50 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l50 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l51 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l51 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l51 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l52 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l52 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l52 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l53 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l53 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l53 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l54 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l54 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l54 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l55 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l55 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l55 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l56 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l56 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l56 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l57 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l57 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l57 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l58 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l58 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l58 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l59 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l59 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l59 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l60 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l60 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l60 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l61 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l61 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l61 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l62 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l62 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l62 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l63 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l63 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l63 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l64 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l64 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l64 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l65 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l65 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l65 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
table,
tbody {
  vertical-align: top;
  overflow: visible;
}

.is1 {
  padding-top: 12pt;
  padding-left: 33pt;
  text-indent: -28pt;
  line-height: 117%;
  text-align: left;
}
