.task-blue {
  background: #1281b9 !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px !important;
}
