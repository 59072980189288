.header-top-bar {
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
}

body,
html {
  height: 100%;
}

.ant-layout-header-padding {
  padding: 0 20px !important;
}

.ant-layout-header-padding .ant-layout-header {
  height: 64px !important;
}

.header-name-cont {
  display: block;
  line-height: 20px !important;
  margin-top: 12px;
}

.header-name-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-name-text-style {
  color: rgb(9, 116, 172);
  margin-left: 6px;
  word-break: break-all;
}

/* .header-name-text .ant-layout-header {
  line-height: 14px !important;
} */

.header-bar {
  background-color: #1179af;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  align-items: center;
}

.header-bar-text-div {
  /* background-color: #33aada; */
  /* width: 115px; */
  display: flex;
  padding: 12px 5px;
  align-items: center;
}

.header-bar-image {
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.header-text {
  font-size: 14px;
  color: #ffffff;
}

.logo-img {
  padding: 2px 0 2px 20px;
}

.menu-icon {
  margin-left: 20px;
  padding: 16px 0px;
}

.header-layout {
  background: #ffff !important;
}

.company-cont {
  display: flex;
  margin: 10px 13px 0 20px;
  box-shadow: 0px 0px 6px 2px rgba(244, 244, 244, 1);
  border: 0.5px solid #929292;
  height: 29px;
  width: 33%;
  border-radius: 4px;
  padding-right: 1px;
}

.company-box {
  background-color: #f4f6f6;
  height: 29px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
  border-right: 1px solid #929292;
}

.company-text {
  color: #77858f;
  margin: 7px;
  padding-right: 20px;
}

.css-yk16xz-control {
  border: none !important;
  min-height: 26px !important;
}

.css-g1d714-ValueContainer {
  padding: 1px 6px !important;
}

.css-1wa3eu0-placeholder {
  font-size: 14px;
}

.css-tlfecz-indicatorContainer {
  padding: 0px 4px !important;
}

.css-1pahdxg-control {
  border: 0px solid #d0d0d0 !important;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.63) !important;
  min-height: 27px !important;
}

.css-1pahdxg-control:hover {
  border: 0px solid !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 0px 4px !important;
}

.search-client {
  margin: 10px 0 0 0px;
  display: flex;
  width: 31%;
  border: 1px solid #0974ac;
  height: 29px;
  border-radius: 4px;
}

.search-box {
  background-color: #0974ac;
  width: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: hidden;
  border-right: 1px solid #0974ac;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input-cont {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.search-input {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 15px;
  border: none;
  outline: none;
}

.select-country {
  width: 100%;
  border: 1px solid #929292;
  height: 29px;
  margin: 10px;
  border-radius: 4px;
}

.btn-logout-cont {
  padding: 10px 0px;
  width: 70px;
}

.btn-logout {
  border: 1px solid #1081b9;
  padding: 7px 9px;
  border-radius: 5px;
  background-color: #1081b9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-downarrow-cont {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  /* padding: 3px; */
  margin-left: 5px;
}

.css-1hwfws3 {
  padding: 0px 6px !important;
}

/* ========== Profile top bar CSS ============ */

.cp-top-bar {
  background-color: #ffffff;
  display: flex;
  padding-right: 5px;
}

.cp-top-bar-div {
  display: flex;
  padding: 12px 10px;
  align-items: center;
}

.cp-top-bar-text {
  color: rgb(9, 59, 94);
  font-size: 12px;
}

/* ======== Potential Clients top bar css ======== */

.pc-header-bar {
  background-color: #1179af;
  display: flex;
  padding-right: 5px;
}

.site-layout .site-layout-background {
  background: #ffffff;
}

.ant-input-search-large .ant-input-search-button {
  height: 31px !important;
}

.header-search-col {
  display: flex !important;
  text-align: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 10px;
}

.header-search-col .ant-input-lg {
  padding: 4px !important;
  font-size: 14px !important;
}

.header-search-col .ant-select-selector {
  border: 0 !important;
}

.image-search-result {
  border: 3px solid #1085bb;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.search-client-card-cont {
  display: flex;
  border-bottom: 1px solid #c7c7c7;
}

.header-search-col .ant-btn-primary {
  background-color: #1281b9 !important;
  border: 1px solid #1281b9 !important;
}
.ActiveSpan {
  background: green;
  color: white;
  border-radius: 6px !important;
  padding: 2px;
  font-size: 12px;
}

.InactiveSpan {
  background: red;
  color: white;
  border-radius: 6px !important;
  padding: 2px;
  font-size: 12px;
}

.user-activity-log {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #e6e9ec;
}

.user-activity-log:hover {
  background-color: #ffffff;
}

.show-more-cont {
  padding: 15px 20px;
  display: flex;
  justify-content: flex-end;
}

.show-more-text {
  color: #1280b8;
  font-size: 12px;
  font-weight: 500;
}
