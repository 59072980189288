@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://weloveiconfonts.com/api/?family=fontawesome|iconicstroke|typicons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff1f4 !important;
}

.line-height .ant-table-thead > tr > th {
  padding: 0px 8px !important;
}

.p-client .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.color-apply-template {
  color: rgb(54, 177, 228) !important;
}
.color-background-template-stage {
  background-color: rgb(54, 177, 228) !important;
}

.balance-input .ant-input-number:hover {
  border: transparent !important;
  display: none !important;
}

.balance-input .ant-input-number:focused {
  border: none !important;
  display: none !important;
}

.success:focus {
  border: none !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
}

.balance-client-des {
  color: #889093;
  font-size: 10px;
  margin: -4px;
}

.center-elements {
  display: block !important;
  align-items: center !important;
}

.marginTemplate {
  margin-top: -13px;
}

.poppins-font-family {
  font-family: "Poppins", sans-serif !important;
}

.width-cross-browser {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.employer .ant-modal-title {
  font-family: "Poppins", sans-serif !important;
}
.textDecoration {
  text-decoration: none !important;
}

.col-emp .ant-table-column-sorters-with-tooltip {
  margin-left: 15px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  /*padding: 2px !important;*/
}

.ant-checkbox-inner {
  position: unset !important;
  margin-bottom: -4px !important;
  height: 19px !important;
  width: 19px !important;
}

.login-checkbox .ant-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.hover-link {
  text-decoration: none;
  cursor: pointer !important;
  color: black;
}
.hover-link:hover {
  color: #1890ff;
  cursor: pointer !important;
}

.emp-section-font {
  color: #556370 !important;
  font-weight: 500;
}

.img-login-bg {
  width: 100%;
  height: calc(100vh - 20px);
  object-fit: cover;
}

.lg-right-image-admin {
  width: 100%;
  float: right;
}

lg-right-image {
  width: 550px;
  margin-top: 80px;
  margin-right: 30px;
}
.ant-upload-profile {
  width: 104px;
  height: 104px;
  margin-left: -11px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-top: -1px !important;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Poppins" !important;
}

/* span {
  font-family: "Poppins";
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker {
  padding: 6px 11px 6px 10px !important;
}

.client-form .ant-picker {
  padding: 4px 11px 4px !important;
}
/* 
.client-form .ant-row {
  display: block !important;
} */

.d-flex-contract {
  display: flex;
  justify-content: flex-end;
}

.d-flex-contract .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: flex-end !important;
}

.multi-select-option .ant-select-selector {
  height: auto !important;
}

.ant-select-selector {
  height: 32px;
  padding: 0 11px;
  width: auto !important;
}

.ant-select-selector span {
  font-size: 12px !important;
}

.ant-picker-input input {
  font-size: 12px !important;
  font-family: "Poppins" !important;
}

.ant-input {
  font-size: 12px !important;
}

.ant-form label {
  font-size: 12px !important;
  font-family: "Poppins";
}

.client-form .ant-select-selector {
  height: 28px !important;
}

.all-client-form .ant-form-item-label {
  font-weight: 400 !important;
}

.container-box {
  width: 90%;
  margin: auto;
}

.width-100 {
  width: 100% !important;
}

th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

td {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.height-100 {
  height: 100% !important;
}
.width-25 {
  width: 22%;
}
.width-52 {
  width: 52%;
}
.w-20 {
  width: 20%;
}
.w-80-t {
  width: 80%;
}
.width-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
  margin: auto;
}
.p-box {
  padding: 23px !important;
}
.employer-doc-tab .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff !important;
}

.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.flex-end {
  justify-content: flex-end;
}

.float-right {
  float: right !important;
}
.border-3 {
  border-radius: 3px !important;
}
.float-right {
  float: right !important;
}
.mar-zero {
  margin: 10px !important;
  padding: 0px !important;
}
.margin-top {
  margin-top: 13px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-btm {
  margin-bottom: 26px;
}
.d-aligin-center {
  display: flex !important;
  align-items: center !important;
}
.align-items {
  align-items: center;
}
.d-aligin-center p {
  font-size: 12px;
}
.text-style .ant-col .ant-form-item-label > label {
  font-weight: 500;
  color: #737373 !important;
  font-size: 13px;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.top-60 {
  margin-top: 60px;
}
.mar-top-35 {
  margin-top: 35px;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.border-color {
  border: 1px solid #d6d0d0;
  border-radius: 3px;
  margin-bottom: 12px;
}

.border-5px .ant-input {
  border-radius: 5px;
}
.border-5px textarea.ant-input {
  border-radius: 5px;
  margin-top: 12px;
}
.mar-top .ant-form-item-control-input-content {
  margin-top: 40px;
}
.margin-btns .ant-btn-primary {
  margin-left: 12px;
}
.space-between {
  justify-content: space-between;
}
.contant-around {
  justify-content: space-around !important;
}
.contant-center {
  justify-content: center !important;
}
.align-center {
  align-items: center;
}
.reminder-tabs-header .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header-temp .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
}
.reminder-tabs-header-temp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header-temp .ant-tabs-tab-active {
  background: #36b1e4;
}

.reminder-tabs-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header .ant-tabs-tab-active {
  background: #36b1e4;
}
.mar-zero .task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
}
.reminder-btn-main {
  display: flex;
  justify-content: center;
}

.add-task-btn .ant-btn {
  background-color: #1281ba;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.reminder-btn .ant-btn {
  background-color: #ff9e37;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.marked-name {
  background-color: #ff9d36;
  border-radius: 15px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  margin-left: 5px;
}

.marked-name-font {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.mdtr-reminder-tasks-cont {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}
.mdtr-reminder-tasks-header-cont {
  background-color: #f8f9fb;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8eaec;
  margin-top: 33px;
}
.mdtr-reminder-tasks-head-text-cont {
  display: flex;
  align-items: center;
}
.mdtr-rem-row-cont {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8eaec;
}
.cv-bold-text {
  color: #073d5d;
  font-size: 19px;
  font-weight: bolder;
}
.todo-main {
  justify-content: space-between;
  padding: 0px 34px;
  font-weight: 700;
}
.heading-style span {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.mdtr-user-cont {
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
}
.sus-checkbox {
  height: 15px;
  width: 20px;
}
.hide-icon {
  border: 1px solid;
  display: flex;
  padding: 0px 8px;
  border-radius: 50%;
  align-items: center;
}
.reminder-dropdown {
  margin-top: 33px;
}
.reminder-dropdown .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.reminder-dropdown .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.balance-accordian {
  margin-top: 15px !important;
}

.balance-accordian
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px;
  left: auto;
}

.invoice-status-btn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block !important;
}

.balance-accordian
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.balance-accordian .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.balance-accordian .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.template .ant-collapse-header {
  display: block !important;
}

.balance-accordian .ant-collapse-extra {
  margin-top: -37px !important;
  padding-right: 27px !important;
}

.reminder-border {
  border-bottom: 1px solid #d6d6d6;
  margin-top: 11px !important;
}

.reminder-head {
  color: #075a80;
  margin-left: 15px;
  font-weight: bold;
}
.reminder-calender {
  margin: 20px 0px 33px 0px;
}
.sidebar-reminder {
  margin-bottom: 33px;
  margin-top: 22px;
}
.reminder-calender .react-calendar {
  width: 100%;
  padding: 23px;
  border: 0;
}
.completed-tasks {
  margin-top: 45px;
}
.reminder-model-main {
  border-radius: 30px;
}
.reminder-model-main .ant-modal-content {
  /* width: 616px !important; */
  padding: 15px;
  border-radius: 3px;
}
.reminder-model-main .mdtr-modal-gray-cont {
  background-color: #f6f7f9;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e4e4e4;
  display: flex;
  border-radius: 5px;
  padding-right: 20px;
}
.reminder-model-main .mdtr-checkbox-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reminder-model-main .cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
}
.reminder-model-main .profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}
.reminder-model-main .profile-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}
.reminder-model-main .mdtr-add-tasks {
  background-color: #1281b9;
  padding: 7px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.reminder-model-main .ant-modal-header {
  border-bottom: none !important;
  color: #6c6c6c !important;
  padding: 2px 26px 0px 26px;
  font-size: 18px;
  margin-bottom: 38px;
  border-radius: 30px 30px 30px 30px !important;
}
.reminder-model-main .ant-modal-title {
  /* color: #6c6c6c; */
  font-size: 14px;
  font-weight: 600;
}
.reminder-model-main .cv-bold-text {
  font-size: 12px;
  font-weight: 600;
  margin-top: 36px;
}
.reminder-model-main .ant-modal-body {
  padding: 0px 20px;
}
.reminder-model {
  border-radius: 40px;
}
.reminder-model-main .emp-ae-input-width p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.reminder-model-main .emp-ae-input-width {
  margin-right: 32px;
}
.modal-parts {
  width: 84%;
  margin: auto;
}
.reminder-model-main .ant-form-item {
  padding: 15px 0px;
}
.form-parts .ant-form-item-label {
  font-weight: 500;
}
.bg-box-white {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  padding: 22px 0;
}
.mar-b {
  margin-bottom: 32px;
}
.w-96 {
  width: 96%;
  margin: auto;
}
.mar-tb {
  padding: 20px 5px;
}
.comment-btn .ant-btn-primary {
  float: right !important;
  margin-top: 14px;
  border-radius: 4px;
  border: 0px;
}
.follower-row h4 {
  margin-right: 13px;
}
.mar-right {
  margin-right: 7px !important;
}
/* .tasks-comment .ant-comment {
  width: 96%;
  margin: auto;
} */
.box-lenght {
  max-height: 400px;
  overflow: scroll;
}

.box-lenght::-webkit-scrollbar {
  width: 4px;
}
.box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 0px;
}
.box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.completed-box-lenght {
  max-height: 1000px;
  overflow: scroll;
}
.completed-box-lenght::-webkit-scrollbar {
  width: 4px;
}
.completed-box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.completed-box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}
.completed-box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.color-check {
  margin: 5px 8px 0px 0px;
  border-radius: 2px;
}
.delete-model p {
  font-size: 15px;
  font-weight: 200;
  padding: 13px 0px 0px 7px;
}
.margin-right {
  margin-right: 10px;
}
.client-form .ant-form-item {
  display: block;
  margin-top: 9px;
}
.all-client-form .ant-form-item-label {
  font-weight: 600;
}
.header-text-style {
  font-size: 17px;
  font-weight: 500;
  color: #083c5d;
  padding: 30px 40px 0px 20px;
}
.all-client-form .client-form .ant-form-item-no-colon {
  color: #55626c !important;
}
.all-client-btn {
  margin: 25px 23px 0 0px;
  justify-content: flex-end;
}
.reminder-settings {
  width: calc(100% - 170px);
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 50px;
  margin: 35px 85px;
  border-radius: 3px;
}

.invoice-table-body-dates {
  font-size: 14px;
  font-weight: 450;
  color: black;
  padding-bottom: 5px;
}
.client-tag-table .ant-row .ant-form-item {
  display: block;
}

.reminder-set-form {
  /* background-color: #ffffff; */
  /* padding: 30px; */
  /* border-radius: 5px; */
  /* border: 1px solid #eeeeee; */
}
.reminder-set-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-set-form p {
  font-size: 16px;
  /* color: #556370; */
  font-weight: 100;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 100;
}

.reminder-setting-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.imap-set-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.imap-set-form .ant-form-item-label {
  margin-right: 0px;
}
.margin-top-34 {
  margin-top: 34px;
}
.Reminder-top {
  width: 100%;
  /* margin: auto; */
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
}
.time-tracking {
  width: 95%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}
.employer-manag {
  width: 91%;
  height: 100%;
  margin: 35px auto;
}
.dynamic-keys {
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  padding: 4px 0px 6px 17px;
}
.dynamicForm {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin: 21px 0px 59px 0px;
}
.mail-Chimp-text {
  padding-top: 35px;
  font-size: 16px;
}
.mail-Chimp-text p {
  font-size: 13px;
  font-weight: 450;
  margin-top: 0px !important;
}
.mail-text p {
  padding-left: 35px;
}
.mail-chimp-btn {
  display: flex;
  justify-content: flex-end;
}
.mail-chimp-box {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 54px 74px 54px;
  margin: 35px auto;
  border-radius: 3px;
}
.default-font-box {
  width: 98%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 35px 20px;
  border-radius: 3px;
}
.document-sync-box {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 10px auto 35px auto;
  border-radius: 3px;
}

.document-checklist-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 17px;
  margin: 35px auto;
  border-radius: 3px;
}
.document-checklist--btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
}
.email-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  margin: 27px auto;
  border-radius: 3px;
}
.email-content-box h4 {
  padding-bottom: 20px;
  color: #448ccb;
  font-size: 16px;
}
.questionnaire-content {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 81px;
  margin: 35px auto;
  border-radius: 3px;
}
.questionnaire-top {
  width: 82%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}

.letter-font p {
  font-size: 14px;
}
.letter-spacing {
  margin: 16px 0px 0px 0px;
}
.letter-height {
  height: 500px;
}
.letter-froala .fr-box.fr-basic .fr-element {
  max-height: 500px;
  border-bottom: 2px solid #cccccc;
  min-height: 274px;
}
.fr-second-toolbar {
  display: none !important;
}
.file-uploader .ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9 !important;
  background-color: transparent;
  padding: 5rem;
  margin-bottom: 2rem;
}
.file-uploader .ant-upload-text {
  color: #1890ff !important;
}
.file-uploader {
  margin-top: 15px;
}
.file-uploader .ant-upload {
  border-radius: 0px !important;
}
.border-box {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-emp-manag {
  background: white;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-add-emp {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px 50px;
  margin-top: 38px !important;
}
.border-box-employer {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 34px;
  /* margin-top: 38px !important; */
}
.padding-20 {
  padding: 20px !important;
}

.w-9 {
  width: 13%;
}
.w-91 {
  width: 87%;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.mar-top-75 {
  margin-top: 75px;
}
.doc-office {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.Upload-signature .avatar-uploader > .ant-upload {
  width: 190px;
  height: 128px;
  border-radius: 0% !important;
  margin-bottom: 10px;
}
.Upload-signature .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background: white;
  border: 2px dotted #d9d9d9 !important;
  border-radius: 0px !important;
}
.MainSideBar .ant-tabs-nav-list {
  background-color: #213037;
  color: #fff;
}
.dashboardSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.dashboardSideBar {
  width: 265px !important;
  /* min-width: 100% !important; */
}
.supervisorSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.supervisorSideBar {
  width: 262px !important;
}
.site-layout {
  background: #ffffff;
  /* height: 100%; */
  position: fixed;
  margin-top: 64px;
  /* min-width: 265px !important; */
  overflow: auto;
}
.site-layout .ant-layout-sider-children {
  overflow: auto;
  position: fixed;
}
.screen-width {
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
  /* margin-left: 265px; */
  /* margin-top: 64px; */
}

.dashboardSideBar .ant-menu-item a {
  /* padding-left: 14px !important; */
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.dashboardSideBar .ant-menu .ant-menu-dark .ant-menu-root .ant-menu-inline {
  color: #ffffff;
  font-size: 18px;
  border: 0px;
}
.dashboardSideBar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: red !important;
}
.ant-layout-header {
  position: fixed;
  z-index: 10;
  width: 100%;
  /* height: 3rem; */
  background: #fff !important;
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
  overflow: overlay;
  width: 100%;
}
/* .open-sidebar div>div>div{
  position: relative!important;
}
.close-sidebar div>div>div{
  position: relative!important;
} */
/* added by aatif */
.site-layout {
  position: absolute !important;
  /* position: fixed !important; */
}
.ant-layout-content {
  padding-top: 65px !important;
}
.site-layout .ant-layout.ant-layout-has-sider {
  width: 100% !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: rgba(33, 48, 55, 255) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(69 89 99);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #f1e9e9 !important;
}
.employer-table .ant-table-pagination.ant-pagination {
  margin: 7px 26px !important;
}
/*.employer-table .ant-table-cell {*/
/*  text-align: center;*/
/*}*/
/* .table-head-clr .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
}
.table-head-clr .ant-table-column-sorters-with-tooltip {
  background-color: #f0f2f5;
} */
.add-employer-para .ant-col .ant-col-12 p {
  font-size: 14px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.employer-manag-tabs .ant-tabs-nav-wrap {
  background-color: #f0f2f5 !important;
}
.employer-manag-tabs .ant-tabs-tab {
  color: black;
  font-weight: 800 !important;
  font-size: 16px;
  margin: 0px;
  background: #0383bc !important;
  padding: 5px 17px !important ;
  font-size: 12px !important;
}
.employer-manag-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.employer-manag-tabs .ant-tabs-tab-active {
  background: #36b1e4 !important;
}
.employer-manag-tabs .ant-form-item {
  padding: 15px 0px !important;
}

.emp-profile-box-employer-new .ant-form-item {
  padding: 15px 0px !important;
}

.employer-manag-tabs .ant-tabs-tab {
  margin-right: 0px !important;
  border-radius: 0px !important;
}
.employer-manag-tabs .ant-tabs-tab-btn {
  font-weight: 500;
  color: white;
}
.upload-button .ant-btn {
  border-radius: 0px !important;
}
.employer-head {
  margin-top: 33px;
  margin: 44px 0px 10px 10px;
}
.form-add-btn {
  width: 26px;
  border-radius: 33px !important;
  height: 26px;
  /* float: right; */
}
.form-add-btn .ant-btn-icon-only {
  height: 25px;
  border-radius: 45px;
  margin-bottom: 4px;
  /* position: fixed; */
  z-index: 20;
}
.form-add-btn .ant-btn-icon-only svg {
  margin-bottom: 4px;
}
.remove-button {
  cursor: pointer;
  background-color: #f52b2b;
  color: white;
  display: flex;
  width: 5%;
  padding: 7px 14px;
  border-radius: 44px;
  font-weight: 400;
  margin: 35px 0px;
}
.remove-button:hover {
  background-color: #1890ff;
  color: white;
}
.contact-Head {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.contact-emp {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin-top: 25px;
}
.add-job-form .ant-row .ant-col .ant-col-4 {
  font-size: 19px !important;
}
.add-job-form .ant-input-number {
  width: 100% !important;
}
.emp-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  /*margin: 27px auto;*/
  border-radius: 3px;
}
.emp-froala h4 {
  color: #448ccb;
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 12px;
}

.green-btn {
  background: #0ca22c !important;
  border: 0px !important;
  color: #fff !important;
}
.margin-client {
  display: flex;
  margin: 18px 0px 0px 15px;
}
.padding-btn {
  padding: 10px 23px;
}
.file-note-light {
  background-color: #fff;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}
.payment-form-labels {
  margin-top: 16px;
}
.file-note-dark {
  background-color: #fffcfc;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}

.accounts-flow-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.table-row-light {
  background-color: #fff !important;
  /* padding: 6px 16px; */
}
.table-row-dark {
  background-color: #f0f2f5 !important;
  /* padding: 6px 16px; */
}
.file-contacts .ant-row .ant-form-item {
  width: 90%;
  margin: auto;
}
.file-delete-head {
  margin: 18px 25px 0px 23px;
}
.border-color-blue .ant-tabs-tabpane .ant-tabs-tabpane-active {
  border: 1px rgb(16, 129, 185);
}
/*.ant-table-thead > tr > th {*/
/*  text-align: center !important;*/
/*  font-size: 13px;*/
/*}*/

.school-table .ant-table-thead > tr > th {
  text-align: left !important;
  font-size: 14px !important;
}

.child-invoice-table .table-head .table-tbody > tr > th {
  padding: 16px 0px 16px 0px !important;
}

.table-head .ant-table-column-sorters {
  padding: 11px 0px;
}
.table-head .ant-table-column-sorters-with-tooltip {
  text-align: center;
}
.table-head .ant-table-cell {
  /* text-align: center; */
}
.table-head .table-tbody > tr > td {
  align-items: center !important;
  padding: 12px 0px !important;
}
/* .table-head .ant-table-cell {
  padding: 10px !important;
} */
.ant-select {
  width: 100%;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
  margin-top: 4px;
}
/* .employer-Header {
  height: 54px;
} */
.alert-msg .ant-form-item-explain .ant-form-item-explain-error {
  float: right !important;
}
/* .required p::before {
  content: "*";
  position: absolute;
  /* right: -8px; */
/* left: 18px;
color: red;
top: -2px; */
/* } * */
.job-table .ant-table-cell {
  padding: 8px 4px 8px 10px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}
.contact-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-table .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}

.contact-tables .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-tables .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  margin: 16px 42px !important;
  text-align: left;
  background: transparent !important;
}

.job-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}
.job-table .ant-table-container {
  /* padding: 20px; */
}

.contact-us .ant-table-cell {
  padding: 13px 7px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: center;
  color: #474a4c;
}
.contact-us .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.add-employer-para .ant-col .ant-col-12 p {
  font-size: 13px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.width-web-kit {
  width: -webkit-fill-available !important;
}

.width-modal-sending-receipt {
  width: 1022px !important;
}

.width-modal-add-template {
  width: 641px !important;
}

.width-modal-add-email-template {
  width: 70vw !important;
}

.border-box-modal-sending-receipt {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 72px 10px 17px;
  margin-top: 38px !important;
}

.font-school-list-filter-text {
  font-size: smaller;
  font-weight: 700;
}

.font-school-list-filter-text-sub {
  color: #889093;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.sus-tab-container-schools {
  display: flex;
  margin: 30px 0px 0px 20px;
  cursor: pointer;
}

.sus-tab-container-clients {
  display: flex;
  margin-left: 20px;
  margin: 44px 0px 0px 20px;
  cursor: pointer;
}

.sus-active-tab-schools {
  background-color: rgb(51, 170, 218);
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.sus-inactive-tab-schools {
  padding: 5px 20px;
  display: flex;
  background-color: #0383bc;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.upload-technical-support-complaint .ant-upload.ant-upload-select-picture-card {
  margin-top: 9px;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.upload-technical-support-complaint .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 9px 8px 8px 0;
  vertical-align: top;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-active-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ts-container-schools {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.button {
  color: #fff !important;
  background: #096dd9 !important;
  border-color: #096dd9 !important;
  padding: 5px 16px !important;
  border-radius: 2px !important;
}

.cont-positioning {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.border-box-modal {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: 38px !important;
}

.add-new-line-container {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: auto;
  margin-top: 16px;
  margin-left: 16px;
  padding-bottom: 15px;
}
.add-new-line-header {
  background-color: #e6e6e6;
  height: 26px;
}

.add-new-line-header-school-invoices {
  background-color: #e6e6e6;
}

.add-new-line-inputs {
  padding: 7px;
}

.add-new-line-header-position {
  margin-top: 5px;
  text-align: center;
}

.add-new-line-button {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: 36px;
  width: 107px;
  margin-left: 22px;
  margin-top: 6px;
  cursor: pointer;
}
.add-new-line-button p {
  text-align: center;
  font-size: 13px;
}

.width-modal {
  width: 702px !important;
}

.label-search-schools {
  font-size: 16px;
  font-weight: bold;
  color: rgb(24, 91, 148);
}

.width-modal-outgoing-payments {
  width: 735px !important;
}

.incoming-payments-table-row {
  background: #bcf6c5 !important;
}

.invoices-children-table-row {
  background: #e5e4e2 !important;
}

.outgoing-payments-table-row {
  background: #f6d6d0 !important;
}

.font-size-add-school-form {
  font-size: 12px;
}

.text-style-show-container {
  color: #fff;
}

.ca-gray-padding {
  padding: 15px 20px 15px 0px !important;
}

.ca-gray-padding-payment-history {
  padding: 15px 20px 15px 20px !important;
}

.payment-buttons-container {
  display: flex;
  margin-left: 19px;
  margin-top: 10px;
}

.payment-label {
  font-size: 12px;
  font-weight: initial;
  margin-left: 19px;
}

.show-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: -3%;
  cursor: pointer;
}

.display-contact-text-container {
  display: block;
  margin-inline-start: 20px !important;
}

.margin-contact-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-icon {
  margin-top: -35px !important;
}

.haha {
  border: 1px solid red;
}

.border-box-modal-add {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}

.positioning-delete-icon {
  margin-top: 7px;
}

.positioning-show-levels {
  text-align: center;
}

.border-show-contacts {
  border-bottom: 1px solid rgb(214, 208, 208);
  width: 587px;
  margin-left: -11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.border-add-template-stages {
  border-bottom: 1px solid rgb(214, 208, 208);
  padding-top: 22px;
  margin-left: 25px;
}

.border-detail {
  border-bottom: 1px solid rgb(214, 208, 208);
}

.hide-levels-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: 7%;
  cursor: pointer;
}

.ts-container-commission-reminders {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.redo-icon {
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.upload-technical-support-complaint {
  margin-top: 13px !important;
  margin-left: 20px !important;
}

.upload-technical-support {
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.employer-doc-tab .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.employerin-box {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 56px;
  height: 100%;
  border-radius: 3px;
}
.ant-table-cell .ant-table-column-sort .ant-table-column-has-sorters {
  background: white;
}
.ant-table-column-sorters-with-tooltip {
  background: white;
}
.ant-table-cell .ant-table-selection-column {
  background: white !important;
}
.document-table .ant-table-cell {
  text-align: center;
}
.send-btn .ant-btn-primary {
  border-radius: 4px !important;
}
.border-box-checklist {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 13px 54px;
}
.checklist-add-field {
  background: #f5f5f5;
  border: 0px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}
.checklist-textarea .ant-input {
  height: 100px !important;
}
.checklist-switch .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch .ant-switch-checked {
  height: 10px;
  background-color: #bf0909b8 !important;
}
.checklist-switch .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #aa2626 !important;
}
.checklist-switch .ant-switch-inner {
  height: 10px;
  /* background-color: black !important; */
}
.checklist-switch-low .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch-low .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #eec914;
}
.checklist-switch-low .ant-switch-checked {
  height: 10px;
  background-color: #eec914ad !important;
}
.checklist-switch-low .ant-switch-inner ::before {
  height: 10px;
  background-color: black !important;
}
.checklist-switch-low .ant-form-item-label > label {
  height: 22px !important;
}
.checklist-switch .ant-form-item-label > label {
  height: 22px !important;
}

.all-client-form .ant-form-item-label > label {
  height: 22px !important;
}

.imap-text p {
  font-size: 13px !important;
  /* color: rgb(141, 139, 139) !important; */
}
.imap-text {
  font-size: 15px !important;
}
.input-name {
  font-weight: 500 !important;
}
.employer-logo-box {
  background-color: #fff;
  justify-content: center !important;
  display: flex !important;
  padding: 50px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 26%);
}
.container {
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.link-client-logo {
  width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  height: 8rem;
}
.footer-info-text {
  display: flex;
}
.red-dot {
  height: 14px;
  width: 14px;
  background-color: #e61616;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}
.yellow-dot {
  height: 14px;
  width: 14px;
  background-color: #faad14;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}

.upload-image-heading {
  margin-top: 70px;
}

.personal-info-heading {
  margin-bottom: 24px;
  padding-top: 18px;
}

.ts-container-add-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 35px 30px 0px;
  border-radius: 3px;
}

.personal-info-add-client-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-right: 30px;
  border-radius: 6px;
  margin-left: 35px;
  padding-bottom: 37px;
}

.personal-info-add-client-container
  .ant-form-horizontal
  .ant-form-item-control {
  min-width: 100% !important;
}

.ts-container-import-clients {
  background-color: #ffffff;
  margin: 15px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.post-to-xero {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.xero-filters-label {
  width: 19%;
  margin-top: -5px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border: solid 10px transparent;
}

#style-3::-webkit-scrollbar {
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px;
}

.save-button {
  margin-left: 7px;
  margin-top: 29px;
}

.container-center {
  height: 2em;
  position: relative;
}
div.container-center h5 {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ts-potential-client-statuses {
  background-color: #ffffff;
  margin: 50px 13px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 2px 16px 5px;
  border-radius: 5px;
}

.duplicate-client-table-container {
  margin-top: 37px;
  margin-left: 31px;
  margin-bottom: 15px;
}

.spinner-xero {
  text-align: center;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  align-content: center;
  vertical-align: middle;
}

.spinner {
  text-align: center;
  z-index: 1000;
  padding-top: calc(50% - 25%);
  width: 100%;
  height: 100vh;
  align-content: center;
  vertical-align: middle;
}
.client-table .ant-table {
  border: 1px solid rgb(184, 179, 179) !important;
  border-radius: 2px;
}
.employer-btn {
  /* border-radius: 6px !important; */
  border: 0px !important;
}
.emp-w-box {
  padding: 29px 84px;
  background-color: white;
  margin-top: 20px 20px;
}

.emp-w-box-employer {
  padding: 29px 84px;
  background-color: rgb(245 247 248) !important;
  margin-top: 20px 20px;
}

.emp-profile-table {
  background-color: whitesmoke;
}
.auto-email {
  border: 1px solid #c9c9ca;
  padding: 14px 41px !important;
  margin-bottom: 10px;
}
.create-email-form .ant-form-item {
  padding: 7px 0px !important;
}
.visa-body-text {
  justify-content: space-between;
  display: flex;
  max-width: 64%;
}
.visa-body-text span {
  color: #5c5c5c;
  font-weight: 450;
}
.visa-status-header {
  height: 100px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 90%;
  margin: auto;
}
.status-header-left {
  width: 80%;
  background-color: #f3f3f3;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.status-header-left h2 {
  color: #858585;
  margin: 0;
}
.status-header-left p {
  color: #858585;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-top: 6px;
}
.event-visa-btn {
  background: #f6752f;
  border: 0;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 3px 17px;
}
.status-header-right {
  width: 20%;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}
.status-header-right h2 {
  color: #ffffff;
  margin: 0;
}
.visa-status-body {
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px 30px 30px 30px;
}
.visa-status-body button {
  margin-bottom: 7px;
}
.visa-status-body-data {
  background-color: white;
  width: 100%;
  margin: auto;
  border-radius: 5px !important;
  padding: 30px !important;
}
.case-new {
  width: 90%;
  background-color: #36b1e4;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  height: 8rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-top: 40px;
  margin-top: 10px;
}
.case-new h4 {
  color: #fff;
  font-size: 18px;
}
.incoive-table .ant-table-container {
  background: #eff1f4;
  width: 90%;
  margin: auto;
}
.file-spacing {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
}
.document-table .ant-row .ant-form-item {
  /* justify-content: center; */
  width: 40%;
}
.box-emp {
  width: 100%;
  align-items: center;
  /* box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 25%); */
  background-color: #ffffff;
  margin: 35px 25px;
  /* height: 100%; */
  border-radius: 3px;
}
.box-emp .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
.close-editor {
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}
.add-emp-form .ant-form-item-explain {
  justify-content: flex-start !important;
  display: flex !important;
}
.slider-small {
}
.slider-small .ant-layout-sider-children {
  width: 84px !important;
}
.slider-small .ant-layout-sider {
  background-color: transparent;
}
.site-layout-background {
  width: 100% auto !important;
}
.open-sidebar {
  width: 89%;
  margin-left: 265px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.rightbar-icons {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.rightbar-icons img {
  margin-right: 10px;
}

.client-tabs {
  margin: 10px 0px 20px 0px !important;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75) !important;
}
.client-tabs .ant-tabs-left > .ant-tabs-content-holder {
  background: white;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  /* width: auto; */
}
.client-tabs .ant-tabs-tab-active {
  background-color: #ffffff !important;
}
.reminder-tabs-header
  .client-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #083c5d !important;
  font-weight: 500 !important;
}
.client-tabs .ant-tabs-tab {
  display: flex;
  color: #083c5d !important;
  font-weight: 500 !important;
}
.client-tabs .ant-tabs-ink-bar-animated {
  border: none;
  background: none;
}
.client-tabs .ant-tabs-nav {
  font-weight: 500;
  color: #083c5d;
}

.client-tabs .ant-tabs-tab {
  padding: 8px 15px !important;
}

.short-tabs {
  margin: 10px 0px 20px 0px !important;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75) !important;
  width: 115px;
}
.short-tabs .ant-tabs-left > .ant-tabs-content-holder {
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  display: none;
}
.employer-tabs-content > .ant-tabs-content-holder {
  background-color: #f0f2f5 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.employer-tabs-contents > .ant-tabs-content-holder {
  font-size: 12px !important;
  font-weight: inherit !important;
  font-family: "Poppins", sans-serif !important;
}
.short-tabs .ant-tabs-tab-active {
  background-color: #ffffff !important;
}
.short-tabs .ant-tabs-ink-bar-animated {
  border: none;
  background: none;
}
.short-tabs .ant-tabs-nav {
  font-weight: 500;
  color: #083c5d !important;
}
.reminder-tabs-header .short-tabs .ant-tabs-tab {
  font-weight: 500;
  color: #083c5d !important;
}

.short-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  color: #083c5d !important;
}
.short-tabs .ant-tabs .ant-tabs-left {
  display: block !important;
}
.profile-tags {
  padding: 4px 10px;
  border: 1px solid #3fb5cf;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.tags-text {
  font-size: 10px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}
.signup-contaienr {
  width: 80%;
  margin: auto;
}
.signup-container {
  width: 67%;
  margin: 51px auto;
  border: 1px solid #cecece;
  padding: 20px 0px;
  background-color: white;
}
.signup-fields .ant-form-item {
  padding: 15px 0 !important;
}
.signup-fields {
  width: 87%;
  margin: 10px auto 32px auto;
  background-color: #f0f2f5;
  padding: 10px 63px;
}
.signup-text {
  margin: 18px 0px;
}
.signup-text span {
  /* font-size: 14px; */
  font-weight: 600;
  color: #5d5d5d;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.save-signup .ant-form-item-control-input {
  width: 88%;
  margin: auto;
}

.cmv-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 16px !important;
}
.cmv-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.ant-input-number-handler-wrap {
  position: absolute;
  display: none !important;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  /*border-left: 1px solid #d9d9d9;*/
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.profile-container {
  width: 98%;
  margin: auto;
  padding-top: 45px;
  display: flex;
}
.profile-input-border .ant-form-item-control-input {
  min-height: 30px !important;
}
.sign-up-head {
  justify-content: space-around;
  align-items: center;
}
.head-d {
  border: 1px solid;
  height: 10px;
}
.head-link {
  padding: 0 10px 10px 0;
}
.head-text h1 {
  color: #095c81;
}
.email-input input {
  width: 440px;
  height: 39;
  border-radius: 44px 0px 0px 44px;
  background: #eeeeee;
  border: 0px;
}
.sign-up-btn button {
  width: 160 !important;
  border-radius: 44px;
  height: 39;
}
.sign-up-btn .ant-col {
  margin-left: -6px !important;
  padding-left: 0px;
}
.email-input .ant-col {
  padding: 0px !important;
}
.bg-white .ant-layout {
  background-color: white;
}

.sign-up-btn .ant-btn-primary {
  background: #083c5d;
  border: 0px;
  margin-left: -20px;
  margin-bottom: 40px;
}
.sign-up-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: rgb(3 131 188);
  border-color: #fff;
  border: 1px solid;
}
.email-input .ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #eeeeee !important;
}
.signup-email .ant-row {
  justify-content: center;
}
.video-btn {
  text-align: center;
}
.video-btn button {
  height: 65px;
  width: 274px !important;
  background: #083c5d;
  border: 0px;
  border-radius: 4px;
}

.quick-box {
  background: #f5f5f5;
  text-align: center;
  padding: 28px 0px;
  margin-top: 23px;
}
.feature-btn button {
  border-radius: 44px;
  margin-top: 30px;
  background: #083c5d;
  border: 0;
}
.feature-content {
  font-weight: 600;
  font-size: 32px;
  color: black;
}
.feature-content-lg {
  font-size: 30px;
}
.quick-box .ant-row {
  justify-content: center;
}
.features-img .ant-image-img {
  margin-bottom: -112px;
}
.amzing-feature .ant-row {
  justify-content: center;
}
.signup-header .ant-layout-header {
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 22px -13px rgb(11 9 10 / 59%);
}
.watch-video .ant-btn-primary {
  background: #1281ba;
  border: 0px;
  border-radius: 32px;
  width: 182px;
  height: 45px;
  margin: 21px;
}
.client-docs {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
}
.client-ehead {
  width: 48%;
  margin: auto;
}
.draft {
  padding: 3px 12px;
  background: #d9534f;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}
.sent-email {
  padding: 3px 12px;
  background: #5cb85c;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}

.client-doc-main {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.doc-filter .ant-select-dropdown {
  width: 119px !important;
}
.add-emp-btn .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}
.required-field {
  color: red;
  padding-left: 2px;
}
.client-detail-modal .ant-modal-body {
  background: rgb(245 245 245);
  margin: 24px;
  border: 1px solid rgb(192 190 190);
}
.detail-head {
  font-size: 18px;
  font-weight: 500;
  color: #636363;
}
.search-name {
  font-size: 15px;
  color: rgb(59, 59, 59);
  font-weight: 500;
  width: 100%;
}
.search-text {
  font-size: 13px;
  color: rgb(126, 126, 126);
  font-weight: 400;
  width: 100%;
}
.docuemt-padding {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.filenote-box {
  width: 98%;
  margin: 0 auto 50px auto;
}
.emp-profile-box {
  width: 90%;
  margin: 0 auto 50px auto;
}

.emp-profile-box-employer {
  width: 98%;
  margin: 0 auto 50px auto;
}

.filenote-btn .ant-form-item-control-input-content {
  margin-top: 10px;
}
.float-left {
  float: left;
}
.agreement-data {
  font-weight: 500;
  color: #556370;
  margin-right: 8px;
}
.single-job
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
  padding: 15px 16px !important;
}
.add-client-form-inputs .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label .ant-form-item-no-colon {
  padding: 0px !important;
  margin: 0;
  font-weight: 600;
}
.add-client-form-inputs .ant-form-item-no-colon {
  /* font-size: 11px !important; */
  color: #556370 !important;
  font-weight: 500;
}

.table-action .anticon {
  margin-right: 2px;
  cursor: pointer;
  color: #4b90cd;
  font-size: 16px;
}
.table-action {
  display: flex;
  align-items: center;
}

.BranchPermissions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminder-small-text {
  color: #33aada;
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
}

.reminder-bold-text {
  color: #33aada;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}

.reminder-date-text {
  color: #555555;
  font-size: 12px;
  margin: 0px;
}

.reminder-tabs-cont {
  border: 1px solid #e1e1e1;
  margin: 10px 15px;
  margin-left: 0px;
  padding: 10px;
}

.ant-tabs-tab-btn {
  width: 100% !important;
}

.short-tabs .ant-tabs-nav-wrap {
  white-space: unset !important;
  width: 115px !important;
  background-color: rgb(243, 244, 246) !important;
}
.client-tabs .ant-tabs-nav-wrap {
  background-color: rgb(243, 244, 246) !important;
}

.short-tabs .ant-tabs-tab {
  margin: 0px !important;
  border-bottom: 1px solid #e8eaec !important;
  padding: 8px 10px !important;
  color: #083c5d;
}

.content-width-open-sidebar {
  width: calc(100% - 458px) !important;
}
.content-width-close-sidebar {
  width: calc(100% - 140px) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}

.ant-modal-title {
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.ec-new-case-btn {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
  border-radius: 5px !important;
}

.ant-modal-footer .ant-btn-primary {
  border: 1px solid #1281b9 !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.doc-dropdown-width {
  width: 250px !important;
}

.action-text-cont .ant-btn {
  font-size: 12px !important;
}

.email-address-btn {
  height: 32px;
  line-height: 30px;
  border: 1px solid #cccccc;
  font-family: Lato-R;
  color: #333333;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 5px;
  background-color: #fff;
  border-radius: 0;
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  transition: all 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
}

.imp-emails-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.attachments-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}

.attachment-content-item {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f8950a;
  margin: 3px;
}

.attachment-content-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.template-des-position {
  /*display: inline-block;*/
  vertical-align: -webkit-baseline-middle;
}

.client-email-label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5bc0de;
}

.client-email-popup-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 15px;
}

.client-email-address {
  font-size: 14px;
  color: #185b94;
  display: inline-block;
  /* width: 42%; */
  margin: 0;
  margin-bottom: 15px;
  padding-right: 5px;
  text-transform: capitalize;
}

.client-email-to-row {
  display: flex;
  justify-content: space-between;
}

.body-attachment-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.body-attachment-text {
  margin-left: 10px;
  color: #337ab7;
}

.select-border .ant-select-borderless .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.faq-main-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 12px;
}

.faq-item-main {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #d36205;
}

.faq-item-first {
  width: 100%;
}

.faq-item-first-child {
  /* float: left; */
  font-family: Lato-R;
  font-size: 16px;
  color: #0f82bd;
  padding: 5px 8px;
}

.first-width {
  width: 85%;
}

.faq-item-sec {
  width: 100%;
}

.faq-item-sec-child {
  width: 100%;
  /* float: left; */
  font-family: Lato-R;
  font-size: 14px;
  color: #0f82bd;
  padding: 5px 25px;
}

.faq-head-text {
  color: #07699b;
  font-weight: 500;
  font-size: 16px;
}

.permission-time-zone .timezone-picker .timezone-picker-textfield input {
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
}

.cq-inactive-inner-tab {
  /* background-color: rgb(51, 170, 218); */
  padding: 4px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 1px solid rgb(51, 170, 218);
}

.cq-active-inner-tab {
  background-color: #2e6da4;
  padding: 5px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.cs-attachment-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  /* margin-top: 15px;
  margin-bottom: 20px; */
  padding: 12px;
  /* margin-left: 30px; */
  margin: 15px 30px 20px;
}

.cs-attachment-haeding {
  font-family: Lato-R;
  font-weight: 300;
  color: #073551;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
}
/* .dashboard-summary-table > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
}
.dashboard-student-summary > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
} */
.dashboard-summary-table table colgroup col {
  width: 100px;
  min-width: 100px;
  /*maxHeight: 10px !important;*/
}
.dashboard-student-summary table colgroup col {
  width: 180px !important;
  min-width: 180px !important;
}

.term-condition-container h4 {
  color: #09587e;
  padding-right: 8px;
  text-transform: uppercase;
}

.add-background-color {
}

.add-background-color .ant-select-selector {
  background-color: #ffffff !important;
}
.forgot-loader {
  position: absolute !important;
  top: 0 !important;
}
.supervisor-batches {
  margin: 0 !important;
  /* height: 650px !important; */
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
/* .superDashboard .ant-layout-sider-children {
  position: relative !important;
} */

.message-textArea {
  padding: 10px;
  display: block !important;
  background-color: #f5f5f6;
  margin-top: 15px;
  border: 1px solid #ebebec;
}

.message-content-box-radius {
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.input-number {
}

.input-number .ant-input-number {
  width: 100% !important;
}

.row-style {
  display: flex;
  justify-content: space-between;
}

.questionnaire-input-width-0 {
  width: 0%;
}

.questionnaire-input-width-55 {
  width: 55%;
}

.questionnaire-input-width-60 {
  width: 60%;
}

.questionnaire-full-name-input {
  width: 60%;
  border: 0;
}

.full-name-row-padding {
  /* paddingleft: 30;
   paddingRight: 30
                             paddingBottom: 30, */
  padding: 0 30px 30px 30px !important;
}

.questionnaire-label-width-38 {
  width: 38%;
}

@media (max-width: 820px) {
  .contact-info-sec {
    display: block !important;
  }
  .footer-logo {
    width: 100% !important;
  }
  .footer-info-text {
    width: 100% !important;
    display: block !important;
  }
  .footer-info-box {
    width: 100% !important;
  }
  .row-style {
    display: block !important;
  }

  .questionnaire-input-width-55 {
    width: 100% !important;
  }
  .questionnaire-input-width-60 {
    width: 100% !important;
  }
  .questionnaire-full-name-input {
    width: 100% !important;
  }

  .questionnaire-label-width-38 {
    width: 100% !important;
  }

  .cq-container {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .full-name-row-padding {
    padding: 0 0 30px 0 !important;
  }
}

.layout-class .ant-layout.ant-layout-has-sider {
  height: 0% !important;
}

.rem-table-margin .table-card {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.rem-table-margin .ant-table-pagination.ant-pagination {
  margin: 15px !important;
}

/* .cell-margin .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px !important;
} */

.display-inline-block {
  display: inline-block;
}

.display-inline-block .ant-select {
  width: 35px !important;
}

.display-inline-block .ant-select-selector span {
  color: #0052cc !important;
  font-size: 14px !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff1f4 !important;
}

.line-height .ant-table-thead > tr > th {
  padding: 0px 8px !important;
}

.p-client .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.color-apply-template {
  color: rgb(54, 177, 228) !important;
}
.color-background-template-stage {
  background-color: rgb(54, 177, 228) !important;
}

.balance-input .ant-input-number:hover {
  border: transparent !important;
  display: none !important;
}

.balance-input .ant-input-number:focused {
  border: none !important;
  display: none !important;
}

.success:focus {
  border: none !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
}

.balance-client-des {
  color: #889093;
  font-size: 10px;
  margin: -4px;
}

.center-elements {
  display: block !important;
  align-items: center !important;
}

.marginTemplate {
  margin-top: -13px;
}

.poppins-font-family {
  font-family: "Poppins", sans-serif !important;
}

.width-cross-browser {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.employer .ant-modal-title {
  font-family: "Poppins", sans-serif !important;
}
.textDecoration {
  text-decoration: none !important;
}

.col-emp .ant-table-column-sorters-with-tooltip {
  margin-left: 15px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  /*padding: 2px !important;*/
}

.ant-checkbox-inner {
  position: unset !important;
  margin-bottom: -4px !important;
  height: 19px !important;
  width: 19px !important;
}

.login-checkbox .ant-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.hover-link {
  text-decoration: none;
  cursor: pointer !important;
  color: black;
}
.hover-link:hover {
  color: #1890ff;
  cursor: pointer !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Poppins" !important;
}

/* span {
  font-family: "Poppins";
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker {
  padding: 6px 11px 6px 10px !important;
}

.client-form .ant-picker {
  padding: 4px 11px 4px !important;
}
/* 
.client-form .ant-row {
  display: block !important;
} */

.d-flex-contract {
  display: flex;
  justify-content: flex-end;
}

.d-flex-contract .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: flex-end !important;
}

.multi-select-option .ant-select-selector {
  height: auto !important;
}

.ant-select-selector {
  height: 32px;
  padding: 0 11px;
  width: auto !important;
}

.ant-select-selector span {
  font-size: 12px !important;
}

.ant-picker-input input {
  font-size: 12px !important;
  font-family: "Poppins" !important;
}

.ant-input {
  font-size: 12px !important;
}

.ant-form label {
  font-size: 12px !important;
  font-family: "Poppins";
}

.client-form .ant-select-selector {
  height: 28px !important;
}

.all-client-form .ant-form-item-label {
  font-weight: 400 !important;
}

.container-box {
  width: 90%;
  margin: auto;
}

.width-100 {
  width: 100% !important;
}

th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

td {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.height-100 {
  height: 100% !important;
}
.width-25 {
  width: 22%;
}
.width-52 {
  width: 52%;
}
.w-20 {
  width: 20%;
}
.w-80-t {
  width: 80%;
}
.width-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
  margin: auto;
}
.p-box {
  padding: 23px !important;
}
.employer-doc-tab .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff !important;
}

.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.flex-end {
  justify-content: flex-end;
}

.float-right {
  float: right !important;
}
.border-3 {
  border-radius: 3px !important;
}
.float-right {
  float: right !important;
}
.mar-zero {
  margin: 10px !important;
  padding: 0px !important;
}
.margin-top {
  margin-top: 13px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-btm {
  margin-bottom: 26px;
}
.d-aligin-center {
  display: flex !important;
  align-items: center !important;
}
.align-items {
  align-items: center;
}
.d-aligin-center p {
  font-size: 12px;
}
.text-style .ant-col .ant-form-item-label > label {
  font-weight: 500;
  color: #737373 !important;
  font-size: 13px;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.top-60 {
  margin-top: 60px;
}
.mar-top-35 {
  margin-top: 35px;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.border-color {
  border: 1px solid #d6d0d0;
  border-radius: 3px;
  margin-bottom: 12px;
}

.border-5px .ant-input {
  border-radius: 5px;
}
.border-5px textarea.ant-input {
  border-radius: 5px;
  margin-top: 12px;
}
.mar-top .ant-form-item-control-input-content {
  margin-top: 40px;
}
.margin-btns .ant-btn-primary {
  margin-left: 12px;
}
.space-between {
  justify-content: space-between;
}
.contant-around {
  justify-content: space-around !important;
}
.contant-center {
  justify-content: center !important;
}
.align-center {
  align-items: center;
}
.reminder-tabs-header .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header-temp .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
}
.reminder-tabs-header-temp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header-temp .ant-tabs-tab-active {
  background: #36b1e4;
}

.reminder-tabs-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header .ant-tabs-tab-active {
  background: #36b1e4;
}
.mar-zero .task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
}
.reminder-btn-main {
  display: flex;
  justify-content: center;
}

.add-task-btn .ant-btn {
  background-color: #1281ba;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.reminder-btn .ant-btn {
  background-color: #ff9e37;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.marked-name {
  background-color: #ff9d36;
  border-radius: 15px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  margin-left: 5px;
}

.marked-name-font {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.mdtr-reminder-tasks-cont {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}
.mdtr-reminder-tasks-header-cont {
  background-color: #f8f9fb;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8eaec;
  margin-top: 33px;
}
.mdtr-reminder-tasks-head-text-cont {
  display: flex;
  align-items: center;
}
.mdtr-rem-row-cont {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8eaec;
}
.cv-bold-text {
  color: #073d5d;
  font-size: 19px;
  font-weight: bolder;
}
.todo-main {
  justify-content: space-between;
  padding: 0px 34px;
  font-weight: 700;
}
.heading-style span {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.mdtr-user-cont {
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
}
.sus-checkbox {
  height: 15px;
  width: 20px;
}
.hide-icon {
  border: 1px solid;
  display: flex;
  padding: 0px 8px;
  border-radius: 50%;
  align-items: center;
}
.reminder-dropdown {
  margin-top: 33px;
}
.reminder-dropdown .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.reminder-dropdown .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.balance-accordian {
  margin-top: 15px !important;
}

.balance-accordian
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px;
  left: auto;
}

.invoice-status-btn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block !important;
}

.balance-accordian
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.balance-accordian .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.balance-accordian .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.template .ant-collapse-header {
  display: block !important;
}

.balance-accordian .ant-collapse-extra {
  margin-top: -37px !important;
  padding-right: 27px !important;
}

.reminder-border {
  border-bottom: 1px solid #d6d6d6;
  margin-top: 11px !important;
}

.reminder-head {
  color: #075a80;
  margin-left: 15px;
  font-weight: bold;
}
.reminder-calender {
  margin: 20px 0px 33px 0px;
}
.sidebar-reminder {
  margin-bottom: 33px;
  margin-top: 22px;
}
.reminder-calender .react-calendar {
  width: 100%;
  padding: 23px;
  border: 0;
}
.completed-tasks {
  margin-top: 45px;
}
.reminder-model-main {
  border-radius: 30px;
}
.reminder-model-main .ant-modal-content {
  /* width: 616px !important; */
  padding: 15px;
  border-radius: 3px;
}
.reminder-model-main .mdtr-modal-gray-cont {
  background-color: #f6f7f9;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e4e4e4;
  display: flex;
  border-radius: 5px;
  padding-right: 20px;
}
.reminder-model-main .mdtr-checkbox-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reminder-model-main .cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
}
.reminder-model-main .profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}
.reminder-model-main .profile-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}
.reminder-model-main .mdtr-add-tasks {
  background-color: #1281b9;
  padding: 7px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.reminder-model-main .ant-modal-header {
  border-bottom: none !important;
  color: #6c6c6c !important;
  padding: 2px 26px 0px 26px;
  font-size: 18px;
  margin-bottom: 38px;
  border-radius: 30px 30px 30px 30px !important;
}
.reminder-model-main .ant-modal-title {
  /* color: #6c6c6c; */
  font-size: 14px;
  font-weight: 600;
}
.reminder-model-main .cv-bold-text {
  font-size: 12px;
  font-weight: 600;
  margin-top: 36px;
}
.reminder-model-main .ant-modal-body {
  padding: 0px 20px;
}
.reminder-model {
  border-radius: 40px;
}
.reminder-model-main .emp-ae-input-width p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.reminder-model-main .emp-ae-input-width {
  margin-right: 32px;
}
.modal-parts {
  width: 84%;
  margin: auto;
}
.reminder-model-main .ant-form-item {
  padding: 15px 0px;
}
.form-parts .ant-form-item-label {
  font-weight: 500;
}
.bg-box-white {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  padding: 22px 0;
}
.mar-b {
  margin-bottom: 32px;
}
.w-96 {
  width: 96%;
  margin: auto;
}
.mar-tb {
  padding: 20px 5px;
}
.comment-btn .ant-btn-primary {
  float: right !important;
  margin-top: 14px;
  border-radius: 4px;
  border: 0px;
}
.follower-row h4 {
  margin-right: 13px;
}
.mar-right {
  margin-right: 7px !important;
}
/* .tasks-comment .ant-comment {
  width: 96%;
  margin: auto;
} */
.box-lenght {
  max-height: 400px;
  overflow: scroll;
}

.box-lenght::-webkit-scrollbar {
  width: 4px;
}
.box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 0px;
}
.box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.completed-box-lenght {
  max-height: 1000px;
  overflow: scroll;
}
.completed-box-lenght::-webkit-scrollbar {
  width: 4px;
}
.completed-box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.completed-box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}
.completed-box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.color-check {
  margin: 5px 8px 0px 0px;
  border-radius: 2px;
}
.delete-model p {
  font-size: 15px;
  font-weight: 200;
  padding: 13px 0px 0px 7px;
}
.margin-right {
  margin-right: 10px;
}
.client-form .ant-form-item {
  display: block;
  margin-top: 9px;
}
.all-client-form .ant-form-item-label {
  font-weight: 600;
}
.header-text-style {
  font-size: 17px;
  font-weight: 500;
  color: #083c5d;
  padding: 30px 40px 0px 20px;
}
.all-client-form .client-form .ant-form-item-no-colon {
  color: #55626c !important;
}
.all-client-btn {
  margin: 25px 23px 0 0px;
  justify-content: flex-end;
}
.reminder-settings {
  width: calc(100% - 170px);
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 50px;
  margin: 35px 85px;
  border-radius: 3px;
}

.invoice-table-body-dates {
  font-size: 14px;
  font-weight: 450;
  color: black;
  padding-bottom: 5px;
}
.client-tag-table .ant-row .ant-form-item {
  display: block;
}

.reminder-set-form {
  /* background-color: #ffffff; */
  /* padding: 30px; */
  /* border-radius: 5px; */
  /* border: 1px solid #eeeeee; */
}
.reminder-set-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-set-form p {
  font-size: 16px;
  /* color: #556370; */
  font-weight: 100;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 100;
}

.reminder-setting-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.imap-set-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.imap-set-form .ant-form-item-label {
  margin-right: 0px;
}
.margin-top-34 {
  margin-top: 34px;
}
.Reminder-top {
  width: 100%;
  /* margin: auto; */
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
}
.time-tracking {
  width: 95%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}
.employer-manag {
  width: 91%;
  height: 100%;
  margin: 35px auto;
}
.dynamic-keys {
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  padding: 4px 0px 6px 17px;
}
.dynamicForm {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin: 21px 0px 59px 0px;
}
.mail-Chimp-text {
  padding-top: 35px;
  font-size: 16px;
}
.mail-Chimp-text p {
  font-size: 13px;
  font-weight: 450;
  margin-top: 0px !important;
}
.mail-text p {
  padding-left: 35px;
}
.mail-chimp-btn {
  display: flex;
  justify-content: flex-end;
}
.mail-chimp-box {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 54px 74px 54px;
  margin: 35px auto;
  border-radius: 3px;
}
.default-font-box {
  width: 98%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 35px 20px;
  border-radius: 3px;
}
.document-sync-box {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 10px auto 35px auto;
  border-radius: 3px;
}

.document-checklist-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 17px;
  margin: 35px auto;
  border-radius: 3px;
}
.document-checklist--btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
}
.email-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  margin: 27px auto;
  border-radius: 3px;
}
.email-content-box h4 {
  padding-bottom: 20px;
  color: #448ccb;
  font-size: 16px;
}
.questionnaire-content {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 81px;
  margin: 35px auto;
  border-radius: 3px;
}
.questionnaire-top {
  width: 82%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}

.letter-font p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-spacing {
  margin: 16px 0px 0px 0px;
}
.letter-height {
  height: 500px;
}

.letter-froala p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-froala .fr-box.fr-basic .fr-element {
  max-height: 500px;
  border-bottom: 2px solid #cccccc;
  min-height: 274px;
}
.fr-second-toolbar {
  display: none !important;
}
.file-uploader .ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9 !important;
  background-color: transparent;
  padding: 5rem;
  margin-bottom: 2rem;
}
.file-uploader .ant-upload-text {
  color: #1890ff !important;
}
.file-uploader {
  margin-top: 15px;
}
.file-uploader .ant-upload {
  border-radius: 0px !important;
}
.border-box {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-emp-manag {
  background: white;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-add-emp {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px 50px;
  margin-top: 38px !important;
}
.border-box-employer {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 34px;
  /* margin-top: 38px !important; */
}
.padding-20 {
  padding: 20px !important;
}

.w-9 {
  width: 13%;
}
.w-91 {
  width: 87%;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.mar-top-75 {
  margin-top: 75px;
}
.doc-office {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.Upload-signature .avatar-uploader > .ant-upload {
  width: 190px;
  height: 128px;
  border-radius: 0% !important;
  margin-bottom: 10px;
}
.Upload-signature .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background: white;
  border: 2px dotted #d9d9d9 !important;
  border-radius: 0px !important;
}
.MainSideBar .ant-tabs-nav-list {
  background-color: #213037;
  color: #fff;
}
.dashboardSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.dashboardSideBar {
  width: 265px !important;
  /* min-width: 100% !important; */
}
.supervisorSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.supervisorSideBar {
  width: 262px !important;
}
.site-layout {
  background: #ffffff;
  /* height: 100%; */
  position: fixed;
  margin-top: 64px;
  /* min-width: 265px !important; */
  overflow: auto;
}
.site-layout .ant-layout-sider-children {
  overflow: auto;
  position: fixed;
}
.screen-width {
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
  /* margin-left: 265px; */
  /* margin-top: 64px; */
}

.dashboardSideBar .ant-menu-item a {
  /* padding-left: 14px !important; */
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.dashboardSideBar .ant-menu .ant-menu-dark .ant-menu-root .ant-menu-inline {
  color: #ffffff;
  font-size: 18px;
  border: 0px;
}
.dashboardSideBar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: red !important;
}
.ant-layout-header {
  position: fixed;
  z-index: 10;
  width: 100%;
  /* height: 3rem; */
  background: #fff !important;
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
  overflow: overlay;
  width: 100%;
}
/* .open-sidebar div>div>div{
  position: relative!important;
}
.close-sidebar div>div>div{
  position: relative!important;
} */
/* added by aatif */
.site-layout {
  position: absolute !important;
  /* position: fixed !important; */
}
.ant-layout-content {
  padding-top: 65px !important;
}
.site-layout .ant-layout.ant-layout-has-sider {
  width: 100% !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: rgba(33, 48, 55, 255) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(69 89 99);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #f1e9e9 !important;
}
.employer-table .ant-table-pagination.ant-pagination {
  margin: 7px 26px !important;
}
/*.employer-table .ant-table-cell {*/
/*  text-align: center;*/
/*}*/
/* .table-head-clr .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
}
.table-head-clr .ant-table-column-sorters-with-tooltip {
  background-color: #f0f2f5;
} */
.add-employer-para .ant-col .ant-col-12 p {
  font-size: 14px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.employer-manag-tabs .ant-tabs-nav-wrap {
  background-color: #f0f2f5 !important;
}
.employer-manag-tabs .ant-tabs-tab {
  color: black;
  font-weight: 800 !important;
  font-size: 16px;
  margin: 0px;
  background: #0383bc !important;
  padding: 5px 17px !important ;
  font-size: 12px !important;
}
.employer-manag-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.employer-manag-tabs .ant-tabs-tab-active {
  background: #36b1e4 !important;
}
.employer-manag-tabs .ant-form-item {
  padding: 15px 0px !important;
}

.employer-manag-tabs .ant-tabs-tab {
  margin-right: 0px !important;
  border-radius: 0px !important;
}
.employer-manag-tabs .ant-tabs-tab-btn {
  font-weight: 500;
  color: white;
}
.upload-button .ant-btn {
  border-radius: 0px !important;
}
.employer-head {
  margin-top: 33px;
  margin: 44px 0px 10px 10px;
}
.form-add-btn {
  width: 26px;
  border-radius: 33px !important;
  height: 26px;
  /* float: right; */
}
.form-add-btn .ant-btn-icon-only {
  height: 25px;
  border-radius: 45px;
  margin-bottom: 4px;
  /* position: fixed; */
  z-index: 20;
}
.form-add-btn .ant-btn-icon-only svg {
  margin-bottom: 4px;
}
.remove-button {
  cursor: pointer;
  background-color: #f52b2b;
  color: white;
  display: flex;
  width: 5%;
  padding: 7px 14px;
  border-radius: 44px;
  font-weight: 400;
  margin: 35px 0px;
}
.remove-button:hover {
  background-color: #1890ff;
  color: white;
}
.contact-Head {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.contact-emp {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin-top: 25px;
}
.add-job-form .ant-row .ant-col .ant-col-4 {
  font-size: 19px !important;
}
.add-job-form .ant-input-number {
  width: 100% !important;
}
.emp-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  /*margin: 27px auto;*/
  border-radius: 3px;
}
.emp-froala h4 {
  color: #448ccb;
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 12px;
}

.green-btn {
  background: #0ca22c !important;
  border: 0px !important;
  color: #fff !important;
}
.margin-client {
  display: flex;
  margin: 18px 0px 0px 15px;
}
.padding-btn {
  padding: 10px 23px;
}
.file-note-light {
  background-color: #fff;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}
.payment-form-labels {
  margin-top: 16px;
}
.file-note-dark {
  background-color: #fffcfc;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}

.accounts-flow-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.table-row-light {
  background-color: #fff !important;
  /* padding: 6px 16px; */
}
.table-row-dark {
  background-color: #f0f2f5 !important;
  /* padding: 6px 16px; */
}
.file-contacts .ant-row .ant-form-item {
  width: 90%;
  margin: auto;
}
.file-delete-head {
  margin: 18px 25px 0px 23px;
}
.border-color-blue .ant-tabs-tabpane .ant-tabs-tabpane-active {
  border: 1px rgb(16, 129, 185);
}
/*.ant-table-thead > tr > th {*/
/*  text-align: center !important;*/
/*  font-size: 13px;*/
/*}*/

.school-table .ant-table-thead > tr > th {
  text-align: left !important;
  font-size: 14px !important;
}

.child-invoice-table .table-head .table-tbody > tr > th {
  padding: 16px 0px 16px 0px !important;
}

.table-head .ant-table-column-sorters {
  padding: 11px 0px;
}
.table-head .ant-table-column-sorters-with-tooltip {
  text-align: center;
}
.table-head .ant-table-cell {
  /* text-align: center; */
}
.table-head .table-tbody > tr > td {
  align-items: center !important;
  padding: 12px 0px !important;
}
/* .table-head .ant-table-cell {
  padding: 10px !important;
} */
.ant-select {
  width: 100%;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
  margin-top: 4px;
}
/* .employer-Header {
  height: 54px;
} */
.alert-msg .ant-form-item-explain .ant-form-item-explain-error {
  float: right !important;
}
/* .required p::before {
  content: "*";
  position: absolute;
  /* right: -8px; */
/* left: 18px;
color: red;
top: -2px; */
/* } * */
.job-table .ant-table-cell {
  padding: 8px 4px 8px 10px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}
.contact-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-table .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}

.contact-tables .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-tables .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  margin: 16px 42px !important;
  text-align: left;
  background: transparent !important;
}

.job-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}
.job-table .ant-table-container {
  /* padding: 20px; */
}

.contact-us .ant-table-cell {
  padding: 13px 7px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: center;
  color: #474a4c;
}
.contact-us .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.add-employer-para .ant-col .ant-col-12 p {
  font-size: 13px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.width-web-kit {
  width: -webkit-fill-available !important;
}

.width-modal-sending-receipt {
  width: 1022px !important;
}

.width-modal-add-template {
  width: 641px !important;
}

.width-modal-add-email-template {
  width: 70vw !important;
}

.border-box-modal-sending-receipt {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 72px 10px 17px;
  margin-top: 38px !important;
}

.font-school-list-filter-text {
  font-size: smaller;
  font-weight: 700;
}

.font-school-list-filter-text-sub {
  color: #889093;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.sus-tab-container-schools {
  display: flex;
  margin: 30px 0px 0px 20px;
  cursor: pointer;
}

.sus-tab-container-clients {
  display: flex;
  margin-left: 20px;
  margin: 44px 0px 0px 20px;
  cursor: pointer;
}

.sus-active-tab-schools {
  background-color: rgb(51, 170, 218);
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.sus-inactive-tab-schools {
  padding: 5px 20px;
  display: flex;
  background-color: #0383bc;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.upload-technical-support-complaint .ant-upload.ant-upload-select-picture-card {
  margin-top: 9px;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.upload-technical-support-complaint .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 9px 8px 8px 0;
  vertical-align: top;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-active-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ts-container-schools {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.button {
  color: #fff !important;
  background: #096dd9 !important;
  border-color: #096dd9 !important;
  padding: 5px 16px !important;
  border-radius: 2px !important;
}

.cont-positioning {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.border-box-modal {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: 38px !important;
}

.add-new-line-container {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: auto;
  margin-top: 16px;
  margin-left: 16px;
  padding-bottom: 15px;
}
.add-new-line-header {
  background-color: #e6e6e6;
  height: 26px;
}

.add-new-line-header-school-invoices {
  background-color: #e6e6e6;
}

.add-new-line-inputs {
  padding: 7px;
}

.add-new-line-header-position {
  margin-top: 5px;
  text-align: center;
}

.add-new-line-button {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: 36px;
  width: 107px;
  margin-left: 22px;
  margin-top: 6px;
  cursor: pointer;
}
.add-new-line-button p {
  text-align: center;
  font-size: 13px;
}

.width-modal {
  width: 702px !important;
}

.label-search-schools {
  font-size: 16px;
  font-weight: bold;
  color: rgb(24, 91, 148);
}

.width-modal-outgoing-payments {
  width: 735px !important;
}

.incoming-payments-table-row {
  background: #bcf6c5 !important;
}

.invoices-children-table-row {
  background: #e5e4e2 !important;
}

.outgoing-payments-table-row {
  background: #f6d6d0 !important;
}

.font-size-add-school-form {
  font-size: 12px;
}

.text-style-show-container {
  color: #fff;
}

.ca-gray-padding {
  padding: 15px 20px 15px 0px !important;
}

.ca-gray-padding-payment-history {
  padding: 15px 20px 15px 20px !important;
}

.payment-buttons-container {
  display: flex;
  margin-left: 19px;
  margin-top: 10px;
}

.payment-label {
  font-size: 12px;
  font-weight: initial;
  margin-left: 19px;
}

.show-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: -3%;
  cursor: pointer;
}

.display-contact-text-container {
  display: block;
  margin-inline-start: 20px !important;
}

.margin-contact-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-icon {
  margin-top: -35px !important;
}

.haha {
  border: 1px solid red;
}

.border-box-modal-add {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}

.positioning-delete-icon {
  margin-top: 7px;
}

.positioning-show-levels {
  text-align: center;
}

.border-show-contacts {
  border-bottom: 1px solid rgb(214, 208, 208);
  width: 587px;
  margin-left: -11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.border-add-template-stages {
  border-bottom: 1px solid rgb(214, 208, 208);
  padding-top: 22px;
  margin-left: 25px;
}

.border-detail {
  border-bottom: 1px solid rgb(214, 208, 208);
}

.hide-levels-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: 7%;
  cursor: pointer;
}

.ts-container-commission-reminders {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.redo-icon {
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.upload-technical-support-complaint {
  margin-top: 13px !important;
  margin-left: 20px !important;
}

.upload-technical-support {
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.employer-doc-tab .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.employerin-box {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 56px;
  height: 100%;
  border-radius: 3px;
}
.ant-table-cell .ant-table-column-sort .ant-table-column-has-sorters {
  background: white;
}
.ant-table-column-sorters-with-tooltip {
  background: white;
}
/* .ant-table-cell {
  background: white !important;
} */
.ant-table-cell .ant-table-selection-column {
  background: white !important;
}
.document-table .ant-table-cell {
  text-align: center;
}
.send-btn .ant-btn-primary {
  border-radius: 4px !important;
}
.border-box-checklist {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 13px 54px;
}
.checklist-add-field {
  background: #f5f5f5;
  border: 0px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}
.checklist-textarea .ant-input {
  height: 100px !important;
}
.checklist-switch .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch .ant-switch-checked {
  height: 10px;
  background-color: #bf0909b8 !important;
}
.checklist-switch .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #aa2626 !important;
}
.checklist-switch .ant-switch-inner {
  height: 10px;
  /* background-color: black !important; */
}
.checklist-switch-low .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch-low .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #eec914;
}
.checklist-switch-low .ant-switch-checked {
  height: 10px;
  background-color: #eec914ad !important;
}
.checklist-switch-low .ant-switch-inner ::before {
  height: 10px;
  background-color: black !important;
}
.checklist-switch-low .ant-form-item-label > label {
  height: 22px !important;
}
.checklist-switch .ant-form-item-label > label {
  height: 22px !important;
}

.all-client-form .ant-form-item-label > label {
  height: 22px !important;
}

.imap-text p {
  font-size: 13px !important;
  /* color: rgb(141, 139, 139) !important; */
}
.imap-text {
  font-size: 15px !important;
}
.input-name {
  font-weight: 500 !important;
}
.employer-logo-box {
  background-color: #fff;
  justify-content: center !important;
  display: flex !important;
  padding: 50px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 26%);
}
.container {
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.link-client-logo {
  width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  height: 8rem;
}
.footer-info-text {
  display: flex;
}
.red-dot {
  height: 14px;
  width: 14px;
  background-color: #e61616;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}
.yellow-dot {
  height: 14px;
  width: 14px;
  background-color: #faad14;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}

.upload-image-heading {
  margin-top: 70px;
}

.personal-info-heading {
  margin-bottom: 24px;
  padding-top: 18px;
}

.ts-container-add-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 35px 30px 0px;
  border-radius: 3px;
}

.personal-info-add-client-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-right: 30px;
  border-radius: 6px;
  margin-left: 35px;
  padding-bottom: 37px;
}

.personal-info-add-client-container
  .ant-form-horizontal
  .ant-form-item-control {
  min-width: 100% !important;
}

.ts-container-import-clients {
  background-color: #ffffff;
  margin: 15px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.post-to-xero {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.xero-filters-label {
  width: 19%;
  margin-top: -5px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border: solid 10px transparent;
}

#style-3::-webkit-scrollbar {
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px;
}

.save-button {
  margin-left: 7px;
  margin-top: 29px;
}

.container-center {
  height: 2em;
  position: relative;
}
div.container-center h5 {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ts-potential-client-statuses {
  background-color: #ffffff;
  margin: 50px 13px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 2px 16px 5px;
  border-radius: 5px;
}

.duplicate-client-table-container {
  margin-top: 37px;
  margin-left: 31px;
  margin-bottom: 15px;
}

.spinner-xero {
  text-align: center;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  align-content: center;
  vertical-align: middle;
}

.spinner {
  text-align: center;
  z-index: 1000;
  padding-top: calc(50% - 25%);
  width: 100%;
  height: 100vh;
  align-content: center;
  vertical-align: middle;
}
.client-table .ant-table {
  border: 1px solid rgb(184, 179, 179) !important;
  border-radius: 2px;
}
.employer-btn {
  /* border-radius: 6px !important; */
  border: 0px !important;
}
.emp-w-box {
  padding: 29px 84px;
  background-color: white;
  margin-top: 20px 20px;
}

.emp-w-box-employer {
  padding: 29px 84px;
  background-color: rgb(245 247 248) !important;
  margin-top: 20px 20px;
}

.emp-profile-table {
  background-color: whitesmoke;
}
.auto-email {
  border: 1px solid #c9c9ca;
  padding: 14px 41px !important;
  margin-bottom: 10px;
}
.create-email-form .ant-form-item {
  padding: 7px 0px !important;
}
.visa-body-text {
  justify-content: space-between;
  display: flex;
  max-width: 64%;
}
.visa-body-text span {
  color: #5c5c5c;
  font-weight: 450;
}
.visa-status-header {
  height: 100px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 90%;
  margin: auto;
}
.status-header-left {
  width: 80%;
  background-color: #f3f3f3;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.status-header-left h2 {
  color: #858585;
  margin: 0;
}
.status-header-left p {
  color: #858585;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-top: 6px;
}
.event-visa-btn {
  background: #f6752f;
  border: 0;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 3px 17px;
}
.status-header-right {
  width: 20%;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}
.status-header-right h2 {
  color: #ffffff;
  margin: 0;
}
.visa-status-body {
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px 30px 30px 30px;
}
.visa-status-body button {
  margin-bottom: 7px;
}
.visa-status-body-data {
  background-color: white;
  width: 100%;
  margin: auto;
  border-radius: 5px !important;
  padding: 30px !important;
}
.case-new {
  width: 90%;
  background-color: #36b1e4;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  height: 8rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-top: 40px;
  margin-top: 10px;
}
.case-new h4 {
  color: #fff;
  font-size: 18px;
}
.incoive-table .ant-table-container {
  background: #eff1f4;
  width: 90%;
  margin: auto;
}
.file-spacing {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
}
.document-table .ant-row .ant-form-item {
  /* justify-content: center; */
  width: 40%;
}
.close-editor {
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}
.add-emp-form .ant-form-item-explain {
  justify-content: flex-start !important;
  display: flex !important;
}
.slider-small {
}
.slider-small .ant-layout-sider-children {
  width: 84px !important;
}
.slider-small .ant-layout-sider {
  background-color: transparent;
}
.site-layout-background {
  width: 100% auto !important;
}
.open-sidebar {
  width: 89%;
  margin-left: 265px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.rightbar-icons {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.rightbar-icons img {
  margin-right: 10px;
}

.employer-tabs-content > .ant-tabs-content-holder {
  background-color: #f0f2f5 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.employer-tabs-contents > .ant-tabs-content-holder {
  font-size: 12px !important;
  font-weight: inherit !important;
  font-family: "Poppins", sans-serif !important;
}

.profile-tags {
  padding: 4px 10px;
  border: 1px solid #3fb5cf;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.tags-text {
  font-size: 10px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}
.signup-contaienr {
  width: 80%;
  margin: auto;
}
.signup-container {
  width: 67%;
  margin: 51px auto;
  border: 1px solid #cecece;
  padding: 20px 0px;
  background-color: white;
}
.signup-fields .ant-form-item {
  padding: 15px 0 !important;
}
.signup-fields {
  width: 87%;
  margin: 10px auto 32px auto;
  background-color: #f0f2f5;
  padding: 10px 63px;
}
.signup-text {
  margin: 18px 0px;
}
.signup-text span {
  /* font-size: 14px; */
  font-weight: 600;
  color: #5d5d5d;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.save-signup .ant-form-item-control-input {
  width: 88%;
  margin: auto;
}

.cmv-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 16px !important;
}
.cmv-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.ant-input-number-handler-wrap {
  position: absolute;
  display: none !important;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  /*border-left: 1px solid #d9d9d9;*/
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.profile-container {
  width: 98%;
  margin: auto;
  padding-top: 45px;
  display: flex;
}
.profile-input-border .ant-form-item-control-input {
  min-height: 30px !important;
}
.sign-up-head {
  justify-content: space-around;
  align-items: center;
}
.head-d {
  border: 1px solid;
  height: 10px;
}
.head-link {
  padding: 0 10px 10px 0;
}
.head-text h1 {
  color: #095c81;
}
.email-input input {
  width: 440px;
  height: 39;
  border-radius: 44px 0px 0px 44px;
  background: #eeeeee;
  border: 0px;
}
.sign-up-btn button {
  width: 160 !important;
  border-radius: 44px;
  height: 39;
}
.sign-up-btn .ant-col {
  margin-left: -6px !important;
  padding-left: 0px;
}
.email-input .ant-col {
  padding: 0px !important;
}
.bg-white .ant-layout {
  background-color: white;
}

.sign-up-btn .ant-btn-primary {
  background: #083c5d;
  border: 0px;
  margin-left: -20px;
  margin-bottom: 40px;
}
.sign-up-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: rgb(3 131 188);
  border-color: #fff;
  border: 1px solid;
}
.email-input .ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #eeeeee !important;
}
.signup-email .ant-row {
  justify-content: center;
}
.video-btn {
  text-align: center;
}
.video-btn button {
  height: 65px;
  width: 274px !important;
  background: #083c5d;
  border: 0px;
  border-radius: 4px;
}

.quick-box {
  background: #f5f5f5;
  text-align: center;
  padding: 28px 0px;
  margin-top: 23px;
}
.feature-btn button {
  border-radius: 44px;
  margin-top: 30px;
  background: #083c5d;
  border: 0;
}
.feature-content {
  font-weight: 600;
  font-size: 32px;
  color: black;
}
.feature-content-lg {
  font-size: 30px;
}
.quick-box .ant-row {
  justify-content: center;
}
.features-img .ant-image-img {
  margin-bottom: -112px;
}
.amzing-feature .ant-row {
  justify-content: center;
}
.signup-header .ant-layout-header {
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 22px -13px rgb(11 9 10 / 59%);
}
.watch-video .ant-btn-primary {
  background: #1281ba;
  border: 0px;
  border-radius: 32px;
  width: 182px;
  height: 45px;
  margin: 21px;
}
.client-docs {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
}
.client-ehead {
  width: 48%;
  margin: auto;
}
.draft {
  padding: 3px 12px;
  background: #d9534f;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}
.sent-email {
  padding: 3px 12px;
  background: #5cb85c;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}

.client-doc-main {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.doc-filter .ant-select-dropdown {
  width: 119px !important;
}
.add-emp-btn .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}
.required-field {
  color: red;
  padding-left: 2px;
}
.client-detail-modal .ant-modal-body {
  background: rgb(245 245 245);
  margin: 24px;
  border: 1px solid rgb(192 190 190);
}
.detail-head {
  font-size: 18px;
  font-weight: 500;
  color: #636363;
}
.search-name {
  font-size: 15px;
  color: rgb(59, 59, 59);
  font-weight: 500;
  width: 100%;
}
.search-text {
  font-size: 13px;
  color: rgb(126, 126, 126);
  font-weight: 400;
  width: 100%;
}
.docuemt-padding {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.filenote-box {
  width: 98%;
  margin: 0 auto 50px auto;
}
.emp-profile-box {
  width: 90%;
  margin: 0 auto 50px auto;
}

.emp-profile-box-employer {
  width: 98%;
  margin: 0 auto 50px auto;
}

.filenote-btn .ant-form-item-control-input-content {
  margin-top: 10px;
}
.float-left {
  float: left;
}
.agreement-data {
  font-weight: 500;
  color: #556370;
  margin-right: 8px;
}
.single-job
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
  padding: 15px 16px !important;
}
.add-client-form-inputs .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label .ant-form-item-no-colon {
  padding: 0px !important;
  margin: 0;
  font-weight: 600;
}
.add-client-form-inputs .ant-form-item-no-colon {
  /* font-size: 11px !important; */
  color: #556370 !important;
  font-weight: 500;
}

.table-action .anticon {
  margin-right: 2px;
  cursor: pointer;
  color: #4b90cd;
  font-size: 16px;
}
.table-action {
  display: flex;
  align-items: center;
}

.BranchPermissions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminder-small-text {
  color: #33aada;
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
}

.reminder-bold-text {
  color: #33aada;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}

.reminder-date-text {
  color: #555555;
  font-size: 12px;
  margin: 0px;
}

.reminder-tabs-cont {
  border: 1px solid #e1e1e1;
  margin: 10px 15px;
  margin-left: 0px;
  padding: 10px;
}

.ant-tabs-tab-btn {
  width: 100% !important;
}

.short-tabs .ant-tabs-nav-wrap {
  white-space: unset !important;
  width: 115px !important;
  background-color: rgb(243, 244, 246) !important;
}
.client-tabs .ant-tabs-nav-wrap {
  background-color: rgb(243, 244, 246) !important;
}

.short-tabs .ant-tabs-tab {
  margin: 0px !important;
  border-bottom: 1px solid #e8eaec !important;
  padding: 8px 10px !important;
  color: #083c5d;
}

.content-width-open-sidebar {
  width: calc(100% - 458px) !important;
}
.content-width-close-sidebar {
  width: calc(100% - 140px) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}

.ant-modal-title {
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.ec-new-case-btn {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
  border-radius: 5px !important;
}

.ant-modal-footer .ant-btn-primary {
  border: 1px solid #1281b9 !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.doc-dropdown-width {
  width: 250px !important;
}

.action-text-cont .ant-btn {
  font-size: 12px !important;
}

.email-address-btn {
  height: 32px;
  line-height: 30px;
  border: 1px solid #cccccc;
  font-family: Lato-R;
  color: #333333;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 5px;
  background-color: #fff;
  border-radius: 0;
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  transition: all 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
}

.imp-emails-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.attachments-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}

.attachment-content-item {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f8950a;
  margin: 3px;
}

.attachment-content-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.template-des-position {
  /*display: inline-block;*/
  vertical-align: -webkit-baseline-middle;
}

.client-email-label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5bc0de;
}

.client-email-popup-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 15px;
}

.client-email-address {
  font-size: 14px;
  color: #185b94;
  display: inline-block;
  /* width: 42%; */
  margin: 0;
  margin-bottom: 15px;
  padding-right: 5px;
  text-transform: capitalize;
}

.client-email-to-row {
  display: flex;
  justify-content: space-between;
}

.body-attachment-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.body-attachment-text {
  margin-left: 10px;
  color: #337ab7;
}

.select-border .ant-select-borderless .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.faq-main-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 12px;
}

.faq-item-main {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #d36205;
}

.faq-item-first {
  width: 100%;
}

.faq-item-first-child {
  /* float: left; */
  font-family: Lato-R;
  font-size: 16px;
  color: #0f82bd;
  padding: 5px 8px;
}

.first-width {
  width: 85%;
}

.faq-item-sec {
  width: 100%;
}

.faq-item-sec-child {
  width: 100%;
  /* float: left; */
  font-family: Lato-R;
  font-size: 14px;
  color: #0f82bd;
  padding: 5px 25px;
}

.faq-head-text {
  color: #07699b;
  font-weight: 500;
  font-size: 16px;
}

.permission-time-zone .timezone-picker .timezone-picker-textfield input {
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
}

.cq-inactive-inner-tab {
  /* background-color: rgb(51, 170, 218); */
  padding: 4px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 1px solid rgb(51, 170, 218);
}

.cq-active-inner-tab {
  background-color: #2e6da4;
  padding: 5px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.cs-attachment-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  /* margin-top: 15px;
  margin-bottom: 20px; */
  padding: 12px;
  /* margin-left: 30px; */
  margin: 15px 30px 20px;
}

.cs-attachment-haeding {
  font-family: Lato-R;
  font-weight: 300;
  color: #073551;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
}
/* .dashboard-summary-table > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
}
.dashboard-student-summary > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
} */
.dashboard-summary-table table colgroup col {
  width: 100px;
  min-width: 100px;
  /*maxHeight: 10px !important;*/
}
.dashboard-student-summary table colgroup col {
  width: 180px !important;
  min-width: 180px !important;
}

.term-condition-container h4 {
  color: #09587e;
  padding-right: 8px;
  text-transform: uppercase;
}

.add-background-color {
}

.add-background-color .ant-select-selector {
  background-color: #ffffff !important;
}
.forgot-loader {
  position: absolute !important;
  top: 0 !important;
}
/* .superDashboard .ant-layout-sider-children {
  position: relative !important;
} */

.message-textArea {
  padding: 10px;
  display: block !important;
  background-color: #f5f5f6;
  margin-top: 15px;
  border: 1px solid #ebebec;
}

.message-content-box-radius {
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.input-number {
}

.input-number .ant-input-number {
  width: 100% !important;
}

.row-style {
  display: flex;
  justify-content: space-between;
}

.questionnaire-input-width-0 {
  width: 0%;
}

.questionnaire-input-width-55 {
  width: 55%;
}

.questionnaire-input-width-60 {
  width: 60%;
}

.questionnaire-full-name-input {
  width: 60%;
  border: 0;
}

.full-name-row-padding {
  /* paddingleft: 30;
   paddingRight: 30
                             paddingBottom: 30, */
  padding: 0 30px 30px 30px !important;
}

.questionnaire-label-width-38 {
  width: 38%;
}

@media (max-width: 820px) {
  .contact-info-sec {
    display: block !important;
  }
  .footer-logo {
    width: 100% !important;
  }
  .footer-info-text {
    width: 100% !important;
    display: block !important;
  }
  .footer-info-box {
    width: 100% !important;
  }
  .row-style {
    display: block !important;
  }

  .questionnaire-input-width-55 {
    width: 100% !important;
  }
  .questionnaire-input-width-60 {
    width: 100% !important;
  }
  .questionnaire-full-name-input {
    width: 100% !important;
  }

  .questionnaire-label-width-38 {
    width: 100% !important;
  }

  .cq-container {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .full-name-row-padding {
    padding: 0 0 30px 0 !important;
  }
}

.layout-class .ant-layout.ant-layout-has-sider {
  height: 0% !important;
}

.rem-table-margin .table-card {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.rem-table-margin .ant-table-pagination.ant-pagination {
  margin: 15px !important;
}

/* .cell-margin .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px !important;
} */

.display-inline-block {
  display: inline-block;
}

.display-inline-block .ant-select {
  width: 35px !important;
}

.display-inline-block .ant-select-selector span {
  color: #0052cc !important;
  font-size: 14px !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff1f4 !important;
}

.line-height .ant-table-thead > tr > th {
  padding: 0px 8px !important;
}

.p-client .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.color-apply-template {
  color: rgb(54, 177, 228) !important;
}
.color-background-template-stage {
  background-color: rgb(54, 177, 228) !important;
}

.balance-input .ant-input-number:hover {
  border: transparent !important;
  display: none !important;
}

.balance-input .ant-input-number:focused {
  border: none !important;
  display: none !important;
}

.success:focus {
  border: none !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
}

.balance-client-des {
  color: #889093;
  font-size: 10px;
  margin: -4px;
}

.center-elements {
  display: block !important;
  align-items: center !important;
}

.marginTemplate {
  margin-top: -13px;
}

.poppins-font-family {
  font-family: "Poppins", sans-serif !important;
}

.width-cross-browser {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.employer .ant-modal-title {
  font-family: "Poppins", sans-serif !important;
}
.textDecoration {
  text-decoration: none !important;
}

.col-emp .ant-table-column-sorters-with-tooltip {
  margin-left: 15px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  /*padding: 2px !important;*/
}

.ant-checkbox-inner {
  position: unset !important;
  margin-bottom: -4px !important;
  height: 19px !important;
  width: 19px !important;
}

.login-checkbox .ant-checkbox {
  vertical-align: -webkit-baseline-middle;
}

.hover-link {
  text-decoration: none;
  cursor: pointer !important;
  color: black;
}
.hover-link:hover {
  color: #1890ff;
  cursor: pointer !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Poppins" !important;
}

/* span {
  font-family: "Poppins";
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-picker {
  padding: 6px 11px 6px 10px !important;
}

.client-form .ant-picker {
  padding: 4px 11px 4px !important;
}
/* 
.client-form .ant-row {
  display: block !important;
} */

.d-flex-contract {
  display: flex;
  justify-content: flex-end;
}

.d-flex-contract .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: flex-end !important;
}

.multi-select-option .ant-select-selector {
  height: auto !important;
}

.ant-select-selector {
  height: 32px;
  padding: 0 11px;
  width: auto !important;
}

.ant-select-selector span {
  font-size: 12px !important;
}

.ant-picker-input input {
  font-size: 12px !important;
  font-family: "Poppins" !important;
}

.ant-input {
  font-size: 12px !important;
}

.ant-form label {
  font-size: 12px !important;
  font-family: "Poppins";
}

.client-form .ant-select-selector {
  height: 28px !important;
}

.all-client-form .ant-form-item-label {
  font-weight: 400 !important;
}

.container-box {
  width: 90%;
  margin: auto;
}

.width-100 {
  width: 100% !important;
}

th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

td {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.height-100 {
  height: 100% !important;
}
.width-25 {
  width: 22%;
}
.width-52 {
  width: 52%;
}
.w-20 {
  width: 20%;
}
.w-80-t {
  width: 80%;
}
.width-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
  margin: auto;
}
.p-box {
  padding: 23px !important;
}
.employer-doc-tab .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff !important;
}

.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.flex-end {
  justify-content: flex-end;
}

.float-right {
  float: right !important;
}
.border-3 {
  border-radius: 3px !important;
}
.float-right {
  float: right !important;
}
.mar-zero {
  margin: 10px !important;
  padding: 0px !important;
}
.margin-top {
  margin-top: 13px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-btm {
  margin-bottom: 26px;
}
.d-aligin-center {
  display: flex !important;
  align-items: center !important;
}
.align-items {
  align-items: center;
}
.d-aligin-center p {
  font-size: 12px;
}
.text-style .ant-col .ant-form-item-label > label {
  font-weight: 500;
  color: #737373 !important;
  font-size: 13px;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.top-60 {
  margin-top: 60px;
}
.mar-top-35 {
  margin-top: 35px;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.border-color {
  border: 1px solid #d6d0d0;
  border-radius: 3px;
  margin-bottom: 12px;
}

.border-5px .ant-input {
  border-radius: 5px;
}
.border-5px textarea.ant-input {
  border-radius: 5px;
  margin-top: 12px;
}
.mar-top .ant-form-item-control-input-content {
  margin-top: 40px;
}
.margin-btns .ant-btn-primary {
  margin-left: 12px;
}
.space-between {
  justify-content: space-between;
}
.contant-around {
  justify-content: space-around !important;
}
.contant-center {
  justify-content: center !important;
}
.align-center {
  align-items: center;
}
.reminder-tabs-header .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header-temp .ant-tabs-nav-wrap {
  background-color: #1081b9;
  margin: 0px;
}

.reminder-tabs-header .ant-tabs-tab {
  padding: 13px 22px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
}
.reminder-tabs-header-temp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header-temp .ant-tabs-tab-active {
  background: #36b1e4;
}

.reminder-tabs-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffff;
}
.reminder-tabs-header .ant-tabs-tab-active {
  background: #36b1e4;
}
.mar-zero .task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
}
.reminder-btn-main {
  display: flex;
  justify-content: center;
}

.add-task-btn .ant-btn {
  background-color: #1281ba;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.reminder-btn .ant-btn {
  background-color: #ff9e37;
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  margin: 5px;
}
.marked-name {
  background-color: #ff9d36;
  border-radius: 15px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  margin-left: 5px;
}

.marked-name-font {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.mdtr-reminder-tasks-cont {
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}
.mdtr-reminder-tasks-header-cont {
  background-color: #f8f9fb;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8eaec;
  margin-top: 33px;
}
.mdtr-reminder-tasks-head-text-cont {
  display: flex;
  align-items: center;
}
.mdtr-rem-row-cont {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8eaec;
}
.cv-bold-text {
  color: #073d5d;
  font-size: 19px;
  font-weight: bolder;
}
.todo-main {
  justify-content: space-between;
  padding: 0px 34px;
  font-weight: 700;
}
.heading-style span {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.mdtr-user-cont {
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
}
.sus-checkbox {
  height: 15px;
  width: 20px;
}
.hide-icon {
  border: 1px solid;
  display: flex;
  padding: 0px 8px;
  border-radius: 50%;
  align-items: center;
}
.reminder-dropdown {
  margin-top: 33px;
}
.reminder-dropdown .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.reminder-dropdown .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.balance-accordian {
  margin-top: 15px !important;
}

.balance-accordian
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 16px;
  left: auto;
}

.invoice-status-btn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block !important;
}

.balance-accordian
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.balance-accordian .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.balance-accordian .ant-collapse-header {
  padding: 13px 8px 2px 8px !important;
}

.template .ant-collapse-header {
  display: block !important;
}

.balance-accordian .ant-collapse-extra {
  margin-top: -37px !important;
  padding-right: 27px !important;
}

.reminder-border {
  border-bottom: 1px solid #d6d6d6;
  margin-top: 11px !important;
}

.reminder-head {
  color: #075a80;
  margin-left: 15px;
  font-weight: bold;
}
.reminder-calender {
  margin: 20px 0px 33px 0px;
}
.sidebar-reminder {
  margin-bottom: 33px;
  margin-top: 22px;
}
.reminder-calender .react-calendar {
  width: 100%;
  padding: 23px;
  border: 0;
}
.completed-tasks {
  margin-top: 45px;
}
.reminder-model-main {
  border-radius: 30px;
}
.reminder-model-main .ant-modal-content {
  /* width: 616px !important; */
  padding: 15px;
  border-radius: 3px;
}
.reminder-model-main .mdtr-modal-gray-cont {
  background-color: #f6f7f9;
  margin: 10px 0px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e4e4e4;
  display: flex;
  border-radius: 5px;
  padding-right: 20px;
}
.reminder-model-main .mdtr-checkbox-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reminder-model-main .cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
}
.reminder-model-main .profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}
.reminder-model-main .profile-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}
.reminder-model-main .mdtr-add-tasks {
  background-color: #1281b9;
  padding: 7px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.reminder-model-main .ant-modal-header {
  border-bottom: none !important;
  color: #6c6c6c !important;
  padding: 2px 26px 0px 26px;
  font-size: 18px;
  margin-bottom: 38px;
  border-radius: 30px 30px 30px 30px !important;
}
.reminder-model-main .ant-modal-title {
  /* color: #6c6c6c; */
  font-size: 14px;
  font-weight: 600;
}
.reminder-model-main .cv-bold-text {
  font-size: 12px;
  font-weight: 600;
  margin-top: 36px;
}
.reminder-model-main .ant-modal-body {
  padding: 0px 20px;
}
.reminder-model {
  border-radius: 40px;
}
.reminder-model-main .emp-ae-input-width p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.reminder-model-main .emp-ae-input-width {
  margin-right: 32px;
}
.modal-parts {
  width: 84%;
  margin: auto;
}
.reminder-model-main .ant-form-item {
  padding: 15px 0px;
}
.form-parts .ant-form-item-label {
  font-weight: 500;
}
.bg-box-white {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  padding: 22px 0;
}
.mar-b {
  margin-bottom: 32px;
}
.w-96 {
  width: 96%;
  margin: auto;
}
.mar-tb {
  padding: 20px 5px;
}
.comment-btn .ant-btn-primary {
  float: right !important;
  margin-top: 14px;
  border-radius: 4px;
  border: 0px;
}
.follower-row h4 {
  margin-right: 13px;
}
.mar-right {
  margin-right: 7px !important;
}
/* .tasks-comment .ant-comment {
  width: 96%;
  margin: auto;
} */
.box-lenght {
  max-height: 400px;
  overflow: scroll;
}

.box-lenght::-webkit-scrollbar {
  width: 4px;
}
.box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 0px;
}
.box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.completed-box-lenght {
  max-height: 1000px;
  overflow: scroll;
}
.completed-box-lenght::-webkit-scrollbar {
  width: 4px;
}
.completed-box-lenght::-webkit-scrollbar-track {
  background: transparent;
}
.completed-box-lenght::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 33px;
}
.completed-box-lenght::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.color-check {
  margin: 5px 8px 0px 0px;
  border-radius: 2px;
}
.delete-model p {
  font-size: 15px;
  font-weight: 200;
  padding: 13px 0px 0px 7px;
}
.margin-right {
  margin-right: 10px;
}
.client-form .ant-form-item {
  display: block;
  margin-top: 9px;
}
.all-client-form .ant-form-item-label {
  font-weight: 600;
}
.header-text-style {
  font-size: 17px;
  font-weight: 500;
  color: #083c5d;
  padding: 30px 40px 0px 20px;
}
.all-client-form .client-form .ant-form-item-no-colon {
  color: #55626c !important;
}
.all-client-btn {
  margin: 25px 23px 0 0px;
  justify-content: flex-end;
}
.reminder-settings {
  width: calc(100% - 170px);
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 50px;
  margin: 35px 85px;
  border-radius: 3px;
}

.invoice-table-body-dates {
  font-size: 14px;
  font-weight: 450;
  color: black;
  padding-bottom: 5px;
}
.client-tag-table .ant-row .ant-form-item {
  display: block;
}

.reminder-set-form {
  /* background-color: #ffffff; */
  /* padding: 30px; */
  /* border-radius: 5px; */
  /* border: 1px solid #eeeeee; */
}
.reminder-set-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-set-form p {
  font-size: 16px;
  /* color: #556370; */
  font-weight: 100;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 300;
}

.reminder-set-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.reminder-setting-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.reminder-setting-form h3 {
  font-weight: 700;
  padding-bottom: 20px;
}
.reminder-setting-form p {
  font-size: 16px;
  color: #556370;
  font-weight: 100;
}

.reminder-setting-form .ant-form-item-label {
  margin-right: 100px;
  text-align: left !important;
  width: 100% !important;
}

.imap-set-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}

.imap-set-form .ant-form-item-label {
  margin-right: 0px;
}
.margin-top-34 {
  margin-top: 34px;
}
.Reminder-top {
  width: 100%;
  /* margin: auto; */
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
}
.time-tracking {
  width: 95%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}
.employer-manag {
  width: 91%;
  height: 100%;
  margin: 35px auto;
}
.dynamic-keys {
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  padding: 4px 0px 6px 17px;
}
.dynamicForm {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin: 21px 0px 59px 0px;
}
.mail-Chimp-text {
  padding-top: 35px;
  font-size: 16px;
}
.mail-Chimp-text p {
  font-size: 13px;
  font-weight: 450;
  margin-top: 0px !important;
}
.mail-text p {
  padding-left: 35px;
}
.mail-chimp-btn {
  display: flex;
  justify-content: flex-end;
}
.mail-chimp-box {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 54px 74px 54px;
  margin: 35px auto;
  border-radius: 3px;
}
.default-font-box {
  width: 98%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 35px 20px;
  border-radius: 3px;
}
.document-sync-box {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 0px 69px 34px 74px;
  margin: 10px auto 35px auto;
  border-radius: 3px;
}

.document-checklist-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 17px;
  margin: 35px auto;
  border-radius: 3px;
}
.document-checklist--btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
}
.email-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  margin: 27px auto;
  border-radius: 3px;
}
.email-content-box h4 {
  padding-bottom: 20px;
  color: #448ccb;
  font-size: 16px;
}
.questionnaire-content {
  width: 83%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 45px 81px;
  margin: 35px auto;
  border-radius: 3px;
}
.questionnaire-top {
  width: 82%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}

.letter-font p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-spacing {
  margin: 16px 0px 0px 0px;
}
.letter-height {
  height: 500px;
}

.letter-froala p span {
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 14px; */
  /* color: #000;
  background-color: transparent; */
  line-height: 1.5;
}
.letter-froala .fr-box.fr-basic .fr-element {
  max-height: 500px;
  border-bottom: 2px solid #cccccc;
  min-height: 274px;
}
.fr-second-toolbar {
  display: none !important;
}
.file-uploader .ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9 !important;
  background-color: transparent;
  padding: 5rem;
  margin-bottom: 2rem;
}
.file-uploader .ant-upload-text {
  color: #1890ff !important;
}
.file-uploader {
  margin-top: 15px;
}
.file-uploader .ant-upload {
  border-radius: 0px !important;
}
.border-box {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-emp-manag {
  background: white;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
  margin-top: 38px !important;
}

.border-box-add-emp {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px 50px;
  margin-top: 38px !important;
}
.border-box-employer {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 34px;
  /* margin-top: 38px !important; */
}
.padding-20 {
  padding: 20px !important;
}

.w-9 {
  width: 13%;
}
.w-91 {
  width: 87%;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.mar-top-75 {
  margin-top: 75px;
}
.doc-office {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.Upload-signature .avatar-uploader > .ant-upload {
  width: 190px;
  height: 128px;
  border-radius: 0% !important;
  margin-bottom: 10px;
}
.Upload-signature .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background: white;
  border: 2px dotted #d9d9d9 !important;
  border-radius: 0px !important;
}
.MainSideBar .ant-tabs-nav-list {
  background-color: #213037;
  color: #fff;
}
.dashboardSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.dashboardSideBar {
  width: 265px !important;
  /* min-width: 100% !important; */
}
.supervisorSideBar .ant-menu-item img {
  width: 37px;
  padding-right: 14px;
}
.supervisorSideBar {
  width: 262px !important;
}
.site-layout {
  background: #ffffff;
  /* height: 100%; */
  position: fixed;
  margin-top: 64px;
  /* min-width: 265px !important; */
  overflow: auto;
}
.site-layout .ant-layout-sider-children {
  overflow: auto;
  position: fixed;
}
.screen-width {
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
  /* margin-left: 265px; */
  /* margin-top: 64px; */
}

.dashboardSideBar .ant-menu-item a {
  /* padding-left: 14px !important; */
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.dashboardSideBar .ant-menu .ant-menu-dark .ant-menu-root .ant-menu-inline {
  color: #ffffff;
  font-size: 18px;
  border: 0px;
}
.dashboardSideBar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: red !important;
}
.ant-layout-header {
  position: fixed;
  z-index: 10;
  width: 100%;
  /* height: 3rem; */
  background: #fff !important;
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
  overflow: overlay;
  width: 100%;
}
/* .open-sidebar div>div>div{
  position: relative!important;
}
.close-sidebar div>div>div{
  position: relative!important;
} */
/* added by aatif */
.site-layout {
  position: absolute !important;
  /* position: fixed !important; */
}
.ant-layout-content {
  padding-top: 65px !important;
}
.site-layout .ant-layout.ant-layout-has-sider {
  width: 100% !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 33px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: rgba(33, 48, 55, 255) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(69 89 99);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #f1e9e9 !important;
}
.employer-table .ant-table-pagination.ant-pagination {
  margin: 7px 26px !important;
}
/*.employer-table .ant-table-cell {*/
/*  text-align: center;*/
/*}*/
/* .table-head-clr .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
}
.table-head-clr .ant-table-column-sorters-with-tooltip {
  background-color: #f0f2f5;
} */
.add-employer-para .ant-col .ant-col-12 p {
  font-size: 14px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.employer-manag-tabs .ant-tabs-nav-wrap {
  background-color: #f0f2f5 !important;
}
.employer-manag-tabs .ant-tabs-tab {
  color: black;
  font-weight: 800 !important;
  font-size: 16px;
  margin: 0px;
  background: #0383bc !important;
  padding: 5px 17px !important ;
  font-size: 12px !important;
}
.employer-manag-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.employer-manag-tabs .ant-tabs-tab-active {
  background: #36b1e4 !important;
}
.employer-manag-tabs .ant-form-item {
  padding: 15px 0px !important;
}

.employer-manag-tabs .ant-tabs-tab {
  margin-right: 0px !important;
  border-radius: 0px !important;
}
.employer-manag-tabs .ant-tabs-tab-btn {
  font-weight: 500;
  color: white;
}
.upload-button .ant-btn {
  border-radius: 0px !important;
}
.employer-head {
  margin-top: 33px;
  margin: 44px 0px 10px 10px;
}
.form-add-btn {
  width: 26px;
  border-radius: 33px !important;
  height: 26px;
  /* float: right; */
}
.form-add-btn .ant-btn-icon-only {
  height: 25px;
  border-radius: 45px;
  margin-bottom: 4px;
  /* position: fixed; */
  z-index: 20;
}
.form-add-btn .ant-btn-icon-only svg {
  margin-bottom: 4px;
}
.remove-button {
  cursor: pointer;
  background-color: #f52b2b;
  color: white;
  display: flex;
  width: 5%;
  padding: 7px 14px;
  border-radius: 44px;
  font-weight: 400;
  margin: 35px 0px;
}
.remove-button:hover {
  background-color: #1890ff;
  color: white;
}
.contact-Head {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.contact-emp {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;
  margin-top: 25px;
}
.add-job-form .ant-row .ant-col .ant-col-4 {
  font-size: 19px !important;
}
.add-job-form .ant-input-number {
  width: 100% !important;
}
.emp-content-box {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 103%;
  padding: 29px 33px;
  /*margin: 27px auto;*/
  border-radius: 3px;
}
.emp-froala h4 {
  color: #448ccb;
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 12px;
}

.green-btn {
  background: #0ca22c !important;
  border: 0px !important;
  color: #fff !important;
}
.margin-client {
  display: flex;
  margin: 18px 0px 0px 15px;
}
.padding-btn {
  padding: 10px 23px;
}
.file-note-light {
  background-color: #fff;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}
.payment-form-labels {
  margin-top: 16px;
}
.file-note-dark {
  background-color: #fffcfc;
  padding: 11px 24px;
  border: 1px solid #d6d0d0;
  display: flex;
  align-items: center;
}

.accounts-flow-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.table-row-light {
  background-color: #fff !important;
  /* padding: 6px 16px; */
}
.table-row-dark {
  background-color: #f0f2f5 !important;
  /* padding: 6px 16px; */
}
.file-contacts .ant-row .ant-form-item {
  width: 90%;
  margin: auto;
}
.file-delete-head {
  margin: 18px 25px 0px 23px;
}
.border-color-blue .ant-tabs-tabpane .ant-tabs-tabpane-active {
  border: 1px rgb(16, 129, 185);
}
/*.ant-table-thead > tr > th {*/
/*  text-align: center !important;*/
/*  font-size: 13px;*/
/*}*/

.school-table .ant-table-thead > tr > th {
  text-align: left !important;
  font-size: 14px !important;
}

.child-invoice-table .table-head .table-tbody > tr > th {
  padding: 16px 0px 16px 0px !important;
}

.table-head .ant-table-column-sorters {
  padding: 11px 0px;
}
.table-head .ant-table-column-sorters-with-tooltip {
  text-align: center;
}
.table-head .ant-table-cell {
  /* text-align: center; */
}
.table-head .table-tbody > tr > td {
  align-items: center !important;
  padding: 12px 0px !important;
}
/* .table-head .ant-table-cell {
  padding: 10px !important;
} */
.ant-select {
  width: 100%;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
  margin-top: 4px;
}
/* .employer-Header {
  height: 54px;
} */
.alert-msg .ant-form-item-explain .ant-form-item-explain-error {
  float: right !important;
}
/* .required p::before {
  content: "*";
  position: absolute;
  /* right: -8px; */
/* left: 18px;
color: red;
top: -2px; */
/* } * */
.job-table .ant-table-cell {
  padding: 8px 4px 8px 10px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}
.contact-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-table .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: left;
  color: #0383bc;
  background: transparent !important;
}

.contact-tables .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.contact-tables .ant-table-cell {
  padding: 10px 24px !important;
  width: 20%;
  margin: 16px 42px !important;
  text-align: left;
  background: transparent !important;
}

.job-table .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}
.job-table .ant-table-container {
  /* padding: 20px; */
}

.contact-us .ant-table-cell {
  padding: 13px 7px !important;
  font-size: 13px !important;
  margin: 16px 42px !important;
  text-align: center;
  color: #474a4c;
}
.contact-us .ant-table-content {
  border: 1px solid rgb(192, 190, 190);
}

.add-employer-para .ant-col .ant-col-12 p {
  font-size: 13px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7b869094 !important;
}
.width-web-kit {
  width: -webkit-fill-available !important;
}

.width-modal-sending-receipt {
  width: 1022px !important;
}

.width-modal-add-template {
  width: 641px !important;
}

.width-modal-add-email-template {
  width: 70vw !important;
}

.border-box-modal-sending-receipt {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 72px 10px 17px;
  margin-top: 38px !important;
}

.font-school-list-filter-text {
  font-size: smaller;
  font-weight: 700;
}

.font-school-list-filter-text-sub {
  color: #889093;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.sus-tab-container-schools {
  display: flex;
  margin: 30px 0px 0px 20px;
  cursor: pointer;
}

.sus-tab-container-clients {
  display: flex;
  margin-left: 20px;
  margin: 44px 0px 0px 20px;
  cursor: pointer;
}

.sus-active-tab-schools {
  background-color: rgb(51, 170, 218);
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.sus-inactive-tab-schools {
  padding: 5px 20px;
  display: flex;
  background-color: #0383bc;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.upload-technical-support-complaint .ant-upload.ant-upload-select-picture-card {
  margin-top: 9px;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.upload-technical-support-complaint .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 9px 8px 8px 0;
  vertical-align: top;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-active-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ts-container-schools {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.button {
  color: #fff !important;
  background: #096dd9 !important;
  border-color: #096dd9 !important;
  padding: 5px 16px !important;
  border-radius: 2px !important;
}

.cont-positioning {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.border-box-modal {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: 38px !important;
}

.add-new-line-container {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: auto;
  margin-top: 16px;
  margin-left: 16px;
  padding-bottom: 15px;
}
.add-new-line-header {
  background-color: #e6e6e6;
  height: 26px;
}

.add-new-line-header-school-invoices {
  background-color: #e6e6e6;
}

.add-new-line-inputs {
  padding: 7px;
}

.add-new-line-header-position {
  margin-top: 5px;
  text-align: center;
}

.add-new-line-button {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: 36px;
  width: 107px;
  margin-left: 22px;
  margin-top: 6px;
  cursor: pointer;
}
.add-new-line-button p {
  text-align: center;
  font-size: 13px;
}

.width-modal {
  width: 702px !important;
}

.label-search-schools {
  font-size: 16px;
  font-weight: bold;
  color: rgb(24, 91, 148);
}

.width-modal-outgoing-payments {
  width: 735px !important;
}

.incoming-payments-table-row {
  background: #bcf6c5 !important;
}

.invoices-children-table-row {
  background: #e5e4e2 !important;
}

.outgoing-payments-table-row {
  background: #f6d6d0 !important;
}

.font-size-add-school-form {
  font-size: 12px;
}

.text-style-show-container {
  color: #fff;
}

.ca-gray-padding {
  padding: 15px 20px 15px 0px !important;
}

.ca-gray-padding-payment-history {
  padding: 15px 20px 15px 20px !important;
}

.payment-buttons-container {
  display: flex;
  margin-left: 19px;
  margin-top: 10px;
}

.payment-label {
  font-size: 12px;
  font-weight: initial;
  margin-left: 19px;
}

.show-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: -3%;
  cursor: pointer;
}

.display-contact-text-container {
  display: block;
  margin-inline-start: 20px !important;
}

.margin-contact-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-icon {
  margin-top: -35px !important;
}

.haha {
  border: 1px solid red;
}

.border-box-modal-add {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}

.positioning-delete-icon {
  margin-top: 7px;
}

.positioning-show-levels {
  text-align: center;
}

.border-show-contacts {
  border-bottom: 1px solid rgb(214, 208, 208);
  width: 587px;
  margin-left: -11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.border-add-template-stages {
  border-bottom: 1px solid rgb(214, 208, 208);
  padding-top: 22px;
  margin-left: 25px;
}

.border-detail {
  border-bottom: 1px solid rgb(214, 208, 208);
}

.hide-levels-container {
  background: #1890ff;
  padding: 0.1em 0.6em 0.1em;
  color: #fff !important;
  font-size: 28%;
  /* font-weight: bold; */
  line-height: 1;
  border-color: #1890ff !important;
  /* width: 25%; */
  align-items: center;
  text-align: center !important;
  /* white-space: nowrap; */
  /* vertical-align: unset; */
  border-radius: 4px;
  /* height: 26%; */
  margin-top: 7%;
  cursor: pointer;
}

.ts-container-commission-reminders {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.redo-icon {
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.upload-technical-support-complaint {
  margin-top: 13px !important;
  margin-left: 20px !important;
}

.upload-technical-support {
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.employer-doc-tab .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.employerin-box {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 56px;
  height: 100%;
  border-radius: 3px;
}
.ant-table-cell .ant-table-column-sort .ant-table-column-has-sorters {
  background: white;
}
.ant-table-column-sorters-with-tooltip {
  background: white;
}
/* .ant-table-cell {
  background: white !important;
} */
.ant-table-cell .ant-table-selection-column {
  background: white !important;
}
.document-table .ant-table-cell {
  text-align: center;
}
.send-btn .ant-btn-primary {
  border-radius: 4px !important;
}
.border-box-checklist {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 13px 54px;
}
.checklist-add-field {
  background: #f5f5f5;
  border: 0px solid #d6d0d0;
  border-radius: 4px;
  padding: 0px 11px 9px 34px;
  margin-top: -14px !important;
  margin-bottom: 34px !important;
}
.checklist-textarea .ant-input {
  height: 100px !important;
}
.checklist-switch .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch .ant-switch-checked {
  height: 10px;
  background-color: #bf0909b8 !important;
}
.checklist-switch .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #aa2626 !important;
}
.checklist-switch .ant-switch-inner {
  height: 10px;
  /* background-color: black !important; */
}
.checklist-switch-low .ant-switch {
  height: 10px;
  background-color: rgb(97, 97, 97);
}
.checklist-switch-low .ant-switch-handle::before {
  margin-bottom: 6px;
  margin-top: -6px;
  background-color: #eec914;
}
.checklist-switch-low .ant-switch-checked {
  height: 10px;
  background-color: #eec914ad !important;
}
.checklist-switch-low .ant-switch-inner ::before {
  height: 10px;
  background-color: black !important;
}
.checklist-switch-low .ant-form-item-label > label {
  height: 22px !important;
}
.checklist-switch .ant-form-item-label > label {
  height: 22px !important;
}

.all-client-form .ant-form-item-label > label {
  height: 22px !important;
}

.imap-text p {
  font-size: 13px !important;
  /* color: rgb(141, 139, 139) !important; */
}
.imap-text {
  font-size: 15px !important;
}
.input-name {
  font-weight: 500 !important;
}
.employer-logo-box {
  background-color: #fff;
  justify-content: center !important;
  display: flex !important;
  padding: 50px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 26%);
}
.container {
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.link-client-logo {
  width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  height: 8rem;
}
.footer-info-text {
  display: flex;
}
.red-dot {
  height: 14px;
  width: 14px;
  background-color: #e61616;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}
.yellow-dot {
  height: 14px;
  width: 14px;
  background-color: #faad14;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}

.upload-image-heading {
  margin-top: 70px;
}

.personal-info-heading {
  margin-bottom: 24px;
  padding-top: 18px;
}

.ts-container-add-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 35px 30px 0px;
  border-radius: 3px;
}

.personal-info-add-client-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-right: 30px;
  border-radius: 6px;
  margin-left: 35px;
  padding-bottom: 37px;
}

.personal-info-add-client-container
  .ant-form-horizontal
  .ant-form-item-control {
  min-width: 100% !important;
}

.ts-container-import-clients {
  background-color: #ffffff;
  margin: 15px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.post-to-xero {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.xero-filters-label {
  width: 19%;
  margin-top: -5px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border: solid 10px transparent;
}

#style-3::-webkit-scrollbar {
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px;
}

.save-button {
  margin-left: 7px;
  margin-top: 29px;
}

.container-center {
  height: 2em;
  position: relative;
}
div.container-center h5 {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ts-potential-client-statuses {
  background-color: #ffffff;
  margin: 50px 13px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 2px 16px 5px;
  border-radius: 5px;
}

.duplicate-client-table-container {
  margin-top: 37px;
  margin-left: 31px;
  margin-bottom: 15px;
}

.spinner-xero {
  text-align: center;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  align-content: center;
  vertical-align: middle;
}

.spinner {
  text-align: center;
  z-index: 1000;
  padding-top: calc(50% - 25%);
  width: 100%;
  height: 100vh;
  align-content: center;
  vertical-align: middle;
}
.client-table .ant-table {
  border: 1px solid rgb(184, 179, 179) !important;
  border-radius: 2px;
}
.employer-btn {
  /* border-radius: 6px !important; */
  border: 0px !important;
}
.emp-w-box {
  padding: 29px 84px;
  background-color: white;
  margin-top: 20px 20px;
}

.emp-w-box-employer {
  padding: 29px 84px;
  background-color: rgb(245 247 248) !important;
  margin-top: 20px 20px;
}

.emp-profile-table {
  background-color: whitesmoke;
}
.auto-email {
  border: 1px solid #c9c9ca;
  padding: 14px 41px !important;
  margin-bottom: 10px;
}
.create-email-form .ant-form-item {
  padding: 7px 0px !important;
}
.visa-body-text {
  justify-content: space-between;
  display: flex;
  max-width: 64%;
}
.visa-body-text span {
  color: #5c5c5c;
  font-weight: 450;
}
.visa-status-header {
  height: 100px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 90%;
  margin: auto;
}
.status-header-left {
  width: 80%;
  background-color: #f3f3f3;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.status-header-left h2 {
  color: #858585;
  margin: 0;
}
.status-header-left p {
  color: #858585;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  margin-top: 6px;
}
.event-visa-btn {
  background: #f6752f;
  border: 0;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 3px 17px;
}
.status-header-right {
  width: 20%;
  background-color: #a0d9ee;
  border-radius: 0 7px 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
}
.status-header-right h2 {
  color: #ffffff;
  margin: 0;
}
.visa-status-body {
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px 30px 30px 30px;
}
.visa-status-body button {
  margin-bottom: 7px;
}
.visa-status-body-data {
  background-color: white;
  width: 100%;
  margin: auto;
  border-radius: 5px !important;
  padding: 30px !important;
}
.case-new {
  width: 90%;
  background-color: #36b1e4;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  height: 8rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-top: 40px;
  margin-top: 10px;
}
.case-new h4 {
  color: #fff;
  font-size: 18px;
}
.incoive-table .ant-table-container {
  background: #eff1f4;
  width: 90%;
  margin: auto;
}
.file-spacing {
  background: #f5f5f5;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  padding: 13px;
}
.document-table .ant-row .ant-form-item {
  /* justify-content: center; */
  width: 40%;
}

.close-editor {
  justify-content: flex-end;
  display: flex;
  margin-top: 15px;
}
.add-emp-form .ant-form-item-explain {
  justify-content: flex-start !important;
  display: flex !important;
}
.slider-small {
}
.slider-small .ant-layout-sider-children {
  width: 84px !important;
}
.slider-small .ant-layout-sider {
  background-color: transparent;
}
.site-layout-background {
  width: 100% auto !important;
}
.open-sidebar {
  width: 89%;
  margin-left: 265px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 64px;
  z-index: 1;
  width: 100% !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: fill-available !important;
}
.rightbar-icons {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.rightbar-icons img {
  margin-right: 10px;
}

.employer-tabs-content > .ant-tabs-content-holder {
  background-color: #f0f2f5 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.employer-tabs-contents > .ant-tabs-content-holder {
  font-size: 12px !important;
  font-weight: inherit !important;
  font-family: "Poppins", sans-serif !important;
}

.profile-tags {
  padding: 4px 10px;
  border: 1px solid #3fb5cf;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.tags-text {
  font-size: 10px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}
.signup-contaienr {
  width: 80%;
  margin: auto;
}
.signup-container {
  width: 67%;
  margin: 51px auto;
  border: 1px solid #cecece;
  padding: 20px 0px;
  background-color: white;
}
.signup-fields .ant-form-item {
  padding: 15px 0 !important;
}
.signup-fields {
  width: 87%;
  margin: 10px auto 32px auto;
  background-color: #f0f2f5;
  padding: 10px 63px;
}
.signup-text {
  margin: 18px 0px;
}
.signup-text span {
  /* font-size: 14px; */
  font-weight: 600;
  color: #5d5d5d;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.save-signup .ant-form-item-control-input {
  width: 88%;
  margin: auto;
}

.cmv-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 16px !important;
}
.cmv-table .ant-table-tbody > tr > td {
  background: transparent !important;
}

.ant-input-number-handler-wrap {
  position: absolute;
  display: none !important;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  /*border-left: 1px solid #d9d9d9;*/
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.profile-container {
  width: 98%;
  margin: auto;
  padding-top: 45px;
  display: flex;
}
.profile-input-border .ant-form-item-control-input {
  min-height: 30px !important;
}
.sign-up-head {
  justify-content: space-around;
  align-items: center;
}
.head-d {
  border: 1px solid;
  height: 10px;
}
.head-link {
  padding: 0 10px 10px 0;
}
.head-text h1 {
  color: #095c81;
}
.email-input input {
  width: 440px;
  height: 39;
  border-radius: 44px 0px 0px 44px;
  background: #eeeeee;
  border: 0px;
}
.sign-up-btn button {
  width: 160 !important;
  border-radius: 44px;
  height: 39;
}
.sign-up-btn .ant-col {
  margin-left: -6px !important;
  padding-left: 0px;
}
.email-input .ant-col {
  padding: 0px !important;
}
.bg-white .ant-layout {
  background-color: white;
}

.sign-up-btn .ant-btn-primary {
  background: #083c5d;
  border: 0px;
  margin-left: -20px;
  margin-bottom: 40px;
}
.sign-up-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: rgb(3 131 188);
  border-color: #fff;
  border: 1px solid;
}
.email-input .ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #eeeeee !important;
}
.signup-email .ant-row {
  justify-content: center;
}
.video-btn {
  text-align: center;
}
.video-btn button {
  height: 65px;
  width: 274px !important;
  background: #083c5d;
  border: 0px;
  border-radius: 4px;
}

.quick-box {
  background: #f5f5f5;
  text-align: center;
  padding: 28px 0px;
  margin-top: 23px;
}
.feature-btn button {
  border-radius: 44px;
  margin-top: 30px;
  background: #083c5d;
  border: 0;
}
.feature-content {
  font-weight: 600;
  font-size: 32px;
  color: black;
}
.feature-content-lg {
  font-size: 30px;
}
.quick-box .ant-row {
  justify-content: center;
}
.features-img .ant-image-img {
  margin-bottom: -112px;
}
.amzing-feature .ant-row {
  justify-content: center;
}
.signup-header .ant-layout-header {
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 22px -13px rgb(11 9 10 / 59%);
}
.watch-video .ant-btn-primary {
  background: #1281ba;
  border: 0px;
  border-radius: 32px;
  width: 182px;
  height: 45px;
  margin: 21px;
}
.client-docs {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
}
.client-ehead {
  width: 48%;
  margin: auto;
}
.draft {
  padding: 3px 12px;
  background: #d9534f;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}
.sent-email {
  padding: 3px 12px;
  background: #5cb85c;
  color: white;
  border: 0px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 11px;
}

.client-doc-main {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.doc-filter .ant-select-dropdown {
  width: 119px !important;
}
.add-emp-btn .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}
.required-field {
  color: red;
  padding-left: 2px;
}
.client-detail-modal .ant-modal-body {
  background: rgb(245 245 245);
  margin: 24px;
  border: 1px solid rgb(192 190 190);
}
.detail-head {
  font-size: 18px;
  font-weight: 500;
  color: #636363;
}
.search-name {
  font-size: 15px;
  color: rgb(59, 59, 59);
  font-weight: 500;
  width: 100%;
}
.search-text {
  font-size: 13px;
  color: rgb(126, 126, 126);
  font-weight: 400;
  width: 100%;
}
.docuemt-padding {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: auto;
  padding: 20px;
}
.filenote-box {
  width: 98%;
  margin: 0 auto 50px auto;
}
.emp-profile-box {
  width: 90%;
  margin: 0 auto 50px auto;
}

.emp-profile-box-employer {
  width: 98%;
  margin: 0 auto 50px auto;
}

.filenote-btn .ant-form-item-control-input-content {
  margin-top: 10px;
}
.float-left {
  float: left;
}
.agreement-data {
  font-weight: 500;
  color: #556370;
  margin-right: 8px;
}
.single-job
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
  padding: 15px 16px !important;
}
.add-client-form-inputs .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label .ant-form-item-no-colon {
  padding: 0px !important;
  margin: 0;
  font-weight: 600;
}
.add-client-form-inputs .ant-form-item-no-colon {
  /* font-size: 11px !important; */
  color: #556370 !important;
  font-weight: 500;
}

.table-action .anticon {
  margin-right: 2px;
  cursor: pointer;
  color: #4b90cd;
  font-size: 16px;
}
.table-action {
  display: flex;
}

.BranchPermissions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reminder-small-text {
  color: #33aada;
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
}

.reminder-bold-text {
  color: #33aada;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}

.reminder-date-text {
  color: #555555;
  font-size: 12px;
  margin: 0px;
}

.reminder-tabs-cont {
  border: 1px solid #e1e1e1;
  margin: 10px 15px;
  margin-left: 0px;
  padding: 10px;
}

.ant-tabs-tab-btn {
  width: 100% !important;
}

.content-width-open-sidebar {
  width: calc(100% - 458px) !important;
}
.content-width-close-sidebar {
  width: calc(100% - 140px) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}

.ant-modal-title {
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.ec-new-case-btn {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
  border-radius: 5px !important;
}

.ant-modal-footer .ant-btn-primary {
  border: 1px solid #1281b9 !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.doc-dropdown-width {
  width: 250px !important;
}

.action-text-cont .ant-btn {
  font-size: 12px !important;
}

.email-address-btn {
  height: 32px;
  line-height: 30px;
  border: 1px solid #cccccc;
  font-family: Lato-R;
  color: #333333;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 5px;
  background-color: #fff;
  border-radius: 0;
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  transition: all 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
}

.imp-emails-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.attachments-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}

.attachment-content-item {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f8950a;
  margin: 3px;
}

.attachment-content-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.template-des-position {
  /*display: inline-block;*/
  vertical-align: -webkit-baseline-middle;
}

.client-email-label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5bc0de;
}

.client-email-popup-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 15px;
}

.client-email-address {
  font-size: 14px;
  color: #185b94;
  display: inline-block;
  /* width: 42%; */
  margin: 0;
  margin-bottom: 15px;
  padding-right: 5px;
  text-transform: capitalize;
}

.client-email-to-row {
  display: flex;
  justify-content: space-between;
}

.body-attachment-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.body-attachment-text {
  margin-left: 10px;
  color: #337ab7;
}

.select-border .ant-select-borderless .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.faq-main-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 12px;
}

.faq-item-main {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #d36205;
}

.faq-item-first {
  width: 100%;
}

.faq-item-first-child {
  /* float: left; */
  font-family: Lato-R;
  font-size: 16px;
  color: #0f82bd;
  padding: 5px 8px;
}

.first-width {
  width: 85%;
}

.faq-item-sec {
  width: 100%;
}

.faq-item-sec-child {
  width: 100%;
  /* float: left; */
  font-family: Lato-R;
  font-size: 14px;
  color: #0f82bd;
  padding: 5px 25px;
}

.faq-head-text {
  color: #07699b;
  font-weight: 500;
  font-size: 16px;
}

.permission-time-zone .timezone-picker .timezone-picker-textfield input {
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
}

.cq-inactive-inner-tab {
  /* background-color: rgb(51, 170, 218); */
  padding: 4px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 1px solid rgb(51, 170, 218);
}

.cq-active-inner-tab {
  background-color: #2e6da4;
  padding: 5px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.cs-attachment-body {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 5px;
  /* margin-top: 15px;
  margin-bottom: 20px; */
  padding: 12px;
  /* margin-left: 30px; */
  margin: 15px 30px 20px;
}

.cs-attachment-haeding {
  font-family: Lato-R;
  font-weight: 300;
  color: #073551;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
}
/* .dashboard-summary-table > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
}
.dashboard-student-summary > * > * > * > * > * > * > colgroup > col {
  width: 180px !important;
  min-width: 180px !important;
} */
.dashboard-summary-table table colgroup col {
  width: 100px;
  min-width: 100px;
  /*maxHeight: 10px !important;*/
}
.dashboard-student-summary table colgroup col {
  width: 180px !important;
  min-width: 180px !important;
}

.term-condition-container h4 {
  color: #09587e;
  padding-right: 8px;
  text-transform: uppercase;
}

.add-background-color {
}

.add-background-color .ant-select-selector {
  background-color: #ffffff !important;
}
.forgot-loader {
  position: absolute !important;
  top: 0 !important;
}
/* .superDashboard .ant-layout-sider-children {
  position: relative !important;
} */

.message-textArea {
  padding: 10px;
  display: block !important;
  background-color: #f5f5f6;
  margin-top: 15px;
  border: 1px solid #ebebec;
}

.message-content-box-radius {
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.input-number {
}

.input-number .ant-input-number {
  width: 100% !important;
}

.row-style {
  display: flex;
  justify-content: space-between;
}

.questionnaire-input-width-0 {
  width: 0%;
}

.questionnaire-input-width-55 {
  width: 55%;
}

.questionnaire-input-width-60 {
  width: 60%;
}

.questionnaire-full-name-input {
  width: 60%;
  border: 0;
}

.full-name-row-padding {
  /* paddingleft: 30;
   paddingRight: 30
                             paddingBottom: 30, */
  padding: 0 30px 30px 30px !important;
}

.questionnaire-label-width-38 {
  width: 38%;
}

@media (max-width: 820px) {
  .contact-info-sec {
    display: block !important;
  }
  .footer-logo {
    width: 100% !important;
  }
  .footer-info-text {
    width: 100% !important;
    display: block !important;
  }
  .footer-info-box {
    width: 100% !important;
  }
  .row-style {
    display: block !important;
  }

  .questionnaire-input-width-55 {
    width: 100% !important;
  }
  .questionnaire-input-width-60 {
    width: 100% !important;
  }
  .questionnaire-full-name-input {
    width: 100% !important;
  }

  .questionnaire-label-width-38 {
    width: 100% !important;
  }

  .cq-container {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .full-name-row-padding {
    padding: 0 0 30px 0 !important;
  }
}

.layout-class .ant-layout.ant-layout-has-sider {
  height: 0% !important;
}

.rem-table-margin .table-card {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.rem-table-margin .ant-table-pagination.ant-pagination {
  margin: 15px !important;
}

/* .cell-margin .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px !important;
} */

.display-inline-block {
  display: inline-block;
}

.display-inline-block .ant-select {
  width: 35px !important;
}

.display-inline-block .ant-select-selector span {
  color: #0052cc !important;
  font-size: 14px !important;
}

.display-inline-block .ant-select-selector {
  border: none !important;
  padding: 0 !important;
}
.table-action .ant-select-selector {
  border: none !important;
  padding: 0 !important;
}
.table-action .ant-select-arrow {
  right: 0;
}

.comment-width .ant-spin-nested-loading {
  width: 100% !important;
}
.froala-font-arial-use span p {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  background-color: transparent;
  line-height: 1.5;
}

.display-inline-block .ant-select-selector {
  border: none !important;
  padding: 0 !important;
}

.comment-width .ant-spin-nested-loading {
  width: 100% !important;
}
.froala-font-arial-use span p {
  font-family: Arial, Helvetica, sans-serif;
}

.ant-card-meta-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 44px;
}

.ant-collapse-content > .ant-collapse-content-box {
  background-color: #fff7ec;
}

.deal-title {
  color: #555555;
  font-size: 16px;
  font-weight: 500;
}

.deal-card-text {
  color: gray;
  font-size: 14px;
}

.total-deal-count {
  background-color: #f0ad4e;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px;
  display: inline-block;
  margin-left: 3px;
}

.deal-icon-position {
  display: flex;
  justify-content: flex-end;
}

.ant-table-blue-view {
}

.ant-table-blue-view .ant-spin-nested-loading .ant-spin-container .ant-table {
  background-color: rgb(238, 249, 255);
}

.ant-table-client-yellow-view {
}

.ant-table-client-yellow-view
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table {
  background-color: rgb(255, 247, 236);
}

.ant-table-visa-white-view {
}

.ant-table-visa-white-view
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table {
  background-color: #fff;
}

.box-shadow {
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 75%);
}

.content-text-file-note {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: green;
}

.public-api-view {
  margin-top: 30px;
  background-color: #f1f4f4;
  padding: 15px;
  border-radius: 5px;
}

.link-text-style {
  color: #0283ee;
  font-size: 14px;
  text-decoration: underline;
}

.link-label-style {
  color: #555;
  font-size: 14px;
}

.link-method-style {
  color: #0db990;
  font-size: 14px;
  font-weight: 500;
}

.no-copy-selection {
  -webkit-user-select: none;
          user-select: none;
}

.visa-name {
  font-size: 16px;
  color: #185b94;
  font-family: Lato-B;
  font-weight: 300;
  padding: 10px 0px px;
}

.visa-row {
  font-family: Lato-R;
  font-size: 14px;
  color: #185b94;
  padding: 5px 0px;
}
.remove-icon-cross {
  display: flex;
  justify-content: flex-end;
  margin-right: -35px;
  margin-top: -15px;
  margin-bottom: 15px;
}

.table-row-red {
  background-color: #ffb7be;
}
.table-row-transparent {
  background-color: transparent;
}

.remove-xero-account {
  background-color: #d84961;
  color: #fff;
  border-color: #d84961;
  border-radius: 4px;
}

.reminder-tabs-header .ant-tabs-tab {
  color: #fff !important;
}

.client-tabs .short-tabs .ant-tabs-tab {
  color: #555 !important;
}

.short-tabs .ant-tabs-tab {
  color: #555 !important;
}

.client-tabs .ant-tabs-tab {
  color: #555 !important;
}

.reminder-tabs-header-temp .ant-tabs-tab {
  color: #fff !important;
}

.reload-email-icon-cont {
  border: 1px solid #b8bdbd;
  display: flex;
  height: 31px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

.reload-email-icon {
  transform: rotate(270deg);
  font-size: 18px;
  color: rgb(69, 140, 204);
}

.dropdown-btn-reload-btn-row {
  display: inline-block;
  width: 40%;
}

.dropdown-btn-reload-btn-row-pc-emp {
  /* display: inline-block;
  width: 40%; */
}

.dropdown-btn-reload-btn-row .ant-btn {
  padding: 0 !important;
}

.dropdown-btn-reload-btn-row-pc-emp .ant-btn {
  padding: 0 !important;
}

.disabled-link {
  pointer-events: none;
}

.acc-set-btn-cont {
  display: inline-block;
  width: 250px;
  margin: 15px 25px;
  align-items: center;
  cursor: pointer;
  padding: 15px 7px 15px;
  box-shadow: 0px 0px 5px 1px rgba(204, 204, 204, 0.75);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(204, 204, 204, 0.75);
  -moz-box-shadow: 0px 0px 5px 1px rgba(204, 204, 204, 0.75);
}

.acc-tab-flex {
  display: flex;
  margin-left: 7px;
}

.arrow-round-cont {
  transform: rotate(90deg);
  background-color: #1281b9;
  padding: 5px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
}

.arrow-color-size {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.lgin-text {
  text-align: center;
  font-size: 25px;
  margin: 10px 10px 15px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lgin-text-main {
  margin-left: 17px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lg-item-center {
  display: flex;
  justify-content: center;
}

.lg-blue-normal-text {
  color: #1684ba;
  margin-left: 0px;
  font-size: 10px;
  text-decoration: underline;
  font-family: "Poppins", sans-serif !important;
}

.lg-copyright-text {
  font-size: 8px;
  text-align: center;
  color: #5b5b5b;
  font-family: "Poppins", sans-serif !important;
}
.forgot-loader{
  position: absolute!important;
  top: 0!important;
}

/* . {
  width: 550px;
  margin-top: 80px;
  margin-right: 30px;
} */

.header-top-bar {
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
}

body,
html {
  height: 100%;
}

.ant-layout-header-padding {
  padding: 0 20px !important;
}

.ant-layout-header-padding .ant-layout-header {
  height: 64px !important;
}

.header-name-cont {
  display: block;
  line-height: 20px !important;
  margin-top: 12px;
}

.header-name-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-name-text-style {
  color: rgb(9, 116, 172);
  margin-left: 6px;
  word-break: break-all;
}

/* .header-name-text .ant-layout-header {
  line-height: 14px !important;
} */

.header-bar {
  background-color: #1179af;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  align-items: center;
}

.header-bar-text-div {
  /* background-color: #33aada; */
  /* width: 115px; */
  display: flex;
  padding: 12px 5px;
  align-items: center;
}

.header-bar-image {
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.header-text {
  font-size: 14px;
  color: #ffffff;
}

.logo-img {
  padding: 2px 0 2px 20px;
}

.menu-icon {
  margin-left: 20px;
  padding: 16px 0px;
}

.header-layout {
  background: #ffff !important;
}

.company-cont {
  display: flex;
  margin: 10px 13px 0 20px;
  box-shadow: 0px 0px 6px 2px rgba(244, 244, 244, 1);
  border: 0.5px solid #929292;
  height: 29px;
  width: 33%;
  border-radius: 4px;
  padding-right: 1px;
}

.company-box {
  background-color: #f4f6f6;
  height: 29px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
  border-right: 1px solid #929292;
}

.company-text {
  color: #77858f;
  margin: 7px;
  padding-right: 20px;
}

.css-yk16xz-control {
  border: none !important;
  min-height: 26px !important;
}

.css-g1d714-ValueContainer {
  padding: 1px 6px !important;
}

.css-1wa3eu0-placeholder {
  font-size: 14px;
}

.css-tlfecz-indicatorContainer {
  padding: 0px 4px !important;
}

.css-1pahdxg-control {
  border: 0px solid #d0d0d0 !important;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.63) !important;
  min-height: 27px !important;
}

.css-1pahdxg-control:hover {
  border: 0px solid !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 0px 4px !important;
}

.search-client {
  margin: 10px 0 0 0px;
  display: flex;
  width: 31%;
  border: 1px solid #0974ac;
  height: 29px;
  border-radius: 4px;
}

.search-box {
  background-color: #0974ac;
  width: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: hidden;
  border-right: 1px solid #0974ac;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input-cont {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.search-input {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 15px;
  border: none;
  outline: none;
}

.select-country {
  width: 100%;
  border: 1px solid #929292;
  height: 29px;
  margin: 10px;
  border-radius: 4px;
}

.btn-logout-cont {
  padding: 10px 0px;
  width: 70px;
}

.btn-logout {
  border: 1px solid #1081b9;
  padding: 7px 9px;
  border-radius: 5px;
  background-color: #1081b9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-downarrow-cont {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  /* padding: 3px; */
  margin-left: 5px;
}

.css-1hwfws3 {
  padding: 0px 6px !important;
}

/* ========== Profile top bar CSS ============ */

.cp-top-bar {
  background-color: #ffffff;
  display: flex;
  padding-right: 5px;
}

.cp-top-bar-div {
  display: flex;
  padding: 12px 10px;
  align-items: center;
}

.cp-top-bar-text {
  color: rgb(9, 59, 94);
  font-size: 12px;
}

/* ======== Potential Clients top bar css ======== */

.pc-header-bar {
  background-color: #1179af;
  display: flex;
  padding-right: 5px;
}

.site-layout .site-layout-background {
  background: #ffffff;
}

.ant-input-search-large .ant-input-search-button {
  height: 31px !important;
}

.header-search-col {
  display: flex !important;
  text-align: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 10px;
}

.header-search-col .ant-input-lg {
  padding: 4px !important;
  font-size: 14px !important;
}

.header-search-col .ant-select-selector {
  border: 0 !important;
}

.image-search-result {
  border: 3px solid #1085bb;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.search-client-card-cont {
  display: flex;
  border-bottom: 1px solid #c7c7c7;
}

.header-search-col .ant-btn-primary {
  background-color: #1281b9 !important;
  border: 1px solid #1281b9 !important;
}
.ActiveSpan {
  background: green;
  color: white;
  border-radius: 6px !important;
  padding: 2px;
  font-size: 12px;
}

.InactiveSpan {
  background: red;
  color: white;
  border-radius: 6px !important;
  padding: 2px;
  font-size: 12px;
}

.user-activity-log {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #e6e9ec;
}

.user-activity-log:hover {
  background-color: #ffffff;
}

.show-more-cont {
  padding: 15px 20px;
  display: flex;
  justify-content: flex-end;
}

.show-more-text {
  color: #1280b8;
  font-size: 12px;
  font-weight: 500;
}

.sus-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 20px;
  border-radius: 2px;
}

.sus-content-card-container {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

@media (max-width: 1024px) {
  .sus-content-card-container {
    display: block;
  }
}

.sus-right-cont {
  width: 29%;
}

@media (max-width: 1024px) {
  .sus-right-cont {
    margin: 40px;
    width: calc(100% - 45px);
  }
}

.sus-form-container {
  background-color: #f5f7f8;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
  width: 60%;
}

.sus-pi-form-container {
  background-color: #f5f7f8;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
  width: 62%;
}

.sus-cs-form-container {
  background-color: #f5f7f8;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
}

@media (max-width: 1024px) {
  .sus-pi-form-container {
    width: 80%;
  }
  .sus-cs-form-container {
    width: 80%;
  }
}

.sus-tab-container {
  display: flex;
  margin: 30px 0px 0px 15px;
}

.sus-active-tab {
  background-color: #458ccc;
  padding: 5px 15px;
  display: flex;
  border: 1px solid #458ccc;
  justify-content: center;
  cursor: pointer;
}

.sus-active-tab-text {
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
}

.sus-inactive-tab {
  border: 1px solid #458ccc;
  padding: 5px 10px;
  display: flex;
  background-color: #ebebeb;
  justify-content: center;
  cursor: pointer;
}

.sus-inactive-tab-text {
  font-size: 10px;
  font-weight: 500;
  color: #898989;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-profile-cont {
  border-radius: 50%;
  display: flex;
  width: 120px;
  height: 120px;
}

.sus-profile-img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.sus-form-row-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sus-cp-text {
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 500;
}

.sus-cp-btn {
  background-color: #f7f7f7 !important;
  display: flex !important;
  padding: 7px 10px !important;
  border: 1px solid #acacac !important;
  box-shadow: 0px 0px 5px 2px rgba(204, 202, 204, 1) !important;
}

.sus-cp-btn-text {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 600;
}

.sus-save-btn {
  background-color: #1081ba !important;
  border-color: #1081ba !important;
  display: flex !important;
  padding: 5px !important;
  border-radius: 5px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.sus-add-btn {
  background-color: #1081ba !important;
  border-color: #1081ba !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 5px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.sus-save-btn-text {
  font-size: 8px;
  color: #ffffff;
  font-weight: 600;
}

.sus-card-header {
  display: flex;
  justify-content: space-between;
  background-color: #448ccb;
  padding: 10px;
}

.sus-card-cont {
  width: 100%;
  border: 1px solid #e9eaec;
  border-radius: 10px;
  overflow: hidden;
  height: 185px;
  background-color: #fcfcfc;
}

.sus-card-header-text {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.sus-card-header-small-text {
  color: #ffffff;
  font-size: 12px;
}

.sus-bar-cont {
  padding: 30px 25px 0px;
}

.sus-card-content-cont {
  display: flex;
  justify-content: space-between;
  padding: 15px 35px;
}

.sus-card-content-text {
  color: #848484;
  font-size: 13px;
  font-weight: 500;
}

.sus-logo-img {
  padding: 25px 0px;
}

.sus-bottom-btn-cont {
  display: flex;
  margin: 45px 60px;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 25px;
  width: 350px;
  cursor: pointer;
}

.sus-bottom-icon-cont {
  box-shadow: 0px 0px 5px 0px rgba(227, 223, 227, 1);
  display: flex;
  padding: 5px 10px;
  border-radius: 3px;
}

.sus-bottom-icon {
  width: 35px;
}

.sus-bottom-text {
  /* margin-left: 20px; */
  color: #3f3d3e;
  font-size: 15px;
}

.sus-add-cont-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: -30px;
}

.sus-add-cont {
  background-color: #2397f4;
  display: flex;
  padding: 5px;
  border-radius: 50%;
  align-items: center;
  width: 15px;
  height: 10px;
  justify-content: center;
  padding-bottom: 10px;
  align-self: flex-end;
}

.sus-plus {
  font-size: 24px;
  color: #ffffff;
}

.sus-ppg-text {
  color: #828282;
  font-size: 14px;
  margin-left: 10px;
}

.sus-table {
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  background-color: #ffffff;
}

.sus-table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #d3d3d3;
}

.sus-head-text-cont {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sus-head-text {
  font-size: 14px;
  font-weight: 600;
  color: #828282;
}

.sus-table-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.sus-content-text {
  font-size: 14px;
  color: #828282;
}

.sus-modal-label {
  padding: 0px 60px 10px 0px;
  border-bottom: 1px solid #d3d3d3;
  margin: 0px 0px 20px 10px;
}

.sus-modal-label-text {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.sus-modal-button {
  background-color: #1281b9;
  padding: 5px 15px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

.sus-modal-button-text {
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
}

.submit-support-button-text {
  background: #1179af;
  cursor: pointer;
}

.sus-disable-cont {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.sus-disable-text {
  font-size: 10px;
  color: #0e7ccf;
}

.sus-disable-icon {
  width: 12px;
  height: 12px;
  margin-left: 15px;
  transform: rotate(90deg);
}

.sus-member-card-cont {
  margin: 25px 20px 0px 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(230, 230, 230, 0.75);
  padding: 15px 20px 0px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
  width: 100%;
}

.sus-permission-card-cont {
  margin: 25px 0px 0px 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(230, 230, 230, 0.75);
  padding: 15px 20px 0px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
}

.sus-show-btn {
  background-color: #dce0e3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  padding: 5px 10px 0px;
  cursor: pointer;
}

.sus-checkbox-row {
  display: inline-block;
  justify-content: space-between;
  margin-top: 20px;
}
.sus-days-checkbox-row {
  display: inline-block;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: -50px;
}

.sus-checkbox-cont {
  display: inline-block;
  align-items: center;
  width: 33%;
  padding: 2px;
}

.sus-checkbox-cont .sus-checkbox-label .ant-checkbox + span {
  margin-top: 10px;
}
.sus-days-checkbox-cont {
  display: inline-block;
  align-items: center;
  min-width: 18px;
}

.sus-checkbox-label {
  color: #6d6d6d !important;
  font-size: 11px !important;
  margin-left: 5px !important;
  font-weight: 500 !important;
  display: flex !important;
  /* align-items: center !important; */
}

.sus-team-memb-setting {
  width: calc(65% - 45px);
  margin-left: 45px;
}

@media (max-width: 1024px) {
  .sus-team-memb-setting {
    width: calc(100% - 110px);
    padding-top: 20px;
  }
}

.sus-cb-row {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: 20px;
}

.sus-cb-hr-line {
  width: 65%;
  border: 0.5px solid #b6b6b6;
  height: 0px;
  margin: 0px 10px;
}

.sus-cb-text {
  color: #545454;
  font-size: 14px;
  font-weight: 600;
}

.sus-bn-logo {
  color: #1885bb;
  font-weight: 800;
  font-size: 18px;
}

.sus-member-card-bgcolor {
  background-color: rgb(242, 242, 242);
  margin: 20px -45px 20px -15px;
  padding: 35px;
  border: 1px solid #b7b7b7;
}

.sus-bs-right-card-cont {
  margin-top: 40px;
  background-color: #ffffff;
  padding: 30px;
}

@media (max-width: 1024px) {
  .sus-bs-right-card-cont {
    width: calc(100% - 110px);
    margin: 40px;
  }
}

.sus-modal-scroll {
  height: 450px;
  overflow-y: scroll;
}

.sus-add-processing-person-form {
  background-color: #f5f7f8;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #bfbfbf;
}

.css-26l3qy-menu {
  index: 999 !important;
}

.sus-branch-hide-cont {
  display: flex;
  justify-content: flex-end;
  margin-right: -30px;
  margin-top: -10px;
}

.sus-branch-hide-text {
  font-size: 10px;
  color: #494949;
  font-weight: 500;
}

.ant-select-selector {
  border-radius: 4px !important;
  border: 0.8px solid #c9c9ca !important;
}

.ant-upload-picture-card-wrapper {
  width: 100% !important;
}

/*.ant-upload {*/
/*  margin-top: 10px !important;*/
/*  margin-left: 10px !important;*/
/*  overflow: hidden !important;*/
/*  border: 0px !important;*/
/*}*/

.css-2613qy-menu {
  z-index: 999 !important;
  max-height: 100px !important;
}

.color-picker-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.MuiTypography-body2 {
  font-size: 12px !important;
}

.MuiListItem-dense {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.padding {
  padding-left: 76px !important;
}
.taskListUI {
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
.ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 10px !important;
  padding-right: 10px;
}

.cq-form-cont {
  background-color: #f4f4f4;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
  width: 100%;
  margin-left: 0px;
  margin-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.cq-container {
  width: calc(100% - 50px);
  margin-left: 25px;
}

.cq-primary-btn {
  background-color: #1281b9 !important;
  border-color: #1281b9 !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.cq-orng-btn {
  background-color: #f7941e !important;
  border-color: #f7941e !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.cq-save-btn {
  background-color: #3ab54a !important;
  border-color: #3ab54a !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.cq-close-btn {
  background-color: #f16d4f !important;
  border-color: #f16d4f !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.heading-container {
  padding: 20px 30px 10px;
  border-bottom: 1px solid #dce0e3;
}

.heading-text {
  color: #2f4678;
  font-size: 18px;
  font-weight: 500;
}

.title-container {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
}

.cq-label-text {
  color: #2f4678 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.section-heading-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}

.cq-add-btn {
  color: #929292;
  font-weight: 500;
}

.cq-section-cont {
  background-color: rgb(255, 255, 255);
  padding: 0px 20px 20px;
  margin-top: 40px;
  border-radius: 5px;
}

.cq-optional-cont {
  padding: 30px;
  background-color: rgb(255, 247, 236);
  margin-top: 20px;
  border-radius: 5px;
}

.cq-optional-section-cont {
  padding: 30px;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 5px;
}

.cq-btn-text {
  color: #ffffff !important;
}

.cq-section-name-cont {
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
}

.footer-title {
  color: #209f8f;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 1px;
}
.contact-info-sec {
  padding: 45px 0px;
  border: 1px solid #e5e9eb;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.footer-logo {
  width: 25%;
  text-align: center;
  position: relative;
}
.footer-logo img {
  max-width: 170px;
  margin-bottom: 1rem;
}
.footer-logo p {
  margin: 0;
  color: #2e2e2e;
  line-height: 20px;
  font-size: 18px;
}
.footer-logo:after {
  content: "";
  background-color: #b7b7b7;
  width: 2px;
  height: 150px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -34px;
}
.footer-info-text {
  width: 75%;
}
.footer-info-box {
  width: 33.33%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-info-box h4 {
  font-size: 18px;
  color: #2e2e2e;
  margin-bottom: 0px;
  position: relative;
}
.footer-info-box h4:nth-child(1),
.footer-info-box h4:nth-child(2) {
  margin-bottom: 10px;
}
.footer-info-box h4 span {
  position: relative;
}
.footer-info-box h4 span:after {
  content: "";
  height: 2px;
  width: 30px;
  bottom: -5px;
  left: 50%;
  margin-left: -15px;
  background-color: #b7b7b7;
  position: absolute;
}
.footer-info-box p {
  color: #686767;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}

.cq-list-table-cont {
  padding: 0px 20px;
}

.cq-questionnaire-list-cont {
  border: 1px solid #458ccc;
}

.cq-questionnaire-list-heading {
  display: flex;
  background-color: #f9f9fb;
  padding: 10px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
  align-items: center;
  justify-content: space-between;
}

.cq-list-content-row {
  display: flex;
  padding: 3px 10px 2px;
  border-top: 1px solid #dbdcde;
  margin-bottom: 10px;
}

.cq-add-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.cq-width-30 {
  width: 30%;
}

.bf-content {
  height: 250px !important;
}

.rich-text-area {
  width: 70%;
  background: rgb(255, 255, 255);
  border: 0.3px solid #bab9b9;
}

.section-arrow-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.section-arrow-icons .anticon-up {
  font-size: 19px !important;
}
.section-arrow-icons .anticon-down {
  font-size: 19px !important;
}

.question-field-row {
  display: flex;
  justify-content: space-between;
  width: 82%;
  margin-left: 5%;
}

.cq-checkbox-width {
  display: flex;
  align-items: center;
  margin-right: 35px;
}

.options-cont .ant-radio-wrapper {
  white-space: inherit !important;
}

.contact-us-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 40px;
}

.container {
  background-color: #ffffff;
  margin: 40px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 20px;
  border-radius: 2px;
}
.container-ui {
  background-color: #ffffff;
  margin: 40px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 5%);
  padding-bottom: 20px;
  border-radius: 2px;
}

.overflow {
  overflow: auto !important;
}

.container-bar {
  background-color: #f6f7f8;
  height: 100px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}

.bar-left-side {
  background-color: #edf0f2;
  width: 200px;
  height: 100px;
  border-right: 1px solid;
  border-color: #cbcbcb;
}

.partner-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #1280b9;
  margin-top: -60px;
  margin-left: 25px;
}

.header-title {
  padding: 30px 40px 0px 20px;
}

.form-container {
  background-color: #f5f7f8;
  margin: 20px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
}

.form {
  background-color: #f5f7f8;
}
.input {
  width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  outline: none;
}

.input-border {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
  overflow: hidden;
}

.select-options {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
}

.select-options-technical-support-types {
  width: 76%;
  margin-left: 2%;
}

.drop-files-technical-support-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  font-size: 12px;
  color: #556370;
  font-family: "Poppins";
}

.button-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.button {
  border: 1px solid #1179af;
  padding: 5px 30px;
  border-radius: 5px;
}

.all-clients-table {
  width: calc(100% - 40px);
  padding: 20px;
}
.all-clients-tables {
  padding: 20px;
}

.header-table {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.header-tab-5 {
  width: 5%;
}

.header-tab-7 {
  width: 7%;
}

.header-tab-8 {
  width: 8%;
}

.header-tab-10 {
  width: 10%;
}

.header-tab-15 {
  width: 15%;
}

.header-tab-20 {
  width: 20%;
}

.header-tab-25 {
  width: 25%;
}

.header-tab-30 {
  width: 30%;
}

.header-tab-40 {
  width: 40%;
}

.header-tab-50 {
  width: 50%;
}

.table-content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e2e2e3;
  align-items: center;
}

.main-table-cont {
  border: 1px solid #e2e2e3;
  border-radius: 3px;
}

.content-index-5 {
  width: 5%;
}

.content-index-7 {
  width: 7%;
}

.content-index-8 {
  width: 8%;
}

.content-index-10 {
  width: 10%;
}

.content-index-15 {
  width: 15%;
}

.content-index-20 {
  width: 20%;
}

.content-index-30 {
  width: 30%;
}

.content-index-40 {
  width: 40%;
}

.content-index-50 {
  width: 50%;
}

.delete-container {
  width: 5%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.delete-icon {
  display: flex;
  border: 1px solid #ccc;
  width: 16px;
  padding: 5px;
  border-radius: 5px;
}

.all-show-button {
  padding: 7px 20px;
  border-radius: 4px;
  background-color: #97cb4a;
}

.button-search {
  padding: 7px 20px;
  border-radius: 4px;
  background-color: #ff9e37;
  margin-left: 9px;
}

.page-container {
  width: 100%;
}

.input-div {
  margin-left: 25px;
}

.header-font {
  font-size: 13px;
  font-weight: 600;
}

.content-text {
  font-size: 13px;
}

/* ====== Visa flow CSS ( .cv-class-name for Client Visa) ===== */

.cv-main-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.cv-left-container {
  background-color: #ffffff;
  margin: 31px 0px 20px 0px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px;
  border-radius: 2px;
  width: 53%;
}

.cv-top-btn-row {
  display: flex;
  padding: 10px 15px;
}

.cv-btn-blue {
  background-color: #36b1e4;
  padding: 10px 20px;
  border-radius: 5px;
}

.cv-top-lbtn-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cv-top-rbtn-text {
  color: #ffffff;
  font-size: 10px;
}

.cv-gray-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  padding: 20px 10px 0px 15px;
}

.cv-bold-text {
  color: #073d5d;
  font-size: 13px;
  font-weight: 600;
}

.cv-assist-cont {
  display: flex;
  /* margin-top: 5px; */
  align-items: center;
}

.cv-green-box {
  border: 1px solid #3fceab;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 10px;
}

.cv-green-text {
  color: #3fceab;
  font-size: 10px;
  display: flex;
}

.cv-purpel-box {
  background-color: #845fa8;
  padding: 5px 15px;
  border-radius: 3px;
}

.cv-preparing-box {
  background-color: #f8940a;
  padding: 5px 15px;
  border-radius: 3px;
}

.cv-imm-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cv-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cv-imm-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.cv-icons-row {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-top: 20px;
}

.cv-show-hide-cont {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dce0e3;
  margin-top: 50px;
}

.cv-ass-rows-cont {
  margin: 20px 30px;
}

.cv-ass-row {
  display: flex;
  align-items: center;
}

.cv-blue-box {
  width: 13px;
  height: 13px;
  background-color: #6ccef6;
}

.cv-normal-text {
  color: #5b5b5b;
  font-size: 13px;
  margin-left: 15px;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif !important;
}

.cv-cross-cont {
  background-color: #7c7c7c;
  padding: 2px;
  border-radius: 50%;
  width: 25px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
}

.cv-svg-8 {
  width: 8px;
  height: 8px;
}

.cv-org-cont {
  background-color: #f26a4f;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  border-radius: 5px;
}

.cv-org-text {
  font-size: 10px;
  color: #ffffff;
}

.cv-delete-cont {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 19px;
  width: 20px;
  justify-content: center;
  border-radius: 3px;
  padding: 2px;
  padding-left: 3px;
  margin-left: 10px;
  margin-top: 10px;
}

.cv-dlt-icon {
  width: 12px;
  height: 12px;
}

.cv-right-cont {
  width: 27%;
}

.cv-top-heading {
  display: flex;
  justify-content: space-between;
}

.cv-textarea {
  width: calc(100% - 7px);
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.cv-active-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  border: 1px solid #acacac;
  justify-content: center;
  box-shadow: 0px 0px 5px 2px rgba(224, 221, 224, 1);
  z-index: 999;
}

.cv-inactive-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 5px 2px rgba(230, 230, 230, 0.75);
}

.cv-active-tab-text {
  font-size: 12px;
  font-weight: 600;
  color: #898989;
}

.cv-inactive-tab-text {
  font-size: 12px;
  font-weight: 500;
  color: #898989;
}

.cv-white-cont {
  background-color: #ffffff;
  margin: 10px;
  margin-top: 0px;
  padding: 10px 10px 0px 15px;
  border: 1px solid #929292;
}

.cv-doc-head-row {
  display: flex;
  background-color: #eeeff1;
  margin-left: -15px;
  margin-right: -10px;
  padding: 15px 10px 2px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
}

.cv-doc-head-text {
  font-size: 12px;
  font-weight: 600;
  color: #093b5e;
}

.cv-width-55 {
  width: 55%;
}

.cv-width-52 {
  width: 52%;
}

.cv-width-20 {
  width: 20%;
}

.cv-width-17 {
  width: 17%;
}

.cv-width-15 {
  width: 15%;
}

.cv-width-13 {
  width: 13%;
}

.cv-doc-inner-index {
  width: 30px;
  margin-left: 5px;
}

.cv-doc-width {
  width: calc(100% - 20px);
}

.cv-doc-row {
  display: flex;
  margin-left: -15px;
  margin-right: -10px;
  padding: 3px 10px 2px;
  border-top: 1px solid #dbdcde;
  margin-bottom: 10px;
  align-items: center;
}

.cv-doc-text {
  font-size: 10px;
  font-weight: 600;
}

.cv-doc-date-text-cont {
  display: flex;
  margin-top: 3px;
}

.cv-doc-date-text {
  font-size: 8px;
  color: #36afe3;
}

.cv-title-box {
  display: flex;
  margin-top: 6px;
  border: 1px solid #dbdcde;
  padding: 2px;
}

.cv-action-icons-border {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 16px;
  width: 16px;
  justify-content: center;
  border-radius: 3px;
  padding: 12px;
}

.cv-action-icon {
  width: 17px;
  height: 17px;
}

.cv-client-circle {
  width: 10px;
  height: 10px;
  background-color: #efad4c;
  border-radius: 50%;
}

.cv-circle-lable-cont {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.cv-agent-circle {
  width: 10px;
  height: 10px;
  background-color: #98d04e;
  border-radius: 50%;
}

.cv-both-circle {
  width: 10px;
  height: 10px;
  background-color: #23bee6;
  border-radius: 50%;
}

.cv-org-btn-cont {
  display: flex;
  background-color: #ff9d36;
  padding: 7px 20px;
  border-radius: 5px;
  margin-left: 10px;
  align-items: center;
}

.cv-org-btn-text {
  font-size: 9px;
  color: #ffffff;
  font-weight: 500;
}

.cv-actions-cont {
  margin-left: 10px;
  border: 1px solid #dbdcde;
  padding: 2px 10px;
  display: flex;
  border-radius: 3px;
}

.cv-print-icon-cont {
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #2cc3ff;
}

.cv-extend-icon-cont {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.cv-extend-icon {
  width: 12px;
  height: 12px;
}

.cv-plus-cont {
  background-color: #ffffff;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
}

.cv-plus-icon {
  color: #ff9d36;
  font-size: 16px;
  margin-bottom: 2px;
  margin-left: 1px;
  font-weight: 500;
}

.cv-time-spent-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
  align-items: flex-end;
}

.cv-file-notes-gray-text {
  color: rgb(118, 133, 143);
  font-size: 10px;
  font-weight: 500;
}

.cv-filenotes-cont {
  width: calc(100% - 20px);
  padding: 5px 0px;
  margin: 5px 10px;
}

.cv-filenotes-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
  align-items: center;
}

.cv-redish-btn {
  background-color: #ec441f;
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 15px 0px 5px 15px;
}

.cv-redish-btn-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cv-lf-white-cont {
  display: flex;
  background-color: #ffffff;
  margin: 30px 20px 20px 20px;
  box-shadow: 0px 0px 5px 0px rgba(189, 189, 189, 1);
  border-radius: 10px;
  height: 200px;
}

.cv-lf-checkbox-label-cont {
  margin-left: 10px;
  width: 120px;
  display: flex;
}

.cv-horizontal-line {
  border: 1px solid #dce0e3;
  margin: 30px -10px 10px -15px;
}

/* ====== Client Documents CSS ( .cd-class-name for Client Documents) ===== */

.cd-container {
  background-color: #ffffff;
  margin: 40px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 50px 0px 20px;
  border-radius: 2px;
}

.cd-blue-plus-btn-cont {
  display: flex;
  background-color: #448bcb;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
  align-items: center;
}

.cd-blue-plus-text {
  color: #448bcb;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: 500;
}

.cd-white-cont {
  background-color: #ffffff;
  margin: 10px;
  margin-top: 0px;
  padding: 50px 10px 0px 15px;
  border: 1px solid #929292;
}

.cd-doc-head-row {
  display: flex;
  background-color: #f9f9fb;
  margin-left: -15px;
  margin-right: -10px;
  padding: 10px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
  align-items: center;
  justify-content: space-between;
}

.cd-table-row {
  height: 30px;
  background-color: #f9f9fb;
  margin: 5px -10px 10px -14px;
}

.cd-inactive-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #acacae;
}

.cd-blue-border-cont {
  border: 1px solid #458ccc;
  padding: 20px 0px;
}

.cd-doc-checklist-cont {
  background-color: transparent;
  margin-top: 0px;
  padding: 50px 10px 0px 15px;
  border-top: 1px solid #929292;
}

.cd-doc-active-tab {
  background-color: #458ccc;
  padding: 7px 15px;
  display: flex;
  border: 1px solid #458ccc;
  justify-content: center;
  z-index: 999;
}

.cd-doc-active-tab-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.cd-doc-inactive-tab {
  background-color: #1cbbb4;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #1cbbb4;
}

.cd-doc-inactive-tab-text {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}

.cd-doc-sub-inactive-tab {
  background-color: #f7f7f7;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #458ccc;
}

.cd-header-title-index {
  display: flex;
  width: 33.3%;
  border: 1px solid rgb(146, 146, 146);
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
  margin-top: -5px;
  margin-bottom: -5px;
  align-items: center;
  padding: 6px;
}

.cd-top-row-table {
  display: flex;
  margin-left: -15px;
  margin-right: -10px;
  padding: 15px 10px 2px;
  border-top: 2px solid #dbdcde;
  border-bottom: 1px solid #dbdcde;
}

.cd-send-btn-cont {
  background-color: #0172bb;
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
}

.cd-send-btn-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

/* ======== Employer CSS ======== */

.emp-container {
  margin: 45px 14px 20px 14px;
  padding-bottom: 20px;
  border-radius: 2px;
  display: flex;
}

.emp-prof-cont {
  width: 150px;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
  padding-bottom: 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 3px;
}

.emp-lab-val-cont {
  display: flex;
  border: 0.5px solid #c8c8c8;
  height: 21px;
  width: 70%;
  border-radius: 4px;
  padding-right: 1px;
  box-shadow: 0px 0px 5px 0px rgba(235, 232, 235, 1);
}

.emp-student-label {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #1182bc;
}

.emp-middle-cont {
  background-color: #ffffff;
  margin: 0px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 10px;
  width: 99%;
  border-radius: 3px;
}

.emp-ace-bold-text {
  margin: 5px 10px;
  color: #073d5d;
  font-size: 13px;
  font-weight: 600;
}

.emp-ace-cont {
  background-color: #f6f7f9;
  margin: 0px 10px;
  border-radius: 3px;
  padding: 15px;
  width: calc(100% - 20px);
}

.emp-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden;
  background-color: #ffffff !important;
  width: 65%;
}

.emp-blue-btn {
  border: 1px solid #1081b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1081b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* ========= Client Account CSS ========== */

.ca-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 0px 20px;
  border: 1px solid #8c8c8a;
  border-radius: 3px;
  width: 50%;
}

.ca-inner-cont {
  padding: 15px 10px 0px 15px;
}

.ca-org-cont {
  background-color: #f48f59;
  display: flex;
  padding: 3px 10px;
  border-radius: 3px;
  align-items: center;
}

.ca-inactive-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
}

.ca-inactive-text {
  color: #ffffff;
  font-size: 10px;
}

.ca-active-arrow-cont {
  display: flex;
  background-color: #d0d4d6;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.ca-active-arrow-icon {
  width: 9px;
  height: 9px;
  margin-bottom: 1px;
  transform: rotate(180deg);
}

.ca-active-status-cont {
  background-color: #41ab51;
  display: flex;
  padding: 3px 14px;
  border-radius: 3px;
  align-items: center;
}

.ca-table-cont {
  width: 100%;
  background-color: #ebeff2;
}

.ca-table-head-row {
  background-color: #ebeff2;
}

.ca-head-text {
  font-size: 10px;
  color: #213037;
  width: 25%;
  padding: 7px 10px;
  text-align: left;
}

.ca-cell-background-color {
  background-color: #40b5ce;
}

.ca-td-row-text {
  font-size: 10px;
  color: #ffffff;
  width: 25%;
  padding: 7px 10px;
  text-align: left;
  font-weight: 600;
}

.ca-checkbox-row {
  display: flex;
  align-items: center;
  margin: 10px;
}

.ca-other-table {
  width: 100%;
  border: 1px solid #e5e9ec;
  border-radius: 3px;
  overflow: hidden;
  border-spacing: 0px;
}

.ca-other-table-cont {
  padding: 15px 20px;
}

.ca-other-tab-head {
  font-size: 10px;
  color: #213037;
  padding: 7px 10px;
  text-align: left;
}

.ca-oth-td-row-text {
  font-size: 10px;
  color: #54626b;
  padding: 5px;
  text-align: left;
  font-weight: 600;
}

.ca-actions-td-row {
  display: flex;
  justify-content: space-around;
}

.ca-action-blue-index {
  display: flex;
  border: 1px solid #3fb4cf;
  padding: 5px;
  border-radius: 3px;
}

.ca-action-blue-text {
  font-size: 8px;
  color: #3fb4cf;
}

.ca-action-green-index {
  display: flex;
  border: 1px solid #45bb56;
  padding: 7px 10px;
  border-radius: 3px;
}

.ca-active-tab {
  background-color: #458ccc;
  padding: 5px 15px;
  display: flex;
  border: 1px solid #458ccc;
  justify-content: center;
  z-index: 999;
  cursor: pointer !important;
}

.ca-active-tab-text {
  font-size: 9px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer !important;
}

.ca-inactive-tab {
  background-color: #f7f7f7;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  border: 1px solid #458ccc;
  cursor: pointer !important;
}

.ca-inactive-tab-text {
  font-size: 8px;
  font-weight: 500;
  color: #898989;
  cursor: pointer !important;
}

.ca-label-cont {
  margin: 15px 30px;
}

.ca-label-text {
  font-size: 12px;
  font-weight: 500;
  color: #012d83;
}

.ca-invoice-row-cont {
  display: flex;
}

.ca-small-index {
  width: 14%;
  margin-left: 8px;
}

.ca-form-cont {
  background-color: #f5f7f8;
  margin: 20px 20px 0px 20px;
  border-radius: 2px;
  padding: 10px 20px;
  border: 1px solid #e6e6e6;
}

.ca-white-cont {
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  margin-top: 10px;
  padding: 3px;
  border-radius: 2px;
}

.ca-color-row {
  display: flex;
  background-color: #ebebeb;
  padding: 5px 0px;
}

.ca-color-row-index {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ca-color-row-text {
  color: #04214b;
  font-size: 12px;
  font-weight: 600;
}

.ca-input-cont {
  display: flex;
}

.ca-text-area {
  width: calc(100% - 0px);
  /* margin-left: 30px; */
  margin-right: 30px;
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.ca-right-input-cont {
  margin-left: 30px;
  width: 40%;
}

.ca-btn-blue {
  padding: 8px 20px;
  background-color: #4e7eea;
  display: flex;
  border-radius: 3px;
}

.ca-btn-blue-text {
  font-size: 12px;
  color: #ffffff;
}

.ca-gray-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  padding: 20px 0px;
  border: 1px solid #e6e6e6;
}

.ca-calendar-cont-row {
  display: flex;
  justify-content: space-around;
}

.ca-show-add-btn-cont {
  display: flex;
  width: 35%;
  justify-content: space-around;
}

.ca-show-add-cont {
  background-color: #4e7eea;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.ca-show-add-text {
  font-size: 12px;
  color: #ffffff;
}

.ca-invoice-table-cont {
  background-color: #f3f3f3;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-spacing: 1px;
  border-radius: 5px;
}

.ca-table-heading {
  color: #6e6e6e;
  font-size: 10px;
  padding: 10px;
}

.ca-table-head-width {
  width: 55px;
  padding: 10px;
}

.ca-green-cont {
  background-color: #41ab51;
  border-radius: 10px;
  align-items: center;
  width: 46px;
  justify-content: center;
  display: flex;
  padding: 1px 0px;
}

.ca-colored-box-text {
  font-size: 10px;
  color: #ffffff;
}

.ca-font-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  text-align: center;
  padding: 15px;
}

.ca-og-show-add-cont {
  display: flex;
  width: 35%;
}

.ca-table-bottom-text {
  color: rgb(109, 109, 109);
  font-size: 10px;
  margin-left: 10px;
  font-weight: 600;
}

.ca-acc-flow-row {
  display: flex;
  justify-content: space-between;
  padding: 0px 70px;
}

.ca-form-label {
  margin-top: 8px;
  margin-bottom: 9px;
  font-weight: 500;
  width: 120px;
}

.ca-form-a-label {
  color: #0e5ea3;
  font-size: 12px;
  font-weight: 500;
}

.ca-add-btn-row {
  display: flex;
  justify-content: space-between;
  margin-right: 38%;
  margin-top: 10px;
}

.ca-add-btn-cont {
  background-color: #4e7eea;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.ca-agent-table-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
}

/* ========= Client Activities CSS ========== */

.cl-act-container {
  background-color: #ffffff;
  margin: 40px 0px 0px 0px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 25px 15px;
}

.cl-act-org-btn-cont {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cl-act-org-btn-text {
  color: rgb(255, 255, 255);
  font-size: 9px;
}

/* ======= Client email CSS ========= */

.cl-em-container {
  margin: 25px 30px 25px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 50px 33px 20px;
  border-radius: 2px;
}

.cl-em-blue-btn {
  background-color: #1179af;
  display: flex;
  padding: 5px 10px;
  border-radius: 3px;
}

.cl-em-btn-text {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.cl-em-aei-text {
  color: #013ea5;
  font-size: 14px;
  font-weight: 600;
}

.cl-em-white-cont {
  background-color: #ffffff;
  margin: 20px;
  padding: 10px;
}

/* .all-client-form form input {
  width: 19vw;
} */

.all-client-form form p {
  font-size: 13px;
}

.all-client-form form {
  padding: 12px 8px;
}
.all-client-form {
  width: 100%;
}

.width-100 {
  width: 100% !important;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.w-91 {
  width: 91%;
}

.linked-member-cont {
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
  border: 0.5px solid #8f8e8d;
  background-color: transparent;
}

.linked-member-text {
  font-size: 10px;
  color: #555555;
  font-weight: 500;
}

.ce-field-row {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.ce-add-button-cont {
  margin: 0px 14px;
  padding-bottom: 10px;
  width: 99%;
}

.emp-field-div {
  width: 65%;
}

.emp-line {
  border-top: 1px solid #9b9b9b;
  margin: 10px 0px 20px;
}

.cj-job-status-text {
  color: rgb(51, 170, 218);
  font-size: 12px;
}

.cj-status-cross-row {
  display: flex;
  align-items: center;
}

.input-number-field {
  width: 100% !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.dropdown-options-width {
  width: 330px !important;
}
.dropdown-options-width-search {
  width: 150px !important;
}

.mobile-field-margin-left {
  margin-left: 5px !important;
  margin-top: 12px !important;
}

.mobile-field-margin-left .ant-input-number {
  width: 100% !important;
}

.cv-date-row {
  display: flex;
  align-items: center;
}

.cv-date-label {
  width: 175px;
  font-size: 10px;
}

.emp-input-border .ant-form-item {
  margin-bottom: 0px !important;
}

.cv-detail-ppi-date {
  background-color: #f5f5f5;
  padding: 15px;
  margin: 0px 40px 40px;
  border-radius: 10px;
}

.save-button-add-client {
  display: flex !important;
  justify-content: flex-end;
  margin-right: 19px;
  margin-bottom: 5px;
}

.dropdown-options-width-potential {
  width: 150px !important;
}

.task-blue {
  background: #1281b9 !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px !important;
}

body {
  background-color: #e9ecee;
}

.profile-first-box {
  background-color: #ffffff;
  /* margin: 10px 14px 20px 14px; */
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 20px;
  /* width: 99%; */
}

.profile-cont-left {
  width: 150px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 0.5px solid #e3e3e3;
  height: auto;
  margin-bottom: -20px;
}

.profile-img-cont {
  border: 3px solid #1085bb;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -30px !important;
}

.profile-img {
  width: 100px;
  height: 100px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h5 {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #1182bc;
}

.svg-img {
  width: 15px;
  height: 15px;
}

.svg-btn-img {
  width: 13px;
  height: 13px;
}

.right-cont {
  width: calc(100% - 150px);
}

.profile-puq-cont {
  display: flex;
  padding: 15px 10px 0px 10px;
  justify-content: space-between;
}

.profile-print-box {
  background-color: #36b1e4;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.profile-print-icon {
  width: 15px;
  height: 14px;
}

.date-div {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 20px 16px;
  width: calc(100% - 50px);
}
.date-text {
  font-size: 12px;
  color: #889093;
}

.lv-main-cont {
  display: flex;
  width: calc(100% - 40px);
  justify-content: space-between;
  padding: 0px 10px;
}

.label-value-cont {
  display: flex;
  border: 0.5px solid #c8c8c8;
  height: 21px;
  width: 30%;
  border-radius: 4px;
  padding-right: 1px;
  box-shadow: 0px 0px 5px 0px rgba(235, 232, 235, 1);
}

.label-cont {
  background-color: #e6eaec;
  height: 19px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
  border-right: 1px solid #929292;
  width: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.label-text {
  color: #788892;
  font-size: 10px;
  font-weight: 600;
}

.profile-inner-emailicon-cont {
  background-color: #35b2e4;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  border-radius: 3px;
}

.profile-updbtn-cont {
  background-color: #45bb56;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid #08a576;
  display: flex;
  align-items: center;
}

.profile-updbtn-text {
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

.profile-down-arrow-cont {
  display: flex;
  background-color: #a2d950;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.profile-down-arrow-icon {
  width: 9px;
  height: 9px;
  margin-top: 1px;
}

.profile-btn-img {
  width: 20px;
  height: 20px;
}

.value-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 39px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.value-text {
  font-size: 10px;
  color: #9ba6ac;
}

.buttons-row {
  margin: 20px 10px;
  display: flex;
}

.black-button {
  width: 65px;
  background-color: #535e62;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px 0px;
}

.black-button-text {
  color: #ffffff;
  font-size: 12px;
}

.cross-cont {
  background-color: #dc2f36;
  width: 65px;
  height: 30px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.right-green-btn-cont {
  background-color: #98d14b;
  width: 65px;
  height: 30px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.agent-tag-cont {
  display: flex;
  padding: 10px;
}

.agent-tag {
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-text {
  margin-left: 10px;
  font-size: 10px;
  color: #7c8992;
}

.profile-additional-box {
  background-color: #ffffff;
  margin: 20px 0px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 10px;
  /* width: 99%; */
}

.form-cont {
  padding: 10px 20px;
  display: flex;
}

.input-cont-width {
  width: 48%;
}

.form-btn-cont {
  display: flex;
}

.button-first {
  background-color: #3fb5cf;
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
}
.client-tag-btn {
  background-color: #40d2b3;
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 5px;
  display: inline-block;
  margin-left: 5px;
}
.main-tags {
  margin: auto;
}

.button-second {
  background-color: #40d2b3;
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: 10px;
  display: inline-block;
  margin-left: 5px;
}

.button-first-second-row {
  display: flex;
  align-items: center;
}

.btn-cross-icon {
  width: 8px;
  height: 8px;
}

.btn-fst-scnd-text {
  font-size: 10px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  margin-left: 5px !important;
}

.radio-label {
  color: #555555;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.divided-box {
  width: 47%;
}

.denied-cont {
  padding: 20px 20px 0px 20px;
  display: flex;
}

.detailed-text {
  font-size: 12px;
  margin-left: 40px;
  color: #0a3c5f;
  font-weight: 500;
}

.medical-label {
  margin-top: 8px;
  margin-bottom: 9px;
  font-weight: 500;
}

.denied-text {
  font-size: 12px;
  font-weight: 600;
}

.profile-input-with-icon {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.profile-refresh-cont {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
  margin-left: 5px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.label {
  color: #00003a;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0px;
}

.button-org-cont {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.button-org {
  border: 1px solid #ed441f !important;
  padding: 4px 12px !important;
  border-radius: 5px !important;
  background-color: #ed441f !important;
  display: flex !important;
  height: auto !important;
}

.header-title {
  padding: 30px 40px 0px 20px;
}

.form-container {
  background-color: #f5f7f8;
  margin: 20px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
}

.form {
  background-color: #f5f7f8;
}

.profile-calendar-icon {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.profile-input {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 100%;
}

.profile-input-login {
  padding-top: 16px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  font-size: 16px !important;
  border: none !important;
  border-radius: 4px !important;
  outline: none !important;
}

.profile-input-border {
  border: 0.8px solid #c9c9ca !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #ffffff !important;
}

.select-options {
  border: 0.8px solid #c9c9ca;
  border-radius: 4px;
}

.button-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.button {
  border: 1px solid #1179af;
  padding: 5px 30px;
  border-radius: 5px;
}

.visa-table {
  width: calc(100% - 0px);
  padding: 20px;
}

.visa-table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fb;
  border-bottom: 1px solid #e2e2e3;
}

.authority-table-head-font {
  font-size: 10px;
  font-weight: 500;
}

.auth-text {
  font-size: 10px;
}

.remove-cont {
  border: 1px solid #40b5d0;
  padding: 5px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-text {
  font-size: 10px;
}

.button-blue-cont {
  padding: 0px 10px;
  width: 80px;
}

.button-blue {
  border: 1px solid #1281b9 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: #1281b9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #ffffff !important;
}

.visa-type-text {
  font-size: 12px;
  font-weight: 600;
  color: #2a6282;
}

.visa-date-text {
  font-size: 12px;
  color: #b3b9bc;
}

.invoice-status-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px 3px;
}

.pdf-file-btn {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  margin-top: 12px;
  margin-bottom: -10px;
  margin-right: -60px;
}

.case-priority-btn {
  width: 40px;
  background-color: #3fb6cd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px 0px;
}

.case-priority-text {
  font-size: 10px;
  color: #ffffff;
}

.invoice-status-text {
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  word-break: break-all;
}

.invoice-status-text-due-over {
  font-size: 10px;
  color: #ffffff;
  text-align: center;
}

.pdf-file-text {
  font-size: 10px;
  text-decoration: none;
  text-align: center;
  word-break: break-all;
}

.case-priority-cross {
  background-color: #9d0c0e;
  width: 40px;
  height: 15px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cp-green-btn-cont {
  background-color: #98d14b;
  width: 40px;
  height: 15px;
  margin-top: 5px;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.case-priority {
  font-size: 11px;
  color: #2a6282;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__divider {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}

.react-date-picker__inputGroup__input {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}

.white-box {
  background-color: rgb(255, 255, 255);
  width: 10px;
  height: 10px;
  /* margin-right: 15px; */
}

.profile-progressbar-cont {
  padding: 20px 10px 0px;
}

p {
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}

.profile-input-border .ant-form-item {
  margin-bottom: 0px !important;
}

.ant-picker {
  width: 100% !important;
}

.add-partner-btn {
  border: 1px solid #53a8cc !important;
  padding: 20px !important;
  border-radius: 5px !important;
  background-color: #53a8cc !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.add-member-btn {
  border: 1px solid #53a8cc !important;
  /* padding: 20px !important; */
  border-radius: 5px !important;
  /* background-color: #53a8cc !important; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.cf-tags-cont {
  display: inline-block;
  padding: 15px;
  padding-bottom: 0px;
}

.ant-upload-select-picture-card {
}

.form-item-email {
  width: 100%;
  grid-row-gap: 0px;
  row-gap: 0px;
  margin-right: 10px !important;
  border-radius: 4px;
  margin-top: 7px !important;
}

.form-item-email .ant-form-item-control-input {
  min-height: 30px;
}

.form-item-email .ant-form-item-control-input {
  border: 0.8px solid #c9c9ca !important;
}

.form-mobile-number {
  display: flex;
  margin-left: -31px;
  margin-top: -14px;
}

.form-mobile-number .ant-form-item {
  margin-bottom: 0px !important;
}

.file-notes-container {
  background-color: #ffffff;
  margin: 40px 0px 0px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 25px 24px;
  width: 100%;
}

.container-bar {
  background-color: #f6f7f8;
  height: 100px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
}

.button-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.button-container-cont {
  padding: 20px;
  display: flex;
}

.button {
  border: 1px solid #1179af;
  padding: 5px 30px;
  border-radius: 5px;
}

.file-notes-content {
  width: 100%;
  padding: 5px 0px;
}

.file-content-index {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
}

.activity-content-index {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
}

.activity-content-index:hover {
  background-color: #ffffff;
}

.file-content-index-cont {
  padding: 15px 20px;
  background-color: #fafafa;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
}

.file-content-index-row {
  display: flex;
  justify-content: space-between;
}

.title {
  color: #34b0e1;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}

.text-area {
  width: 100%;
  margin: 15px 0px;
  height: 100px;
}

.date-time {
  color: #768790;
  font-size: 10px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.file-content {
  color: #1280b8;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  padding: 3px 0px 5px;
  font-weight: 600;
}

.table-file-content {
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
}

.content-index {
  width: 20%;
}

.delete-cont {
  justify-content: center;
  /* align-items: center; */
  display: flex;
  margin-top: 10px;
}

.delete-icon {
  display: flex;
  border: 1px solid #ccc;
  width: 27px;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
}

.button-show-all {
  border: 1px solid #eb441f;
  padding: 5px 30px;
  border-radius: 5px;
  background-color: #eb441f;
}

.right-nav-div {
  background-color: #f3f4f5;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  margin: 10px 20px 0 30px;
}

.right-nav-contant {
  border-bottom: 1px solid #e8eaec;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.detail-button {
  margin-left: 10px;
  border: 1px solid #1281b1;
  padding: 2px 7px;
  border-radius: 3px;
  background-color: #1281b1;
  display: flex;
}

.detail-btn-text {
  color: rgb(255, 255, 255);
  font-size: 10px;
}

.client-doc {
  border: 1px solid #ffa037;
  padding: 3px 10px;
  border-radius: 12px;
  background-color: #ffa037;
  display: flex;
}

.fn-normal-text {
  color: #53626c;
  margin-left: 0;
  font-size: 9px;
}

.right-bar-icon {
  width: 20px;
  height: 20px;
}

.right-bar-text {
  font-size: 10px;
  color: #093b5c;
  font-weight: 600;
  text-align: center;
}

.add-file-note-btn {
  padding: 5px 15px;
  border-radius: 3px;
  background-color: #4b91ff;
  display: flex;
}

.file-notes-editor-cont {
  margin-top: 20px;
  margin-bottom: 20px;
}

.file-notes-editor-cont .fr-wrapper {
  height: 175px !important;
}

.ad-row-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ad-paid-text-cont-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.ad-paid-text-cont {
  display: flex;
  border: 1px solid #ffffff;
  padding: 2px 10px;
  border-radius: 20px;
}

.ad-paid-text {
  color: #ffffff;
  font-size: 9px;
  font-weight: 600;
}

.ad-btn-row {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}

.ad-save-btn-cont {
  background-color: #1281b9;
  padding: 5px 5px;
  border-radius: 3px;
  display: flex;
  width: 40px;
  justify-content: center;
}

.ad-save-btn-text {
  font-size: 10px;
  color: #ffffff;
}

.ad-pp-heading-text {
  color: #1282ba;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
}

.ad-table-content {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  padding: 7px 15px;
  border-bottom: 1px solid #ededed;
}

.ad-form-container {
  background-color: #f5f7f8;
  margin: 20px 10px 0px 10px;
  border-radius: 5px;
  padding: 5px 20px 15px;
}

.ad-input-row {
  display: flex;
  justify-content: space-between;
}

.ad-date-row {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.ad-date-text {
  font-size: 10px;
  color: #ffffff;
}

.ad-upd-button {
  border: 1px solid #40b5ce !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: transparent !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ad-right-sidebar-hide {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.ad-right-sidebar-hide-text {
  color: #53a8cc;
  font-size: 13px;
  font-weight: 500;
}

.ad-button-width {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.acc-table-head-row {
  display: flex;
  align-items: center;
}

.acc-color-row-text {
  color: #04214b;
  font-size: 10px;
  font-weight: 500;
  margin-left: 8px;
}

.acc-due-over-due-table-cont {
  width: 90%;
  height: 100%;
  margin: 35px auto;
  border-radius: 3px;
}

.ts-container-settings {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 24px 7px 30px;
  border-radius: 3px;
}

.ts-container-settings-graph-accounts {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 3px;
}

.settings-heading {
  margin-left: 28px !important;
  margin-top: 20px !important;
}

.acc-saved-cont {
  background-color: #ff9f36;
  padding: 1px 15px;
  border-radius: 20px;
}

.acc-color-text {
  color: #ffffff;
  font-size: 13px;
}

.acc-actions-button {
  color: #120bab;
  font-size: 13px;
  font-weight: 500;
}

.acc-search-cont {
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 0%);
  background-color: #ffffff;
  margin: 35px 25px;
  height: 100%;
  border-radius: 3px;
}

.acc-search-head-cont {
  padding-top: 20px;
}

.acc-search-text {
  font-size: 14px;
  color: #075980;
  font-weight: 600;
  margin-left: 20px;
}

.acc-search-input-cont {
  width: 95%;
  height: 100%;
  margin: 0px auto;
  border-radius: 3px;
  padding-top: 20px;
}

.remove-account {
  position: absolute !important;
  right: 10px;
  top: 3px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #000 !important;
  font-weight: bold;
}
.userDetail {
  position: relative !important;
}

.cm-profile-cont {
  border-radius: 50%;
  margin-top: -12px;
  border: 0px solid #007eba;
  display: flex;
  width: 120px;
  height: 120px;
}

.cm-profile-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #1085bb;
}

.label-name-cont {
  display: flex;
}

.label-blue {
  color: #006099;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.cm-profile-fill-cont {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: 10px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  width: 24px;
  height: 24px;
}

.cm-profile-fill {
  width: 12px;
  height: 12px;
}

.cm-active-status {
  background-color: #00aff0;
  display: flex;
  width: 75px;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: 5px;
}

.cm-active-text {
  font-size: 12px;
  color: #ffffff;
}

.cm-id-text {
  font-size: 12px;
  color: #9da2a7;
}

.cm-blue-btn {
  /* box-shadow: 0px 0px 5px 0px rgba(232, 229, 232, 1); */
  padding: 5px 10px;
  display: flex;
  align-items: center;
  /* background-color: #0171bb; */
  border-radius: 5px;
  margin-left: 3px;
}

.cm-blue-btn-text {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}

.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 40px;
  width: 500px;
}

.progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #000;
  height: 30px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 68%;
  }
}

.cm-visa-cont {
  background-color: #e8eced;
  margin: 10px 20px;
  border-radius: 10px;
}

.cm-student-visa-cnt {
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm-student-visa-text {
  color: #255d8e;
  font-size: 14px;
  font-weight: 500;
}

.cm-status-approved {
  /* background-color: #9D0C0E; */
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.cm-status-completed {
  background-color: #3ab449;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.cm-status-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cm-checklist-label {
  margin-left: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #2d5d84;
}

.cm-header-text {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.cm-clintlist-content {
  color: #255d8e;
  font-size: 12px;
  font-weight: 500;
}

.cm-childlist-table {
  width: calc(100% - 40px);
  padding: 20px;
}

.cm-main-table {
  border: 1px solid #e2e2e3;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}

.cm-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 2px solid #cccccc;
}

.cm-header-font {
  font-size: 16px;
  color: #093d6a;
  font-weight: 600;
}

.cm-icon-size {
  width: 13px;
  height: 13px;
}

.cm-green-box {
  width: 20px;
  height: 20px;
  background-color: #fafafa;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.cm-greenbox-cont {
  display: flex;
  margin-left: 0px;
  align-items: center;
}

.cm-task-text {
  margin-left: 10px;
  color: #266090;
  font-size: 12px;
  font-weight: 500;
}
.cm-task-comment {
  color: #266090;
  font-size: 14px;
  font-weight: 500;
}
.cm-table-content-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e3;
  align-items: center;
  margin: 15px 25px;
  padding-bottom: 10px;
}

.cm-followers-text {
  color: #416d99;
  font-size: 12px;
}

.cm-textarea {
  width: calc(100% - 50px);
  margin-left: 25px;
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.cm-gray-btn-cont {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  padding-bottom: 25px;
}

.cm-gray-btn {
  background-color: #cccccc;
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.cm-gray-btn-text {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.cm-client-cl-status {
  /* display: flex;
  justify-content: space-between; */
  padding: 20px 0px;
}

.cm-add-btn-cont {
  width: 210px;
  display: flex;
  align-items: center;
  margin-top: 45px;
}

.cm-add-btn {
  background-color: #0172bb;
  padding: 5px 25px;
  display: flex;
  height: 15px;
}

.cm-add-btn-text {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}

.cm-down-arrow {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.p-text {
  color: #005400;
  font-size: 16px;
  font-weight: 500;
}
.file-notes-container {
  margin: 0px 15px 0px 15px;
}
.ant-list-item-meta-title {
  line-height: 1;
}
.top-blubar {
  background-color: #083c5d;
  padding: 20px 28px;
  display: block;
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.top-row {
  display: block !important;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  background: white;
  margin: 20px 15px 20px 15px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 75%);
  padding-bottom: 5px;
  position: relative;
  border-radius: 4px;
}
.top-row-button {
  display: flex;
  position: absolute;
  right: 30px;
  top: 30px;
}
.container {
  background-color: transparent;
  margin: 0;
  box-shadow: none;
  padding-bottom: 20px;
  border-radius: 2px;
}
.cm-add-btn {
  height: 30px;
}
.ant-form-item {
  margin-bottom: 5px !important;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: fixed !important;
  top: 120px !important;
}
.remove-client {
  position: absolute !important;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  font-size: 22px;
  color: #a8a7a5 !important;
  font-weight: bold;
}

.client-card {
  position: relative !important;
}
.cm-table-content-rows {
  display: block;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e3;
  align-items: center;
  margin: 15px 25px !important;
  padding-bottom: 10px;
}
.task-description-cm {
  color: rgb(38, 96, 144);
  font-size: 12px;
  font-weight: 600;
  word-break: break-all;
  margin: -10px 25px 5px 25px;
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: block;
}
.cm-profile-fill-conts {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin-right: 10px;
}
.cm-profile-boxes {
  display: flex;
  margin-bottom: 5px;
}
.cm-profile-fills {
  width: 24px;
  height: 24px;
}
.cm-task-texts {
  margin-left: 0px;
  color: #266090;
  font-size: 16px;
}
.cm-task-text:hover {
  cursor: pointer;
  color: #000;
}
.list-heading {
  padding: 10px;
  background-color: #1179af;
  display: flex;
  justify-content: space-between;
}
.list-heading h1 {
  color: white;
  margin: 0;
}
.cm-childlist-table {
  width: calc(100% - 0px) !important;
}
.cm-cross {
  position: absolute;
  right: 0;
  top: 0;
}
.advance-search-area {
  background-color: #f3f3f3;
  padding: 10px;
  margin: 10px 0;
}
.cm-form-input {
  margin-right: 30px;
}
.cm-form-input label {
  font-weight: 500;
  margin-right: 15px;
  color: #083c5d;
  font-size: 14px;
}
.cm-form-input input {
  width: 200px;
  border-radius: 5px;
}
.advance-search-area .ant-btn {
  background: #1179af;
  color: white;
  border-radius: 6px;
  font-size: 16px;
  height: 36px;
  line-height: 1;
  width: 110px;
  font-weight: 400;
}
.cm-form-date .ant-picker {
  width: 200px !important;
  border-radius: 5px;
}
.cm-form-date label {
  font-weight: 500;
  margin-right: 15px;
  margin-left: 15px;
  color: #083c5d;
}
.cm-form-date {
  display: flex;
}
.cm-search-area {
  /* background-color: #083c5d; */
  display: flex;
  padding: 8px 0;
}
.cm-pagination {
  margin-bottom: 30px;
  text-align: end;
  margin-right: 15px;
}
.ant-popover-inner-content .cm-profile-fill {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}
.ant-popover-placement-bottom .ant-popover-title {
  min-width: 95px !important;
  text-align: center;
}
/* .ant-popover-open{
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-left: 10px;
    box-shadow: 0px 0px 5px -2px rgb(0 0 0 / 75%);
} */
.cm-blue-btn .ant-btn {
  background: #1281b9;
  border-color: #1281b9;
  font-size: 11px;
  border-radius: 5px;
  font-weight: 700;
}
.cm-popover {
  text-align: center;
}
.adv-btn .ant-btn {
  box-shadow: 0px 1px 9px -2px rgb(0 0 0 / 24%);
  border: none;
  border-radius: 0px !important;
  margin: 0 5px;
}
.select-options {
  /* border: none !important; */
  /* margin-right: 5px; */
}

.select-options-technical-support {
  border: none !important;
}
.select-options .ant-select-arrow {
  top: 40%;
}
.select-options .ant-btn {
  width: 275px;
  text-align: inherit;
}
.select-options .anticon {
  float: right;
  margin-top: 6px;
}
.profile-container {
  padding-top: 30px;
}
.cm-search-area .ant-select-single .ant-select-selector {
  border: none !important;
}
.cm-search-area .ant-input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #eee;
}
.cm-search-area .ant-input-search-button {
  font-size: 14px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-left: 0px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  background: white;
  color: #aaa;
  box-shadow: none !important;
  text-shadow: none;
  padding: 0px 12px;
}
.cm-search-area
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child {
  left: 0px !important;
  padding: 0;
  border: 0;
}
.content-index-100 {
  border-bottom: 1px solid #e2e2e3;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.comment-bg {
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
}

/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #edeff2;
  font-family: "Calibri", "Roboto", sans-serif;
}

/* .chat_window {
  position: absolute;
  width: calc(100% - 20px);
  max-width: 800px;
  height: 500px;
  border-radius: 10px;
  background-color:#fbfbfb;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
  overflow: hidden;
  height: calc(100vh - 120px);
} */

.top_menu {
  background-color: #fff;
  width: 100%;
  padding: 13px 0px;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}
.top_menu .buttons {
  margin: 3px 0 0 20px;
  position: absolute;
}
.top_menu .buttons .button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.top_menu .buttons .button.close {
  background-color: #f5886e;
}
.top_menu .buttons .button.minimize {
  background-color: #fdbf68;
}
.top_menu .buttons .button.maximize {
  background-color: #a3d063;
}
.top_menu .title {
  text-align: center;
  color: #337ab7;
  font-size: 20px;
}

.messages {
  position: relative;
  list-style: none;
  padding: 20px 10px 0 10px;
  margin: 0;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
.messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}
.messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}
.messages .message.left .text_wrapper {
  background-color: #74d0ff;
  margin-left: 20px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
}
/* .messages .message.left .text_wrapper::after, .messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #74d0ff;
} */
.messages .message.left .text {
  color: #c48843;
}
.messages .message.right .avatar {
  background-color: #fdbf68;
  float: right;
}
.messages .message.right .text_wrapper {
  background-color: #ebebeb;
  margin-right: 20px;
  float: right;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
}
/* .messages .message.right .text_wrapper::after, .messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #ebebeb;
} */
.messages .message.right .text {
  color: black;
}

.messages .message.left .text {
  color: black;
  font-weight: inherit;
}

.messages .message.appeared {
  opacity: 1;
}
.messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
.messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 85px);
  min-width: 100px;
  position: relative;
}
.messages .message .text_wrapper::after,
.messages .message .text_wrapper:before {
  top: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.messages .message .text_wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}
.messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}
.messages .message .text_wrapper .text {
  font-size: 15px;
  font-weight: 500;
}

.bottom_wrapper {
  position: relative;
  width: 90%;
  background-color: #fbfbfb;
  border: 1px solid #bcbdc0;
  border-radius: 30px;
  margin: 20px auto;
  height: 50px;
}
.bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 48px;
  border-radius: 25px;
  border: none;
  width: calc(100% - 100px);
  position: relative;
  padding: 0px 0px 0px 30px;
}
.bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  outline-width: 0;
  color: gray;
  background: #fbfbfb;
}
.bottom_wrapper .send_message {
  width: 75px;
  height: 48px;
  display: inline-block;
  border-radius: 50px;
  background-color: #fbfbfb;
  border: 2px solid #fbfbfb;
  color: #1890ff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: right;
  margin-right: 1px;
}
.bottom_wrapper .send_message:hover {
  color: #20b2aa;
  background-color: #fff;
}
.bottom_wrapper .send_message .text {
  font-size: 28px;
  font-weight: 700;
  display: inline-block;
}

.message_template {
  display: none;
}

#msg_input {
  color: black;
}
/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: "FontAwesome", sans-serif;
}
/* iconicstroke */
[class*="iconicstroke-"]:before {
  font-family: "IconicStroke", sans-serif;
}
/* typicons */
[class*="typicons-"]:before {
  font-family: "Typicons", sans-serif;
}
body {
  font-family: arial, sans-serif;
}
.chat-container {
  width: 300px;
  margin-right: 20px;
  float: right;
  overflow: hidden;
}
.top-header {
  background: #666;
  color: white;
  padding: 0.2rem;
  position: relative;
  overflow: hidden;
  border-bottom: 4px solid #35ac19;
  cursor: pointer;
}
.top-header:hover {
  background-color: #35ac19;
}
.top-header-tit {
  display: inline;
  font-size: 14px;
}
.top-header .typicons-message {
  display: inline-block;
  padding: 2px 5px 2px 5px;
  font-size: 20px;
  position: relative;
  top: 5px;
}
.top-header .typicons-minus {
  position: relative;
  top: 3px;
  font-size: 15px;
  cursor: pointer;
}
.top-header .typicons-plus {
  position: relative;
  top: 3px;
  font-size: 15px;
}
.top-header .typicons-times {
  position: relative;
  top: 3px;
  font-size: 15px;
}
.top-header .left {
  float: left;
}
.top-header .right {
  float: right;
  padding-top: 5px;
}
.top-header > * {
  position: relative;
}
.top-header::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  bottom: -100%;
  opacity: 0.25;
  background-color: #404040;
}
.chat-box {
  list-style: none;
  background: #e5e5e5;
  margin: 0;
  padding: 0 0 50px 0;
  height: 280px;
  overflow-y: auto;
}
.chat-box li {
  padding: 0.5rem;
  overflow: hidden;
  display: flex;
}
.chat-box .avatar-icon {
  width: 40px;
  position: relative;
}
.chat-box .avatar-icon img {
  display: block;
  width: 100%;
  background-color: #1469a6;
}
.ibo .avatar-icon:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.me {
  justify-content: flex-end;
  align-items: flex-end;
}
.me .messages {
  order: 1;
  border-bottom-right-radius: 0;
}
.me .avatar-icon {
  order: 2;
}
.me .avatar-icon:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.messages {
  background: #fbfbfb;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.messages p {
  font-size: 0.8rem;
  margin: 0 0 0.2rem 0;
}
.messages time {
  font-size: 0.7rem;
  color: #ccc;
}
.setting {
  background-color: #e5e5e5;
  height: 32px;
  padding-top: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.setting .left {
  float: left;
}
.setting .right {
  float: right;
}
.iconicstroke-user {
  font-size: 22px;
  position: relative;
  top: 4px;
  left: 10px;
  color: #414141;
  cursor: pointer;
}
.typicons-cog {
  font-size: 23px;
  position: relative;
  top: 7px;
  right: 4px;
  color: #414141;
  cursor: pointer;
}

.font-family {
  font-family: "Poppins", sans-serif !important;
}
.fontawesome-facetime-video {
  font-size: 18px;
  position: relative;
  top: 3px;
  color: #414141;
  left: 5px;
  cursor: pointer;
}
.iconicstroke-user:hover,
.typicons-cog:hover,
.fontawesome-facetime-video:hover {
  color: #000000;
}
::-webkit-scrollbar {
  height: 14px;
  width: 10px;
  background: #eee;
  border-left: 1px solid #ddd;
}
::-webkit-scrollbar-thumb {
  background: #ddd;
  border: 1px solid #cfcfcf;
}
::-webkit-scrollbar-thumb:hover {
  background: #b2b2b2;
  border: 1px solid #b2b2b2;
}
::-webkit-scrollbar-thumb:active {
  background: #b2b2b2;
  border: 1px solid #b2b2b2;
}

/************
    * response  *
    ************/
#response {
  width: 320px;
  background-color: rgba(255, 255, 255, 0.8);
}
.say {
  width: 220px;
  padding: 8px 15px;
  background: rgba(50, 50, 50, 0.2);
  border: 0px solid #dbdbdb;
}
.saybtn {
  position: relative;
  padding: 6px 15px;
  left: -8px;
  border: 2px solid #207cca;
  background-color: #207cca;
  color: #fafafa;
}
.saybtn:hover {
  background-color: #409cea;
}

.ace_editor {
  border: 1px solid lightgray;
  margin: auto;
  height: 200px;
  width: 80%;
}
.scrollmargin {
  height: 80px;
  text-align: center;
}
.view {
  border: 1px solid lightgray;
  margin: auto;
  width: 80%;
}
.view div span {
  margin: 20px;
  display: inline-block;
}
.view div span span {
  margin: 0px;
  display: inline;
}
.output {
  min-height: 70px;
}
.TitleBar {
  margin: auto;
  width: 80%;
  border: 1px solid lightgray;
  height: 30px;
  border-bottom: 0px;
  background-color: #fcfafa;
  font-size: 20px;
}
.TitleBar b {
  margin: 15px;
  color: #454444;
}
.actionbar {
  margin: auto;
  width: 80%;
}
#statusBar {
  margin: auto;
  width: 80%;
  border: 1px solid lightgray;
  height: 20px;
  border-top: 0px;
}

.ace_status-indicator {
  color: gray;
  float: right;
  right: 0;
  border-left: 1px solid;
}
.submit {
  float: right;
  border: 1px solid #aaa;
  margin-left: 10px;
  padding: 4px;
  cursor: pointer;
}

.blinking-cursor {
  font-weight: 500;
  font-size: 15px;
  color: #2e3d48;
  animation: 1s blink step-end infinite;
}

@keyframes "blink" {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
#submit:disabled,
#SubmitMCQ :disabled {
  background-color: white;
}
#submit,
#SubmitMCQ {
  background: #2cbb0f;
  color: white;
}
#submit:hover,
#SubmitMCQ:hover {
  background: white;
  color: #2cbb0f;
}
.submit:hover {
  box-shadow: 1px 1px 3px gray;
}
.submit:disabled {
  box-shadow: unset;
  cursor: unset;
  color: lightgrey !important;
  background-color: #f6f6f6 !important;
}
#run {
  background-color: #4c9ed9;
  color: white;
}
#compiler::first-letter {
  text-transform: uppercase;
}
@media only screen and (max-height: 480px) {
  .chat-box {
    height: calc(100vh - 180px);
  }
}
#response {
  width: calc(100% + 20px);
}
@media only screen and (max-width: 340px) {
  .chat-container {
    width: calc(100vw - 20px);
    max-width: 300px;
    margin: auto 10px;
  }
  .say {
    width: calc(100% - 100px);
  }
}
.view {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

#QI {
  overflow-x: hidden;
}

.slide {
  opacity: 0;
  position: relative;
  font-family: Arial;
  font-size: 14px;
  /*line-height: 50px;*/
  right: -100%;
  width: 100%;
}

/*.choice {
    -webkit-column-count: 2; 
    -moz-column-count: 2; 
    column-count: 2;
}*/
.MCanswer {
  display: inline-block;
  vertical-align: top;
}
.tab-container {
  background: #f1f1f1;
  /* margin: 0; */
  padding: 0;
  /* max-height: 35px; */
  /* position: relative; */
  overflow: hidden;
}

ul.tabs {
  margin: 0;
  list-style-type: none;
  line-height: 35px;
  max-height: 35px;
  overflow: hidden;
  display: inline-block;
  padding: 0;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
}

ul.tabs > li.active {
  z-index: 2;
  background: white;
}

ul.tabs > li.active:before {
  border-color: transparent #efefef transparent transparent;
}

ul.tabs > li.active:after {
  border-color: transparent transparent transparent #efefef;
}

ul.tabs > li {
  float: left;
  margin: 5px -10px 0;
  border-top-right-radius: 25px 170px;
  border-top-left-radius: 20px 90px;
  padding: 0 30px 0 25px;
  height: 170px;
  background: #ddd;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  max-width: 200px;
}

ul.tabs > li > a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #222;
}

ul.tabs > li:before,
ul.tabs > li:after {
  content: "";
  background: transparent;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border-width: 10px;
  top: 0px;
  border-style: solid;
  position: absolute;
}

ul.tabs > li:before {
  border-color: transparent #ddd transparent transparent;
  left: -23px;
  transform: rotate(48deg);
}

ul.tabs > li:after {
  border-color: transparent transparent transparent #ddd;
  transform: rotate(-48deg);
  right: -17px;
}

/* Clear Fix took for HTML 5 Boilerlate*/
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.clearfix:after,
.clearfix:before {
  /* -webkit-box-sizing: unset;
  -moz-box-sizing: unset; */
  /* box-sizing: unset; */
}

* {
  /* -webkit-box-sizing: unset;
  -moz-box-sizing: unset; */
  /* box-sizing: unset; */
}
body {
  line-height: unset;
}
pre {
  padding: unset;
  border-radius: unset;
}
#modal-backdrop {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.53);
  z-index: 10000;
  position: fixed;
  top: 0px;
  left: 0px;
}
#modal {
  border-radius: 4px;
  background: white;
  width: 500px;
  margin: auto;
  top: 100px;
  position: relative;
  color: #757575;
  overflow: hidden;
}
#modal-header {
  min-height: 20px;
  width: 100%;
  font-size: 16px;
  font-weight: 100;
  border-bottom: 1px solid lightgray;
  padding: 12px;
  /*text-shadow: 1px 1px 1px rgba(114, 119, 112, 0.8);*/
}
#modal-body {
  min-height: 20px;
  width: 100%;
  padding: 5px;
  overflow: hidden;
}
#modal-footer {
  min-height: 20px;
  width: 100%;
  border-top: 1px solid lightgray;
  padding: 5px;
}
#modal-footer #next {
  cursor: unset;
  color: white;
  background-color: #4caf50;
  float: right;
  margin: 5px;
  margin-right: 8px;
  border: unset;
  padding: 6px;
  border-radius: 1px;
}
#modal-footer #next:hover {
  background-color: #ffffff;
  color: #4caf50;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#modal-footer #skip {
  cursor: unset;
  color: white;
  background-color: #ff622c;
  float: right;
  margin: 5px;
  margin-right: 8px;
  border: unset;
  padding: 6px;
  border-radius: 1px;
}
#modal-footer #skip:hover {
  background-color: white;
  color: #ff622c;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.skip:disabled {
  box-shadow: unset;
  cursor: unset;
  color: lightgrey !important;
  background-color: #f6f6f6 !important;
}
.test-text {
  background-color: white;
  margin-left: 40px;
  margin-right: 479px;
  min-height: 58px;
}
#modal-body div {
  float: left;
  width: 166px;
  display: inline-block;
  /* possibly also height: 300px; */
}
#modal-body div p span {
  font-family: serif;
}

.ant-card {
  background: #fbfbfb !important;
  border-radius: 10px !important;
}
.ant-card-bordered {
  border: 1px solid #ccc !important;
}
.basic-single {
  border: 1px solid #ddd;
}
.chat-search-box .ant-input {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 30px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 30px !important;
  background: #ededed !important;
  height: 36px;
}
.chat-search-box .ant-btn {
  border-top-right-radius: 30px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 30px !important;
  border-bottom-left-radius: 0px !important;
  background: #ededed !important;
  color: #fff !important;
  height: 36px;
  width: 48px;
}
.chat-search-box .anticon-search {
  font-size: 24px !important;
  font-weight: 700 !important;
}
.unread-msg {
  background: #ee1b24;
  color: white;
  font-size: 18px;
  width: 24px;
  height: 24px;
  line-height: 1.4;
  text-align: center;
  border-radius: 50px;
  margin: 5px 0 0 10px;
}
.unread-msgs {
  background: #ee1b24;
  color: white;
  font-size: 12px;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50px;
  margin: 0px 5px 0 5px;
}
.chat-header .ant-btn-icon-only.ant-btn-lg {
  width: 32px;
  height: 32px;
  padding: 4px 0;
  font-size: 16px;
  border-radius: 2px;
}

.pc-top-btn-row {
  display: flex;
  margin: 40px 40px 20px;
}

.pc-btn-cont {
  background-color: #1081b9;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

.pc-btn-text {
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

.pc-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 10px 20px;
  border-radius: 3px;
}

.pc-input-width {
  width: 18%;
  margin-left: 8px;
}

.pc-text-field-row {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.pc-select-width {
  width: 22.2%;
  margin-left: 8px;
}

.pc-table-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  text-align: center;
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
}

.pc-table-icon-row {
  display: flex;
  justify-content: center;
}

.pc-icon-container {
  display: flex;
  border: 1px solid #d6d6d6;
  padding: 5px;
  border-radius: 5px;
}

/* ======= Potential clients inquiry questionnaire ======== */

.pciq-top-div {
  margin: 20px 20px 0px;
}

.pc-top-div-text {
  color: #6b6b6b;
  font-weight: 600;
  font-size: 14px;
}

.pciq-top-row {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 40px 15px 20px;
}

.pciq-org-box {
  background-color: #fbaf5c;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.pciq-org-box-text {
  font-size: 10px;
  color: #ffffff;
  font-weight: 500;
}

.pc-inactive-inner-tab {
  background-color: rgb(51, 170, 218);
  padding: 10px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.pc-active-inner-tab {
  background-color: #2e6da4;
  padding: 10px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.pc-text-inner-tab {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
}

.pc-normal-text-cont {
  padding: 20px 40px 10px;
}

.pc-bottom-org-box-cont {
  width: 130px;
  margin-left: 55px;
}

.pc-link-main-cont {
  display: flex;
  padding: 0px 55px;
  margin-top: 10px;
}

.pc-link-color-box {
  background-color: #998675;
  display: flex;
  padding: 5px;
  border-radius: 5px;
}

.pc-link-text {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 400px;
  display: inline-block;
}

.pc-language-cont {
  padding: 15px 40px;
}

.pc-radio-cont {
  display: flex;
  margin: 10px;
  align-items: center;
}

.pc-add-btn {
  background-color: #1281b9;
  padding: 5px 15px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

.pc-wa-web-inquiry-link-detailed-cont {
  background-color: #ffffff;
  margin: 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 50px 10px 80px;
  border-radius: 3px;
}

.buttons-container-potential-clients {
  margin-top: 42px;
  margin-left: 29px;
  border-radius: 40%;
}

.buttons-potential-clients {
  font-size: 11px;
  border-radius: 3px;
}

.filter-text {
  color: #1890ff;
}

.inputs-filters-container {
  width: 17%;
  margin-right: 5px;
}

.width-selects-filters {
  width: -webkit-fill-available !important;
}

.remove-select-border .ant-select-selector {
  border: 0px !important;
}

.remove-select-border .ant-select-selection-placeholder {
  color: #4ca0ff !important;
}

.ts-container-inquiry {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.p-emp-container {
  width: 97%;
  margin: auto;
}

.p-emp-table {
  background-color: #ffffff;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 19px 30px;
  border-radius: 3px;
}

.employer-txt {
  font-size: 18px;
  color: #073551;
  font-weight: 400;
}

.ts-container-client {
  background-color: #ffffff;
  margin: 0px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.ts-container-Pclient {
  background-color: #ffffff;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 17px;
}

.ts-container-school-invoices {
  background-color: #ffffff;
  margin: 22px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 21px 30px;
  border-radius: 3px;
}

.ts-container-employer-invoices {
  margin: 0px 20px 20px;
  padding: 30px 23px 13px;
  border-radius: 3px;
}

.date-from-container {
  width: 43% !important;
  display: flex !important;
  margin-left: 2px !important;
}

.date-to-container {
  width: 41% !important;
  display: flex !important;
  margin-left: 27px !important;
}

.date-text {
  width: 100px;
}

.checkbox {
  width: 15%;
  margin-right: 5px;
  margin-left: 19px;
  align-items: center;
  display: flex;
}

.filters-container-main {
  width: 21%;
  margin-right: 3px;
}

.filters-container-sub {
  width: 22%;
  margin-right: 3px;
}

.filters-container-sub-status {
  width: 29%;
  margin-right: 3px;
}

.add-new-client-heading {
  margin-top: 48px;
  margin-left: 19px;
  margin-bottom: -30px;
}

.add-new-client-heading-new-case {
  margin-top: 48px;
  margin-left: 19px;
}

.back-button {
  margin-left: 19px;
  margin-top: 46px;
  cursor: pointer !important;
}

.processing-persons-container {
  background-color: rgb(245 247 248) !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  border-radius: 6px;
}

.processing-persons-heading {
  margin-left: 71px;
  margin-bottom: 13px;
  color: #556370 !important;
}

.client-tags-form-label {
  font-weight: bold;
  font-size: small;
  margin-left: 2px;
}

.add-client-form-top-element {
  margin-left: 32px !important;
  margin-top: 31px !important;
}

.add-client-form-inputs {
  margin-left: 32px !important;
  margin-top: 0px !important;
}

.pclient-input-margins {
  margin-bottom: 36px !important;
}

.view-detail-link {
  color: #1890ff !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.view-country {
  color: #1890ff !important;
  cursor: auto !important;
  text-decoration: none !important;
}

.add-client-pinput {
  margin-left: 32px !important;
  /* margin-top: 12px !important; */
}

.questionnaire-option-width {
  width: 400px !important;
}

.quesitonnaire-action-buttons {
  display: inline-block;
  border: 1px solid;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #fff;
  border-color: #ccc;
  cursor: pointer;
  margin: 3px;
}

.quesitonnaire-action-buttons > span {
  color: #333;
}

.icon-container {
  width: 20%;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
}
.docIcon {
  width: 26%;
}
.tooltip {
  visibility: hidden; /* Or display: none, depending on how you want it to behave */
}

.tooltip-on-hover:hover + .tooltip {
  /* Uses the adjacent sibling selector */
  visibility: visible; /* Or display: block */
}
.yellow_box {
  background-color: #f5d04c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.green_box {
  background-color: #5cb85c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.blue_box {
  background-color: #46b8da;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.yellow_span {
  background-color: #f5d04c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.green_span {
  background-color: #5cb85c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.blue_span {
  background-color: #46b8da;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.white_span {
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.ant-table .ant-table-selection-col {
  width: 12px !important;
}
.employer-table .ant-table-cell {
  text-align: center !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.employer-tables .ant-table-cell {
  text-align: left !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.ant-table-column-sorters {
  padding: 0px 0px !important;
}
.selectType {
  width: 100%;
}

.docOption-width {
  width: 150px;
}
.docId {
  width: 10%;
}
.docNameInput {
  width: 40%;
}
.docInput {
  width: 100%;
}

.docNameType {
  width: 20%;
  text-align: center !important;
}
.docNameTitle {
  width: 20%;
}
.docNameAction {
  width: 20%;
}

.cv-action-icons-borderDocType {
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 29px;
  width: 27px;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;
  margin: 2px;
}

.folderClass {
  padding: 15px;
  width: 15%;
  display: flex;
  flex-direction: column;
  white-space: normal;
}

.icon-container {
  width: 20%;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
}
.docIcon {
  width: 26%;
}
.tooltip {
  visibility: hidden; /* Or display: none, depending on how you want it to behave */
}

.tooltip-on-hover:hover + .tooltip {
  /* Uses the adjacent sibling selector */
  visibility: visible; /* Or display: block */
}
.yellow_box {
  background-color: #f5d04c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.green_box {
  background-color: #5cb85c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.blue_box {
  background-color: #46b8da;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.yellow_span {
  background-color: #f5d04c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.green_span {
  background-color: #5cb85c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.blue_span {
  background-color: #46b8da;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.white_span {
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.ant-table .ant-table-selection-col {
  width: 12px !important;
}
.employer-table .ant-table-cell {
  text-align: center !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.employer-tables .ant-table-cell {
  font-family: Poppins, serif !important;
  color: #172b4d;
  text-align: left !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.ant-table-column-sorters {
  padding: 0px 0px !important;
}
.selectType {
  width: 100%;
}

.docOption-width {
  width: 150px;
}
.docId {
  width: 10%;
}
.docNameInput {
  width: 40%;
}
.docInput {
  width: 100%;
}

.docNameType {
  width: 20%;
  text-align: center !important;
}
.docNameTitle {
  width: 20%;
}
.docNameAction {
  width: 20%;
}

.cv-action-icons-borderDocType {
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 29px;
  width: 27px;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;
  margin: 2px;
}

.folderClass {
  padding: 15px;
  width: 15%;
  display: flex;
  flex-direction: column;
  white-space: normal;
}

.task-blue {
  background: #1281b9 !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.sm-newinvoice-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 0px 20px;
  border-radius: 3px;
}

.sm-row-cont {
  display: flex;
  align-items: center;
  margin: 25px 10px;
}

.sm-icon-border {
  border: 1px solid #d6d6d6;
  padding: 5px 4px 4px 6px;
  border-radius: 3px;
}

.sm-color-row-text {
  color: #04214b;
  font-size: 10px;
  font-weight: 500;
}

.report-container {
  background-color: #ffffff;
  margin: 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 10px 10px 50px;
  border-radius: 3px;
}

.report-btn-tab-row {
  display: inline-block;
  margin: 15px 15px 0px;
  width: 97%;
}

.report-active-btn-tab {
  background-color: #36b1e4;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  width: 17%;
  text-align: center;
  margin: 5px;
  cursor: pointer;
}

.report-inactive-btn-tab {
  background-color: #3ccfad;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  width: 17%;
  text-align: center;
  margin: 5px;
  cursor: pointer;
}

.report-table-first-row {
  display: flex;
  align-items: center;
}

.report-column-btn {
  background-color: #3ccfad;
  display: flex;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 5px;
}

.report-table-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  padding: 15px;
  text-align: center;
}

.report-total-main-cont {
  display: flex;
  justify-content: space-between;
  padding-right: 3px;
}

.report-total-cont {
  display: flex;
  justify-content: flex-end;
}

.report-total-text-div {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 5px;
}

.rep-rec-am-total-cont {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rep-cs-table-content-text {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
}

.rep-print-row-cont {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.rep-print-icon-cont {
  display: flex;
  border: 1px solid #bfbfbf;
  padding: 5px 3px;
  border-radius: 3px;
  margin-top: 10px;
}

.rep-print-icon {
  width: 15px;
  height: 15px;
}

/* =========== Potential clients reports ========= */

.pcr-active-btn-tab {
  background-color: #36b1e4;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  width: 15%;
  justify-content: center;
}

.pcr-inactive-btn-tab {
  background-color: #3ccfad;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  width: 15%;
  justify-content: center;
}

.report-table {
  padding: 5px 10px 10px;
}

.report-table .ant-table-thead .ant-table-cell {
  background-color: #f5f5f5 !important;
}

.client-source-report-table {
  padding: 5px 10px 10px;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.top-container {
  padding: 15px 6px 0px 6px;
  display: flex;
}
.table-card th {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px;
}
.table-filter .ant-table-filter-trigger-container {
  top: -15px;
}
.table-card td {
  font-family: "Poppins" !important;
  font-size: 12px;
}
.priority-text {
  font-size: 11px;
  margin-left: 5px;
  color: #687278;
  font-weight: 500;
}
/* .table-filter tbody .ant-table-measure-row{
  display: none!important;
} */

.priority-count-text {
  font-size: 8px;
  color: #687278;
}
.c-footer-button button {
  background: #3c79b0 !important;
  border-color: #3c79b0 !important;
}
#rcDialogTitle0 {
  font-size: 14px !important;
}
.dashboard-table {
  width: calc(100% - 40px);
  padding: 20px;
}

.dsh-table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fb;
  border-bottom: 0.5px solid #e9eaec;
}

.dsh-header-font {
  font-size: 10px;
  font-weight: 500;
}

.priority-high-border {
  width: 12px;
  height: 12px;
  border: 0.5px solid #d62f1d;
  border-radius: 50%;
}

.priority-high-filled {
  width: 12px;
  height: 12px;
  background-color: #d8544f;
  border-radius: 50%;
  border: 0.5px solid #d62f1d;
}

.priority-medium-border {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f0a93b;
  border-radius: 50%;
}

.priority-medium-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f0a93b;
  border-radius: 50%;
  background-color: #efae4d;
}

.priority-low-border {
  width: 12px;
  height: 12px;
  border: 0.5px solid #55b451;
  border-radius: 50%;
}

.priority-low-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #55b451;
  border-radius: 50%;
  background-color: #5ab859;
}

.todays-tasks-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f5e1af;
  border-radius: 50%;
  background-color: #f6e4b6;
}

.pending-tasks-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f6af92;
  border-radius: 50%;
  background-color: #f7b69c;
}

.offshore-clients-filled {
  width: 12px;
  height: 12px;
  border: 0.5px solid #f6d3fd;
  border-radius: 50%;
  background-color: #f8d5ff;
}

.clients-count-cont {
  box-shadow: 0px 0px 5px 0px rgba(232, 229, 232, 1);
  padding: 5px 7px;
  display: flex;
  align-items: center;
}

.setting-export-cont {
  padding: 5px;
  background-color: #35b1e4;
  border-radius: 4px;
  display: flex;
  margin-left: 5px;
}

.setting-export-img {
  width: 15px;
  height: 15px;
}

.dsh-content-text {
  font-size: 10px;
  color: #687278;
}

.size-row {
  display: flex;
  margin: 25px 20px 0px 20px;
  align-items: center;
}

.size-font {
  font-size: 12px;
  font-weight: 600;
  color: #555555;
}

.count-text-cont {
  display: flex;
  margin-left: 10px;
  margin-top: 4px;
}

.content-drpdown {
}
/* .cp-top-bar {
  background-color: #127eb6 !important;
}
.cp-top-bar-text {
  color: white;
  font-size: 14px;
} */
.table-card {
  display: block !important;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 0px 0px 20px 15px;
  padding-bottom: 5px;
}
/* .site-layout {
  position: relative !important;
} */
.close-sidebar {
  width: 89%;
  margin-left: 84px;
  margin-top: 0px;
  z-index: 1;
  width: 100% !important;
  width: -webkit-fill-available !important;
  width: fill-available !important;
}
.open-sidebar {
  margin-top: 0px;
}
.priority-count-text {
  font-size: 10px;
  color: #687278;
}
.count-text-cont {
  display: flex;
  margin-left: 20px;
  margin-top: 4px;
}
.priority-cont {
  margin-left: 20px;
}
.priority-button {
  width: 16px;
  height: 16px;
  float: left;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid;
  margin: 2px;
}
.grid-view .ant-form-item {
  width: 25%;
  display: inline-grid !important;
}
.c-footer-button {
  display: block !important;
  width: 100% !important;
  text-align: end;
}
.offshore {
  background-color: #f8d5ff !important;
  cursor: pointer !important;
}
.dueTask {
  background-color: #f7b69c !important;
  cursor: pointer;
}
.followDate .ant-picker {
  width: 120px !important;
}
.ant-table-row .dueTask {
  cursor: pointer !important;
}
.ant-table-row .cursorPointer {
  cursor: pointer !important;
}
.grid-view .ant-col-16 {
  max-width: 100% !important;
}
.modalTable {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.modalTable .cm-table-content-row {
  margin: 15px 10px;
}
.email-table {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin: 10px 0;
}
.email-table .ant-table-cell {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #e3e3e3 !important;
  width: 250px;
}
.email-table table {
  background-color: #f5f5f5;
}
.file-note-modal .ant-col {
  max-width: 100% !important;
}
.file-note {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
}
.file-note-list {
  border: 1px solid #e3e3e3;
  padding: 5px;
}
.addPointer {
  cursor: pointer !important;
}
/* .ant-table table {
  border-spacing: 0 10px;
} */
.today-task-reminders {
  background-color: #f6e4b6 !important;
  cursor: pointer;
}
.client-user-cont {
  margin-right: 7px;
  border: 1px solid #d6d6d6;
  display: flex;
  padding: 1px 3px;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 3px;
  cursor: pointer;
}
/* .ant-table-measure-row {
  display: none !important;
} */
.ant-picker-content th:first-child {
  padding-left: 8px !important;
}
.ant-picker-content td {
  text-align: center;
}
.table-filter .ant-table-filter-column-title {
  padding: 0px 2.3em 16px 16px !important;
}

.dashboard_container .hide_btn {
  font-size: 12px;
  font-family: "Poppins";
  /* text-transform: uppercase; */
  text-align: center;
  padding: 0 4px;
  height: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  cursor: default;
  border: none;
  box-shadow: none;
  outline: 0;
  color: #fff;
  background-color: #46b8da;
  transition: all 0.2s ease;
  margin-top: 5px;
  width: 50%;
  margin-left: auto;
}
.task-blue {
  background: #1281b9 !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.task-blue {
  background: #1281b9 !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.cv-normal-text {
  word-break: break-all !important;
  text-overflow: inherit !important;
}

.modalTable {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 15px;
}
.modalTable .cm-table-content-row {
  margin: 15px 10px;
}
.file-note-modal .ant-col {
  max-width: 100% !important;
}
.file-note {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
}
.file-note-list {
  border: 1px solid #e3e3e3;
  padding: 5px;
}
.potential-client-select .ant-select-selector {
  background-color: #4e7eea !important ;
  color: white !important;
}
.addCursor {
  cursor: pointer !important;
}
.potential-client-select .ant-select-selection-placeholder {
  color: white !important;
}

.addCursor {
  cursor: pointer !important;
}
.emp-contact-form .ant-table {
  background: #f5f5f5 !important;
}
.emp-contact-form .contact-Head {
  padding: 5px;
  margin: 0 !important;
  background: #f5f5f5 !important;
}
.emp-contact-form .contact-emp {
  margin: 0 !important;
}
.file-note-modal .ant-col {
  max-width: 100% !important;
}

.bi-card {
  margin: 10px !important;
  border-radius: 4px !important;
  border: none !important;
  background: white !important;
}
.bi-card .ant-card-body {
  padding: 0 !important;
}
.bi-card .ant-card-extra {
}
.bi-count-circle {
  width: 40px;
  height: 40px;
  line-height: 2.8;
  text-align: center;
  border-radius: 50%;
}
.color1 {
  background: #f26d7d;
}
.color2 {
  background: #3eceae;
}
.color3 {
  background: #f69679;
}
.color4 {
  background: #9f4bcd;
}
.color5 {
  background: #7cc576;
}
.color6 {
  background: #2180fe;
}
.bi-date {
  width: 140px !important;
  border-radius: 4px !important;
}
.bi-date1 {
  width: 110px !important;
  border-radius: 4px !important;
}
.abc .apexcharts-legend-series {
  margin: 2px 3px !important;
  width: 130px;
  background: #eee;
  padding: 30px 15px;
}
.abc .apexcharts-legend-text {
  color: rgb(55, 61, 63);
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica, Arial, sans-serif;
}
.abc .apexcharts-legend {
  padding: 0 !important;
}
/* .abc .apexcharts-legend-marker{
  background: rgb(0, 143, 251) !important;
    color: rgb(0, 143, 251);
    height: 12px;
    width: 12px;
    right: 10px;
    top: 10px;
    position: absolute;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 12px;
} */
.mixed-chart svg {
  overflow: visible !important;
}
#apexchartsbasicxbar svg {
  overflow: visible !important;
}
.client-ico-download {
  right: 0px;
  top: -48px;
  position: absolute;
}
.visa-ico-download {
  right: 0px;
  top: -48px;
  position: absolute;
}
.idle-ico-download {
  right: 0px;
  top: -55px;
  position: absolute;
}
.status-ico-download {
  right: 0px;
  top: -45px;
  position: absolute;
}
.bi-card .ant-card-head-title {
  color: #0f82b8;
  font-weight: 600;
  font-style: italic;
}
.bi-card .ant-card-extra {
  padding: 0;
}
.DashboardBiWrapper .ant-btn-primary {
  color: #fff !important;
  background: #1281b9 !important;
  border-color: #1281b9 !important;
  padding: 0px 15px !important;
  border-radius: 5px !important;
  font-size: 13px !important;
}
.DashboardBiWrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(4, 61, 91);
  font-weight: 600;
  font-size: 14px;
  font-family: "POPPINS";
}
.ant-tabs-tab:hover {
  color: #1281b9;
}
.DashboardBiWrapper .ant-tabs-ink-bar {
  background: rgb(4, 61, 91) !important;
}
.task-to-do {
  background-color: #ffff;
  padding: 21px;
  margin: 0px 20px 40px 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 15%);
  width: 100%;
}
.donut svg {
  overflow: visible !important;
}
.add-pointer {
  cursor: pointer !important;
}
.bi-table-card {
  background: #fff;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 5px;
}
.bi-table-card .heading-bi {
  background: #f5f5f5;
  padding: 12px 10px;
  border-radius: 4px 0px 4px 0px;
}

.remove-account {
  position: absolute !important;
  right: 10px;
  top: 3px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #000 !important;
  font-weight: bold;
}
.userDetail {
  position: relative !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.lgin-text {
  text-align: center;
  font-size: 25px;
  margin: 10px 10px 15px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lgin-text-main {
  margin-left: 17px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lg-item-center {
  display: flex;
  justify-content: center;
}

.lg-blue-normal-text {
  color: #1684ba;
  margin-left: 0px;
  font-size: 10px;
  text-decoration: underline;
  font-family: "Poppins", sans-serif !important;
}

.lg-copyright-text {
  font-size: 8px;
  text-align: center;
  color: #5b5b5b;
  font-family: "Poppins", sans-serif !important;
}

. {
  width: 550px;
  margin-top: 80px;
  margin-right: 30px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.lgin-text {
  text-align: center;
  font-size: 25px;
  margin: 10px 10px 15px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lgin-text-main {
  margin-left: 17px;
  font-weight: 500;
  color: #1d1d1d;
  font-family: "Poppins", sans-serif !important;
}

.lg-item-center {
  display: flex;
  justify-content: center;
}

.lg-blue-normal-text {
  color: #1684ba;
  margin-left: 0px;
  font-size: 10px;
  text-decoration: underline;
  font-family: "Poppins", sans-serif !important;
}

.lg-copyright-text {
  font-size: 8px;
  text-align: center;
  color: #5b5b5b;
  font-family: "Poppins", sans-serif !important;
}

. {
  width: 550px;
  margin-top: 80px;
  margin-right: 30px;
}

.tt-wt-heading {
  color: #6E6E6E;
  font-size: 10px;
  padding: 10px;
  border-bottom: 1px solid #BFBFBF;
}

.tt-wt-data-row {
  font-size: 10px;
  color: #6E6E6E;
  font-weight: 500;
  padding: 15px;
  border-bottom: 1px solid #CECECE;
  text-align: center;
}

.tt-vtp-text-border-cont {
  display: flex;
  border: 1px solid #BFBFBF;
  padding: 3px 20px 3px 5px;
  width: 70px;
  border-radius: 3px;
}

.ag-login-link-cont {
  border: 1px solid #40B5CE;
  padding: 5px;
  border-radius: 3px;
}

.ag-user-icon-cont {
  background-color: #BFBFBF;
  width: 100px;
  height: 75px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.icon-container {
  width: 20%;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
}
.docIcon {
  width: 26%;
}
.tooltip {
  visibility: hidden; /* Or display: none, depending on how you want it to behave */
}

.tooltip-on-hover:hover + .tooltip {
  /* Uses the adjacent sibling selector */
  visibility: visible; /* Or display: block */
}
.yellow_box {
  background-color: #f5d04c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.green_box {
  background-color: #5cb85c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.blue_box {
  background-color: #46b8da;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.yellow_span {
  background-color: #f5d04c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.green_span {
  background-color: #5cb85c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.blue_span {
  background-color: #46b8da;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.white_span {
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.ant-table .ant-table-selection-col {
  width: 12px !important;
}
.employer-table .ant-table-cell {
  text-align: center !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.employer-tables .ant-table-cell {
  text-align: left !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.ant-table-column-sorters {
  padding: 0px 0px !important;
}
.selectType {
  width: 100%;
}

.docOption-width {
  width: 150px;
}
.docId {
  width: 10%;
}
.docNameInput {
  width: 40%;
}
.docInput {
  width: 100%;
}

.docNameType {
  width: 20%;
  text-align: center !important;
}
.docNameTitle {
  width: 20%;
}
.docNameAction {
  width: 20%;
}

.cv-action-icons-borderDocType {
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 29px;
  width: 27px;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;
  margin: 2px;
}

.folderClass {
  padding: 15px;
  width: 15%;
  display: flex;
  flex-direction: column;
  white-space: normal;
}

.as-container {
  padding: 30px 35px 80px 35px;
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  border-radius: 2px;
}

.client-tag {
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 25%);
  background-color: #ffffff;
  margin: 35px 25px;
  height: 100%;
  border-radius: 3px;
}
.client-tag-form {
  width: 80%;
  /* background-color: red; */
  margin: auto;
}
.d-flex {
  display: flex;
}
.align-item {
  align-items: center;
}

.client-tag-top {
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  background-color: #ffffff;
  padding: 13px;
}
.client-tag-table .ant-table-wrapper {
  margin-top: 35px;
}
.client-tag-table .ant-table-tbody {
  padding: 8px 16px !important;
}

.client-section {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  height: 100%;
  padding: 45px 74px 74px 74px;
  margin: 35px auto;
  border-radius: 3px;
}

.client-top {
  width: 90%;
  margin: auto;
  padding-left: 23px;
  padding-top: 70px;
}
.top-text {
  /* font-size: 19px; */
  /* font-weight: 400; */
  margin-left: 20px;
  color: #5b6875;
}

.heading-forms {
  /* font-size: 19px; */
  /* font-weight: 400; */
  color: #172b4d !important;
  font-family: "Poppins", sans-serif !important;
}

.icons-client {
  width: 26px;
  cursor: pointer;
}
.add-tag-btn {
  display: flex;
  justify-content: flex-end;
}

.form-btn {
  float: right;
  margin: 10px 0px 0px 0px;
}

.ac-info-icon {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.ac-info-icon-email-temp {
  width: 20px;
  height: 20px;
  margin-top: -2vh;
}

.ac-back-icon {
  color: #1281b9 !important;
  font-size: 25px !important;
}

.icon-container {
  width: 20%;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
}
.docIcon {
  width: 26%;
}
.tooltip {
  visibility: hidden; /* Or display: none, depending on how you want it to behave */
}

.tooltip-on-hover:hover + .tooltip {
  /* Uses the adjacent sibling selector */
  visibility: visible; /* Or display: block */
}
.yellow_box {
  background-color: #f5d04c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.green_box {
  background-color: #5cb85c;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.blue_box {
  background-color: #46b8da;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.yellow_span {
  background-color: #f5d04c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.green_span {
  background-color: #5cb85c;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.blue_span {
  background-color: #46b8da;
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.white_span {
  width: 36px;
  height: 23px;
  border-radius: 22%;
  margin: 0 auto;
}
.ant-table .ant-table-selection-col {
  width: 12px !important;
}
.employer-table .ant-table-cell {
  text-align: center !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.employer-tables .ant-table-cell {
  text-align: left !important;
  padding: 4px !important;
  white-space: break-spaces !important;
}

.ant-table-column-sorters {
  padding: 0px 0px !important;
}
.selectType {
  width: 100%;
}

.docOption-width {
  width: 150px;
}
.docId {
  width: 10%;
}
.docNameInput {
  width: 40%;
}
.docInput {
  width: 100%;
}

.docNameType {
  width: 20%;
  text-align: center !important;
}
.docNameTitle {
  width: 20%;
}
.docNameAction {
  width: 20%;
}

.cv-action-icons-borderDocType {
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  align-items: center;
  height: 29px;
  width: 27px;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;
  margin: 2px;
}

.folderClass {
  padding: 15px;
  width: 15%;
  display: flex;
  flex-direction: column;
  white-space: normal;
}


.ts-container {
  background-color: #ffffff;
  margin: 50px 20px 20px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 30px 40px 30px;
  border-radius: 3px;
}

.ts-normal-text {
  color: #5b5b5b;
  font-size: 10px;
}

.ts-text-area-cont {
  width: 80%;
  padding: 8px 20px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin-top: 10px;
  margin-left: 10px;
}

.ts-upload-file-cont {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  height: 150px;
}

.ts-text-area {
  width: calc(100% - 5px);
  border: 1px solid #c8c8c8;
  border-top: 1.5px solid #c8c8c8;
  outline: none;
}

.ts-send-btn-cont {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.ts-send-btn {
  background-color: #1281b9 !important;
  padding: 5px 15px !important;
  border-radius: 3px !important;
  display: flex !important;
  justify-content: center !important;
  border-color: #1281b9 !important;
}

.m-container {
  background-color: #ffffff;
  margin: 20px 15px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding: 20px 35px 20px 20px;
  border-radius: 3px;
  border: 1px solid #a0a0a0;
}

.m-row-cont {
  display: flex;
  align-items: center;
}

.m-mehroon-circle {
  background-color: #9d0b0e;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.m-icon-border {
  border: 1px solid #bfbfbf;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}

.m-gray-cont {
  background-color: #f6f7f9;
  margin: 10px;
  border-radius: 3px;
  padding: 20px 30px 20px 20px;
  border: 1px solid #e6e6e6;
}

.m-calendar-input-row {
  display: flex;
  margin-top: 10px;
}

.m-white-cont {
  display: flex;
  justify-content: space-between;
  background-color: #fcfcfc;
  box-shadow: 0px 0px 5px 0px rgba(199, 195, 199, 1);
  width: calc(30% - 13px);
  padding: 10px 20px;
  margin-left: -20px;
  margin-top: 35px;
}

.m-card-cont {
  background-color: #fcfcfc;
  box-shadow: 0px 0px 5px 0px rgba(199, 195, 199, 1);
  width: 31%;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
}

.m-org-cont {
  background-color: #fbaf5c;
  display: flex;
  padding: 3px 5px;
  margin-left: 10px;
  border-radius: 5px;
}

.m-row-cont {
  display: flex;
  align-items: center;
}

.m-white-font {
  font-size: 10px;
  color: #ffffff;
}

.m-title-cont {
  padding: 10px 20px;
}

.m-org-border-cont {
  border: 1px solid #fbaf5c;
  padding: 5px;
  padding: 3px 10px;
  display: flex;
  border-radius: 3px;
  margin-left: 20px;
}

.m-popup-user-cont {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
}

.m-followers-row {
  display: flex;
  padding: 0px 20px;
  margin-bottom: 10px;
}

.m-client-btn-row-cont {
  display: flex;
  padding: 10px 40px;
}

.m-client-btn-cont {
  background-color: #1cbbb4;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  border-radius: 15px;
}

.m-client-btn-text {
  font-size: 12px;
  color: #ffffff;
  margin-left: 5px;
}

.pp-container {
  background-color: #fff;
  padding: 20px 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  /* -webkit-box-shadow: 0px 2px 7px 1px rgb(0 0 0 / 17%); */
  box-shadow: 0px 2px 7px 1px rgb(0 0 0 / 17%);
  /* -webkit-transition: all 0.7s ease; */
  transition: all 0.7s ease;
  display: flex;
  justify-content: space-between;
  padding: 15px 50px;
}

.pp-heading-text {
  font-size: 11pt;
  font-family: Arial;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  /* white-space: pre; */
  white-space: pre-wrap;
}

.pp-heading1-div {
  line-height: 1.38;
  margin-top: 0px;
  margin-bottom: 0px;
}

.pp-link-text {
  font-size: 11pt;
  font-family: Arial;
  color: #1155cc;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-decoration: underline;
  /* -webkit-text-decoration-skip: none; */
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  vertical-align: baseline;
  /* white-space: pre; */
  white-space: pre-wrap;
}

.sign-in-btn {
  font-family: Lato-B;
  font-size: 24px;
  color: #09587e;
  background-color: #fff;
  text-align: left;
  border: none;
  padding: 0;
  text-transform: uppercase;
  box-shadow: none;
  outline: 0;
  z-index: 9999;
  cursor: pointer;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.fa-sign-in:before {
  content: "\f090";
}

* {
  margin: 0;
  padding: 0;
  text-indent: 0;
}
.term-conditions {
}
.term-conditions .s1 {
  color: #2b5cac;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 20pt;
  padding-top: 3pt;
  text-indent: 0pt;
  text-align: center;
}
.term-conditions .s2 {
  color: #44536a;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.term-conditions h1 {
  color: #555;

  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.term-conditions p {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin: 0pt;
}
.term-conditions .p {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin: 0pt;
}
.term-conditions .s3 {
  color: #44536a;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s4 {
  color: #555;

  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.s5 {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s6 {
  color: #c00000;

  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}
.s8 {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s9 {
  color: #c00000;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.term-conditions .a {
  color: #555;
  /*  */
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
.s13 {
  color: #c00000;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
}
.s14 {
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}
li {
  display: block;
}
#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}
#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) " ";
  color: #1f487c;
  font-family: "Arial Black", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}
#l2 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l2 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l2 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l3 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l3 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l3 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l4 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l4 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l4 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l5 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l5 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l5 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l6 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l6 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l6 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l7 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l7 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l7 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l8 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l8 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l8 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l9 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l9 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l9 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l10 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l10 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l10 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l11 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l11 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l11 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l12 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l12 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l12 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l13 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l13 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l13 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l14 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l14 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l14 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l15 {
  padding-left: 0pt;
  counter-reset: d1 2;
}
#l15 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
  margin-right: 20px;
}
#l15 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}
#l16 {
  padding-left: 0pt;
  counter-reset: d2 1;
}
#l16 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d2, lower-latin) " ";
  color: #555;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  margin-right: 20px;
}
#l16 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}
#l17 {
  padding-left: 0pt;
  counter-reset: d2 1;
}
#l17 > li > *:first-child:before {
  counter-increment: d2;
  content: counter(d2, lower-latin) " ";
  color: #555;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  margin-right: 20px;
}
#l17 > li:first-child > *:first-child:before {
  counter-increment: d2 0;
}
#l18 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l18 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l18 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l19 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l19 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l19 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l20 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l20 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l20 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l21 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l21 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l21 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l22 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l22 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l22 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l23 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l23 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l23 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l24 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l24 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l24 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l25 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l25 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l25 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l26 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l26 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l26 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l27 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l27 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l27 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l28 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l28 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l28 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l29 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l29 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l29 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l30 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l30 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l30 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l31 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l31 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l31 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l32 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l32 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l32 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l33 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l33 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l33 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l34 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l34 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l34 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l35 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l35 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l35 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l36 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l36 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l36 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l37 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l37 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l37 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l38 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l38 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l38 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l39 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l39 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l39 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l40 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l40 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l40 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l41 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l41 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l41 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l42 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l42 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l42 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l43 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l43 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l43 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l44 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l44 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l44 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l45 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l45 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l45 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l46 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l46 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l46 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l47 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l47 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l47 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l48 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l48 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l48 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l49 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l49 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l49 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l50 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l50 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l50 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l51 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l51 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l51 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l52 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l52 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l52 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l53 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l53 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l53 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l54 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l54 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l54 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l55 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l55 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l55 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l56 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l56 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l56 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l57 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l57 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l57 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l58 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l58 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l58 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l59 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l59 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l59 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l60 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l60 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l60 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l61 {
  padding-left: 0pt;
  counter-reset: c4 1;
}
#l61 > li > *:first-child:before {
  counter-increment: c4;
  content: counter(c4, lower-roman) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l61 > li:first-child > *:first-child:before {
  counter-increment: c4 0;
}
#l62 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l62 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l62 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l63 {
  padding-left: 0pt;
  counter-reset: c2 1;
}
#l63 > li > *:first-child:before {
  counter-increment: c2;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l63 > li:first-child > *:first-child:before {
  counter-increment: c2 0;
}
#l64 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l64 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l64 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
#l65 {
  padding-left: 0pt;
  counter-reset: c3 1;
}
#l65 > li > *:first-child:before {
  counter-increment: c3;
  content: counter(c3, lower-latin) " ";
  color: #555;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
  margin-right: 20px;
}
#l65 > li:first-child > *:first-child:before {
  counter-increment: c3 0;
}
table,
tbody {
  vertical-align: top;
  overflow: visible;
}

.is1 {
  padding-top: 12pt;
  padding-left: 33pt;
  text-indent: -28pt;
  line-height: 117%;
  text-align: left;
}

