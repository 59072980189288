.MuiTypography-body2 {
  font-size: 12px !important;
}

.MuiListItem-dense {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.padding {
  padding-left: 76px !important;
}
.taskListUI {
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
.ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 10px !important;
  padding-right: 10px;
}
