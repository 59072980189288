.ad-row-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ad-paid-text-cont-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.ad-paid-text-cont {
  display: flex;
  border: 1px solid #ffffff;
  padding: 2px 10px;
  border-radius: 20px;
}

.ad-paid-text {
  color: #ffffff;
  font-size: 9px;
  font-weight: 600;
}

.ad-btn-row {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}

.ad-save-btn-cont {
  background-color: #1281b9;
  padding: 5px 5px;
  border-radius: 3px;
  display: flex;
  width: 40px;
  justify-content: center;
}

.ad-save-btn-text {
  font-size: 10px;
  color: #ffffff;
}

.ad-pp-heading-text {
  color: #1282ba;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
}

.ad-table-content {
  font-size: 10px;
  color: #6e6e6e;
  font-weight: 500;
  padding: 7px 15px;
  border-bottom: 1px solid #ededed;
}

.ad-form-container {
  background-color: #f5f7f8;
  margin: 20px 10px 0px 10px;
  border-radius: 5px;
  padding: 5px 20px 15px;
}

.ad-input-row {
  display: flex;
  justify-content: space-between;
}

.ad-date-row {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.ad-date-text {
  font-size: 10px;
  color: #ffffff;
}

.ad-upd-button {
  border: 1px solid #40b5ce !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  background-color: transparent !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ad-right-sidebar-hide {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.ad-right-sidebar-hide-text {
  color: #53a8cc;
  font-size: 13px;
  font-weight: 500;
}

.ad-button-width {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
