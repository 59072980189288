.sus-container {
  background-color: #ffffff;
  margin: 0px 14px 20px 14px;
  box-shadow: 0px 0px 22px -13px rgba(0, 0, 0, 0.75);
  padding-bottom: 20px;
  border-radius: 2px;
}

.sus-content-card-container {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

@media (max-width: 1024px) {
  .sus-content-card-container {
    display: block;
  }
}

.sus-right-cont {
  width: 29%;
}

@media (max-width: 1024px) {
  .sus-right-cont {
    margin: 40px;
    width: calc(100% - 45px);
  }
}

.sus-form-container {
  background-color: #f5f7f8;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
  width: 60%;
}

.sus-pi-form-container {
  background-color: #f5f7f8;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
  width: 62%;
}

.sus-cs-form-container {
  background-color: #f5f7f8;
  margin: 0px 20px 0px 20px;
  border-radius: 5px;
  padding-bottom: 20px;
}

@media (max-width: 1024px) {
  .sus-pi-form-container {
    width: 80%;
  }
  .sus-cs-form-container {
    width: 80%;
  }
}

.sus-tab-container {
  display: flex;
  margin: 30px 0px 0px 15px;
}

.sus-active-tab {
  background-color: #458ccc;
  padding: 5px 15px;
  display: flex;
  border: 1px solid #458ccc;
  justify-content: center;
  cursor: pointer;
}

.sus-active-tab-text {
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
}

.sus-inactive-tab {
  border: 1px solid #458ccc;
  padding: 5px 10px;
  display: flex;
  background-color: #ebebeb;
  justify-content: center;
  cursor: pointer;
}

.sus-inactive-tab-text {
  font-size: 10px;
  font-weight: 500;
  color: #898989;
}

.sus-inactive-tab-text-school {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
}

.sus-profile-cont {
  border-radius: 50%;
  display: flex;
  width: 120px;
  height: 120px;
}

.sus-profile-img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.sus-form-row-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sus-cp-text {
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 500;
}

.sus-cp-btn {
  background-color: #f7f7f7 !important;
  display: flex !important;
  padding: 7px 10px !important;
  border: 1px solid #acacac !important;
  box-shadow: 0px 0px 5px 2px rgba(204, 202, 204, 1) !important;
}

.sus-cp-btn-text {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 600;
}

.sus-save-btn {
  background-color: #1081ba !important;
  border-color: #1081ba !important;
  display: flex !important;
  padding: 5px !important;
  border-radius: 5px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.sus-add-btn {
  background-color: #1081ba !important;
  border-color: #1081ba !important;
  display: flex !important;
  padding: 5px 15px !important;
  border-radius: 5px !important;
  height: auto !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.sus-save-btn-text {
  font-size: 8px;
  color: #ffffff;
  font-weight: 600;
}

.sus-card-header {
  display: flex;
  justify-content: space-between;
  background-color: #448ccb;
  padding: 10px;
}

.sus-card-cont {
  width: 100%;
  border: 1px solid #e9eaec;
  border-radius: 10px;
  overflow: hidden;
  height: 185px;
  background-color: #fcfcfc;
}

.sus-card-header-text {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.sus-card-header-small-text {
  color: #ffffff;
  font-size: 12px;
}

.sus-bar-cont {
  padding: 30px 25px 0px;
}

.sus-card-content-cont {
  display: flex;
  justify-content: space-between;
  padding: 15px 35px;
}

.sus-card-content-text {
  color: #848484;
  font-size: 13px;
  font-weight: 500;
}

.sus-logo-img {
  padding: 25px 0px;
}

.sus-bottom-btn-cont {
  display: flex;
  margin: 45px 60px;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 25px;
  width: 350px;
  cursor: pointer;
}

.sus-bottom-icon-cont {
  box-shadow: 0px 0px 5px 0px rgba(227, 223, 227, 1);
  display: flex;
  padding: 5px 10px;
  border-radius: 3px;
}

.sus-bottom-icon {
  width: 35px;
}

.sus-bottom-text {
  /* margin-left: 20px; */
  color: #3f3d3e;
  font-size: 15px;
}

.sus-add-cont-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: -30px;
}

.sus-add-cont {
  background-color: #2397f4;
  display: flex;
  padding: 5px;
  border-radius: 50%;
  align-items: center;
  width: 15px;
  height: 10px;
  justify-content: center;
  padding-bottom: 10px;
  align-self: flex-end;
}

.sus-plus {
  font-size: 24px;
  color: #ffffff;
}

.sus-ppg-text {
  color: #828282;
  font-size: 14px;
  margin-left: 10px;
}

.sus-table {
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  background-color: #ffffff;
}

.sus-table-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #d3d3d3;
}

.sus-head-text-cont {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sus-head-text {
  font-size: 14px;
  font-weight: 600;
  color: #828282;
}

.sus-table-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.sus-content-text {
  font-size: 14px;
  color: #828282;
}

.sus-modal-label {
  padding: 0px 60px 10px 0px;
  border-bottom: 1px solid #d3d3d3;
  margin: 0px 0px 20px 10px;
}

.sus-modal-label-text {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.sus-modal-button {
  background-color: #1281b9;
  padding: 5px 15px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

.sus-modal-button-text {
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
}

.submit-support-button-text {
  background: #1179af;
  cursor: pointer;
}

.sus-disable-cont {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.sus-disable-text {
  font-size: 10px;
  color: #0e7ccf;
}

.sus-disable-icon {
  width: 12px;
  height: 12px;
  margin-left: 15px;
  transform: rotate(90deg);
}

.sus-member-card-cont {
  margin: 25px 20px 0px 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(230, 230, 230, 0.75);
  padding: 15px 20px 0px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
  width: 100%;
}

.sus-permission-card-cont {
  margin: 25px 0px 0px 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(230, 230, 230, 0.75);
  padding: 15px 20px 0px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
}

.sus-show-btn {
  background-color: #dce0e3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  padding: 5px 10px 0px;
  cursor: pointer;
}

.sus-checkbox-row {
  display: inline-block;
  justify-content: space-between;
  margin-top: 20px;
}
.sus-days-checkbox-row {
  display: inline-block;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: -50px;
}

.sus-checkbox-cont {
  display: inline-block;
  align-items: center;
  width: 33%;
  padding: 2px;
}

.sus-checkbox-cont .sus-checkbox-label .ant-checkbox + span {
  margin-top: 10px;
}
.sus-days-checkbox-cont {
  display: inline-block;
  align-items: center;
  min-width: 18px;
}

.sus-checkbox-label {
  color: #6d6d6d !important;
  font-size: 11px !important;
  margin-left: 5px !important;
  font-weight: 500 !important;
  display: flex !important;
  /* align-items: center !important; */
}

.sus-team-memb-setting {
  width: calc(65% - 45px);
  margin-left: 45px;
}

@media (max-width: 1024px) {
  .sus-team-memb-setting {
    width: calc(100% - 110px);
    padding-top: 20px;
  }
}

.sus-cb-row {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: 20px;
}

.sus-cb-hr-line {
  width: 65%;
  border: 0.5px solid #b6b6b6;
  height: 0px;
  margin: 0px 10px;
}

.sus-cb-text {
  color: #545454;
  font-size: 14px;
  font-weight: 600;
}

.sus-bn-logo {
  color: #1885bb;
  font-weight: 800;
  font-size: 18px;
}

.sus-member-card-bgcolor {
  background-color: rgb(242, 242, 242);
  margin: 20px -45px 20px -15px;
  padding: 35px;
  border: 1px solid #b7b7b7;
}

.sus-bs-right-card-cont {
  margin-top: 40px;
  background-color: #ffffff;
  padding: 30px;
}

@media (max-width: 1024px) {
  .sus-bs-right-card-cont {
    width: calc(100% - 110px);
    margin: 40px;
  }
}

.sus-modal-scroll {
  height: 450px;
  overflow-y: scroll;
}

.sus-add-processing-person-form {
  background-color: #f5f7f8;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #bfbfbf;
}

.css-26l3qy-menu {
  index: 999 !important;
}

.sus-branch-hide-cont {
  display: flex;
  justify-content: flex-end;
  margin-right: -30px;
  margin-top: -10px;
}

.sus-branch-hide-text {
  font-size: 10px;
  color: #494949;
  font-weight: 500;
}

.ant-select-selector {
  border-radius: 4px !important;
  border: 0.8px solid #c9c9ca !important;
}

.ant-upload-picture-card-wrapper {
  width: 100% !important;
}

/*.ant-upload {*/
/*  margin-top: 10px !important;*/
/*  margin-left: 10px !important;*/
/*  overflow: hidden !important;*/
/*  border: 0px !important;*/
/*}*/

.css-2613qy-menu {
  z-index: 999 !important;
  max-height: 100px !important;
}

.color-picker-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}
